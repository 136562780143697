import Vue from 'vue';
import Router from 'vue-router';


import  CoreRoutes from '@/core/routes';
import  CustRoutes from '@/cust/routes';

import HelpPageView from '@/core/views/help/help-page.view';
import ViewNotFoundView from '@/views/view-not-found.view';


import get from 'lodash/get';


Vue.use(Router)


// =======================================================
// Assemble routes
// -------------------------------------------------------
// Core and custom routes are defined in separate modules.
// These modules will be merged by the code below.
// =======================================================
const allRoutes = [];

// CORE ROUTES
// These are the routes provided by the core product. They will always be added.
allRoutes.push(...CoreRoutes);

// CUSTOMER-SPECIFIC ROUTES
// Add customer specific routes 
// Be aware that customer specific routes can replace built-in core routes when they use the same ID. 
allRoutes.push(...CustRoutes);

// DEFAULT VIEWS
// These views are defined client-wide for certain fallback scenarios.
// Be aware that those path regexes are pretty general in nature - they should always be defined last 
// to ensure that no core or custom regex is "overwritten".

allRoutes.push({
    path: '/help/:file*',
    name: 'help-page',
    component: HelpPageView
})



allRoutes.push({
    path: '/*',
    name: 'view-not-found',
    component: ViewNotFoundView
});


// The actual router is configured centrally.
const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: allRoutes,

    scrollBehavior: () => {
        return { x: 0, y: 0 }
    }
});


// =======================================================
// Configure Guards
// -------------------------------------------------------
// Configure global guards 
// =======================================================

// RBAC Guard
// Enforce Role Base Access Control

router.beforeResolve((to, from, next) => {
    let authorization = get(to, 'meta.authorization', { roles: [] });
    Vue.$log.debug('Required Roles: ', authorization.roles);

    //next();


    if (Vue.$user.hasAllRoles(authorization.roles)) {
        next();
    } else {
        next({ name: 'view-not-found' });
    }

});



export default router;

