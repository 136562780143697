import api from '@/store/api';
import { get, set } from 'lodash';

export default {

    namespaced: true,

    state : {
        accessControlList: {},
        collectionSettings: [],
    },

    mutations: {

        setAccessControlList(state, accessControlList) {
            set(state, 'accessControlList', accessControlList);
        },

        setCollectionSettings(state, collectionSettings) {
            set(state, 'collectionSettings', collectionSettings);
        }
    },

    actions: {
    
        async loadData(context, { name }) {

            // First load the ACL
            try {
                let response = await api.userMgmt.accessControlLists.findById(name);
                context.commit('setAccessControlList', get(response, 'data.accessControlList', {}));
            } catch (error) {
                context.commit('setAccessControlList', {});
                throw error;
            }

            // The list of collection settings is required for the purpose of configuration.
            try {
                let response = await api.catalog.collectionSettings.findAll();
                
                const collectionSettings = [];
                for (const item of response) {
                    collectionSettings.push(item.collectionSettings);
                }

                context.commit('setCollectionSettings', collectionSettings);
            } catch (error) {
                context.commit('setCollectionSettings', []);
                throw error;
            }

        },

        
        async saveData(context) {

            try {

                const payload = context.getters.payload;
                //console.log(">>> VUEX SAVE DATA ", JSON.stringify(payload));
                let response = await api.userMgmt.accessControlLists.update(payload.name, payload);
                context.commit('setAccessControlList', get(response, 'data.accessControlList', {}));

            } catch (error) {
                context.commit('setAccessControlList', {});
                throw error;

            }
        },

        updateModel(context, model) {
            console.log(' +++ ', JSON.stringify(model, true, 2))
            context.commit('setAccessControlList', get(model, 'accessControlList', {}));
        },

        updateAccessControlList(context, accessControlList) {
            context.commit('setAccessControlList', accessControlList);
        }
        
    },

    getters: {

        model(state) {

            return {
                accessControlList: state.accessControlList,
                collectionSettings: state.collectionSettings,
            };
        },

        payload(state) {
            let payload = get(state, 'accessControlList', {});
            payload.creationDateTime ? set(payload, "creationDateTime", payload.creationDateTime) : set(payload, "creationDateTime", null);
            payload.creationUser ? set(payload, "creationUser", payload.creationUser) : set(payload, "creationUser", null);
            payload.modificationDateTime ? set(payload, "modificationDateTime", payload.modificationDateTime) : set(payload, "modificationDateTime", null);
            payload.modificationUser ? set(payload, "modificationUser", payload.modificationUser) : set(payload, "modificationUser", null);
            return payload;
        }
    }
}
