<template>
    <v-card flat tile>
        <v-card-text  class="warning ma-3" v-if="!active">      
            <div class="text-body-2 white--text">
                <v-icon color="white" class="pr-3">warning</v-icon>
                {{$t('webhook-subscription-editor.component.warnings.notActive')}}
            </div>
        </v-card-text>


        <v-card-text>
            <div class="text-h6">{{$t('webhook-subscription-editor.component.sections.general.title')}}</div>
            <div>{{$t('webhook-subscription-editor.component.sections.general.subtitle')}}</div>
        </v-card-text>
        <v-container fluid>
            <v-row>
                <v-col cols="12">
                    <v-text-field v-model="name" filled disabled></v-text-field>
                    <v-text-field 
                        v-model="eventTypeSelector" 
                        :label="$t('webhook-subscription-editor.component.sections.general.fields.eventSelector.label')" 
                        :rules="[ e => !!e || $t('webhook-subscription-editor.component.sections.general.fields.eventSelector.validation.notEmpty')]"
                        filled 
                    />
                    <v-switch 
                        v-model="active" 
                        :label="$t('webhook-subscription-editor.component.sections.general.fields.active.label')" 
                        inset 
                        color="accent" 
                    />
                </v-col>
            </v-row>
        </v-container>
        <v-divider />
        <v-card-text>
            <div class="text-h6">{{$t('webhook-subscription-editor.component.sections.destination.title')}}</div>
            <div>{{$t('webhook-subscription-editor.component.sections.destination.subtitle')}}</div>
        </v-card-text>
        <v-container fluid>
            <v-row>
                <v-col cols="12">
                    <c-item-selector 
                        v-model="method" 
                        :items="httpMethods" 
                        filled 
                        :label="$t('webhook-subscription-editor.component.sections.destination.fields.method.label')" 
                        :rules="[v => !!v ||  $t('webhook-subscription-editor.component.sections.destination.fields.method.validation.notEmpty')]"
                        :return-object="false" />
                    <v-text-field 
                    v-model="urlTemplate" 
                    :label="$t('webhook-subscription-editor.component.sections.destination.fields.urlTemplate.label')" 
                    :hint="$t('webhook-subscription-editor.component.sections.destination.fields.urlTemplate.hint')"
                    persistent-hint
                    :rules="[v => !!v ||  $t('webhook-subscription-editor.component.sections.destination.fields.urlTemplate.validation.notEmpty')]"
                    filled></v-text-field>
                    <v-expansion-panels>
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                <div><v-icon color="accent" class="mr-2">help</v-icon>{{$t('webhook-subscription-editor.component.sections.mappingRules.writeRule')}}</div>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                        
                                <v-card tile flat>
                                    <v-card-text>
                                        {{$t('webhook-subscription-editor.component.sections.mappingRules.information')}}
                                    </v-card-text>
                                    <dxs-webhook-expression-help :value="{source, target, custData}" />
                                </v-card>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-col>
            </v-row>
            
            <v-row>
                <v-col cols="12">
                    <v-text-field 
                        v-model="timeoutMilliseconds"
                        type="number"
                        :label="$t('webhook-subscription-editor.component.sections.destination.fields.timeout.label')" 
                        :hint="$t('webhook-subscription-editor.component.sections.destination.fields.timeout.hint')"
                        :rules="[v => (v >= 2500 &&v <= 25000) ||  $t('webhook-subscription-editor.component.sections.destination.fields.timeout.validation.valueRange')]"
                        persistent-hint 
                      
                        filled></v-text-field>

                    <v-text-field 
                        v-model="retries"
                        type="number"
                        :label="$t('webhook-subscription-editor.component.sections.destination.fields.retries.label')" 
                        :hint="$t('webhook-subscription-editor.component.sections.destination.fields.retries.hint')"
                        :rules="[v => (v >= 0 && v <= 15) ||  $t('webhook-subscription-editor.component.sections.destination.fields.retries.validation.valueRange')]"
                        persistent-hint
                        filled></v-text-field>
                </v-col>
            </v-row>
        </v-container>

        <v-card-text>
            <div class="text-h6">{{$t('webhook-subscription-editor.component.sections.header.title')}}</div>
            <div>{{$t('webhook-subscription-editor.component.sections.header.subtitle')}}</div>
        </v-card-text>
        <v-container fluid>
            <c-params-editor 
                header-style="lite"
                flat
                :items="headers" 
                editDialogStyle="fullscreen"
                :disableAddItem="!hasCreateRole"
                :disableEditItem="!hasEditRole"
                :disableDeleteItem="!hasDeleteRole"
                paramNameRegex=".*"
                @item-updated="update"
                @item-removed="remove">
            
                <template v-slot:item-list-editor-additional-content>
                    <v-card>
                        <v-card-text>
                            <div><v-icon color="accent" class="mr-2">help</v-icon>{{$t('webhook-subscription-editor.component.sections.mappingRules.writeRule')}}</div>
                        </v-card-text>
                        <v-card-text>
                            {{ $t('webhook-subscription-editor.component.sections.destination.fields.headers.hint') }}
                        </v-card-text>        
                        <v-card tile flat>
                            <v-card-text>
                                {{$t('webhook-subscription-editor.component.sections.mappingRules.information')}}
                            </v-card-text>
                            <dxs-webhook-expression-help :value="{source, target, custData}" />
                        </v-card>
                    </v-card>
                </template>

            </c-params-editor> 
        </v-container>
        
        <v-divider />
        <v-card-text>
            <div class="text-h6">{{$t('webhook-subscription-editor.component.sections.trust.title')}}</div>
            <div>{{$t('webhook-subscription-editor.component.sections.trust.subtitle')}}</div>
        </v-card-text>
        <v-container fluid>
            <v-row>
                <v-col cols="12">
                    <v-textarea 
                    v-model="clientSecret" 
                    :label="$t('webhook-subscription-editor.component.sections.trust.fields.clientSecret.label')" 
                    :rules="[v => !!v || $t('webhook-subscription-editor.component.sections.trust.fields.clientSecret.validation.notEmpty')]"
                    auto-grow 
                    filled></v-textarea>
                </v-col>

            </v-row>
        </v-container>
    </v-card>  
</template>

<script>
import { cloneDeep, get, set } from 'lodash';
import Roles from '@/roles';

const HTTP_METHODS = [
    { name: 'POST', value: 'POST', i18n : { en: { name: 'POST', description: 'The HTTP method POST will be used for the request.' }, de: { name: 'POST', description: 'Die Anfrage wird mit der HTTP-Methode POST verschickt.'}} },
    { name: 'PUT', value: 'PUT', },
    { name: 'PATCH', value: 'PATCH', },
    { name: 'DELETE', value: 'DELETE', },
];

import WebhookExpressionHelpComponent from './webhook-expression-help.component';

export default {
    
    name: 'webhook-subscriptions-editor-component',
    inject: ['userContextService'],
    components: {
        'dxs-webhook-expression-help' : WebhookExpressionHelpComponent
    },
    props: {
        value: {
            type: Object,
            required: true
        }
    },

    watch: {

        // Due to asynchronous behaviour we need to watch the value props in order to 
        // re-initialize the model.
        'value': {
            handler(value) {
                this.model = value ? cloneDeep(value) : {}
            }
        }
    },

    data() {

        return {
            model: this.value ? cloneDeep( this.value ) : {}
        };
    },

    methods: {

        fireInput() {

            // Always clone the model object in order to avoid problems
            // with call by reference
            const clone = cloneDeep(this.model);
            return this.$emit('input', clone);
        },

        update(event) {
            if(event){
                this.model.headers[event.item.name] = event.item.value; 
            }
            this.fireInput();
        },

        remove(event) {
            if(event){
                delete this.model.headers[event.item.name]; 
            }
            this.fireInput();
        },
    },

    computed: {

        name: {
            get() {
                return get(this.model, 'name', null);
            },

            set(value) {
                set(this.model, 'name', value);
                this.fireInput();
            }
            
        },

        eventTypeSelector: {
            get() {
                return get(this.model, 'eventTypeSelector');
            },

            set(value) {
                set(this.model, 'eventTypeSelector', value);
                this.fireInput();
            }
        },

        timeoutMilliseconds: {
            get() {
                return get(this.model, 'timeoutMilliseconds', 0);
            },

            set(value) {
                set(this.model, 'timeoutMilliseconds', value);
                this.fireInput();
            }
        },

        retries: {
            get() {
                return get(this.model, 'retries', 0);
            },

            set(value) {
                set(this.model, 'retries', value);
                this.fireInput();
            }
        },

        clientSecret: {
            get() {
                return get(this.model, 'clientSecret', 0);
            },

            set(value) {
                set(this.model, 'clientSecret', value);
                this.fireInput();
            }
        },

        method: {
            get() {
                return get(this.model, 'method', 0);
            },

            set(value) {
                set(this.model, 'method', value);
                this.fireInput();
            }
        },

        urlTemplate: {
            get() {
                return get(this.model, 'urlTemplate', 0);
            },

            set(value) {
                set(this.model, 'urlTemplate', value);
                this.fireInput();
            }
        },

        active: {
            get() {
                return get(this.model, 'active', 0);
            },

            set(value) {
                set(this.model, 'active', value);
                this.fireInput();
            }
        },

        headers() {
            let items = [];
            let map = this.model.headers
            for (let [key, value] of Object.entries(map)) {
                items.push({name: key, value: value});
            }
            return items;
        },

        httpMethods() {
            return HTTP_METHODS;
        },

        hasCreateRole() {
            return this.userContextService.hasRole(Roles.WEBHOOKS_SUBSCRIPTIONS_CREATE);
        },

        hasEditRole() {
            return this.userContextService.hasRole(Roles.WEBHOOKS_SUBSCRIPTIONS_EDIT);
        },

        hasDeleteRole() {
            return this.userContextService.hasRole(Roles.WEBHOOKS_SUBSCRIPTIONS_DELETE);
        },
    }
}
</script>

<i18n>
{
    "en" : {
        "webhook-subscription-editor.component.sections.general.title" : "General",
        "webhook-subscription-editor.component.sections.general.subtitle" : "This section contains general settings for the webhook subscription.",
        "webhook-subscription-editor.component.sections.general.fields.eventSelector.label" : "Event Selector",
        "webhook-subscription-editor.component.sections.general.fields.eventSelector.validation.notEmpty" : "The event selector must not be empty. Please provide a valid Event Selector.",
        "webhook-subscription-editor.component.sections.general.fields.active.label" : "Active",

        "webhook-subscription-editor.component.sections.destination.title" : "HTTP Destination",
        "webhook-subscription-editor.component.sections.destination.subtitle" : "This section contains settings about the destination: the system invokes this HTTP address when a subscribed event occurs.",
        "webhook-subscription-editor.component.sections.header.title" : "HTTP Header",
        "webhook-subscription-editor.component.sections.header.subtitle" : "This section contains settings about the header information: the system is capable of evaluating expression language in the value field of the headers.",
        "webhook-subscription-editor.component.sections.destination.fields.method.label" : "HTTP Method",
        "webhook-subscription-editor.component.sections.destination.fields.method.validation.notEmpty" : "The HTTP method must not be empty. Please select a HTTP method.",
        "webhook-subscription-editor.component.sections.destination.fields.urlTemplate.label" : "HTTP URL Template",
        "webhook-subscription-editor.component.sections.destination.fields.urlTemplate.hint" : "This field supports embedded script expressions inside of curly braces in order to generate the target URL dynamically, e.g. https://my.server/target/?userId{{$tenant.tenantId}}-user",
        "webhook-subscription-editor.component.sections.destination.fields.urlTemplate.validation.notEmpty" : "The HTTP URL Template must not be empty. Please enter a valid template expression.",
        "webhook-subscription-editor.component.sections.destination.fields.timeout.label" : "Timeout in Miliseconds",
        "webhook-subscription-editor.component.sections.destination.fields.timeout.hint" : "The system waits the specified duration in miliseconds for a response from the endpoint before terminating the request as erroneous. Up to 25,000 miliseconds of wait time is allowed.",
        "webhook-subscription-editor.component.sections.destination.fields.timeout.validation.valueRange" : "Timeouts must be between 2,500 and 25,000 miliseconds long. Negative and empty values are not allowed. Please adjust your input.",
        "webhook-subscription-editor.component.sections.destination.fields.retries.label" : "Retries on Error",
        "webhook-subscription-editor.component.sections.destination.fields.retries.validation.valueRange" : "You can set a maximum of 15 retries. Negative and empty values are not allowed. Please adjust your input.",
        "webhook-subscription-editor.component.sections.destination.fields.retries.hint" : "If an error occurs when sending the request, the system tries the configured number of retries. Up to 15 retries are allowed.",

        "webhook-subscription-editor.component.sections.destination.fields.headers.hint" : "The Value field may contain embedded script expressionsinside of curly braces in order to generate the field content dynamically, e.g. Basic {{ BASE64_ENCODE($tenant.custParams.user += ':' + $tenant.custParams.password)  }}",
        "webhook-subscription-editor.component.sections.trust.title" : "Trustworthiness",
        "webhook-subscription-editor.component.sections.trust.subtitle" : "Please define a client secret. The system sends a signature based on the secret when the destination is called, which your destination can use in order to verify the authenticity of the call. The client secret can be any text. We recommend a randomly generated UUID for this purpose.",
        "webhook-subscription-editor.component.sections.trust.fields.clientSecret.label"  : "Client Secret",
        "webhook-subscription-editor.component.sections.trust.fields.clientSecret.validation.notEmpty"  : "The Client Secret must not be empty. Please provide an arbitrary, non-empty alphanumeric string.",


        "webhook-subscription-editor.component.warnings.notActive" : "This Webhook Subscription is currently not active. No requests will be sent to this destination.",

        "webhook-subscription-editor.component.sections.mappingRules.writeRule" : "How to define template expressions",
        "webhook-subscription-editor.component.sections.mappingRules.information" : "Please select one of the topics below for detailed information"
    },

    "de" : {
        "webhook-subscription-editor.component.sections.general.title" : "Allgemein",
        "webhook-subscription-editor.component.sections.general.subtitle" : "Dieser Abschnitt enthält die allgemeinen Einstellungen für das Webhook-Abonnement.",
        "webhook-subscription-editor.component.sections.general.fields.eventSelector.label" : "Event-Selektor",
        "webhook-subscription-editor.component.sections.general.fields.eventSelector.validation.notEmpty" : "Der Event-Selektor darf nicht leer sein. Bitte geben Sie einen gültigen Selektor ein.",
         "webhook-subscription-editor.component.sections.general.fields.active.label" : "Aktiv",
        
        "webhook-subscription-editor.component.sections.destination.title" : "HTTP-Endpunkt",
        "webhook-subscription-editor.component.sections.destination.subtitle" : "Dieser Abschnitt enthält Einstellungen zum Ziel-Endpunkt: Das System ruft diese HTTP-Adresse auf, wenn ein abonniertes Ereignis eintritt.",
        "webhook-subscription-editor.component.sections.header.title" : "HTTP-Header",
        "webhook-subscription-editor.component.sections.header.subtitle" : "Dieser Abschnitt enthält Einstellungen zu optionalen headern. Template-Ausdrücke im Wertefeld werden hierbei ausgewertet",
        "webhook-subscription-editor.component.sections.destination.fields.method.label" : "HTTP-Methode",
        "webhook-subscription-editor.component.sections.destination.fields.method.validation.notEmpty" : "Die HTTP-Methode darf nicht leer sein. Bitte wählen Sie eine HTTP-Methode aus.",
        "webhook-subscription-editor.component.sections.destination.fields.urlTemplate.label" : "HTTP-URL-Template",
        "webhook-subscription-editor.component.sections.destination.fields.urlTemplate.hint" : "Dieses Feld unterstützt eingebettete Skriptausdrücke in geschweiften Klammern {{ }} zur dynamischen Generierung der URL, z.B. https://my.server/target/?userId{{$tenant.tenantId}}-user",
        "webhook-subscription-editor.component.sections.destination.fields.urlTemplate.validation.notEmpty" : "Das HTTP-UR-Template darf nicht leer sein. Bitte geben Sie einen gültigen Ausdruck ein.",
        "webhook-subscription-editor.component.sections.destination.fields.timeout.label" : "Timeout in Milisekunden",
        "webhook-subscription-editor.component.sections.destination.fields.timeout.hint" : "Das System wartet die angebenene Dauer in Milisekunden auf eine Antwort des Endpunkts, bevor die Anfrage als fehlerhaft abgebrochen wird. Bis zu 25.000 Milisekunden Wartzeit sind zulässig.",
        "webhook-subscription-editor.component.sections.destination.fields.timeout.validation.valueRange" : "Timeouts müssen zwischen 2.500 und 25.000 Milisekunden lang sein. Negative und leere Werte sind nicht zulässig. Bitte passen Sie Ihre Eingabe an.",
        "webhook-subscription-editor.component.sections.destination.fields.retries.label" : "Anzahl Versuche im Fehlerfall",
        "webhook-subscription-editor.component.sections.destination.fields.retries.validation.valueRange" : "Sie können maximal 15 Wiederholungen festlegen. Negative und leere Werte sind nicht zulässig. Bitte passen Sie Ihre Eingabe an.",
        "webhook-subscription-editor.component.sections.destination.fields.retries.hint" : "Wenn beim Senden der Anfrage ein Fehler auftritt, versucht das System die konfigurierte Anzahl an Wiederholungen. Bis zu 15 Wiederholungen sind zulässig.",

        "webhook-subscription-editor.component.sections.destination.fields.headers.hint" : "Das Feld Wert kann eingebettete Skript-Ausdrücke in geschweiften Klammern {{ }} enthalten, um den Inhalt des Felds dynamisch zu bestimmen, z.B. Basic {{ BASE64_ENCODE($tenant.custParams.user += ':' + $tenant.custParams.password)  }}",
        "webhook-subscription-editor.component.sections.trust.title" : "Vertrauenswürdigkeit",
        "webhook-subscription-editor.component.sections.trust.subtitle" : "Bitte definieren Sie ein Client Secret. Das System schickt beim Aufruf des Endpunkts eine Signatur auf Basis des Secrets, mit der Ihr Endpunkt die Authentizität des Aufrufs verifizieren kann. Das Client-Secret kann ein beliebiger Text sein. Wir empfehlen eine zufallsgeneriere UUID für diesen Zweck.",
        "webhook-subscription-editor.component.sections.trust.fields.clientSecret.label"  : "Client Secret",
        "webhook-subscription-editor.component.sections.trust.fields.clientSecret.validation.notEmpty"  : "Das Client Secret darf nicht leer sein. Bitte geben Sie eine Zeichenkette ein.",


        "webhook-subscription-editor.component.warnings.notActive" : "Dieses Webhook-Abonnement ist aktuell nicht aktiviert. Das System schickt keine Aufrufe an den Endpunkt. ",

        "webhook-subscription-editor.component.sections.mappingRules.writeRule" : "Anleitung für Template-Ausdrücke",
        "webhook-subscription-editor.component.sections.mappingRules.information" : "Wählen Sie bitte eines der untenstehenden Themen für detaillierte Informationen aus"
    }
}
</i18n>