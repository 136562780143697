<template>
  <v-card>
      <v-card-text>
          <div class="text-subtitle-1">Kategorie</div>
          <div class="text-h6">{{value | localized-name($i18n.locale) | truncate(24)}}</div>
          
      </v-card-text>
      <v-card-text>
          <template v-for="label of labels" >
            <v-chip label :key="'chip_' + label"  >{{label}}</v-chip> 
            &nbsp;
          </template>
      </v-card-text>
  </v-card>
</template>

<script>
export default {
    
    name: 'board-item-card',

    props: {


        value: {
            type : Object,
            required: true        
        }
    },

    computed: {


        labels() {
            return this.value && this.value.labels ? this.value.labels : [];
        }
    }
}
</script>

<style>

</style>