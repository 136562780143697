<template>
    <div>
        <c-section :title="$t('collection-settings-list.view.title')" :subtitle="$t('collection-settings-list.view.subtitle')"  class="secondary">
            <v-card class="mt-5 mb-5" tile>
                <c-item-list 
                   :title="$t('collection-settings-list.view.toolbar.title')" 
                   :items="items"
                   @refresh-list="refresh"
                   @item-added="onItemAdded" 
                   @item-edited="onItemEdited"  
                   @item-deleted="onItemDeleted"
                   @item-list-filter-applied="onFilterChanged"
                   :disable-add-item="!hasCreateRole" 
                   :disable-edit-item="!hasEditRole && !hasReadRole" 
                   :disable-delete-item="!hasDeleteRole"
                   supports-delete-item
                >
                
                    <template v-slot:item-list-table-head-columns>
                        <th class="text-center" >{{$t('collection-settings-list.view.columns.embeddedCollections')}}</th>
                        <!--<th class="text-center">{{$t('collection-settings-list.view.columns.apis')}}</th>-->
                        <th class="text-center">{{$t('collection-settings-list.view.columns.open')}}</th>
                        <th class="text-center">{{$t('collection-settings-list.view.columns.reindex')}}</th>
                    </template>

                    <template v-slot:item-list-table-body-columns="props">
                        <td class="text-center"><v-icon small v-if="hasEmbeddedCollections(props.item)">check_circle</v-icon></td>
                        <td class="text-center"><v-icon small @click="goToCollectionsView(props.item)">open_in_new</v-icon></td>
                        <!-- <td class="text-center"><v-btn icon @click="goToAPIView(props.item)"><v-icon small>api</v-icon></v-btn></td> -->
                        <td class="text-center"><v-icon small @click="showConfirmationDialog(props.item)">play_arrow</v-icon></td>
                    </template>

                    <template v-slot:delete-dialog="{item, onDeleteConfirmed, onDeleteCanceled}">
                        <v-card color="error pa-5">
                            <v-card-text class="text-h4 white--text">{{$t('collection-settings-list.view.dialogs.delete.title')}}</v-card-text>
                            <v-card-text class="white--text">{{$t('collection-settings-list.view.dialogs.delete.description', {name: $dxs.i18n.localizedName(item, $i18n.locale)})}}</v-card-text>
                            <v-card-actions>
                                <v-btn @click="onDeleteConfirmed">{{$t('collection-settings-list.view.dialogs.delete.actions.confirm')}}</v-btn>
                                <v-btn @click="onDeleteCanceled">{{$t('collection-settings-list.view.dialogs.delete.actions.cancel')}}</v-btn>
                            </v-card-actions>
                        </v-card>
                    </template>
                    
                </c-item-list>
                <v-card-text></v-card-text>
                <v-divider />
                <v-subheader class="grey lighten-5">{{$t('collection-settings-list.view.help.title')}}</v-subheader>
                <v-card-text class="grey lighten-5">{{$t('collection-settings-list.view.help.description')}}</v-card-text>
            </v-card>
            <!--
            <c-item-list-container :value="{ items: items}" @item-clicked="onItemClicked" @item-list-filter-changed="onFilterChanged" :loading="loading">

            </c-item-list-container>
            -->
            <v-dialog v-model="displayConfirmationDialog" width="420" >
                <v-card tile>
                    <v-card-title>
                        {{$t('collection-settings-list.view.reindex.dialog.title')}}
                    </v-card-title>
                    <v-spacer/>
                    <v-card-subtitle>
                        {{$t('collection-settings-list.view.reindex.dialog.subtitle')}}
                    </v-card-subtitle>
                    <v-card-actions class="text-right pa-5">
                        <v-spacer />
                        <v-btn text color="accent" @click="onConfirm">{{$t('collection-settings-list.view.reindex.dialog.confirm')}}</v-btn>
                        <v-btn text color="grey" @click="onCancel">{{$t('collection-settings-list.view.reindex.dialog.cancel')}}</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>


            

        </c-section>
        
    </div>
</template>

<script>
const MODULE_NAME = 'collectionSettingsList';
import Roles from '@/roles';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';

export default {

    inject: ['errorHandlerService', 'progressIndicatorService', 'userContextService'],

     data: () =>({

        collectionSetting : {},

        displayConfirmationDialog : false,
        
        displayDeleteDialog: false
        
     }),

    computed: {

        response() {
            return this.$store.getters[MODULE_NAME + '/response'];
        },

        items() {
            return this.response.items;
        },

        hasReadRole() {
            return this.userContextService.hasAllRoles(Roles.COLLECTION_SETTINGS_READ);
        },

        hasEditRole() {
            return this.userContextService.hasAllRoles(Roles.COLLECTION_SETTINGS_EDIT);
        },

        hasCreateRole() {
            return this.userContextService.hasAllRoles(Roles.COLLECTION_SETTINGS_CREATE);
        },

        hasDeleteRole() {
            return this.userContextService.hasAllRoles(Roles.COLLECTION_SETTINGS_DELETE);
        }
    },

    methods: {

        async refresh() {

            this.progressIndicatorService.show();
            try {
                await this.$store.dispatch(MODULE_NAME + "/loadResponse")
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }

        },

        hasEmbeddedCollections(collectionSetting) {
            return !_isEmpty(_get(collectionSetting, 'embeddedCollections', []));
        },

        onItemEdited(event) {

            if (event) {

                this.$router.push({
                    name: 'collectionSettingsEditor',
                    params: {
                        name : event.item.name
                    }
                });
            } else {
                this.$log.debug("Event object is undefined. Can't navigate to prediction scenario.")
            }
        },

        onItemAdded() {
            this.$router.push({ name: 'collectionSettingsWizardView'});
        },

        async onItemDeleted({ item }) {
            this.progressIndicatorService.show();
            try {

                // Delete the item, then reload the list in order to reflect change.
                await this.$store.dispatch(MODULE_NAME + "/deleteItem", item);
                await this.$store.dispatch(MODULE_NAME + "/loadResponse");
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },

        async onFilterChanged(event) {

            this.progressIndicatorService.show();
            try {
                await this.$store.dispatch(MODULE_NAME + "/updateFilter", event.filter)
                await this.refresh()
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },

        onConfirm() {
            this.startReindexCollection(this.collectionSetting);
            this.hideConfirmationDialog();
        },

        onCancel() {
           this.hideConfirmationDialog(); 
        },

        goToAPIView(collectionSetting) {
            this.$router.push({ 
                name: 'collectionSettingsAPI',
                params: {
                    name : collectionSetting.name
                }
            });
        },

        goToCollectionsView(collectionSetting){
            this.$router.push({ 
                name: 'collectionDocumentsView',
                params: {
                    collectionId : collectionSetting.name
                }
            });
        },

        async startReindexCollection(collectionSetting){
            let jobInstanceId = await this.$store.dispatch(MODULE_NAME + "/startReindexCollection", collectionSetting);

            // In case everything went as expected, navigate forward to the job status dashboard. 
                this.$router.push({
                    name: 'mappingJobInstanceDetails',
                    params: { name: jobInstanceId} 
                });
        },

        showConfirmationDialog(collectionSetting) {
            this.collectionSetting = collectionSetting;
            this.displayConfirmationDialog = true;
        },

        hideConfirmationDialog() {
            this.collectionSetting = {};
            this.displayConfirmationDialog = false;
        },

    },

    created() {
        this.refresh();
    }
}
</script>

<i18n>
{
    "en" : {
        "collection-settings-list.view.title" : "Collection Settings Management",
        "collection-settings-list.view.subtitle" : "You may use this dialog for creating, editing or deleting collection settings.",
        "collection-settings-list.view.toolbar.title" : "Collection Settings",

        "collection-settings-list.view.columns.open" : "Open",
        "collection-settings-list.view.columns.reindex" : "Reindex",
        "collection-settings-list.view.columns.apis" : "APIs",
        "collection-settings-list.view.columns.embeddedCollections" : "Embed. Coll.",
        "collection-settings-list.view.columns.systemCollection" : "System?",
        "collection-settings-list.view.columns.systemCollection.hint" : "System Collections are automatically provided by the platform in order to provided flexible access to technical data like dead letters, logs, etc.",

        "collection-settings-list.view.help.title" : "What are Collection Settings?",
        "collection-settings-list.view.help.description" : "Collection Settings are used for storing data documents. On the level of collection settings you can make settings regarding data governance and access.",

        "collection-settings-list.view.reindex.dialog.title" : "Reindex",
        "collection-settings-list.view.reindex.dialog.subtitle" : "The reindexing of a collection may take up to several minutes. Do you really want to start this process?",
        "collection-settings-list.view.reindex.dialog.confirm" : "Start now",
        "collection-settings-list.view.reindex.dialog.cancel" : "Cancel",

        "collection-settings-list.view.dialogs.delete.title" : "You really want to delete these Collection Settings?",
        "collection-settings-list.view.dialogs.delete.description" : "You are about to delete the collection {name}. When deleting a collection, all associated data documents of the collection will also be deleted irrevocably. This action cannot be undone. Therefore, please confirm that you now want to proceed to delete the entire collection and all associated data documents.",
        "collection-settings-list.view.dialogs.delete.actions.confirm" : "Confirm",
        "collection-settings-list.view.dialogs.delete.actions.cancel" : "Cancel"


    },

    "de" : {
        "collection-settings-list.view.title" : "Verwaltung Datenbereiche",
        "collection-settings-list.view.subtitle" : "Sie können neue Datenbereiche anlegen, einen Datenbereich editieren oder löschen.",
        "collection-settings-list.view.toolbar.title" : "Datenbereiche",

        "collection-settings-list.view.columns.open" : "Öffnen",
        "collection-settings-list.view.columns.reindex" : "Neu indizieren",
        "collection-settings-list.view.columns.apis" : "APIs",
        "collection-settings-list.view.columns.embeddedCollections" : "Unterbereiche?",
        "collection-settings-list.view.columns.systemCollection" : "System?",
        "collection-settings-list.view.columns.systemCollection.hint" : "System-Datenbereiche werden von der Plattform automatisch bereitgestellt. Sie ermöglichen für Systembetreuer den flexiblen Zugriff auf technische Informationen wie Logs und fehlerhafte Nachrichten.",


        "collection-settings-list.view.help.title" : "Was sind Datenbereiche?",
        "collection-settings-list.view.help.description" : "Das System speichert die Daten-Dokumente in Datenbereichen. Auf Ebene des Datenbereichs können Einstellungen zur Governance und zur Zugriffssteuerung durchgeführt werden.",

        "collection-settings-list.view.reindex.dialog.title" : "Neu indizieren",
        "collection-settings-list.view.reindex.dialog.subtitle" : "Die Neuindizierung eines Datenbereichs kann ggf. einige Minuten dauern. Möchten Sie diesen Prozess wirklich starten?",
        "collection-settings-list.view.reindex.dialog.confirm" : "Jetzt ausführen",
        "collection-settings-list.view.reindex.dialog.cancel" : "Abbrechen",

        
        "collection-settings-list.view.dialogs.delete.title" : "Möchten Sie den Datenbereich wirklich löschen?",
        "collection-settings-list.view.dialogs.delete.description" : "Sie möchten den Datenbereich {name} löschen. Beim Löschen eines Datenbereichs werden auch alle Daten des Datenbereichs unwiderruflich gelöscht. Diese Aktion kann nicht rückgängig gemacht werden. Bitte bestätigen Sie deshalb, dass Sie nun fortfahren möchten, um den gesamten Datenbereich und alle damit verbunden Daten zu löschen.",
        "collection-settings-list.view.dialogs.delete.actions.confirm" : "Bestätigen",
        "collection-settings-list.view.dialogs.delete.actions.cancel" : "Abbrechen"

    }
}
</i18n>