<template>
  <v-sheet>
    <c-section :title="$t('webhook-subscriptions-list.view.title')" :subtitle="$t('webhook-subscriptions-list.view.subtitle')" class="secondary">
        <v-card class="mt-5 mb-5" tile>
            <c-item-list 
                   :title="$t('webhook-subscriptions-list.view.toolbar.title')" 
                   :items="items"
                   @refresh-list="refresh"
                   @item-added="onItemAdded" 
                   @item-edited="onItemEdited" 
                   @item-deleted="onItemDeleted" 
                   @item-list-filter-applied="onFilterChanged"
                   :disable-add-item="!hasCreateRole" 
                   :disable-edit-item="!hasEditRole && !hasReadRole" 
                   :disable-delete-item="!hasDeleteRole"
                  
            >

                <template v-slot:item-list-table-head-columns>
                    <th class="text-center">{{$t('webhook-subscriptions-list.view.columns.active')}}</th>
                    <th class="text-left">{{$t('webhook-subscriptions-list.view.columns.eventTypeSelector')}}</th>
                    <th class="text-left">{{$t('webhook-subscriptions-list.view.columns.target')}}</th>
                    <th class="text-right">{{$t('webhook-subscriptions-list.view.columns.timeoutMilliseconds')}}</th>
                    <th class="text-right">{{$t('webhook-subscriptions-list.view.columns.retries')}}</th>
                </template>


                <template v-slot:item-list-table-body-columns="{item}">
                    <td class="text-center"><v-icon small v-if="item.active">check_box</v-icon><v-icon small v-else>check_box_outline_blank</v-icon></td>
                    <td class="text-left"><code>{{item.eventTypeSelector}}</code></td>
                    <td class="text-left"><code>{{item.method}}</code> &nbsp; <code>{{item.urlTemplate}}</code></td>
                    <td class="text-right">{{item.timeoutMilliseconds  | integer-format($i18n.locale)}}</td>
                    <td class="text-right">{{item.retries  | integer-format($i18n.locale)}}</td>
                </template>


            </c-item-list>
        </v-card>
    </c-section>
  </v-sheet>
</template>

<script>
import { get } from 'lodash';
const MODULE_NAME = 'webhookSubscriptionsList';

export default {


    inject: ['errorHandlerService', 'progressIndicatorService', 'userContextService'],

    methods: {
        
        onItemAdded() {
            this.$router.push({
                name: 'webhookSubscriptionsWizard',
            });
        },

        onItemEdited({ item }) {
            this.$router.push({
                name: 'webhookSubscriptionsEditor',
                params: {
                    name: item.name
                }
            });
        },

        async onItemDeleted({ item }) {
            this.progressIndicatorService.show();
            try {

                // Delete the item, then reload the list in order to reflect change.
                await this.$store.dispatch(MODULE_NAME + "/deleteItem", item);
                await this.$store.dispatch(MODULE_NAME + "/loadResponse");
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },

        async onFilterChanged(event) {

            this.progressIndicatorService.show();
            try {
                await this.$store.dispatch(MODULE_NAME + "/updateFilter", event.filter)
                await this.refresh()
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },

        async refresh() {

            this.progressIndicatorService.show();
            try {
                await this.$store.dispatch(MODULE_NAME + "/loadResponse");
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },

        
    },

    computed: {

        model() {
            return this.$store.getters[MODULE_NAME + '/model'];
        },

        items() {
            return get(this.model, 'items', []);
        },

        hasCreateRole() {
            return true;
        },

        hasEditRole() {
            return true;
        },

        hasReadRole() {
            return true;
        },

        hasDeleteRole() {
            return true;
        },

    },

    created() {
        this.refresh();
    }
}
</script>

<i18n>
{
    "en" : {
        "webhook-subscriptions-list.view.title" : "Webhook Subscriptions",
        "webhook-subscriptions-list.view.subtitle" : "By creating subscriptions for webhooks you can provide event-based integration with other systems.",
        "webhook-subscriptions-list.view.toolbar.title" : "Webhook Subscriptions",

        "webhook-subscriptions-list.view.columns.active" : "Active",
        "webhook-subscriptions-list.view.columns.eventTypeSelector" : "Event Type Selector",
        "webhook-subscriptions-list.view.columns.target" : "Target",
        "webhook-subscriptions-list.view.columns.timeoutMilliseconds" : "Timeout (Miliseconds)",
        "webhook-subscriptions-list.view.columns.retries" : "Retries"
    },

    "de" : {
        "webhook-subscriptions-list.view.title" : "Webhook-Abonnements",
        "webhook-subscriptions-list.view.subtitle" : "Erstellen Sie ein Abonnements für einen Webhook, um andere Systeme Ereignis-basiert zu benachrichtigen.",
        "webhook-subscriptions-list.view.toolbar.title" : "Webhook-Abonnements",

        "webhook-subscriptions-list.view.columns.active" : "Aktiv",
        "webhook-subscriptions-list.view.columns.eventTypeSelector" : "Event-Typ-Selektor",
        "webhook-subscriptions-list.view.columns.target" : "Ziel",
        "webhook-subscriptions-list.view.columns.timeoutMilliseconds" : "Timeout (Millisekunden)",
        "webhook-subscriptions-list.view.columns.retries" : "Wiederholungen"
    }
}
</i18n>