<template>
    <v-sheet>

        <v-toolbar flat color="grey lighten-3">
            <v-toolbar-items>
                <v-btn class="grey--text darken-2" text @click="goToTestTrackList(testBenchId)"><v-icon class="mr-2">keyboard_backspace</v-icon> {{$t('test-track-detail.test-tracks.label')}}</v-btn>
            </v-toolbar-items>
            <v-spacer />
            <v-toolbar-items>
                
                <v-btn color="primary" text :href="kibanaLink" target="_blank" ><v-icon left>fact_check</v-icon>{{$t('test-track-detail.to-dashboard.label')}}</v-btn>
                <v-btn color="primary" text :to="{name: 'testTrackExport', params: {testBenchId: testBenchId, testBenchType: testBenchType, testTrackId: testTrackId}}"><v-icon left>file_download</v-icon>{{$t('test-track-detail.export-protocol.label')}}</v-btn>
                <!--<v-btn class="grey--text darken-2" text @click="exportCSV()"><v-icon left>cloud_download</v-icon>{{$t('test-track-detail.export-csv.label')}}</v-btn> -->
            </v-toolbar-items>
        </v-toolbar>

        <c-section :title="$t('test-track-detail.view.title', {testTrackId: testTrackId, testBenchId: testBenchId})" :subtitle="$t('test-track-detail.view.subtitle')" class="grey lighten-3">
            

           
            <v-tabs v-model="selectedTabIndex" fixed-tabs background-color="transparent">
                
                <v-tab :to="{name: 'testTrackDetailParamsView', props: { testBenchId: this.value.testBenchId, testTrackId: this.value.testTrackId, collectionId: this.value.collectionId, testBenchType: this.value.testBenchType}}">
                    {{$t('test-track-detail.view.tabs.params')}}
                </v-tab>

                <v-tab :to="{name: 'testTrackSamplesView', props: { testBenchId: this.value.testBenchId, testTrackId: this.value.testTrackId, collectionId: this.value.collectionId, testBenchType: this.value.testBenchType}}">
                    {{$t('test-track-detail.view.tabs.samples', {sampleRetentionDays: sampleRetentionDays})}}
                </v-tab>

                <v-tab :to="{name: 'testTrackSamplesAggregatedView', props: { testBenchId: this.value.testBenchId, testTrackId: this.value.testTrackId, collectionId: this.value.collectionId, testBenchType: this.value.testBenchType}}">
                    {{$t('test-track-detail.view.tabs.samplesAggregated')}}
                </v-tab>
                  <!--  
                <v-tab :key="0">Parameter</v-tab>
                <v-tab :key="1">Daten (Letzte 10 Tage)</v-tab>
                <v-tab :key="1">Agg. Daten (Letzte 366 Tage)</v-tab>
                <v-tab :key="2">Prüfprotokoll</v-tab>
                -->
            </v-tabs>
            <v-divider />
            <router-view  name="testTrackRouterView"></router-view>
        </c-section>
    </v-sheet>

</template>

<script>
import api from '@/store/api';
import KibanaDashboardFactory from '@/cust/utils/kibana-dashboard-factory';

export default {
    

    props: {
        value: {
            type: Object,
            required: true,
        },
    },

    data: () => ({
        selectedTabIndex: 0
    }),

    computed: {
        testBenchId() {
            return (this.value) ? this.value.testBenchId : '';
        },

        testTrackId() {
             return (this.value) ? this.value.testTrackId : '';
        },

        testBenchType() {
             return (this.value) ? this.value.testBenchType : '';
        },

        testBenchTypeHT() {
            // Note: Using the computed getter will make sure that testBenchType is never nil and it is therefore save
            // to call toIpperCase directly.
            return this.testBenchType.toUpperCase() === 'HT';
        },

        collectionId() {
            return (this.value) ? this.value.collectionId : '';
        },

        
        kibanaLink() {
            return KibanaDashboardFactory.getDashboard(this.testBenchId, this.testTrackId, this.testBenchType);
        },

        /**
         * The retention is actually derived from the testBenchType
         */
        sampleRetentionDays() {
            return this.testBenchTypeHT ? 30 : 10;
        }
    },

    methods: {

        goToTestTrackList(testBenchId) {
            this.$router.push({
                name: 'testTracksList',
                params: { name: testBenchId }
            });
        },

        exportCSV() {
            const body = {
                filter: {
                    facetFilters: [
                        {
                            filterQuery: this.testBenchId,
                            name: "testBenchId",
                            values: []
                        },
                        {
                            filterQuery: this.testTrackId,
                            name: "testTrackId",
                            values: []
                        }
                    ],
                    filterQuery: "*",
                    filterQueryLanguage: "SIMPLE",
                    rangeFilters: []
                },
                format: "CSV",
                sort: {
                    sortCriteria: [
                        {
                            name: "sampleDateTime",
                            ordering: "DESC"
                        }
                    ]
                },
            }

            return api.collections.notifications.exportDocument(this.collectionId, body).then(() => {
                const title = this.$t('collection-documents.view.messages.export.success.title', this.$i18n.locale);
                const message = this.$t('collection-documents.view.messages.export.success.message', this.$i18n.locale);
                this.setMessage({type: 'info', title: title,  message: message});
            }).catch(error => {
                this.handleError(error);
            });
        }

    },

    
}
</script>
<style scoped>
#sticky {
  position: sticky;
  position: -webkit-sticky;
  top: 64px;
  z-index: 1;
}
</style>

<i18n>
{
    "en": {

        "test-track-detail.view.title" : "Test Track {testBenchId} / {testTrackId}",
        "test-track-detail.view.subtitle" : "On this page you can find the recorded test samples as well as the test track's current parameters.",
        "test-track-detail.test-tracks.label": "Test tracks",
        "test-track-detail.to-dashboard.label": "To the dashboard",
        "test-track-detail.export-protocol.label": "Export protocol",
        "test-track-detail.export-csv.label": "Export CSV",

        "test-track-detail.view.tabs.params" : "Parameters",
        "test-track-detail.view.tabs.samples" : "Test Samples ({sampleRetentionDays} Days)",
        "test-track-detail.view.tabs.samplesAggregated" : "Test Samples (366 Days)",

        "test-track-detail.status.label": "Status",
        "test-track-detail.order-data.label": "Order data",
        "test-track-detail.last-ten-minutes.label": "Last 10 minutes",
        "test-track-detail.view.timeseries.section.result.hint": "The time series charts are updated every minute.",
        "test-track-detail.test-bench-data.label": "Test bench data",
        "test-track-detail.test-track-data.label": "Test track data"
    },

    "de": {
        "test-track-detail.view.title" : "Prüfstrecke {testBenchId} / {testTrackId}",
        "test-track-detail.view.subtitle" : "Auf dieser Seite finden Sie die aufgezeichneten Prüfdaten und die aktuellen Parameter der Prüfstrecke.",

        "test-track-detail.test-tracks.label": "Prüfstrecken",
        "test-track-detail.to-dashboard.label": "Zum Dashboard",
        "test-track-detail.export-protocol.label": "Protokoll Exportieren",
        "test-track-detail.export-csv.label": "CSV exportieren",

        "test-track-detail.view.tabs.params" : "Parameter",
        "test-track-detail.view.tabs.samples" : "Prüfdaten ({sampleRetentionDays} Tage)",
        "test-track-detail.view.tabs.samplesAggregated" : "Prüfdaten (366 Tage, Aggregiert)",

        "test-track-detail.status.label": "Status",
        "test-track-detail.order-data.label": "Auftragsdaten",
        "test-track-detail.last-ten-minutes.label": "Letzte 10 Minuten",
        "test-track-detail.view.timeseries.section.result.hint": "Die Zeitreihen Diagramme werden jede Minute aktualisiert.",
        "test-track-detail.test-bench-data.label": "Prüfstandsdaten",
        "test-track-detail.test-track-data.label": "Streckendaten"
    }
}
</i18n>