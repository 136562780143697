<template>
  <div class="grey lighten-3">
      
      <!-- Breadcrumbs -->
      <v-container fluid grid-list-md class="grey lighten-3">
            <v-row>
                <v-col :cols="12">
                    <v-btn text @click="goToTestBenchOverview" ><v-icon class="mr-2">keyboard_backspace</v-icon> {{$t('test-track-list.test-benches.label')}}</v-btn>
                </v-col>
            </v-row>
      </v-container>

      <v-container fluid>

    <div id="wrapper" v-if="testTracks.length">
        
        <div id="sticky"  class="my-5">
            <v-card tile class="info">
                <v-container fluid>
                    <v-row align="center">
                    <v-col cols="auto"><v-icon large color="black">info</v-icon></v-col>
                    <v-col class="black--text">{{$t('test-track-list.test-track.update.description')}}</v-col>
                    </v-row>
                </v-container> 
            </v-card>
        </div>
      <!-- Content -->
      <c-section :title="$t('test-track-list.test-bench.label') + ' ' + value.name" :subtitle="$t('test-track-list.test-tracks.overview.label')" class="grey lighten-3">
          
          <v-container fluid v-if="true">
              <v-card tile>
                  <v-card-title>{{$t('test-track-list.test-tracks.status-overview.label')}}</v-card-title>
                  <v-card-text>{{$t('test-track-list.test-tracks.last-query-time')}}: {{lastFetchTime}}</v-card-text>
                  <v-simple-table>
                      <thead>
                          <tr>
                              <th class="text-center">{{status('statusOff') | localized-name($i18n.locale)}}</th>
                              <th class="text-center">{{status('statusBreak') | localized-name($i18n.locale)}}</th>
                              <th class="text-center">{{status('statusRunning') | localized-name($i18n.locale)}}</th>
                              <th class="text-center">{{status('statusMalfunction') | localized-name($i18n.locale)}}</th>
                              <th class="text-center">{{status('statusMalfunctionAcknowledge') | localized-name($i18n.locale)}}</th>
                              <th class="text-center">{{status('statusFinished') | localized-name($i18n.locale)}}</th>
                          </tr>
                      </thead>
                      <tbody>
                          <tr>
                              <td class="text-center">{{statusCount('statusOff')}}</td>
                              <td class="text-center">{{statusCount('statusBreak')}}</td>
                              <td class="text-center">{{statusCount('statusRunning')}}</td>
                              <td class="text-center error white--text">{{statusCount('statusMalfunction')}}</td>
                              <td class="text-center error white--text">{{statusCount('statusMalfunctionAcknowledge')}}</td>
                              <td class="text-center">{{statusCount('statusFinished')}}</td>
                          </tr>
                      </tbody>
                  </v-simple-table>
              </v-card>
              
          </v-container>
          
          <v-container fluid>
              <v-row justify="center">
                  <v-col lg="4" md="6" cols="12" v-for="testTrack in testTracks" :key="testTrack.data.testTrackId">
                      <v-card tile @click="goToTestTrackDetail(testTrack.data.testTrackId, testTrack.data.testBenchType)" class="ma-5">
                          <v-btn 
                            color="accent" 
                            @click="showKibanaDashboard($event, testTrack.data.testTrackId, testTrack.data.testBenchType)"
                            fab 
                            absolute 
                            top 
                            right>
                                <v-icon>fact_check</v-icon>
                            </v-btn> 
                          <v-card-text class="text-h6">
                              {{$t('test-track-list.test-track.label')}} {{testTrack.data.testTrackId}}
                          </v-card-text>
                          <!--
                          <v-simple-table dense light>
                              <tbody>
                                <tr>
                                    <td class="text-left"> 
                                        <v-card-title>{{$t('test-track-list.test-track.label')}}</v-card-title>
                                    </td>
                                    <td class="text-left"> 
                                        <v-card-title>{{testTrack.data.testTrackId}}</v-card-title>
                                    </td>
                                    <td class="text-right">
                                        <v-img @click="showKibanaDashboard($event, testTrack.data.testTrackId, testTrack.data.testBenchType)" class="ml-auto" contain max-width="36" src="/cust/assets/icons/kibana.svg" />
                                    </td>
                                </tr>
                                -->
                            <v-simple-table dense light>
                              <tbody>
                                <tr>
                                    <td class="text-left">
                                        <v-card-text>{{$t('test-track-list.test-track.tv-number.label')}}</v-card-text>
                                    </td>
                                    <td class="text-left">
                                        <v-card-text>{{testTrack.data['TVID']}}</v-card-text>
                                    </td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td colspan="3" class="px-0">
                                        <v-simple-table dense light>
                                            <thead>
                                                <tr>
                                                    <th class="text-center">{{status('statusOff') | localized-name($i18n.locale)}}</th>
                                                    <th class="text-center">{{status('statusBreak') | localized-name($i18n.locale)}}</th>
                                                    <th class="text-center">{{status('statusRunning') | localized-name($i18n.locale)}}</th>
                                                    <th class="text-center">{{status('statusMalfunction') | localized-name($i18n.locale)}}</th>
                                                    <th class="text-center">{{status('statusMalfunctionAcknowledge') | localized-name($i18n.locale)}}</th>
                                                    <th class="text-center">{{status('statusFinished') | localized-name($i18n.locale)}}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-bind:class="{'error': testTrack.data.error}">
                                                    <td class="text-center">
                                                        <template v-if="testTrack.data.statusOff">
                                                            <v-icon class="py-2" size="28">check_circle_outline</v-icon>
                                                        </template>
                                                    </td>
                                                    <td class="text-center">
                                                        <template v-if="testTrack.data.statusBreak">
                                                            <v-icon class="py-2" size="28">check_circle_outline</v-icon>
                                                        </template>
                                                    </td>
                                                    <td class="text-center">
                                                        <template v-if="testTrack.data.statusRunning">
                                                            <v-icon class="py-2" size="28">check_circle_outline</v-icon>
                                                        </template>
                                                    </td>
                                                    <td class="text-center">
                                                        <template v-if="testTrack.data.statusMalfunction">
                                                            <v-icon class="py-2" size="28" color="white">check_circle_outline</v-icon>
                                                        </template>
                                                    </td>
                                                    <td class="text-center">
                                                        <template v-if="testTrack.data.statusMalfunctionAcknowledge">
                                                            <v-icon class="py-2" size="28" color="white">check_circle_outline</v-icon>
                                                        </template>
                                                    </td>
                                                    <td class="text-center">
                                                        <template v-if="testTrack.data.statusFinished">
                                                            <v-icon class="py-2" size="28">check_circle_outline</v-icon>
                                                        </template>
                                                    </td>
                                            </tr>
                                            </tbody>
                                            
                                            
                                        </v-simple-table>
                                        
                                    </td>
                                </tr>
                              </tbody>
                          </v-simple-table>
                      </v-card>
                  </v-col>
              </v-row>
          </v-container>
      </c-section>
    </div>
      </v-container>
  </div>
</template>

<script>
import * as moment from 'moment';
import KibanaDashboardFactory from '@/cust/utils/kibana-dashboard-factory';

const MODULE_NAME = 'testTrackList';

export default {
    inject: ['errorHandlerService'],

    props: {

        value: {
            type: Object,
            required: true
        }
    },

    data: () => ({
        loading: true,
        fetchInterval: null,
        lastFetchTime: null,
    }),

    methods: {

        async loadData() {
            this.loading = true;
            try {
                await this.$store.dispatch(MODULE_NAME + '/loadData', {    
                    collectionId : 'TestTrackStatus',
                });
                
            } catch (error) {
                this.handleError(error);
            } finally {
                this.loading = false;
                this.lastFetchTime = moment().format('DD.MM.YY HH:mm:ss').toString();
            }
        },

        handleError(error) {
            this.$log.debug(error.toJSON ? error.toJSON() : error );
            this.errorHandlerService.handleError(error);
        },

        goToTestBenchOverview() {
            this.$router.push({name : 'testBenchesList'})
        },

        goToTestTrackDetail(testTrackId, testBenchType) {
            this.$router.push({
                name : 'testTrackDetailParamsView',
                params: {
                    testBenchId: this.value.name,
                    testTrackId: testTrackId,
                    testBenchType: testBenchType,
                    collectionId: `EnduranceTesting${testBenchType}Samples`
                }
            });
        },
        showKibanaDashboard(event, testTrackId, testBenchType) {
            event.stopPropagation();
            window.open(this.kibanaLink(testTrackId, testBenchType), '_blank');
        }
    },

    computed: {

        status() {
            return status => this.response.meta.attributes[status];
        },

        statusCount() {
            return status => this.response.items.filter(testTrack => testTrack.data[status]).length;
        },

        response() {
          return this.$store.getters[MODULE_NAME + "/response"]
        },

        testTracks() {
            const testTracks = this.response.items
            return testTracks.sort((a, b) => {
                return a.data.testTrackId - b.data.testTrackId
            });
        },

        testBenchId() {
            return this.value ? this.value.name : ''
        },

        kibanaLink() {
            return (testTrackId, testBenchType) => KibanaDashboardFactory.getDashboard(this.testBenchId, testTrackId, testBenchType);
        },
    },

    created() {
        this.$store.dispatch(MODULE_NAME + '/setPagination', {
            from: 0,
            size: 200,
            searchAfter: []
        });
        this.$store.dispatch(MODULE_NAME + '/setFacetFilters', {"facetFilters":[{"name":"testBenchId","filterQuery": this.value.name, filterMode:"CONTAINS", "values":[]}],"rangeFilters":[]});
        
        this.loadData();
        this.fetchInterval = setTimeout(() => {
            this.$store.dispatch(MODULE_NAME + '/setPagination', {
                from: 0,
                size: 200,
                searchAfter: []
            });
            this.loadData();
        }, 60000);
    },

    beforeDestroy() {
        clearTimeout(this.fetchInterval);
    }
}
</script>

<i18n>
{
    "en": {
        "test-track-list.test-bench.label": "Test bench",
        "test-track-list.test-benches.label": "Test benches",
        "test-track-list.test-track.label": "Test track",
        "test-track-list.test-tracks.overview.label": "The following overview shows all test tracks of this test bench",
        "test-track-list.test-tracks.status-overview.label": "Status overview of all test tracks",
        "test-track-list.test-tracks.last-query-time": "Last query time",
        "test-track-list.test-track.tv-number.label": "TV number",
        "test-track-list.test-track.update.description": "The test tracks are updated every minute." 
        
    },

    "de": {
        "test-track-list.test-bench.label": "Prüfstand",
        "test-track-list.test-benches.label": "Prüfstände",
        "test-track-list.test-track.label": "Prüfstrecke",
        "test-track-list.test-tracks.overview.label": "Die folgende Übersicht zeigt alle Prüfstrecken dieses Prüfstands",
        "test-track-list.test-tracks.status-overview.label": "Statusübersicht aller Prüfstrecken",
        "test-track-list.test-tracks.last-query-time": "Letzte Abfragezeit",
        "test-track-list.test-track.tv-number.label": "TV-Nummer",
        "test-track-list.test-track.update.description": "Die Prüfstrecken werden jede Minute aktualisiert." 
    }
}
</i18n>

<style scoped>
#sticky {
  position: sticky;
  position: -webkit-sticky;
  top: 64px;
  z-index: 1;
}
</style>
