<template>

    <c-section :title="$t('test-bench-list.test-benches.label')" :subtitle="$t('test-bench-list.test-benches.label.description')" class="secondary">

        <template v-if="testBenchIdsForUserEmpty">
            <v-container fluid>
                <v-row justify="center">
                    <v-col :cols="12" :md="9">
                        <v-card color="accent pa-5">
                            <v-card-text class=" text-center">
                                <v-icon class="pa-5" color="white" x-large>do_not_disturb_alt</v-icon>
                                <div class="text-h6 text-center white--text">{{$t('test-bench-list.no-permissions.title')}}</div>
                                <div class="text-center white--text">{{$t('test-bench-list.no-permissions.description')}}</div>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </template>

        <template v-else>
            <v-container fluid grid-list-md v-bind:class="{'error': malfunctionCount > 0, 'accent': malfunctionCount === 0}">
                <v-row align="center">
                        <v-col :cols="12">
                            <div class="text-center mt-5 mb-5 white--text">
                                <template v-if="malfunctionCount > 0">
                                    <v-icon x-large color="white">notification_important</v-icon>
                                    <p />
                                    <div v-if="malfunctionCount === 1" class="title">{{malfunctionCount}} {{$t('test-bench-list.test-bench-malfunction.check')}}</div>
                                    <div v-else class="title">{{malfunctionCount}} {{$t('test-bench-list.test-benches-malfunction.check')}}</div>
                                </template>
                                <template v-else>
                                    <v-icon x-large color="white">check_circle_outline</v-icon>
                                    <p />
                                    <div class="title">{{$t('test-bench-list.test-benches.healthy.label')}}</div>
                                </template>
                            </div>
                        </v-col>
                </v-row>
            </v-container>

            <v-container fluid grid-list-md>
                <v-combobox solo clearable deletable-chips
                    v-model="filterTvIds"
                    :items="tvIds"
                    :label="$t('test-bench-list.test-benches.filter.tv.label')"
                    multiple
                    chips>
                </v-combobox>
            </v-container>

            <v-container fluid >
                <div id="wrapper" v-if="testBenches.length">
                    <div id="sticky"  class="my-5">
                        <v-card tile class="info">
                            <v-container fluid>
                                <v-row align="center">
                                <v-col cols="auto"><v-icon large color="black">info</v-icon></v-col>
                                <v-col class="black--text">{{$t('test-bench-list.test-bench.update.description')}}</v-col>
                                </v-row>
                            </v-container>
                        </v-card>
                    </div>
                    <v-row>
                        <!-- ======================================================================================== -->
                        <!-- IMPORTANT                                                                                -->
                        <!-- Test Benches are FILTERED according to token information from keycloak.                  -->
                        <!-- In case you don't see anything here, most probably the respective attribute testbenchIds -->
                        <!-- is not configured for your user or your groups respectively.                             -->
                        <!-- ======================================================================================== -->
                        <template v-for="testBench in testBenches">
                            <v-col lg="4" md="6" :key="testBench.testBenchId" v-if="showTestBench(testBench.tvIds)">
                                <v-card tile @click="goToTestTrackList(testBench.testBenchId)">
                                    <v-card-text>
                                        <div class="title">{{$t('test-bench-list.test-bench.label')}} {{testBench.testBenchId}}</div>
                                        <div class="subheading-1" v-if="testBench.numberOfTestTracks > 1">{{testBench.numberOfTestTracks}} {{$t('test-bench-list.test-tracks.label')}}</div>
                                        <div class="subheading-1" v-else>{{testBench.numberOfTestTracks}} {{$t('test-bench-list.test-track.label')}}</div>
                                    </v-card-text>
                                    <v-divider />
                                    <v-subheader>{{$t('test-bench-list.test-bench.last-modification', {modificationDate: getDateTimeFormatted(testBench.modificationDateTime, 'yyyy-MM-dd'), modificationTime: getDateTimeFormatted(testBench.modificationDateTime, 'HH:mm:ss')})}}</v-subheader>
                                        <v-simple-table>
                                        <tbody>
                                            <tr>
                                                <td></td>
                                                <td>{{response.meta.attributes['statusOff'] | localized-name($i18n.locale) | truncate(24)}}</td>
                                                <td>{{testBench.statusOff | integer-format($i18n.locale)}}</td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td>{{response.meta.attributes['statusBreak'] | localized-name($i18n.locale) | truncate(24)}}</td>
                                                <td>{{testBench.statusBreak | integer-format($i18n.locale)}}</td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td>{{response.meta.attributes['statusRunning'] | localized-name($i18n.locale) | truncate(24)}}</td>
                                                <td>{{testBench.statusRunning | integer-format($i18n.locale)}}</td>
                                            </tr>
                                            <tr v-bind:class="{'error white--text': testBench.statusMalfunction > 0}">
                                                <td><v-icon v-if="testBench.statusMalfunction > 0" color="white">notification_important</v-icon></td>
                                                <td>{{response.meta.attributes['statusMalfunction'] | localized-name($i18n.locale) | truncate(24)}}</td>
                                                <td>{{testBench.statusMalfunction | integer-format($i18n.locale)}}</td>
                                            </tr>
                                            <tr v-bind:class="{'error white--text': testBench.statusMalfunctionAcknowledge > 0}">
                                                <td><v-icon v-if="testBench.statusMalfunctionAcknowledge > 0" color="white">notification_important</v-icon></td>
                                                <td>{{response.meta.attributes['statusMalfunctionAcknowledge'] | localized-name($i18n.locale) | truncate(24)}}</td>
                                                <td>{{testBench.statusMalfunctionAcknowledge | integer-format($i18n.locale)}}</td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td>{{response.meta.attributes['statusFinished'] | localized-name($i18n.locale) | truncate(24)}}</td>
                                                <td>{{testBench.statusFinished | integer-format($i18n.locale)}}</td>
                                            </tr>
                                        </tbody>
                                        </v-simple-table>
                                </v-card>
                            </v-col>
                        </template>
                    </v-row>
                </div>
            </v-container>
        </template>

    </c-section>

</template>

<script>
const MODULE_NAME = 'testBenchList';
const MALFUNCTION_ATTRIBUTE = 'statusMalfunctionCounter';

export default {
    inject: ['errorHandlerService', 'userContextService'],

  data: () => ({
    loading: false,
    selectedTvIds: [],
  }),

  methods: {

    getDateTimeFormatted(dateTime, format) {
        const dateFormat = format || 'DD.MM.YY HH:mm:ss'
        return this.$core.filters.dateTimeFormat(dateTime, dateFormat, this.$i18n.locale)
    },

    goToTestTrackList(testBenchId) {
      this.$router.push({
        name: 'testTracksList',
        params: { name: testBenchId },
      });
    },

    showTestBench(tvIds) {
        return !this.selectedTvIds.length || tvIds.some((val) => this.selectedTvIds.includes(val));
    },

    async loadData() {
        this.loading = true;
        try {
            await this.$store.dispatch(MODULE_NAME + '/loadData', {
                collectionId : 'TestTrackStatus'
            });

        } catch (error) {
            this.handleError(error);
        } finally {
            this.loading = false;
        }
    },

    handleError(error) {
      this.$log.debug(error.toJSON ? error.toJSON() : error );
      this.errorHandlerService.handleError(error);
    },
  },

    computed: {
        
        testBenches() {

            // SDDXS-108: The testbenches must be filtered in order to filter 
            // testbenches for which the user is not authorized. This is
            // meant as a mere convenience feature in order to shield 
            // users from information overflow, not as a proper means of protecting data.
            const all = this.$store.getters[MODULE_NAME + "/testBenches"];

            const filtered = all.filter(item => this.testBenchIdsForUser.has(item.testBenchId));
            return filtered;
        },

        /**
         * Returns a set of test bench ids which are available for the current user.
         * @since SDDXS-108
         */
        testBenchIdsForUser() {
            
            const testBenchIds = new Set();

            // SDDXS-108: The permissions are stored as an array of comma-separated lists in the user token and must be parsed.
            // Maybe not the most advanced solution, but the configuration is very easy in Keycloak for user admins -> ease of use!
            const tokenInfo = this.userContextService.getAttribute('testbenchIds', []);

            // tokenInfo looks like ['12345,747474,091081', '471115']
            tokenInfo.forEach(
                csv => csv.split(',').forEach(
                    item => testBenchIds.add(item.trim())
                )
            );

            return testBenchIds;
        },

        testBenchIdsForUserEmpty() {
            return this.testBenchIdsForUser.size === 0;
        },

        tvIds() {
            return this.$store.getters[MODULE_NAME + "/tvIds"];
        },

        response() {
            return this.$store.getters[MODULE_NAME + "/response"]
        },

        filterTvIds: {
            get() {
                return this.selectedTvIds
            },

            set(value) {
                this.selectedTvIds = value;
            }
        },

        orderedStatusList() {
            const attributes = this.response.meta.attributes;
            const statusList = [];
            Object.keys(attributes).forEach(key => {
                const attribute = attributes[key];
                if (attribute.datatype === "INTEGER") {
                statusList.push({...attribute, malfunction: attribute.name === MALFUNCTION_ATTRIBUTE});
                }
            });
        statusList.sort((a, b) => {
            a.orderding > b.ordering;
        });
        return statusList;
        },

        malfunctionCount() {
            let malfunctionCount = 0;

            this.testBenches.forEach(testBench => {
                if (testBench.statusMalfunction > 0 || testBench.statusMalfunctionAcknowledge) {
                    malfunctionCount++;
                }
            });

            return malfunctionCount;

        },
    },

    created() {
        this.$store.dispatch(MODULE_NAME + '/setPagination', {
            from: 0,
            size: 200,
            searchAfter: []
        });
        this.loadData();
        this.fetchInterval = setInterval(() => {
            this.$store.dispatch(MODULE_NAME + '/setPagination', {
                from: 0,
                size: 200,
                searchAfter: []
            });
            this.loadData();
        }, 60000);
    },

    beforeDestroy() {
        clearInterval(this.fetchInterval);
    }
};
</script>

<i18n>
{
    "en": {
        "test-bench-list.test-bench.label": "Test bench",
        "test-bench-list.test-benches.label": "Test benches",
        "test-bench-list.test-track.label": "Test track",
        "test-bench-list.test-tracks.label": "Test tracks",
        "test-bench-list.test-benches.label.description": "Below you will find an overview of all currently connected test benches",
        "test-bench-list.test-benches.healthy.label": "No test benches in malfunction",
        "test-bench-list.test-bench-malfunction.check": "Test bench in malfunction - please check!",
        "test-bench-list.test-benches-malfunction.check": "Test benches in malfunction - please check!",
        "test-bench-list.test-benches.filter.tv.label": "Enter filtering by TV number",
        "test-bench-list.test-bench.last-modification": "Last updated on {modificationDate} at {modificationTime} clock",
        "test-bench-list.test-bench.update.description": "The test benches are updated every minute.",

        "test-bench-list.no-permissions.title" : "Missing Permissions",
        "test-bench-list.no-permissions.description" : "Your user account is currently not granted access permissions for test benches. Please refer to you system administrator in order to be granted access."
    },

    "de": {
        "test-bench-list.test-bench.label": "Prüfstand",
        "test-bench-list.test-benches.label": "Prüfstände",
        "test-bench-list.test-track.label": "Prüfstrecke",
        "test-bench-list.test-tracks.label": "Prüfstrecken",
        "test-bench-list.test-benches.label.description": "Sie finden nachfolgend eine Übersicht aller aktuell angeschlossenen Prüfstände",
        "test-bench-list.test-benches.healthy.label": "Keine Prüfstände in Störung",
        "test-bench-list.test-bench-malfunction.check": "Prüfstand in Störung - bitte prüfen!",
        "test-bench-list.test-benches-malfunction.check": "Prüfstände in Störung - bitte prüfen!",
        "test-bench-list.test-benches.filter.tv.label": "Filterung nach TV-Nummer eingeben",
        "test-bench-list.test-bench.last-modification": "Letzte Aktualisierung am {modificationDate} um {modificationTime} Uhr",
        "test-bench-list.test-bench.update.description": "Die Prüfstände werden jede Minute aktualisiert.",

        "test-bench-list.no-permissions.title" : "Keine Berechtigungen vorhanden",
        "test-bench-list.no-permissions.description" : "In Ihrem Benutzerkonto sind aktuell keine Zugriffsberechtigungen für Prüfstände hinterlegt. Bitte wenden Sie sich an Ihren Systembetreuer, um Zugriff zu erhalten."

    }
}
</i18n>

<style scoped>
#sticky {
  position: sticky;
  position: -webkit-sticky;
  top: 64px;
  z-index: 1;
}
</style>
