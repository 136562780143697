import api from '@/store/api';
import _ from 'lodash';

const DEFAULT_PAGEGINATION_SIZE = 500;

export default {

    namespaced : true,

    state: {

        items: [],

        meta: {},

        query: {

            filter: {

                filterQuery: '*',

                filterQueryLanguage: 'SIMPLE',

                facetFilters: [],

                rangeFilters: []
            },

            sort: {
                sortCriteria: []
            },

            pagination: {
                from: 0,
                size: DEFAULT_PAGEGINATION_SIZE,
                searchAfter: []
            },
        },

        viewSettings: {

            attributes: []
        },

        pagination: {
            from: 0,
            size: DEFAULT_PAGEGINATION_SIZE,
            searchAfter: []
        },

        facets: {},

        links: {},
    },

    mutations: {

        setPagination(state, pagination) {
            state.pagination = pagination;
        },

        setSortCriteria(state, sortCriteria) {
            state.query.sort.sortCriteria = sortCriteria;
        },

        setFilter(state, filter) {
            state.query.filter = filter;
        },
        
        setResponse(state, response) {


            state.items = response.items ? response.items : [];
            state.meta  = response.meta ? response.meta : {};
            
            state.facets     = response.facets ? response.facets : {};
            state.links      = response.links ? response.links : {};
            state.pagination = response.pagination ? response.pagination : {from: 0, size: DEFAULT_PAGEGINATION_SIZE, searchAfter: []};
            
            state.query.filter     = (response.query && response.query.filter) ? response.query.filter : { filterQuery: '*', filterQueryLanguage: 'SIMPLE', facetFilters: [], rangeFilters: [] };
            state.query.sort       = (response.query && response.query.sort) ? response.query.sort : { sortCriteria: [] },
            state.query.pagination = (response.query && response.query.pagination) ? response.query.pagination : { from: 0, size: DEFAULT_PAGEGINATION_SIZE, searchAfter: [] };

            // DXS-99: Reset the view settings if an empty response has been received.
            // It is necessary to do so in order to support the reusing of components by vue router.
            // Otherwise the view settings will not change when navigating.
            if (_.isEmpty(response)) { // Always remember: Empty objects are NOT falsy... thus use the lodash is empty method for a safe check.
                state.viewSettings.attributes = [];   
            }

            // If no explicit view settings have been set, 
            // compute them on the fly.
            

            if (_.isEmpty(state.viewSettings.attributes)) {
                _.forOwn(state.meta.attributes, attribute => state.viewSettings.attributes.push({
                    value: attribute,
                    display: !!_.get(attribute, 'clientSettings.table.display', true)
                }));
                

                state.viewSettings.attributes.sort( (first, second) => first.value.ordering - second.value.ordering);
            }
            
        }, 
        
        appendResponse(state, response) {
        
            // In order to play it safe, make sure there is an actual instance.
            if (!state.items) {
                state.items = [];
            }

            // Push additional items
            // Note: Due to reasons with vuex it is necessary to push each item separately inside of a loop
            // It is not sufficient to push the array as is.
            for (let item of response.items) {
                state.items.push(item);
            }
            

            // Of course we also need to update the pagination
            state.pagination = response.pagination;
        },

    },

    actions: {

        setPagination(context, pagination) {
            context.commit('setPagination', pagination);
        },

        setFacetFilters(context, {facetFilters}) {
            context.commit('setFacetFilters', facetFilters);
        },

        async loadData(context, {collectionId}) {

            // Reset the pagination... otherwise sooner or later we will run out of pages to display
            let pagination = {
                from: 0,
                searchAfter: [],
                size: DEFAULT_PAGEGINATION_SIZE
            };
            context.commit('setPagination', pagination);
            const query = context.getters.query;
            
                
            return api.collections.documents.findByQuery(collectionId, query).then(response => context.commit('setResponse', response));
        },

        async loadMore(context, {collectionId}) {

            // Please note that load more will add another page to the existing set of results.
            const query = context.getters.query;

            // We need to tweak the query in order to load the next page.
            query.pagination.searchAfter = context.state.pagination.searchAfter; 
            query.pagination.size = context.state.pagination.size;
            
            // DXS-95: Add the from - parameter: This is not required on subsequent calls by elastic, 
            // but causes trouble in the Backend if missing.
            if (!query.pagination.from) query.pagination.from = 0;

            //console.log(">>> LOAD MORE : " + JSON.stringify(query))
                
            return api.collections.documents.findByQuery(collectionId, query).then(response => context.commit('appendResponse', response));
        },

        setSortCriteria(context, {sortCriteria}) {
            context.commit('setSortCriteria', sortCriteria);
        },

        setFilter(context, {filter}) {
            context.commit('setFilter', filter);
        },

    },

    getters: {

        query(state) {
            
            return { 
                filter: state.query.filter,
                sort: state.query.sort,
                pagination: state.pagination,
                queryTimeoutMiliseconds: 25 * 1000 // 25 seconds
            }
        },

        filter(state) {
            
            return state.query.filter;  
        },

        response(state) {

            return {
                items        : state.items,
                meta         : state.meta,
                filter       : state.query.filter,
                viewSettings : state.viewSettings,
                sort         : state.query.sort,
                pagination   : state.pagination,
                facets       : state.facets,
                links        : state.links
            }
        }
    }


}
