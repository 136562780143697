<template>
    <v-expansion-panels  :popout="false">
        <v-expansion-panel>
            <v-expansion-panel-header>
                <div>
                    <v-icon color="accent" class="mr-2">help</v-icon>{{$t("collection-settings-wizard-help.view.title")}}
                </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <div class="text-body-2">{{$t("collection-settings-wizard-help.view.description")}}</div>
                
                    <v-tabs 
                        v-model="selectedTab"
                        color="grey" 
                        background-color="grey lighten-3"
                        class="mt-5">
                        
                        <v-tab :key="0">{{$t('collection-settings-wizard-help.view.tabs.variables.title')}}</v-tab>
                        <v-tab :key="1">{{$t('collection-settings-wizard-help.view.tabs.data.title')}}</v-tab>
                        <v-tab :Key="2">{{$t('collection-settings-wizard-help.view.tabs.functions.title')}}</v-tab>

                        <v-tabs-items v-model="selectedTab">
                            

                            <v-tab-item>
                                <v-simple-table >
                                    <thead>
                                        <tr>
                                            <th>{{$t('collection-settings-wizard-help.view.tabs.data.cols.field')}}</th>
                                            <th>{{$t('collection-settings-wizard-help.view.tabs.data.cols.datatype')}}</th>
                                            <th>{{$t('collection-settings-wizard-help.view.tabs.data.cols.description')}}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td><code>$now</code></td>
                                            <td><code>DATETIME</code></td>
                                            <td>{{$t('collection-settings-wizard-help.view.tabs.variables.now')}}</td>
                                        </tr>
                                        <tr>
                                            <td><code>$tenant.tenantId</code></td>
                                            <td><code>STRING</code></td>
                                            <td>{{$t('collection-settings-wizard-help.view.tabs.variables.tenantId')}}</td>
                                        </tr>
                                        <tr>
                                            <td><code>$tenant.stage</code></td>
                                            <td><code>STRING</code></td>
                                            <td>{{$t('collection-settings-wizard-help.view.tabs.variables.stage')}}</td>
                                        </tr>
                                        <tr>
                                            <td><code>$source.collectionSettings.name</code></td>
                                            <td><code>MAP</code></td>
                                            <td>{{$t('collection-settings-wizard-help.view.tabs.variables.collectionSettingsId')}}</td>
                                        </tr>
                                        <tr>
                                            <td><code>$source.schema.name</code></td>
                                            <td><code>MAP</code></td>
                                            <td>{{$t('collection-settings-wizard-help.view.tabs.variables.schemaId')}}</td>
                                        </tr>
                                    </tbody>
                                </v-simple-table>
                            </v-tab-item>

                            <!-- Data Fields -->
                            <v-tab-item>
                                <v-simple-table >
                                    <thead>
                                        <tr>
                                            <th>{{$t('collection-settings-wizard-help.view.tabs.data.cols.field')}}</th>
                                            <th>{{$t('collection-settings-wizard-help.view.tabs.data.cols.datatype')}}</th>
                                            <th>{{$t('collection-settings-wizard-help.view.tabs.data.cols.description')}}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="attribute of attributes" :key="'tr_data_' + attribute.name">
                                            <td><code>$data.{{attribute.name}}</code></td>
                                            <td><code>{{attribute.datatype}}</code></td>
                                            <td>{{attribute | localized-name($i18n.locale)}}: {{attribute | localized-description($i18n.locale)}}</td>
                                        </tr>
                                    </tbody>
                                </v-simple-table>
                            </v-tab-item> 

                            <v-tab-item>
                                <v-simple-table >
                                    <thead>
                                        <tr>
                                            <th class="text-no-wrap">{{$t('collection-settings-wizard-help.view.tabs.functions.cols.function')}}</th>
                                            <th class="text-no-wrap">{{$t('collection-settings-wizard-help.view.tabs.functions.cols.returnValue')}}</th>
                                            <th class="text-no-wrap">{{$t('collection-settings-wizard-help.view.tabs.functions.cols.description')}}</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td><code>RANDOM_ID()</code></td>
                                            <td><code>STRING</code></td>
                                            <td>
                                                {{$t('collection-settings-wizard-help.view.tabs.functions.random_id')}}
                                                <br />
                                                {{$t('collection-settings-wizard-help.view.tabs.functions.random_id.hint')}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><code>UUID(any)</code></td>
                                            <td><code>STRING</code></td>
                                            <td>{{$t('collection-settings-wizard-help.view.tabs.functions.uuid')}}</td>
                                        </tr>
                                        <tr>
                                            <td><code>UUID_BYTES(byte[])</code></td>
                                            <td><code>STRING</code></td>
                                            <td>{{$t('collection-settings-wizard-help.view.tabs.functions.uuid_bytes')}}</td>
                                        </tr>
                                        <tr>
                                            <td><code>UUID_STRINGS(String[])</code></td>
                                            <td><code>STRING</code></td>
                                            <td>{{$t('collection-settings-wizard-help.view.tabs.functions.uuid_strings')}}</td>
                                        </tr>
                                        <tr>
                                            <td><code>DAY_OF_MONTH(DateTime)</code></td>
                                            <td><code>STRING</code></td>
                                            <td>{{$t('collection-settings-wizard-help.view.tabs.functions.day_of_month')}}</td>
                                        </tr>
                                        <tr>
                                            <td><code>DAY_OF_YEAR(DateTime)</code></td>
                                            <td><code>STRING</code></td>
                                            <td>{{$t('collection-settings-wizard-help.view.tabs.functions.day_of_year')}}</td>
                                        </tr>
                                        <tr>
                                            <td><code>WEEK(DateTime)</code></td>
                                            <td><code>STRING</code></td>
                                            <td>{{$t('collection-settings-wizard-help.view.tabs.functions.week')}}</td>
                                        </tr>
                                        <tr>
                                            <td><code>YEAR_WEEK(DateTime)</code></td>
                                            <td><code>STRING</code></td>
                                            <td>{{$t('collection-settings-wizard-help.view.tabs.functions.year_week')}}</td>
                                        </tr>
                                        <tr>
                                            <td><code>MONTH(DateTime)</code></td>
                                            <td><code>STRING</code></td>
                                            <td>{{$t('collection-settings-wizard-help.view.tabs.functions.month')}}</td>
                                        </tr>
                                        <tr>
                                            <td><code>YEAR_MONTH(DateTime)</code></td>
                                            <td><code>STRING</code></td>
                                            <td>{{$t('collection-settings-wizard-help.view.tabs.functions.year_month')}}</td>
                                        </tr>
                                        <tr>
                                            <td><code>QUARTER(DateTime)</code></td>
                                            <td><code>STRING</code></td>
                                            <td>{{$t('collection-settings-wizard-help.view.tabs.functions.quarter')}}</td>
                                        </tr>
                                        <tr>
                                            <td><code>YEAR_QUARTER(DateTime)</code></td>
                                            <td><code>STRING</code></td>
                                            <td>{{$t('collection-settings-wizard-help.view.tabs.functions.year_quarter')}}</td>
                                        </tr>
                                        <tr>
                                            <td><code>YEAR(DateTime)</code></td>
                                            <td><code>STRING</code></td>
                                            <td>{{$t('collection-settings-wizard-help.view.tabs.functions.year')}}</td>
                                        </tr>
                                         <tr>
                                            <td><code>ISO_DATE(DateTime)</code></td>
                                            <td><code>STRING</code></td>
                                            <td>{{$t('collection-settings-wizard-help.view.tabs.functions.iso_date')}}</td>
                                        </tr>
                                        <tr>
                                            <td><code>ISO_DATE_TIME(DateTime)</code></td>
                                            <td><code>STRING</code></td>
                                            <td>{{$t('collection-settings-wizard-help.view.tabs.functions.iso_date_time')}}</td>
                                        </tr>

                                        
                                    </tbody>
                                </v-simple-table>
                            </v-tab-item>

                        </v-tabs-items>
                    </v-tabs>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
import { get} from 'lodash';
export default {

    props: {

        value: {
            type: Object,
            required: true,
        }
    },

    data: () => ({
        selectedTab: 0
    }),

    computed: {

        schema() {
            return get(this.value, 'schema', {});
        },

        attributes() {

            const map = get(this.schema, 'attributes', {});
            const attributes = [];
            for (const key in map) {
                attributes.push(map[key]);
            }

            return attributes;
        }
    }


}
</script>

<i18n>
{
    "en" : {

        "collection-settings-wizard-help.view.title" : "Help",
        "collection-settings-wizard-help.view.description" : "The Document ID and the Partition ID will be created by means of the formula expressions defined in the fields above. The following documentation serves as an overview of available functions and fields.",
        
        "collection-settings-wizard-help.view.tabs.variables.title" : "Variables",
        "collection-settings-wizard-help.view.tabs.variables.now" : "Represents the current date and time.",
        "collection-settings-wizard-help.view.tabs.variables.tenantId" : "Represents the tenant's ID.",
        "collection-settings-wizard-help.view.tabs.variables.stage" : "Represents the tenant's stage, e.g. prod, qa or dev.",
        "collection-settings-wizard-help.view.tabs.variables.collectionSettingsId" : "Represent's the collection's unique ID.",
        "collection-settings-wizard-help.view.tabs.variables.schemaId" : "Represents the unique ID of the collection's schema.",
        
        "collection-settings-wizard-help.view.tabs.data.title" : "Fields",
        "collection-settings-wizard-help.view.tabs.data.cols.field" : "Name",
        "collection-settings-wizard-help.view.tabs.data.cols.datatype" : "Datatype",
        "collection-settings-wizard-help.view.tabs.data.cols.description" : "Description",
        "collection-settings-wizard-help.view.tabs.functions.title" : "Functions",
        "collection-settings-wizard-help.view.tabs.functions.cols.function" : "Function",
        "collection-settings-wizard-help.view.tabs.functions.cols.returnValue" : "Return Value",
        "collection-settings-wizard-help.view.tabs.functions.cols.description" : "Description",

        "collection-settings-wizard-help.view.tabs.functions.random_id" : "Creates a random alphanumeric ID. ",
        "collection-settings-wizard-help.view.tabs.functions.random_id.hint" : "Please Note: Do not use this function when your documents need to be updated at a later time.",
        "collection-settings-wizard-help.view.tabs.functions.uuid" : "Creates a unique ID based on the given input data.",
        "collection-settings-wizard-help.view.tabs.functions.uuid_bytes" : "Creates a unique ID based on the given byte array.",
        "collection-settings-wizard-help.view.tabs.functions.uuid_strings" : "Creates a unique ID based on the given string array",
        "collection-settings-wizard-help.view.tabs.functions.day_of_month" : "Extracts a string representing the day in month fraction from the given date time input. Examples: 2021-08-09 results in '09'.",
        "collection-settings-wizard-help.view.tabs.functions.day_of_year" : "Extracts a string representing the day in year fraction from the given date time input. Examples: 2021-01-03 results in '003' ",
        "collection-settings-wizard-help.view.tabs.functions.week" : "Extracts a string representing the week fraction from the given date time input. Examples: 2021-01-03 results in '01' ",
        "collection-settings-wizard-help.view.tabs.functions.year_week" : "Extracts a string representing the week and year fraction from the given date time input. Examples: 2021-01-03 results in '2021-01' ",
        "collection-settings-wizard-help.view.tabs.functions.month" : "Extracts a string representing the month fraction from the given date time input. Examples: 2021-04-27 results in '04' ",
        "collection-settings-wizard-help.view.tabs.functions.year_month" : "Extracts a string representing the month and year fraction from the given date time input. Examples: 2021-04-27 results in '2021-04' ",
        "collection-settings-wizard-help.view.tabs.functions.quarter" : "Extracts a string representing the qaurter fraction from the given date time input. Examples: 2021-04-27 results in '02' ",
        "collection-settings-wizard-help.view.tabs.functions.year_quarter" : "Extracts a string representing the quarter and year fraction from the given date time input. Examples: 2021-04-27 results in '2021-02' ",
        "collection-settings-wizard-help.view.tabs.functions.year" : "Extracts a string representing the year fraction from the given date time input. Examples: 2021-04-27 results in '2021' ",
        "collection-settings-wizard-help.view.tabs.functions.iso_date" : "Formats the given input as a date string according to the ISO-8601 guidelines. Examples: 2021-01-97 results in '2021-01-27' ",
        "collection-settings-wizard-help.view.tabs.functions.iso_date_time" : "Formats the given input as a date and time string according to the ISO-8601 guidelines. Examples: 2021-01-271 06:35:16 pm (GMT+02) results in '2021-01-27T18:35:16.000+02:00' "
        


    },

    "de" : {
        "collection-settings-wizard-help.view.title" : "Hilfe",
        "collection-settings-wizard-help.view.description" : "Die Dokumenten-ID und die Partitions-ID werden bei der Speicherung anhand der oben definierten Formel-Ausdrücke generiert. Die nachfolgende Auflistung gibt Ihnen einen Überblick über die zur Verfügung stehenden Datenobjekte.",
        
        "collection-settings-wizard-help.view.tabs.variables.title" : "Variablen",
        "collection-settings-wizard-help.view.tabs.variables.now" : "Repräsentiert den aktuellen Zeitpunkt (Datum und Uhrzeit).",
        "collection-settings-wizard-help.view.tabs.variables.tenantId" : "Repräsentiert die ID des aktuellen Mandanten.",
        "collection-settings-wizard-help.view.tabs.variables.stage" : "Repräsentiert die aktuelle Stufe des Mandanten , z.B. Produktion, Test.",
        "collection-settings-wizard-help.view.tabs.variables.collectionSettingsId" : "Repräsentiert die eindeutige ID des aktuellen Datenbereichs.",
        "collection-settings-wizard-help.view.tabs.variables.schemaId" : "Repräsentiert die eindeutige ID des verwendeten Schemas.",
        
        "collection-settings-wizard-help.view.tabs.data.title" : "Felder",
        "collection-settings-wizard-help.view.tabs.data.cols.field" : "Feld",
        "collection-settings-wizard-help.view.tabs.data.cols.datatype" : "Datentyp",
        "collection-settings-wizard-help.view.tabs.data.cols.description" : "Erläuterung",
        "collection-settings-wizard-help.view.tabs.functions.title" : "Funktionen",
        "collection-settings-wizard-help.view.tabs.functions.cols.function" : "Funktion",
        "collection-settings-wizard-help.view.tabs.functions.cols.returnValue" : "Rückgabewert",
        "collection-settings-wizard-help.view.tabs.functions.cols.description" : "Erläuterung",

        "collection-settings-wizard-help.view.tabs.functions.random_id" : "Erzeugt eine zufallsgenerierte ID.",
        "collection-settings-wizard-help.view.tabs.functions.random_id.hint" : "Achtung: Verwenden Sie diese Funktion nicht, wenn das Dokument später aktualisiert werden soll.",
        "collection-settings-wizard-help.view.tabs.functions.uuid" : "Erzeugt eine eindeutige ID auf Basis des übergebenen Objekts.",
        "collection-settings-wizard-help.view.tabs.functions.uuid_bytes" : "Erzeugt eine eindeutige ID auf Basis des übergebenen Byte-Arrays.",
        "collection-settings-wizard-help.view.tabs.functions.uuid_strings" : "Erzeugt eine eindeutige ID auf Basis des übergebenen String-Arrays.",
        "collection-settings-wizard-help.view.tabs.functions.day_of_month" : "Entnimmt aus dem übergebenen Datum den Wert des Tages im Monat und gibt diesen als String zurück. Beispiel: 09.08.2021 wird zu '09' ",
        "collection-settings-wizard-help.view.tabs.functions.day_of_year" : "Entnimmt aus dem übergebenen Datum den Wert des Tages im Jahr und gibt diesen als String zurück. Beispiel: 03.01.2021 wird zu '003' ",
        "collection-settings-wizard-help.view.tabs.functions.week" : "Entnimmt aus dem übergebenen Datum den Wert der Woche gibt diesen als String zurück. Beispiel: 03.01.2021 wird zu '01' ",
        "collection-settings-wizard-help.view.tabs.functions.year_week" : "Entnimmt aus dem übergebenen Datum den Wert der Woche und des Jahres gibt diesen als String zurück. Beispiel: 03.01.2021 wird zu '2021-01' ",
        "collection-settings-wizard-help.view.tabs.functions.month" : "Entnimmt aus dem übergebenen Datum den Wert des Monats gibt diesen als String zurück. Beispiel: 27.04.2021 wird zu '04' ",
        "collection-settings-wizard-help.view.tabs.functions.year_month" : "Entnimmt aus dem übergebenen Datum den Wert des Monats und des Jahres gibt diesen als String zurück. Beispiel: 27.04.2021 wird zu '2021-04' ",
        "collection-settings-wizard-help.view.tabs.functions.quarter" : "Entnimmt aus dem übergebenen Datum den Wert des Quartals gibt diesen als String zurück. Beispiel: 27.04.2021 wird zu '02' ",
        "collection-settings-wizard-help.view.tabs.functions.year_quarter" : "Entnimmt aus dem übergebenen Datum den Wert des Quartals und des Jahres gibt diesen als String zurück. Beispiel: 27.04.2021 wird zu '2021-02' ",
        "collection-settings-wizard-help.view.tabs.functions.year" : "Entnimmt aus dem übergebenen Datum den Wert des Jahres gibt diesen als String zurück. Beispiel: 27.04.2021 wird zu '2021' ",
        "collection-settings-wizard-help.view.tabs.functions.iso_date" : "Formatiert das Datum und die Uhrzeit gemäß ISO-8601 Richtline und gibt diesen String zurück. Beispiel: 27.01.2021 wird zu '2021-01-27' ",
        "collection-settings-wizard-help.view.tabs.functions.iso_date_time" : "Formatiert das Datum und die Uhrzeit gemäß ISO-8601 Richtline und gibt diesen String zurück. Beispiel: 27.01.2021 18:35:16 (GMT+02) wird zu '2021-01-27T18:35:16.000+02:00' "
               

    }
}
</i18n>