<template>
 <c-section :title="$t('order-status-dashboard.view.common.title')" :subtitle="$t('order-status-dashboard.view.common.subtitle')" class="grey lighten-3">
     <v-overlay :absolute="true" :value="loading">
            <c-data-loading-progress />
        </v-overlay>
    <v-sheet fluid class="mt-5 grey lighten-3">

        <template v-if="ordersNotEmpty">
        <!-- ==========================================================  -->
        <!--  HEADER  -->
        <!-- ==========================================================  -->
        <gemue-board :items="items" flat tile color="grey lighten-3">

            <template v-slot:board-lane-title="props">
                <div class="text-subtitle-2 text-center">
                    {{props.lane | localized-name($i18n.locale)}}
                </div>
                <div class="text-overline text-center">
                    {{props.lane.items ? props.lane.items.length : 0}} {{$t('order-status-dashboard.view.common.orders')}}
                </div>
            </template>

            <template v-slot:board-lane-body-empty>
                <v-container fluid>
                    <v-row justify="center">
                        <v-col>
                            <!--<gemue-board-lane-fill-indicator :value="{ items: [] }" :ranges="{okay: 100, warning: 200, critical: 300}" />-->
                        </v-col>
                    </v-row>
                    </v-container>
            </template>

            <template v-slot:board-lane-body="props">
                <v-container fluid>
                    <v-row justify="center">
                        <v-col>
                            <gemue-board-lane-fill-indicator :value="props" :ranges="props && props.lane ? props.lane.ranges : {}"/>
                            <!--{{JSON.stringify(props.lane)}}-->
                        </v-col>
                    </v-row>
                </v-container>
            </template>
        </gemue-board>

        <!-- ==========================================================  -->
        <!--  PRIO HIGH SEGMENT  -->
        <!-- ==========================================================  -->
        <v-divider />

        <v-subheader class="grey lighten-3 font-weight-bold">{{$t('order-status-dashboard.view.common.priority')}}: {{$t('order-status-dashboard.view.common.priority.high')}}</v-subheader>

        <gemue-board :items="items" lane-header-style="none" flat tile color="grey lighten-3">


            <template v-slot:board-lane-body="props">
                <div class="text-body-2 text-center grey lighten-1">
                    <v-container>
                        <v-row dense>
                            <v-col cols="12" v-for="(item, index) of props.items.filter(e => e.priority === 'HIGH')" :key="'boardItem_prioHigh_' + index">
                                 <gemue-order-board-card :value="item" @priority-changed="onPrioChanged" @clicked="onCardClicked({item : item})" />
                            </v-col>
                        </v-row>
                    </v-container>
                </div>
            </template>

            <template v-slot:board-lane-body-empty>
                <div class="text-body-2 text-center grey lighten-1">
                    &nbsp;
                </div>
            </template>

        </gemue-board>

        <!-- ==========================================================  -->
        <!--  PRIO MEDIUM SEGMENT  -->
        <!-- ==========================================================  -->
        <v-divider />

        <v-subheader class="grey lighten-3 font-weight-bold">{{$t('order-status-dashboard.view.common.priority')}}: {{$t('order-status-dashboard.view.common.priority.medium')}}</v-subheader>

        <gemue-board lane-header-style="none" :items="items" flat tile color="grey lighten-3">

            <template v-slot:board-lane-body="props">
                <div class="text-body-2 text-center grey lighten-1">
                    <v-container>
                        <v-row dense>
                            <v-col cols="12" v-for="(item, index) of props.items.filter(e => e.priority === 'MEDIUM')" :key="'boardItem_prioMedium_' + index" >
                                 <gemue-order-board-card :value="item" @priority-changed="onPrioChanged" @clicked="onCardClicked" />
                            </v-col>
                        </v-row>
                    </v-container>
                </div>
            </template>

            <template v-slot:board-lane-body-empty>
                <div class="text-body-2 text-center grey lighten-1">
                    &nbsp;
                </div>
            </template>

        </gemue-board>

        <v-divider />

        <!-- ==========================================================  -->
        <!--  PRIO LOW SEGMENT  -->
        <!-- ==========================================================  -->
        <v-subheader class="grey lighten-3 font-weight-bold">{{$t('order-status-dashboard.view.common.priority')}}: {{$t('order-status-dashboard.view.common.priority.low')}}</v-subheader>

        <gemue-board lane-header-style="none" :items="items" flat tile color="grey lighten-3">

            <template v-slot:board-lane-body="props">
                <div class="text-body-2 text-center grey lighten-1">
                    <v-container>
                        <v-row dense>
                            <v-col cols="12" v-for="(item, index) of props.items.filter(e => e.priority === 'LOW')" :key="'boardItem_PrioLow_' + index">
                                <gemue-order-board-card :value="item" @priority-changed="onPrioChanged" @clicked="onCardClicked" />
                            </v-col>
                        </v-row>
                    </v-container>
                </div>
            </template>

            <template v-slot:board-lane-body-empty>
                <div class="text-body-2 text-center grey lighten-1">
                    &nbsp;
                </div>
            </template>

        </gemue-board>
        </template>
        <template v-else>
            <v-container>
                <v-row>
                    <v-col>
                        <v-card tile>
                            Keine Aufträge
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </template>
    </v-sheet>

    <!-- ============================================================================= -->
    <!-- DETAIL DIALOG -->
    <!-- ============================================================================= -->
    <v-dialog transition="bottom" v-model="detailDialogVisible" width="640">
        <v-card tile>
            <v-card-text />
            <v-card-text class="text-h6">{{$t('order-status-dashboard.view.details.title')}} {{selectedItem.TVID}}</v-card-text>
            <v-divider />
            <v-subheader>{{$t('order-status-dashboard.view.details.labels.reason')}}</v-subheader>
            <v-card-text />
            <v-card-text class="body-2">
                {{selectedItem.Versuchstitel}}
            </v-card-text>
            <v-divider />
            <v-simple-table>
                <tbody>
                    <tr>
                        <td>{{$t('order-status-dashboard.view.details.labels.projectId')}}</td>
                        <td>{{selectedItem.projectNumber}}</td>
                    </tr>
                    <tr>
                        <td>{{$t('order-status-dashboard.view.details.labels.testBenchId')}}</td>
                        <td>{{selectedItem.testBenchId}}</td>
                    </tr>
                    <tr>
                        <td>{{$t('order-status-dashboard.view.details.labels.testTrackId')}}</td>
                        <td>{{selectedItem.testTrackId}}</td>
                    </tr>
                    <tr>
                        <td>{{$t('order-status-dashboard.view.details.labels.batchId')}}</td>
                        <td>{{selectedItem.batchId}}</td>
                    </tr>
                </tbody>
            </v-simple-table>
            <v-divider />
            <v-card-text />
            <v-card-actions>
                <v-btn @click="hideDetailDialog" text block>{{$t('order-status-dashboard.view.details.actions.close')}}</v-btn>
            </v-card-actions>
        </v-card>

    </v-dialog>
 </c-section>

</template>

<script>
import BoardComponent from '@/cust/components/board/board.component';
import OrderBoardCardComponent from '@/cust/components/board/order-board-card.component';
import BoardLaneFillIndicatorComponent from '@/cust/components/board/board-lane-fill-indicator.component';

const MODULE_NAME = 'orderStatusDashboard';



export default {

    data: () => ({

        detailDialogVisible: false,
        selectedItem: {},
        loading: false,
    }),

    inject: ["errorHandlerService"],

    components: {
        'gemue-board': BoardComponent,
        'gemue-order-board-card' : OrderBoardCardComponent,
        'gemue-board-lane-fill-indicator' : BoardLaneFillIndicatorComponent

    },

    methods: {

        onPrioChanged(event) {
            this.$log.debug("Updating Priority")
            this.updatePriority(event)
        },

        onCardClicked(event) {
            this.selectedItem = event.item ? event.item : {};
            this.showDetailDialog();
            //console.log(JSON.stringify(event));
        },

        showDetailDialog() {
            this.detailDialogVisible = true;
        },

        hideDetailDialog() {
            this.detailDialogVisible = false;
        },

        async updatePriority(event) {
            //console.log(JSON.stringify(event));
            this.$log.debug("Updating Priority")
            this.loading = true;
            try {
                await this.$store.dispatch(MODULE_NAME + '/updatePriority', event);
                // Wait 750ms in case the update has not been processed by elasticsearch yet.
                await this.sleep(750)
                await this.refresh()

            } catch (error) {
                this.handleError(error);
            } finally {
                this.loading = false;
            }
        },

        sleep(milliseconds) {
             return new Promise(resolve => setTimeout(resolve, milliseconds));
        },


        async refresh() {
            this.$log.debug("Starting Refresh")
            this.loading = true;
            try {
                await this.$store.dispatch(MODULE_NAME + '/loadData');

            } catch (error) {
                this.handleError(error);
            } finally {
                this.loading = false;
            }
        },

        handleError(error) {
            this.$log.debug(error.toJSON ? error.toJSON() : error );
            this.errorHandlerService.handleError(error);
        },
    },

    computed: {

        response() {
             return this.$store.getters[MODULE_NAME + "/response"];
        },

        items() {
            return this.response.items ? this.response.items : [];
        },

        ordersNotEmpty() {
            return this.items && this.items.length > 0
        }
    },

    created() {

        this.refresh();
        this.fetchInterval = setInterval(() => {
            this.refresh();
        }, 60000);
    },

    beforeDestroy() {
        clearInterval(this.fetchInterval);
    }
}
</script>

<i18n>
{
    "en" : {

        "order-status-dashboard.view.common.title" : "Order Status Dashboard",
        "order-status-dashboard.view.common.subtitle" : "This dashboard contains an overview of all current orders and their respective status.",

        "order-status-dashboard.view.common.orders" : "Orders",
         "order-status-dashboard.view.common.noOrders" : "No Orders",
        "order-status-dashboard.view.common.priority" : "Priority",
        "order-status-dashboard.view.common.priority.high" : "High",
        "order-status-dashboard.view.common.priority.medium" : "Medium",
        "order-status-dashboard.view.common.priority.low" : "Low",

        "order-status-dashboard.view.details.title" : "Order Details",
        "order-status-dashboard.view.details.labels.reason" : "Reason",
        "order-status-dashboard.view.details.labels.projectId" : "Project Id",
         "order-status-dashboard.view.details.labels.testBenchId" : "Test Bench",
        "order-status-dashboard.view.details.labels.testTrackId" : "Test Track",
        "order-status-dashboard.view.details.labels.batchId" : "Batch",
        "order-status-dashboard.view.details.actions.close" : "Close"
    },

    "de" : {

        "order-status-dashboard.view.common.title" : "Übersicht Auftragsstatus",
        "order-status-dashboard.view.common.subtitle" : "Sie finden nachfolgend eine Übersicht aller aktuellen Aufträge und deren Status.",

        "order-status-dashboard.view.common.orders" : "Aufträge",
        "order-status-dashboard.view.common.noOrders" : "Keine Aufträge",
        "order-status-dashboard.view.common.priority" : "Priorität",
        "order-status-dashboard.view.common.priority.high" : "Hoch",
        "order-status-dashboard.view.common.priority.medium" : "Mittel",
        "order-status-dashboard.view.common.priority.low" : "Niedrig",

        "order-status-dashboard.view.details.title" : "Auftrag",
        "order-status-dashboard.view.details.labels.reason" : "Versuchstitel",
        "order-status-dashboard.view.details.labels.projectId" : "Projekt-Nr.",
         "order-status-dashboard.view.details.labels.testBenchId" : "Prüfstand",
        "order-status-dashboard.view.details.labels.testTrackId" : "Prüfstrecke",
        "order-status-dashboard.view.details.labels.batchId" : "Charge",
        "order-status-dashboard.view.details.actions.close" : "Schliessen"
    }
}
</i18n>