<template>
<div>
  <table width="100%">
    <tfoot>
      <tr>
        <td id="footer-spacer"></td>
      </tr>
    </tfoot>
    <thead>
      <tr>
        <td></td>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>
            <div class="page">
              <table class="text-center" width="100%">
                <tr><td><dxs-logo-comoponent type="PRINT_HEADER"></dxs-logo-comoponent></td></tr>
                <tr><td><h3>{{collectionSettings | localized-name($i18n.locale)}}</h3></td></tr>
                <tr><td><h4>{{$t('collection-series.print.printDate')}}: {{printDate}}</h4></td></tr>
                <tr><td>{{$t('collection-series.print.totalItemCount', {totalItemCount: totalItemCountFormatted})}}</td></tr>
              </table>
              <table width="100%">
                <tr>
                  <td>
                    <img class="full-width print-img" contain :src="image">
                  </td>
                </tr>
              </table>
          </div>
          
          <div class="page mt-10">
            <table class="text-center" width="100%">
              <tr>
                <td><h4 class="mb-5">{{$t('dashboard.view.displayFilter.title')}}</h4></td>
              </tr>
              <tr>
                <td>
                  <table class="table-print">
                    <thead>
                      <tr scope="row">
                        <th>{{$t('filter.i18n.name')}}</th>
                        <th>{{$t('value.i18n.name')}}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{{$t('collections.common.view.attribute.title')}}</td>
                        <td>{{form.attribute | localizedName($i18n.locale)}}</td>
                      </tr>
                      <tr>
                        <td>{{$t('collection-series.view.max-num-cols.label')}}</td>
                        <td>{{form.interval}}</td>
                      </tr>
                      <tr>
                        <td>{{$t('collection-series.view.rowAttributes.search-query.label')}}</td>
                        <td>{{filter.filterQuery}}</td>
                      </tr>
                      <tr v-for="facetFilter in filter.facetFilters" :key="facetFilter.name">
                        <td>{{attributes[facetFilter.name] | localized-name($i18n.locale)}}</td>
                        <td>{{facetFilter.filterQuery}}</td>
                      </tr>
                      <tr v-for="rangeFilter in filter.rangeFilters" :key="rangeFilter.name">
                        <td>{{attributes[rangeFilter.name] | localized-name($i18n.locale)}}</td>
                        <td>{{$t('collection-series.print.from')}} {{rangeFilter.from}} {{$t('collection-series.print.until')}} {{rangeFilter.until}}</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </table>
          </div>
          <!-- Show table here -->
          <div class="page mt-10" v-if="showTable">
            <table class="text-center" width="100%">
              <tr>
                <td :colspan="2"><h4 class="mb-5">{{$t('collection-distribution.view.title')}}</h4></td>
              </tr>
              <tr valign="top">
                <td>
                  <div class="overflow--hidden print-overflow-visible">
                    <table class="table-print">
                      <thead>
                      <tr>
                        <th>{{$t('collections.common.view.value.title')}}</th>
                        <th class="text-right">{{$t('collections.common.view.frequency-absolute.title')}}</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="bucket in response.data.buckets" :key="bucket.name">
                        <td class="text-left">{{bucket.name}}</td>
                        <td class="text-right">
                          {{bucket.count | decimal($i18n.locale)}}
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </td>
              </tr>
            </table>
          </div>

        </td>
      </tr>
    </tbody>
  </table>

  <div class="print-footer text-center">
    <div class="footer-item">
      {{$t('collection-series.print.printedOn')}}: {{printDate}}
      <div class="mt-2">
        <dxs-logo-comoponent type="PRINT_FOOTER"></dxs-logo-comoponent>
      </div>
    </div>
    <div class="footer-item">{{$t('collection-series.print.printedBy')}}: {{user.userName}}</div>
    <div class="footer-item">
      <span class="d-block">{{collectionSettings | localized-name($i18n.locale)}}</span>
      <span class="d-block black--text"><strong>{{$t('common.print.confidential')}}</strong></span>
    </div>
  </div>
</div>
</template>

<script>
import Vue from 'vue';
import get from 'lodash/get';
import LogoComponent from '@/components/logo.component'

const MODULE_NAME = "collectionDistribution";

export default {
  data: () => ({
  }),


  components: {
    'dxs-logo-comoponent' : LogoComponent
  },

  computed: {
    image() {
      return this.$route.params.image;
    },

    showTable() {
      return this.$route.params.type === 'ALL';
    },
    
    response() {
      return this.$store.getters[MODULE_NAME + '/response'] || {};
    },

    pagination() {
            return get(this.response, 'data.pagination', {});
    },

    totalItemCount() {
        return this.pagination.totalItemCount || 0;
    },

    totalItemCountFormatted() {
        return this.$core.filters.numericFormat(this.totalItemCount, '#,###', this.$i18n.locale);
    },

    collectionSettings() {
      return this.$store.getters[MODULE_NAME + "/collectionSettings"]
    },

    schema() {
      return this.$store.getters[MODULE_NAME + "/schema"]
    },

    attributes() {
      return get(this.schema, 'attributes', {});
    },

    filter() {
      return this.$store.getters[MODULE_NAME + "/filter"]
    },

    user() {
      return Vue.$user;
    },

    printDate() {
      const d = new Date();
      return `${d.toLocaleDateString(this.$i18n.locale)} ${d.toLocaleTimeString(this.$i18n.locale)}`;
    },

    form() {
      return this.$store.getters[MODULE_NAME + '/form'];
    }
  },

  methods: {
    getAttributes(seriesAttributes) {
      const attributesList = [];
      const attributes = seriesAttributes.map(groupingAttribute => groupingAttribute.attribute);

      attributes.forEach(attribute => {
        attributesList.push(this.$core.filters.localizedName(this.attributes[attribute], this.$i18n.locale));
      });

      return attributesList.join(',');
    },

    getSerieItemValue(item, serie) {
      const seriesItem = serie.items.find(_item => _item.label === item.label);
      return seriesItem && seriesItem.value ? seriesItem.value : '-';
    },

    getSeriesItemByLabelValue(series, label) {
      const seriesItem = series.items.find(item => item.label === label);
      return seriesItem && seriesItem.value ? seriesItem.value : '-';
    }
  },

  beforeMount() {
    /** allow only access when chart image and print type is set
     * navigate to root page
     */
    if (!this.image && !this.showTable) {
      this.$router.push("/");
    }
  }
};
</script>
<style lang="scss" scoped>
.table {
  width: 100%;
}
</style>

<i18n>
{
  "en" : {
    "collection-series.view.groupingAttributes.label": "Attributes for Columns",
    "collection-series.view.rowAttributes.label": "Attribute for Segments",
    "collection-series.view.rowAttributes.search-query.label": "Search query"
  },

  "de" : {
    "collection-series.view.groupingAttributes.label": "Attribute für Spalten",
    "collection-series.view.rowAttributes.label": "Attribute für Segmente",
    "collection-series.view.rowAttributes.search-query.label": "Suchabfrage"
  }
}
</i18n>