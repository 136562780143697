import api from '@/store/api';

export default {

    namespaced : true,

    state: {

        orders: [],
        priorities: {}
    },

    mutations: {

        setOrders(state, orders) {
            state.orders = orders ? orders : [];
        },

        setPriorities(state, priorities) {
            state.priorities = priorities ? priorities : {};
        },
    },

    actions: {


        async loadData(context) {

            let orders = [];

            // ================== Construct the query

            const collectionId = "Orders";
            const query = {
                filter : {
                    facetFilters: [],
                    filterQuery: '*',
                    filterQueryLanguage : 'SIMPLE',
                    rangeFilters : []
                },
                sort:{
                    sortCriteria : []
                },
                pagination : {
                    from : 0,
                    searchAfter : [],
                    size : 500
                },
                queryTimeoutMiliseconds : 25000
            };


            let response = await api.collections.documents.findByQuery(collectionId, query);
            if (response.items) {
                for (let item of response.items) {
                    orders.push(item.data ? item.data : {})
                }

            }

            context.commit('setOrders', orders);

            const TVIDS = orders.map(e => e.TVID);

            const queryOrder = {
                filter : {
                    facetFilters: [
                        {
                            name: "TVID",
                            filterMode : "CONTAINS",
                            filterQuery: "*",
                            values: TVIDS
                        }
                    ],
                    filterQuery: '*',
                    filterQueryLanguage : 'SIMPLE',
                    rangeFilters : []
                },
                sort:{
                    sortCriteria : []
                },
                pagination : {
                    from : 0,
                    searchAfter : [],
                    size : 500
                },
                queryTimeoutMiliseconds : 25000
            };

            // Next add the priorities...
            let priorities = {};

            response = await api.collections.documents.findByQuery("OrderPriority", queryOrder);
            if (response.items) {
                for (let item of response.items) {
                    if(item.data && item.data.siteId && item.data.TVID && item.data.priority) {
                        priorities[item.data.TVID] = item.data.priority
                    }
                }
            }

            //console.log(JSON.stringify(priorities))

            context.commit('setPriorities', priorities);
        },

        async updatePriority(context, event) {

            const collectionId = "OrderPriority"
            const siteId = event.item.siteId
            const TVID = event.item.TVID
            const priority = event.priority

            // ================== Construct the query
            const documentRecords = [
                {
                    data : {
                        siteId : siteId,
                        TVID : TVID,
                        priority: priority
                    },
                    i18n : {},
                    embedded : {}
                }
            ]

            await api.collections.documents.import(collectionId, documentRecords);
        },
    },

    getters: {

        response(state) {
            //console.log(state)

            let orderMap = new Map();
            for (let order of state.orders) {
                //console.log(">>> " + JSON.stringify(order.projectState));

                //order.priority = 1
                order.priority = state.priorities[order.TVID] ? state.priorities[order.TVID] : 'LOW'
                //console.log("+++" + order.TVID + ": " + order.priority)
                if(order.projectState) {
                    if (!orderMap.has(order.projectState)) {
                        orderMap.set(order.projectState, [])
                    }

                    orderMap.get(order.projectState).push(order);
                }
                else {
                    console.log("The following order does currently not have a projectState and is therefore not displayed: " + order.TVID)
                }
            }

            return {
                items: [
                    {
                        name: '1',
                        i18n : {
                            de : {

                                name: 'Auftrag angelegt',
                                shortName: 'Angelegt',
                                description: 'Der Auftrag wurde angelegt'

                            },

                            en : {

                                name: 'Order created',
                                shortName: 'Created',
                                description: 'Order has been created'

                            }
                        } ,

                        ranges: {okay: 25, warning: 50, critical: 60},

                        items: orderMap.has('1') ? orderMap.get('1') : []
                    },
                    {
                        name: '2',
                        i18n : {
                            de : {

                                name: 'Auftrag in Vorbereitung',
                                shortName: 'In Vorbereitung',
                                description: 'Der Auftrag wird vorbereitet'

                            },

                            en : {

                                name: 'Order in Preparation',
                                shortName: 'In Preparation',
                                description: 'Order is currently prepared'

                            }
                        } ,
                        ranges: {okay: 5, warning: 7, critical: 10},
                        items: orderMap.has('2') ? orderMap.get('2') : []

                    },
                    {
                        name: '3',
                        i18n : {
                            de : {

                                name: 'Auftrag In Wartestellung',
                                shortName: 'In Wartestellung',
                                description: 'Der Auftrag ist on hold'

                            },
                            en : {

                                name: 'Order on Hold',
                                shortName: 'On Hold',
                                description: 'The order is on hold'

                            }
                        } ,
                        ranges: {okay: 10, warning: 15, critical: 20},
                        items: orderMap.has('3') ? orderMap.get('3') : []
                    },
                    {
                        name: '4',
                        i18n : {
                            de : {

                                name: 'Auftrag läuft',
                                shortName: 'Läuft',
                                description: 'Der Auftrag läuft aktuell'

                            },

                            en : {

                                name: 'Order in Progress',
                                shortName: 'In Progress',
                                description: 'The order is currently in progress'

                            }
                        } ,
                        ranges: {okay: 100, warning: 125, critical: 150},
                        items: orderMap.has('4') ? orderMap.get('4') : []
                    },
                    {
                        name: '5',
                        i18n : {
                            de : {

                                name: 'Auftrag in ZW',
                                shortName: 'In ZW',
                                description: 'Der Auftrag ist in ZW'

                            },

                            en : {

                                name: 'Order in ZW',
                                shortName: 'In ZW',
                                description: 'The order is in ZW'

                            },
                        } ,
                        ranges: {okay: 5, warning: 7, critical: 10},
                        items: orderMap.has('5') ? orderMap.get('5') : []
                    },
                    {
                        name: '6',
                        i18n : {
                            de : {

                                name: 'Auftrag in Untersuchung',
                                shortName: 'In Untersuchung',
                                description: 'Der Auftrag ist in ZW'

                            },

                            en : {

                                name: 'Order in Inspection',
                                shortName: 'In Inspection',
                                description: 'The order is in ZW'

                            },


                        } ,
                        ranges: {okay: 5, warning: 7, critical: 10},
                        items: orderMap.has('6') ? orderMap.get('6') : []
                    },
                    {
                        name: '7',
                        i18n : {
                            de : {

                                name: 'Bericht wird erstellt',
                                shortName: 'Bericht wird erstellt',
                                description: 'Der Auftrag ist in ZW'

                            },

                            en : {

                                name: 'Order in Documentation',
                                shortName: 'In Documentation',
                                description: 'The order is currently documented'

                            }
                        } ,
                        ranges: {okay: 10, warning: 15, critical: 20},
                        items: orderMap.has('7') ? orderMap.get('7') : []
                    },
                    {
                        name: '8',
                        i18n : {
                            de : {

                                name: 'Auftrag fertig',
                                shortName: 'Fertig',
                                description: 'Der Auftrag ist fertig'

                            },

                            en : {

                                name: 'Order is Finished',
                                shortName: 'Finished',
                                description: 'The order has been finished'

                            }
                        } ,

                        ranges: {okay: 100, warning: 125, critical: 150},
                        items: orderMap.has('8') ? orderMap.get('8') : []
                        /*items: [
                            {tvId: 'TV_EtIl1', testBenchId: '03', testTrackId: '04', batchId: 'A00345', status: 3, prio: 1, reason: 'Beschreibungstext mit sehr viel Überlänge, der ganz sicher niemals, nimmer und unter keinen Umständen in das Layout passen wird.'},
                            {tvId: 'TV_EtI89', testBenchId: '12', testTrackId: '01', batchId: 'B00888', status: 3, prio: 2,},
                            {tvId: 'TV_EtI89', testBenchId: '19', testTrackId: '01', batchId: 'C00778', status: 3, prio: 3},
                        ]*/
                    }
                ]
            }
        }
    }


}
