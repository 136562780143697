import api from '@/store/api';

export default {

    namespaced: true,

    state : {
        response: getDefaultResponse(),
        collectionSettings: [],
        selectedCollectionSetting: null,
        selectedFileFormat: null,
        fileName: null,
        data : ""
    },

    mutations: {

       setCollectionSettings(state,collectionSettings) {
           state.collectionSettings = collectionSettings ? collectionSettings : []
       },

       setSelectedCollectionSetting(state, selectedCollectionSetting) {
           state.selectedCollectionSetting = selectedCollectionSetting;
       },

       setSelectedFileFormat(state, selectedFileFormat) {
        state.selectedFileFormat = selectedFileFormat;
    },
       

       updateData(state, new_data) {
           state.data = new_data
       },
       
       updateFileName(state, fileName) {
           state.fileName = fileName;
       },

       setResponse(state, response) {
            state.response = response;
       }
    },

    actions: {
        async loadResponse(context) {
            try {
                let response = null;
                context.commit('setResponse', response);
            } catch (error) {
                console.log("An error has occured while loading response: " + error);
                throw error;
            }
        },

        async loadCollectionSettings(context) {
            try {
                let response = await api.catalog.collectionSettings.findAll('*');
                let collectionSettings = [];
                for (let item of response) {
                    collectionSettings.push(item.collectionSettings);
                }
                context.commit('setCollectionSettings', collectionSettings);
            } catch (error) {
                console.log("An error has occured while loading collection settings: " + error);
                throw error;
            }
        },
       async uploadFile(context) {
            try {
                let response = await api.upload.uploadFile(context.getters.selectedCollectionSetting.itemName, context.getters.data, context.getters.fileName, context.getters.fileFormat);
                context.commit('setResponse', response);
                
            } catch (error) {
                console.log("An error has occured while uploading file: " + error);
                throw error;
              }
        },
        async updateData(context, {data}) {
            try {
                context.commit('updateData', data);
            } catch (error) {
                console.log("An error has occured while updating data: " + error);
                throw error;              
            }
        },
        async updateFileName(context, fileName) {
            try {
                context.commit('updateFileName', fileName);
            } catch (error) {
                console.log("An error has occured while updateing file name: "+ error);
                throw error;
            }
        }
    },

    getters: {
        collectionSettings(state) {
            return state.collectionSettings ? state.collectionSettings : [];
        },
        selectedCollectionSetting(state) {
            return state.selectedCollectionSetting;
        },
        data(state) {
            return state.data ? state.data : "";
        },
        fileName(state) {
            return state.fileName ? state.fileName : "";
        },
        fileFormat(state) {
            return state.selectedFileFormat ? state.selectedFileFormat.itemName : "";
        },
        response(state) {
            return state.response ? state.response : getDefaultResponse();
        },
    }
}

function getDefaultResponse() {
    return {
        result: null
    };
}
