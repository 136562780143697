<template>
    <v-list-item :to="path">
        <v-list-item-action>
            <v-icon>{{navigation.icon}}</v-icon>
        </v-list-item-action>
        <v-list-item-content>
            <v-list-item-title :title="navigation | localized-name($i18n.locale)">{{navigation | localized-name($i18n.locale)}}</v-list-item-title>
        </v-list-item-content>
    </v-list-item>
</template>

<script>


export default {

    props: {

        route: {
            type: Object,
            required: true
        }
    },



    computed: {


        path() {
            return this.route && this.route.path ? this.route.path : null; 
        },

        meta() {
            return this.route && this.route.meta ? this.route.meta : {};
        },

        navigation() {
            return this.meta.navigation ? this.meta.navigation : {}; 
        },

        authorization() {
            return this.meta.authorization ? this.meta.authorization : {}; 
        }
    }
}
</script>

<style>

</style>