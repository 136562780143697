<template>
  <v-sheet>
    <!-- *********************************************************************** -->
    <!-- TOOLBAR                                                                 -->
    <!-- *********************************************************************** -->
  <template v-if="hasMeasures && hasTimeDimensions">
    <v-container fluid grid-list-md class="grey lighten-4">
      <v-row justify="center">
        <v-col :cols="12">
          <c-filter-query-input
            :value="{ query: '*' }"
            :disabled="loading"
            :loading="loading"
            @filter-query-changed="onFilterQueryChange"
          />
        </v-col>
      </v-row>
    </v-container>

    <v-divider />
    <v-toolbar flat color="white">
      <v-toolbar-title>{{
        $t("collection-series.view.results", {totalItemCount: totalItemCountFormatted})        
      }}</v-toolbar-title>
      <v-btn icon @click="toogleQuerySummary">
        <v-icon v-if="!querySummary">expand_more</v-icon>
        <v-icon v-else>expand_less</v-icon>
      </v-btn>
      <v-spacer />
      <v-spacer />
      <v-toolbar-items>
        <v-btn icon @click="getMetricData" :disabled="!formValid">
          <v-icon>refresh</v-icon>
        </v-btn>
        <v-btn icon @click="filterDialog = !filterDialog">
          <v-icon>filter_list</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>

     <!-- Filter Settings Sidebar -->
    <v-navigation-drawer 
      fixed
      right
      temporary
      v-model="filterDialog"
      width="500"
    >
      <c-filter-settings :value="filter" :schema="schema" @filter-changed="onFilterChanged"  />
    </v-navigation-drawer>

    <v-divider />

    <template v-if="querySummary">
      <v-divider />
      <c-query-summary :value="{ filter: filter, meta: schema }" />
    </template>
    <template>
      <v-card>
        <v-divider />
        <v-card-text>
          <v-row>
            <v-col
            cols="12"
              sm="6"
              xl="6">
              <c-attribute-single-selector
                v-model="measure"
                :label="this.$t('collection-series.view.measure.label')"
                filled
                :items="{ ...attributes }"
                :filter="measureFilter"
               />
            </v-col>
            <v-col
            cols="12"
              sm="6"
              xl="6">
              <c-attribute-single-selector
                v-model="timeDimension"
                :label="this.$t('collection-series.view.timeDimension.label')"
                filled
                :items="{ ...attributes }"
                :filter="timeDimensionFilter"
              />
            </v-col>
            <v-col cols="12">
               <c-item-multi-selector chips
                v-model="groupAttributes"
                :label="this.$t('collection-series.view.groupBy.label')"
                filled
                :items="attributesGroupBy"
              />
            </v-col>
          </v-row>
          
        </v-card-text>
       
        <v-container fluid>
          <v-btn
            :disabled="!formValid"
            block
            color="accent"
            v-on:click="getMetricData"
            ><v-icon class="mr-2">input</v-icon>
            {{ $t("collection-metrics.view.showResult.title") }}
          </v-btn>
        </v-container>
      </v-card>
      <v-divider />
      <v-sheet class="pa-5" color="grey lighten-3">
        <v-container fluid v-if="hasMetrics && formValid">
          <v-row v-if="items.length > 1">
            <v-col
              cols="12"
              sm="4"
              xl="3"
              v-for="group in items"
              :key="group.name"
            >
              <c-collection-group-tile
                :value="getGroupValue(group)"
              >
                <template v-slot:collection-group-tile-actions>
                  <v-btn block text @click="openDetailDialog(group)">
                    {{$t("collection-metrics.view.dialog.open.label")}}
                  </v-btn>
                </template>
              </c-collection-group-tile>
            </v-col>

            <!-- Load More -->
            <v-col v-if="formSent && moreElements" cols="12" sm="4" xl="3">
                <v-card tile class="accent text-center" @click="onLoadMore" height="100%">
                    <v-card-text>
                        <v-icon x-large color="white pa-10">arrow_downward</v-icon>
                    </v-card-text>
                    <v-card-text class="text-h6 white--text">
                        {{$t('collection-documents.view.actions.loadMore')}}
                    </v-card-text>
                    <v-card-text class="white--text">
                        {{$t('collection-documents.view.results.currentResultCount', {currentResultCount: items.length})}}
                        <br />
                        {{$t('collection-documents.view.results.adjustFilterLessResults')}}
                    </v-card-text>
                </v-card>
              </v-col>
          </v-row>
          <v-row  v-if="items.length === 1">
            <v-col
              cols="12"
              v-for="group in items"
              :key="group.name"
            >
              <c-collection-group-tile
                :value="getGroupValue(group)"
              >
                <template v-slot:collection-group-tile-actions>
                    <v-btn block text @click="openDetailDialog(group)">
                        {{$t("collection-metrics.view.dialog.open.label")}}
                    </v-btn>
                </template>
              </c-collection-group-tile>
              <v-divider/>
              
            </v-col>
            
          </v-row>
        </v-container>
                 
        <!-- CHECK WHY THERE IS NO DATA -->
        <v-container v-else>
          <!-- FORM HAS NOT BEEN SUBMITTED YET -->
          <div v-if="(!hasMetrics || !formValid) && !formSent">
            <v-card tile flat>
              <v-card-text>
                <p />
                <div class="text-center">
                  <v-icon color="primary" large>info</v-icon>
                </div>
                <div class="title text-center">
                  {{ $t("collection-metrics.view.configuration.title") }}
                </div>
                <div class="text-center">
                  {{ $t("collection-metrics.view.configuration.description") }}
                </div>
                <div class="text-center">
                  <v-chip
                    v-for="formField in formFields"
                    :outlined="!formField.isValid"
                    :key="formField.name"
                    class="ma-2"
                    :input-value="formField.isValid"
                    filter
                    >{{ formField.text }}</v-chip
                  >
                </div>
              </v-card-text>
            </v-card>
          </div>
          <!-- QUERY DOES NOT MATCH ANY RESULTS -->
          <c-empty-result-tile v-else />
        </v-container>
      </v-sheet>
    </template>
  </template>
    <template v-else>
    <v-card tile flat >
      <v-card-text>
          <p />
          <div class="text-center"><v-icon color="accent" large>warning</v-icon></div>
          <div class="title text-center">{{$t('collections-metrics.view.noData.title')}}</div>
          <div class="text-center">{{$t('collections-metrics.view.noData.subtitle')}}</div>
      </v-card-text>
    </v-card>
    </template>

      <v-dialog fullscreen v-model="groupWithRequest" v-if="showDetailDialog">

        <v-sheet class="grey">
          <v-toolbar color="primary" flat>
            <v-btn dark icon @click.stop="closeDetailDialog()" >
              <v-icon>close</v-icon>
            </v-btn>
            <v-toolbar-title class="white--text">{{ selectedGroup.name }}</v-toolbar-title>
          </v-toolbar>
          <!-- <v-chip class="white ma-3" label>
            {{ numberOfDocuments }} {{$t("collection-metrics.view.group.elements.number.label")}}
          </v-chip> -->
          <v-divider/>
          <c-collection-group-dialog v-model="groupWithRequest" ref="groupDialog"/>
        </v-sheet>
      </v-dialog>
      

  </v-sheet>
</template>

<script>
const MODULE_NAME = "collectionMetrics";

import { get, isEmpty } from "lodash";
import CollectionGroupTileComponent from "@/core/components/collections/collection-group-tile.component.vue";
import CollectionGroupDialogComponent from "@/core/components/collections/collection-group-dialog.component.vue";

export default {
  components: {
    "c-collection-group-tile": CollectionGroupTileComponent,
    "c-collection-group-dialog": CollectionGroupDialogComponent,
  },
  inject: ["errorHandlerService", "progressIndicatorService"],
  props: {
    value: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    dialog: false,
    loading: false,
    querySummary: false,
    filterDialog: false,
    showDetailDialog: false,
    selectedGroup: null,
  }),

  computed: {

    metrics() {
      return this.$store.getters[MODULE_NAME + "/metrics"];
    },
    items() {
      return get(this.metrics, "items", []);
    },
    collectionSettings() {
      return get(this.metrics, 'collectionSettings', {name: null});
    },
    groupWithRequest(){
      return { 
          group: this.selectedGroup, 
          request: this.request, 
          collectionSettings: this.collectionSettings,
          schema: this.schema};
    },
    collectionId() {
      return get(this.value, "collectionId", null);
    },
    schema() {
      return this.$store.getters[MODULE_NAME + "/schema"];
    },
    pagination() {
      return get(this.metrics, "pagination", {});
    },
    attributes() {
      const attributes = get(this.schema, "attributes", {});
      const attributeList = [];
      Object.keys(attributes).forEach((key) => {
        attributeList.push(attributes[key]);
      });

      return attributeList;
    },
    totalItemCountFormatted() {
            const totalItemCount = this.items.length;
            return this.$core.filters.numericFormat(totalItemCount, '#,###', this.$i18n.locale);
        },
    moreElements() {
      return this.items.length % 5  == 0 && this.items.length > 0;
    },
    attributesGroupBy() {
      var result = [];
      for (let attribute of this.attributes) {
        if (this.dimensionFilter(attribute)) {
          result.push(attribute);
        }
      }
      return result;
    },
    
    measure: {
      get() {
        return this.$store.getters[MODULE_NAME + "/measure"];
      },

      set(value) {
        this.$store.commit(MODULE_NAME + "/setMeasure", value);
      },
    },
    timeDimension: {
      get() {
        return this.$store.getters[MODULE_NAME + "/timeDimension"];
      },

      set(value) {
        this.$store.commit(MODULE_NAME + "/setTimeDimension", value);
      },
    },
    groupAttributes: {
      get() {
        return this.$store.getters[MODULE_NAME + "/groupAttributes"];
      },

      set(value) {
        this.$store.commit(MODULE_NAME + "/setGroupAttributes", value);
      },
    },
    formValid() {
      return this.$store.getters[MODULE_NAME + "/formValid"];
    },
    formSent() {
      return this.$store.getters[MODULE_NAME + "/formSent"];
    },
    hasMetrics() {
      return get(this.metrics, "items", []).length > 0;
    },
    hasMeasures() {
      for (let attribute of this.attributes) {
        if (this.$dxs.attributes.isMeasure(attribute)) {
          return true;
        }
      }
      return false;
    },
    hasTimeDimensions() {
      for (let attribute of this.attributes) {
        if (
          this.$dxs.attributes.isDimension(attribute) &&
          this.$dxs.attributes.isTypeDateTime(attribute)
        ) {
          return true;
        }
      }
      return false;
    },
    formFields() {
      return [
        {
          name: "measure",
          text: this.$t("collection-series.view.measure.label"),
          value: this.measure,
          isValid: !isEmpty(this.measure),
        },
        {
          name: "timeDimension",
          text: this.$t("collection-series.view.timeDimension.label"),
          value: this.timeDimension,
          isValid: !isEmpty(this.timeDimension),
        },
      ];
    },
    request(){
      return this.$store.getters[MODULE_NAME + "/request"];
    },
    numberOfDocuments(){
      return get(this.selectedGroup, "documents", []).length
    },
    filter(){
      return this.$store.getters[MODULE_NAME + "/filter"];
    }
  },
  methods: {
    async getMetricData() {
      try {
        this.progressIndicatorService.show();
        await this.$store.dispatch(MODULE_NAME + "/loadMetricData", {
          collectionId: this.collectionId,
        });
      } catch (error) {
        this.errorHandlerService.handleError(error);
      } finally {
        this.progressIndicatorService.hide();
      }
    },
    async onLoadMore() {

      this.progressIndicatorService.show();

      // if (this.items.length && this.pagination.size) {
      //   this.progressIndicatorService.updateText({ from: this.items.length + this.pagination.size, to: "Unknown" })
      // }
            
      try {
        await this.$store.dispatch(MODULE_NAME + '/loadMore', {collectionId: this.collectionId});
      } catch (error) {
        this.errorHandlerService.handleError(error);
      } finally {
        this.progressIndicatorService.hide();
        this.submitted = true;
      }
    },
    getGroupValue(group){
      return {group: group, request: this.request}
    },
    onFilterChanged(event) {
      this.$log.debug("Filter Changed::: " + JSON.stringify(event));
      this.$store.dispatch(MODULE_NAME + '/updateFacetFilters', {collectionId: this.collectionId, facetFilters: event});
    },
    measureFilter(value) {
      return this.$dxs.attributes.isMeasure(value);
    },
    timeDimensionFilter(value) {
      return (
        this.$dxs.attributes.isDimension(value) &&
        this.$dxs.attributes.isTypeDateTime(value)
      );
    },
    dimensionFilter(value) {
      return this.$dxs.attributes.isDimension(value);
    },
    async onFilterQueryChange(event) {
      this.progressIndicatorService.show();
      const query = {
        collectionId: this.collectionId,
        filterQuery: {
          filterQuery: event.query,
          filterQueryLanguage: event.mode,
        },
      };

      try {
        await this.$store.dispatch(MODULE_NAME + "/updateFilterQuery", query);
      } catch (error) {
        this.errorHandlerService.handleError(error);
      } finally {
        this.progressIndicatorService.hide();
      }
    },
    toogleQuerySummary() {
      this.querySummary = !this.querySummary;
    },
    openDetailDialog(group){
      this.showDetailDialog = true;
      this.selectedGroup = group;
    },
     closeDetailDialog(){
      this.showDetailDialog = false;
      this.selectedGroup = null;
    },
  },
  created() {
    this.$store
      .dispatch(MODULE_NAME + "/clearMetrics", {})
    this.$store
      .dispatch(MODULE_NAME + "/loadCollectionSettings", {
        collectionId: this.collectionId,
      })
      .finally(() => this.progressIndicatorService.hide());
  },
};
</script>

<i18n>
    {
        "en" : {
            "collection-metrics.view.showResult.title": "SHOW RESULT",
            "collection-metrics.view.configuration.title": "Configure a Measure, Time Dimension and the Attributes to group by",
            "collection-metrics.view.configuration.description": "To perform a metric analysis, please select at least the following:",
            "collection-metrics.view.group.elements.number.label": "Elements",
            "collections-metrics.view.noData.title" : "Metrics Analysis is not Available",
            "collections-metrics.view.noData.subtitle" : "This collection does not consist of measures and/or time dimensions to generate metrics.",
            "collections-metrics.view.noData.hint.text" : "In order to show metrics, at least one measure and time dimension must be available.",

            "collection-metrics.view.dialog.open.label": "Further Details..."

        },
        "de" : {
            "collection-metrics.view.showResult.title": "ERGEBNIS ANZEIGEN",
            "collection-metrics.view.configuration.title": "Konfigurieren sie eine Kennzahl, Zeitdimension und die Attribute nach denen gruppiert werden soll",
            "collection-metrics.view.configuration.description": "Um eine metrische Analyse ausführen zu können, wählen sie bitte mindestens:",
            "collection-metrics.view.group.elements.number.label": "Elemente",
            "collections-metrics.view.noData.title" : "Metrikenanalyse nicht verfügbar",
            "collections-metrics.view.noData.subtitle" : "Dieser Datenbereich enthält keine Kennzahlen und/oder Zeit-Dimensionen um Metriken anzuzeigen.",
            "collections-metrics.view.noData.hint.text" : "Um Metriken anzeigen zu können, muss mindestens eine Kennzahl und eine Zeit-Dimension verfügbar sein.",

            "collection-metrics.view.dialog.open.label": "Weitere Details..."
        }
    }
</i18n>
