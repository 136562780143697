<template>
    <v-card :color="backgroundCssClass"  flat height="100%" >
        
        <template v-if="displayHeader">
            <v-card-text>
                <slot name="board-lane-title" v-bind:items="items" v-bind:title="title"> 
                    <div class="text-subtitle-1">{{title | localized-name($i18n.locale) | truncate(24)}} ({{itemCount}})</div>
                </slot>
            </v-card-text>
            
            <slot name="board-lane-header" v-bind:items="items" v-bind:title="title">
                <!-- Default implementation is emtpy -->
            </slot>
        </template>

        <template v-if="emptyLane">
            <slot name="board-lane-body-empty">
                <v-container >
                    <v-row dense>
                        <v-col cols="12">
                            <div class="text-body">{{$t('board-lane.component.noItems')}}</div>
                        </v-col>
                    </v-row>
                </v-container>
            </slot>
        </template>
        <template v-else>
            <slot name="board-lane-body" >
            <v-container>
                <v-row dense>
                    <v-col cols="12" v-for="(item, index) of items" :key="'boardItem_' + index" @click="fireClickEvent(item, index)">
                        <slot name="board-lane-item" v-bind:item="item" v-bind:index="index" >                            
                            <gemue-board-item-card :value="item" />
                        </slot>
                    </v-col>
                </v-row>
            </v-container>
            </slot>
        </template>
        <slot name="board-lane-footer" v-bind:items="items" v-bind:title="title">
            <!-- Default implementation is emtpy -->
        </slot>
    </v-card>
</template>

<script>
import _ from 'lodash';
import BoardItemCardComponent from '@/cust/components/board/board-item-card.component';

export default {

    name: 'board-lane',

    props: {

        title: {
            type : [Object, String],
            required: false,
        },

        items: {
            type : [Object, Array],
            required: false,
            default: () => { return []; }
        },

        color: {
            type: String,
            required: false
        },

        /**
         * Default, full, none
         */
        headerStyle: {
            type: String,
            required: false,
            default: 'default'
        }
    },

    components: {
        'gemue-board-item-card': BoardItemCardComponent
    },

    methods: {

        fireClickEvent(item, index) {
            
            this.$emit('item-click', {
                item: item,
                index: index
            })
        }
    },

    computed: {

        displayHeader() {
            return this.headerStyle !== 'none';
        },

        emptyLane() {
            return _.isEmpty(this.items);
        },

        itemCount() {
            return this.items ? this.items.length : 0;
        },

        backgroundCssClass() {
            return this.color ? this.color : 'grey lighten-1'
        }
    }
}
</script>

<i18n>
{
    "en" : {
        "board-lane.component.noItems" : "No Items to display" 
    },

    "de" : {
        "board-lane.component.noItems" : "Keine Elemente zur Anzeige vorhanden" 
    }
}
</i18n>