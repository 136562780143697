<template>
<div>

    <c-editable-item-list 
        :title="$t('attribute-edit-list.component.title')" 
        @item-updated="fireItemUpdated" 
        @item-removed="fireItemRemoved" 
        :items="attributes"
        :new-item="emptyItem" 
        :readonly="readonly" 
        :flat="flat" 
        :headerStyle="headerStyle"
        :editDialogStyle="editDialogStyle"
        :disabled="disabled"
        :check-duplicate="(e1, e2) => (e1.name === e2.name)"
    >

        <!-- Custom Header -->
        <template v-slot:item-list-head>
            <thead>
                <tr>
                    <th>&nbsp;</th>
                    <th>&nbsp;</th>
                    <th>{{$t('attribute-edit-list.component.columns.name')}}</th>
                    <th>{{$t('attribute-edit-list.component.columns.translatedName')}}</th>
                    <th>{{$t('attribute-edit-list.component.columns.key')}}</th>
                    <th>{{$t('attribute-edit-list.component.columns.optional')}}</th>
                    <th>{{$t('attribute-edit-list.component.columns.computed')}}</th>
                    <th>{{$t('attribute-edit-list.component.columns.path')}}</th>
                    <th>{{$t('attribute-edit-list.component.columns.dataType')}}</th>
                    <th>{{$t('attribute-edit-list.component.columns.analyticType')}}</th>
                    
                </tr>
                </thead>
        </template>

       <template v-slot:item-list-body-row="props"> 

            <!-- Only for restricted devices -->
            <template v-if="$vuetify.breakpoint.xsOnly">
                <td class="d-xs-only d-xs-table-cell">
                {{props.item.name | truncate(40)}}
                </td>
            </template>
            <!-- For regular devices use tabular layout-->
            <template v-else>  
                <td class="d-none d-sm-table-cell" :title="props.item.name">{{props.item.name}}</td>
                <td class="d-none d-sm-table-cell" :title="props.item.name">{{props.item | localized-name($i18n.locale)}}</td>
                <td class="d-none d-sm-table-cell"><v-icon v-if="props.item.key">check_circle</v-icon></td>
                <td class="d-none d-sm-table-cell"><v-icon v-if="props.item.optional">check_circle</v-icon></td>
                <td class="d-none d-sm-table-cell"><v-icon v-if="props.item.computed">check_circle</v-icon></td>
                <td class="d-none d-sm-table-cell" :title="props.item.path">{{props.item.path}}</td>
                <td class="d-none d-sm-table-cell" :title="props.item.datatype">{{props.item.datatype}}</td>
                <td class="d-none d-sm-table-cell" :title="props.item.attributeType">{{props.item.attributeType}}</td>
            </template>
        </template>

        <template v-slot:item-list-editor="props">
            <dxs-attribute-editor :value="props.item" :newItem="props.isNewItem" :attributes="attributes" />
       </template>

    </c-editable-item-list>
</div>
</template>

<script>
import AttributeEditorComponent from '@/core/components/catalog/schema/attribute-editor.component';
import defaults from 'lodash/defaults';

export default {

    props: {
    
        items: {
            type: [Object, Array],
            required: false,
            default: () => []
        },

        readonly : {
            type: Boolean,
            required: false,
            default: false 
        },

        disabled : {
            type: Boolean,
            required: false,
            default: false 
        },

        /**
         * Available options are 'default', 'full' and 'lite'
         */
        headerStyle : {
            type: String,
            default: 'default'
        },

        flat : {
            type: Boolean,
            required: false,
            default: false
        },

        editDialogStyle : {
            type: String,
            required: false,
            default: 'fullscreen'
        }
    },
    
    components: {
        'dxs-attribute-editor' : AttributeEditorComponent
    },

    data: () => ({

        attributes: []
    }),

    watch: {

        items: {

            handler(items) {
                this.$log.debug("Props 'items' has changed.");
                this.initAttributes(items);
            },

            immediate: true
            
        }
    },

    

    
    model: {
        prop: 'items',
        event: 'input'
    },


    computed: {


        itemList() {

            let itemList = [];
            if (this.items) {

                if (this.items instanceof Array) {
                    itemList.push(... this.items);
                
                } else if (this.items instanceof Object) {

                    // In case we are dealing with a map-like i18n object
                    // iterate its keys and add the items.
                    for (let key in this.items) {
                        itemList.push(this.items[key])
                    }
                }
            } 

            return itemList;
        },

        /**
         * Returns an object literal representation of the attribute list.
         */
        attributeMap() {

            let map = {};
            for (let item of this.attributes) {
                map[item.name] = item;
            }
            return map;
        },

       emptyItem() {

            return {

                name: null,
                path: null,
                datatype: null,
                attributeType: null,
                ordering: 0,
                key: false,
                computed: false,
                optional: false,
                clientSettings: {
                    displayTable : true,
                    displayTile  : true,
                    exportActive : true,
                    facetFilterActive : false,
                    filterActive : true,
                    sortActive : true,
                    tooltipTemplate: null,
                },
                
                i18n: {},
                format: {},
                custData: {},
                valueMapping: {},
                computation: {
                    script: null
                }
            };
       }
    },

    methods: {

        initAttributes(items) {


            let attributes = [];

            if (items) {

                if (items instanceof Array) {
                    this.$log.debug('Parsing items as array');
                    attributes.push(... items);
                
                } else if (this.items instanceof Object) {

                    this.$log.debug('Parsing items as object');

                    // In case we are dealing with a map-like i18n object
                    // iterate its keys and add the items.
                    for (let key in items) {
                        this.$log.debug('Adding item with key ' + key);
                        attributes.push(items[key]);
                    }
                }
            } 

            attributes = attributes.sort( (first, second) => first.ordering - second.ordering );

            this.attributes = attributes;
        },

        addAttribute(attribute) {

            
            // Before adding the attribute verify it's structure
            // and correct it if necessary
            defaults(attribute, {
                attributeType: 'DIMENSION',
                key: false,
                optional: false,
                computed: false,
                ordering: 0,
                custData: {},
                datatype: 'STRING',
                format: {},
                i18n: {},
               
                path: 'data_' + attribute.name
            });

            if (!attribute.clientSettings) {
                attribute.clientSettings = {}
            }

            defaults(attribute.clientSettings, {
                filterActive: true,
                facetFilterActive: false,
                sortActive: true,
                exportActive: true,
                displayTable: true,
                displayTile: true,
                tooltipTemplate: null,
            });

            if (!attribute.computation) {
                attribute.computation = {}
            }

            defaults(attribute.computation, {
                script : null
            })
            


            this.attributes.push(attribute);
        },

        removeAttribute(attribute) {
            this.attributes = this.attributes.filter(e => e.name !== attribute.name);
        },

        fireItemRemoved(event) {

            this.removeAttribute(event.item);
            
            // Send input event for model-binding.
            this.$emit('input', this.attributeMap);
            
            // Propagate removed event.
            this.$emit('item-removed', event);
        },

        fireInput() {

            this.$emit('input', this.attributeMap);
        },

        fireItemUpdated(event) {
            // Delegate the event.
        
            this.addAttribute(event.item);

            // Send input event for model-binding.
            this.$emit('input', this.attributeMap);
            
            // Propagate removed event.
            this.$emit('item-updated', event);
        },

        onI18NUpdated(event, item) {

            if (event) {
                if (!item.i18n) {
                    item.i18n = {};
                }
                
                item.i18n[event.item.languageTag] = event.item; 
            }
        },

        onI18NRemoved(event, item) {
            
            if (event) {

                if (item.i18n) {
                    delete item.i18n[event.item.languageTag];
                }
            }
        }

    },


}
</script>

<i18n>
{
    "en" : {
        "attribute-edit-list.component.title" : "Attributes",
        "attribute-edit-list.component.columns.name" : "Tech. Attribute ID",
        "attribute-edit-list.component.columns.translatedName" : "Attribute Name",
        "attribute-edit-list.component.columns.key" : "Key",
        "attribute-edit-list.component.columns.optional" : "Optional",
        "attribute-edit-list.component.columns.computed" : "Computed",
        "attribute-edit-list.component.columns.path" : "Tech. Mapping",
        "attribute-edit-list.component.columns.dataType" : "Tech. Datatype",
        "attribute-edit-list.component.columns.analyticType" : "Analytic Type"
    },

    "de" : {
        "attribute-edit-list.component.title" : "Attribute",
        "attribute-edit-list.component.columns.name" : "Techn. Attributname",
        "attribute-edit-list.component.columns.translatedName" : "Attributbezeichnung",
        "attribute-edit-list.component.columns.key" : "Schlüssel",
        "attribute-edit-list.component.columns.optional" : "Optional",
        "attribute-edit-list.component.columns.computed" : "Berechnet",
        "attribute-edit-list.component.columns.path" : "Techn. Mapping",
        "attribute-edit-list.component.columns.dataType" : "Tech. Datentyp",
        "attribute-edit-list.component.columns.analyticType" : "Analytics-Type"
    }
}
</i18n>