<template>
    <v-sheet  color="secondary">
        <!--
        <c-section :title="$t('schema-list.view.sections.add.title')" :subtitle="$t('schema-list.view.sections.add.subtitle')" class="secondary darken-1">
            <div class="text-center pa-5"><v-btn  dark color="accent" :to="{ name: 'newSchemaWizard'}"><v-icon class="pr-5" color="white">add</v-icon> Neues Schema anlegen...</v-btn></div>
        </c-section>
        -->
        <c-section :title="$t('schema-list.view.sections.list.title')" :subtitle="$t('schema-list.view.sections.list.subtitle')" class="secondary">

            <v-card class="mt-5 mb-5" tile>
                <c-item-list 
                    :title="$t('schema-list.view.toolbar.title')" 
                    :items="items" 
                    :disable-add-item="!hasCreateRole" 
                    :disable-edit-item="!hasEditRole && !hasReadRole" 
                    :disable-delete-item="!hasDeleteRole"
                    :supportsDeleteItem="false"
                    @refresh-list="refresh"
                    @item-added="onItemAdded" 
                    @item-edited="onItemEdited"  
                    @item-list-filter-applied="onFilterChanged"

                />
                <v-card-text></v-card-text>
                <v-divider />
                <v-subheader class="grey lighten-5">{{$t('schema-list.view.help.title')}}</v-subheader>
                <v-card-text class="grey lighten-5">{{$t('schema-list.view.help.description')}}</v-card-text>
            </v-card>
        </c-section>
        
    </v-sheet>
</template>

<script>
const MODULE_NAME = 'schemaList';

import Roles from '@/roles';

export default {



    inject: ['errorHandlerService', 'progressIndicatorService', 'userContextService'],

    computed: {

        response() {
            return this.$store.getters[MODULE_NAME + '/response'];
        },

        items() {
            return this.response.items;
        },

        hasReadRole() {
            return this.userContextService.hasAllRoles(Roles.SCHEMAS_READ);
        },

        hasEditRole() {
            return this.userContextService.hasAllRoles(Roles.SCHEMAS_EDIT);
        },

        hasCreateRole() {
            return this.userContextService.hasAllRoles(Roles.SCHEMAS_CREATE);
        },

        hasDeleteRole() {
            return this.userContextService.hasAllRoles(Roles.SCHEMAS_DELETE);
        }
    },

    methods: {

        async refresh() {

            this.progressIndicatorService.show();
            try {
                await this.$store.dispatch(MODULE_NAME + "/loadResponse")
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }

        },

        onItemEdited(event) {

            if (event) {
                this.$router.push({
                    name: 'schemaEditor',
                    params: {
                        name : event.item.name
                    }
                });
            } else {
                this.$log.debug("Event object is undefined. Can't navigate to schema editor.")
            }
        },

        async onFilterChanged(event) {

            this.progressIndicatorService.show();
            try {
                await this.$store.dispatch(MODULE_NAME + "/updateFilter", event.filter)
                await this.refresh()
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },

        onItemAdded() {
            this.$router.push({ name: 'newSchemaWizard'});
        }
    },

    created() {
        this.refresh();
    }
}
</script>

<i18n>
{
    "en" : {
        "schema-list.view.sections.add.title" : "New Schema",
        "schema-list.view.sections.add.subtitle" : "Add a new schema by means of a guided assistent",
        "schema-list.view.sections.add.actions.add" : "Add New Schemas",

        "schema-list.view.sections.list.title" : "Schema Management",
        "schema-list.view.sections.list.subtitle" : "You may use this dialog for creating, editing or deleting schemas.",
        "schema-list.view.toolbar.title" : "Schemas",

        "schema-list.view.help.title" : "What are Schemas?",
        "schema-list.view.help.description" : "Schemas are used to define the structure of documents which are stored in the system. The system will refer to the schema information in order to automatically provide services for search and analytics."

    },

    "de" : {
        "schema-list.view.sections.add.title" : "Neues Schemas",
        "schema-list.view.sections.add.subtitle" : "Fügen Sie mit dem Assistenten ein neues Schema hinzu",
        "schema-list.view.sections.add.title" : "Neues Schema hinzufügen",

        "schema-list.view.sections.list.title" : "Verwaltung Schemata",
        "schema-list.view.sections.list.subtitle" : "Sie können neue Schemata anlegen, ein Schema editieren oder löschen.",
        "schema-list.view.toolbar.title" : "Schemata",

        "schema-list.view.help.title" : "Was sind Schemata?",
        "schema-list.view.help.description" : "Schemata definieren die Struktur der gespeicherten Dokumente. Das System verwendet Informationen aus dem Schema, um automatische Such- und Analysemöglichkeiten anzubieten."
    }
}
</i18n>