<template>
    <v-sheet  color="secondary">
        <c-section :title="$t('user-list-templates-list.view.sections.list.title', {userName: userName})" :subtitle="$t('user-list-templates-list.view.sections.list.subtitle')" class="secondary">

            <v-card class="mt-5 mb-5" tile>
                <c-item-list
                    :title="$t('user-list-templates-list.view.toolbar.title', {userName: userName})"
                    :items="items"
                    :supportsDeleteItem="true"
                    :supportsAddItem="false"
                    @refresh-list="refresh"
                    @item-edited="onItemEdited" 
                    @item-deleted="onItemDeleted"
                    @item-list-filter-applied="onFilterChanged"
                >
                    <template v-slot:item-list-table-head-columns>
                        <th class="text-left" >{{$t('user-list-templates-list.view.columns.collectionId')}}</th>
                        <th class="text-left" >{{$t('user-list-templates-list.view.columns.templateId')}}</th>
                        <th class="text-center" >{{$t('user-list-templates-list.view.columns.bookmark')}}</th>
                    </template>

                    <template v-slot:item-list-table-body-columns="props">
                        <td class="text-left">{{props.item ? props.item.collectionId : '-'}}</td>
                        <td class="text-left">{{props.item ? props.item.templateId : '-'}}</td>
                        <td class="text-center"><v-icon small>{{props.item.bookmark ? 'bookmark' : '-'}}</v-icon></td>
                    </template>

                </c-item-list>
                <v-card-text></v-card-text>
                <v-divider />
                <v-subheader class="grey lighten-5">{{$t('user-list-templates-list.view.help.title')}}</v-subheader>
                <v-card-text class="grey lighten-5">{{$t('user-list-templates-list.view.help.description')}}</v-card-text>
            </v-card>
        </c-section>
        
    </v-sheet>
</template>

<script>
const MODULE_NAME = 'myUserListTemplatesList';
import { get } from 'lodash';

export default {

    inject: ['errorHandlerService', 'progressIndicatorService', 'userContextService'],

    computed: {

        response() {
            return this.$store.getters[MODULE_NAME + '/response'];
        },

        items() {
            return this.response.items;
        },

        userId: {
            get(){
                return get(this.userContextService, "userName", "")
            },
            set(user){
                this.$store.dispatch(MODULE_NAME + "/updateUser", user);
            }
        },

        userName() { 
            return get(this.userContextService, "name", "");
        }
    },

    methods: {

        async refresh() {

            this.progressIndicatorService.show();

            try {
                this.userId = get(this.userContextService, "userName", "");
                await this.$store.dispatch(MODULE_NAME + "/loadResponse")
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }

        },

        onItemEdited(event) {

            if (event) {
                this.$router.push({
                    name: 'myUserListTemplatesEditor',
                    params: {
                        name: get(event, 'item.name', ""),
                    }
                });
            } else {
                this.$log.debug("Event object is undefined. Can't navigate to user list template editor.")
            }
        },

        async onFilterChanged(event) {

            this.progressIndicatorService.show();
            try {
                await this.$store.dispatch(MODULE_NAME + "/updateFilter", event.filter)
                await this.refresh()
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },

        async onItemDeleted(event) {

            try {
               let name = get(event, 'item.name', "");
               await this.$store.dispatch(MODULE_NAME + "/deleteTemplate", name)
               await this.refresh()
            }  catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },
    },

    created() {
        this.refresh();
    }
}
</script>

<i18n>
{
    "en" : {
        "user-list-templates-list.view.sections.list.title" : "List Template Management ({userName})",
        "user-list-templates-list.view.sections.list.subtitle" : "You may use this dialog for editing and deleting your list templates.",
        "user-list-templates-list.view.toolbar.title" : "List Templates of {userName}",

        "user-list-templates-list.view.columns.collectionId" : "Collection",
        "user-list-templates-list.view.columns.userId" : "User",
        "user-list-templates-list.view.columns.templateId": "Template ID",
        "user-list-templates-list.view.columns.bookmark" : "Bookmark",

        "user-list-templates-list.view.help.title" : "What are List Templates?",
        "user-list-templates-list.view.help.description" : "These List Templates consist of your own saved filter-, view- & sort-settings for specific collections. List Templates can be used to apply a set of settings in a collection."

    },

    "de" : {
        "user-list-templates-list.view.sections.list.title" : "Verwaltung Listenvorlagen ({userName})",
        "user-list-templates-list.view.sections.list.subtitle" : "Sie können Listenvorlagen bearbeiten oder löschen.",
        "user-list-templates-list.view.toolbar.title" : "Listenvorlagen von {userName}",

        "user-list-templates-list.view.columns.collectionId" : "Datenbereich",
        "user-list-templates-list.view.columns.userId" : "Benutzer",
        "user-list-templates-list.view.columns.templateId": "Template ID",
        "user-list-templates-list.view.columns.bookmark" : "Favorit",

        "user-list-templates-list.view.help.title" : "Was sind Listenvorlagen?",
        "user-list-templates-list.view.help.description" : "Diese Listenvorlagen enthalten Ihre abgespeicherten Einstellungen zu Filterung, Ansicht und Sortierung für bestimmte Datenbereiche. Listenvorlagen können verwendet werden, um gespeicherte Einstellungen in einem Datenbereich wiederverwenden zu können."
    }
}
</i18n>