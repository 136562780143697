import api from '@/store/api';
import { endsWith, escape, get } from 'lodash';

const PARAM_NAME = 'MembraneEvalBaseUrl';

export default {

    namespaced : true,

    state: {
        name: null,
        baseUrl: null,
    },

    mutations: {

        setName(state, name) {
            state.name = name;
        },

        setBaseUrl(state, baseUrl) {
            state.baseUrl = baseUrl;
        }
    },

    actions: {

        async loadBaseUrlFromParams(context) {

            let baseUrlResponse = await api.catalog.systemParameters.findById(PARAM_NAME);
            let baseUrl = get(baseUrlResponse, 'data.systemParameter.value');
            context.commit('setBaseUrl', baseUrl);
        },

        applyName(context, {name}) {
            context.commit('setName', name);
        }
    },

    getters: {
        
        name(state) {
            return state.name;
        },

        baseUrl(state) {
            return state.baseUrl;
        },

        url(state, getters) {

            // Construct the actual url to be called.
            let url = state.baseUrl; 
            
            
            if (!endsWith(url, '/')) {
                url = url + '/';
            }
            

            url = url + getters.name;

            return escape(url);
        }
    }
}