<template>
  <div class="secondary darken-1">

    <!-- Bredcrumbs for navigation -->
    <v-breadcrumbs>
      <v-breadcrumbs-item :disabled="true">{{$t("Katalog")}}</v-breadcrumbs-item>
      <v-breadcrumbs-divider><v-icon>chevron_right</v-icon></v-breadcrumbs-divider>
      <v-breadcrumbs-item :to="{ name: 'collectionSettingsList' }" :disabled="false">{{ $t("Datenbereiche") }}</v-breadcrumbs-item>
      <v-breadcrumbs-divider><v-icon>chevron_right</v-icon></v-breadcrumbs-divider>
      <v-breadcrumbs-item>{{collectionSettings | localizedName($i18n.locale)}}</v-breadcrumbs-item>
    </v-breadcrumbs>

    <v-card tile>
      <c-sticky-content>
        <v-sheet color="error" class="pa-5 white--text" v-if="!hasSchemaReadRole"> 
            <v-icon color="white" class="pr-5">warning</v-icon> {{$t('collection-settings-editor.view.warning.schemaReadRoleMissing')}}
        </v-sheet>
        <v-toolbar color="white" flat>
          <v-toolbar-items
            ><v-btn icon @click="cancel"
              ><v-icon>arrow_back</v-icon></v-btn
            ></v-toolbar-items
          >
          <div class="text-h6">
            {{ $t("catalog.common.collection.label") }}:
            {{ collectionSettings | localizedName($i18n.locale) | truncate(40) }}
          </div>

          <v-spacer />
          <template v-if="hasEditRole">
            <v-toolbar-items
                ><v-btn text @click="save">{{
                $t("actions.save.label")
                }}</v-btn></v-toolbar-items
            >
            <v-toolbar-items
                ><v-btn text @click="cancel">{{
                $t("actions.cancel.label")
                }}</v-btn></v-toolbar-items
            >
          </template>
        </v-toolbar>
        <v-divider />
      </c-sticky-content>


    <v-tabs 
        v-model="selectedTab" 
        :vertical="$vuetify.breakpoint.mdAndUp" 
        color="grey" 
        :background-color="$vuetify.breakpoint.mdAndUp ? 'white' : 'grey lighten-3'" 
        slider-color="grey" 
        show-arrows 
        :fixed-tabs="!$vuetify.breakpoint.mdAndUp"
    >

        <v-tab :key="1">{{$t('collection-settings-editor.view.tabs.general.title')}}</v-tab>
        <v-tab :key="2">{{$t('collection-settings-editor.view.tabs.clientSettings.title')}}</v-tab>
        
        <v-tab :key="3">{{$t('collection-settings-editor.view.tabs.embeddedCollections.title')}}</v-tab>
        <v-tab :key="4">{{$t("collection-settings-editor.view.tabs.schema.title")}}</v-tab>
        <v-tab :key="5">{{$t('collection-settings-editor.view.tabs.accessPermissions.title')}}</v-tab>
        <v-tab :key="6">{{$t('collection-settings-editor.view.tabs.logging.title')}}</v-tab>
        <v-tab :key="7">{{$t('collection-settings-editor.view.tabs.custData.title')}}</v-tab>
        


        <v-tabs-items v-model="selectedTab">
            <!-- ======================================= -->
            <!-- GENERAL INFORMATION                     -->
            <!-- ======================================= -->
            <v-tab-item :key="1">
                <v-card flat tile class="pt-5 pb-5">
                    <v-card-text>
                        <div class="text-h5">{{$t('collection-settings-editor.view.tabs.general.title')}}</div>
                        <div>{{$t('collection-settings-editor.view.tabs.general.subtitle')}}</div>
                    </v-card-text>
                    <v-divider />
                    

                    <v-card-text>
                        <div class="text-subtitle-1 font-weight-medium pb-3">{{$t('collection-settings-editor.view.tabs.general.title')}}</div>
                        <div>{{$t('collection-settings-editor.view.tabs.general.subtitle')}}</div>
                    </v-card-text>
                    <v-card-text>
                        <v-text-field
                            :value="collectionSettings.name"
                            :disabled="true"
                            filled
                            :label="$t('catalog.common.technicalname.label')"
                            :hint="$t('catalog.common.technicalname.collection.description')"
                            persistent-hint
                            class="ma-5"
                        />
                        <v-text-field
                            :value="collectionSettings.idTemplate"
                            :disabled="true"
                            filled
                            :label="$t('collection-settings-editor.view.fields.idTemplate.name')"
                            :hint="$t('collection-settings-editor.view.fields.idTemplate.description')"
                            persistent-hint
                            class="ma-5"
                        />
                        <v-text-field
                            :value="collectionSettings.partitionIdTemplate"
                            :disabled="true"
                            filled
                            :label="$t('collection-settings-editor.view.fields.partitionIdTemplate.name')"
                            :hint="$t('collection-settings-editor.view.fields.partitionIdTemplate.description')"
                            persistent-hint
                            class="ma-5"
                        />
                    </v-card-text>
                    <v-card-text />
                    <v-divider />

                    <v-card-text>
                        <div class="text-subtitle-1 font-weight-medium pb-3">{{$t('catalog.common.translation.localization.label')}}</div>
                        <div>{{$t('catalog.common.translation.localization.collection.description')}}</div>
                    </v-card-text>

                    <c-i18n-editor
                        :items="i18n"
                        :disabled="!hasEditRole"
                        @item-removed="removeI18n"
                        @item-updated="addI18n"
                        header-style="lite"
                        flat    
                    />

                </v-card>
            </v-tab-item>

          <v-tab-item :key="2">
            <v-card flat tile class="pt-5 pb-5">
                <v-card-text>
                    <div class="text-h5">{{$t('collection-settings-editor.view.tabs.clientSettings.title')}}</div>
                    <div>{{$t('collection-settings-editor.view.tabs.clientSettings.subtitle')}}</div>
                </v-card-text>
                <v-divider />

              <v-card-text />
              <v-card-text class="text-body-2">
                 {{ $t("collection-settings-editor.view.clientSettings.description") }}
              </v-card-text>
              <v-card-text>
                  <v-text-field
                    v-model="listDialogUrlTemplate"
                    filled
                    :label="$t('collection-settings-editor.view.fields.listDialogUrlTemplate.name')"
                    :hint="$t('collection-settings-editor.view.fields.listDialogUrlTemplate.description')"
                    persistent-hint
                    class="ma-5"
                    :rules="[v => !!v || $t('collection-settings-editor.view.fields.listDialogUrlTemplate.validation', $i18n.locale)]"
                />
                <v-text-field
                    v-model="detailDialogUrlTemplate"
                    filled
                    :label="$t('collection-settings-editor.view.fields.detailDialogUrlTemplate.name')"
                    :hint="$t('collection-settings-editor.view.fields.detailDialogUrlTemplate.description')"
                    persistent-hint
                    class="ma-5"
                    :rules="[v => !!v || $t('collection-settings-editor.view.fields.detailDialogUrlTemplate.validation', $i18n.locale)]"
                />
              </v-card-text>
              
              <v-divider/>

              <!-- Time Intervals -->
              <v-card-text />
              <v-card-text class="text-h6">{{$t("common.distribution.title")}}</v-card-text>
              <v-card-text>
                <c-time-interval-editor 
                  v-model="timeIntervals" 
                  flat 
                  headerStyle="lite"
                  @item-updated="addTimeInterval"
                />
              </v-card-text>
            </v-card>
            
            
          </v-tab-item>


          <v-tab-item :key="3">
              <v-card flat tile class="pt-5 pb-5">
                <v-card-text>
                    <div class="text-h5">{{$t('collection-settings-editor.view.tabs.embeddedCollections.title')}}</div>
                    <div>{{$t('collection-settings-editor.view.tabs.embeddedCollections.subtitle')}}</div>
                </v-card-text>
                <v-divider />
                

                <dxs-embedded-collections-editor 
                    v-model="embeddedCollections" 
                    :schemas="schemas"
                    :disabled="!hasEditRole" 
                />
              </v-card>
          </v-tab-item>

        <v-tab-item :key="4">
            <v-card flat tile class="pt-5 pb-5">
                <v-card-text>
                    <div class="text-h5">{{$t('collection-settings-editor.view.tabs.schema.title')}}</div>
                    <div>{{$t('collection-settings-editor.view.tabs.schema.subtitle')}}</div>
                </v-card-text>
                <v-divider />
              <v-card-text />
              <v-card-text>
                <v-row justify="space-between" align="center">
                  <v-col cols="auto" class="pt-0">
                    <v-btn small color="accent" @click="openSchema">
                      <v-icon left>open_in_new</v-icon>
                      {{ $t("catalog.common.schema.label") }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-text>
                <c-schema-table :value="{ schema: schema }" />
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item :key="5">
            <v-card flat tile class="pt-5 pb-5">
                <v-card-text>
                    <div class="text-h5">{{$t('collection-settings-editor.view.tabs.accessPermissions.title')}}</div>
                    <div>{{$t('collection-settings-editor.view.tabs.accessPermissions.subtitle')}}</div>
                </v-card-text>
                <v-divider />
              <v-card-text />

              <v-card-text class="text-center accent white--text pt-5">
                <v-icon x-large color="white">security</v-icon>
              </v-card-text>
              <v-card-text class="text-center accent white--text pt-5 pb-5">
                <div class="title">
                  {{
                    $t(
                      "collection-settings-editor.view.sections.dataProtection.disabledWarning.title"
                    )
                  }}
                </div>
                <div class="body-2">
                  {{
                    $t(
                      "collection-settings-editor.view.sections.dataProtection.disabledWarning.subtitle"
                    )
                  }}
                </div>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item :key="6">
            <v-card flat tile class="pt-5 pb-5">
                <v-card-text>
                    <div class="text-h5">{{$t('collection-settings-editor.view.tabs.logging.title')}}</div>
                    <div>{{$t('collection-settings-editor.view.tabs.logging.subtitle')}}</div>
                </v-card-text>
                <v-divider />
              <!-- AUDIT LOG -->
              <v-card-text>
                <div class="text-h6">{{$t('logging-config-page.component.sections.documentChangeLogging.title')}}</div>
                <div>{{$t('logging-config-page.component.sections.documentChangeLogging.subtitle')}}</div>
                <div class="grey--text">{{$t('logging-config-page.component.sections.documentChangeLogging.fields')}}</div>
              </v-card-text>
              <v-list>
                  <v-radio-group mandatory v-model="documentChangeLogging" :disabled="disabled">
                      <v-list-item three-line>
                          <v-list-item-action>
                              <v-radio key="TENANT" value="TENANT" />
                          </v-list-item-action>
                          <v-list-item-content>
                              <v-list-item-title>{{$t('logging-config-page.component.sections.documentChangeLogging.tenantSetting.title')}}</v-list-item-title>
                              <v-list-item-subtitle>{{$t('logging-config-page.component.sections.documentChangeLogging.tenantSetting.subtitle')}}</v-list-item-subtitle>
                          </v-list-item-content>
                      </v-list-item>

                      <v-list-item three-line>
                          <v-list-item-action>
                              <v-radio key="ON" value="ON" />
                          </v-list-item-action>
                          <v-list-item-content>
                              <v-list-item-title>{{$t('logging-config-page.component.sections.documentChangeLogging.ON.title')}}</v-list-item-title>
                              <v-list-item-subtitle>{{$t('logging-config-page.component.sections.documentChangeLogging.ON.subtitle')}}</v-list-item-subtitle>
                          </v-list-item-content>
                      </v-list-item>

                      <v-list-item three-line>
                          <v-list-item-action>
                              <v-radio key="OFF" value="OFF" />
                          </v-list-item-action>
                          <v-list-item-content>
                              <v-list-item-title>{{$t('logging-config-page.component.sections.documentChangeLogging.OFF.title')}}</v-list-item-title>
                              <v-list-item-subtitle>{{$t('logging-config-page.component.sections.documentChangeLogging.OFF.subtitle')}}</v-list-item-subtitle>
                          </v-list-item-content>
                      </v-list-item>
                  </v-radio-group>
              </v-list>
              <!-- QUERY LOG -->
              <v-card-text>
                  <div class="text-h6">{{$t('logging-config-page.component.sections.queryLogging.title')}}</div>
                  <div>{{$t('logging-config-page.component.sections.queryLogging.subtitle')}}</div>
                  <div class="grey--text">{{$t('logging-config-page.component.sections.queryLogging.fields')}}</div>
              </v-card-text>
              <v-list>
                  <v-radio-group mandatory v-model="queryLogging" :disabled="disabled">
                    <v-list-item three-line>
                        <v-list-item-action>
                            <v-radio key="TENANT" value="TENANT" />
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>{{$t('logging-config-page.component.sections.queryLogging.tenantSetting.title')}}</v-list-item-title>
                            <v-list-item-subtitle>{{$t('logging-config-page.component.sections.queryLogging.tenantSetting.subtitle')}}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>                    
                    <v-list-item three-line> 
                        <v-list-item-action>
                            <v-radio key="ON" value="ON" />
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>{{$t('logging-config-page.component.sections.queryLogging.ON.title')}}</v-list-item-title>
                            <v-list-item-subtitle>{{$t('logging-config-page.component.sections.queryLogging.ON.subtitle')}}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item three-line>
                        <v-list-item-action>
                            <v-radio key="OFF" value="OFF" />
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>{{$t('logging-config-page.component.sections.queryLogging.OFF.title')}}</v-list-item-title>
                            <v-list-item-subtitle>{{$t('logging-config-page.component.sections.queryLogging.OFF.subtitle')}}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                  </v-radio-group>
              </v-list>       

            </v-card>
          </v-tab-item>


          <v-tab-item :key="7">
            <v-card flat tile class="pt-5 pb-5">
                <v-card-text>
                    <div class="text-h5">{{$t('collection-settings-editor.view.tabs.custData.title')}}</div>
                    <div>{{$t('collection-settings-editor.view.tabs.custData.subtitle')}}</div>
                </v-card-text>
                <v-divider />
                <c-params-editor
                    header-style="lite"
                    flat
                    :items="custDataItems"
                    :disabled="!hasEditRole"
                    @item-updated="onCustDataUpdated"
                    @item-removed="onCustDataRemoved"
                />
            </v-card>

          </v-tab-item>

        </v-tabs-items>
      </v-tabs>

      <v-divider />

      <v-divider />
    </v-card>
  </div>
</template>


<script>
import cloneDeep from 'lodash/cloneDeep';
import omit from 'lodash/omit';
import set from 'lodash/set';
import get from 'lodash/get';

import Roles from '@/roles';

const MODULE_NAME = "collectionSettingsEditor";

//import TimeScaleEditorComponent from '@/components/editors/time-scale-editor.component.vue'

import EmbeddedCollectionsEditorComponent from '@/core/components/catalog/collection-settings/embedded-collections-editor.component';

export default {
  name: "collection-settings-editor",

  props: {
    value: {
      type: Object,
      required: false,
    },

    options: {
      type: Object,
      required: false,
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  inject: ["errorHandlerService", "userContextService"],

  components: {
    // 'dxs-time-scale-editor' : TimeScaleEditorComponent
    'dxs-embedded-collections-editor' : EmbeddedCollectionsEditorComponent
  },

  data: () => ({
    loading: false,
    selectedTab: 0,
    logging: ""
  }),

  methods: {

    addI18NItem(i18n, item) {

        set(i18n, item.languageTag, item);
    },

    removeI18NItem(i18n, item){
        omit(i18n, item.languageTag);
    },

    addI18n(event) {
      this.i18n[event.item.languageTag] = event.item;
      this.$store
        .dispatch(MODULE_NAME + "/updateI18N", { i18n: cloneDeep(this.i18n) })
        .catch(this.errorHandlerService.handleError);
    },

    removeI18n(event) {
      this.$store
        .dispatch(MODULE_NAME + "/updateI18N", {
          i18n: omit(this.i18n, event.item.languageTag),
        })
        .catch(this.errorHandlerService.handleError);
    },

    onCustDataUpdated(event) {
      this.$log.debug(JSON.stringify(event));

      if (event && event.item) {
        let clone = cloneDeep(this.custData);
        clone[event.item.name] = event.item.value;

        this.custData = clone;
      }
    },

    onCustDataRemoved(event) {
      this.$log.debug(JSON.stringify(event));

      if (event && event.item) {
        let clone = cloneDeep(this.custData);
        delete clone[event.item.name];

        this.custData = clone;
      }
    },

    addParameter(event) {
      this.parameter[event.item.name] = event.item;
      this.$store
        .dispatch(MODULE_NAME + "/updateParameter", {
          parameter: cloneDeep(this.parameter),
        })
        .catch(this.errorHandlerService.handleError);
    },

    removeParameter(event) {
      this.$store
        .dispatch(MODULE_NAME + "/updateParameter", {
          parameter: omit(this.parameter, event.item.name),
        })
        .catch(this.errorHandlerService.handleError);
    },

    async refresh() {
      this.loading = true;
      try {
        await this.$store.dispatch(MODULE_NAME + "/loadResponse", {
          name: this.name,
        });
      } catch (error) {
        this.errorHandlerService.handleError(error);
      } finally {
        this.loading = false;
      }
    },


    async save() {
      this.loading = true;
      try {

        // Collection Settings are only created by means of the wizard.
        // Therefore at this point we can safely assume that we are dealing with an update.
        await this.$store.dispatch(MODULE_NAME + "/update", {
          name: this.name,
        });
      } catch (error) {
        this.errorHandlerService.handleError(error);
      } finally {
        this.loading = false;
      }
    },

    cancel() {
      this.$router.push({
        name: "collectionSettingsList",
      });
    },

    openSchema() {
      this.$router.push({
        name: "schemaEditor",
        params: {
          name: this.schema.name,
        },
      });
    }
  },

  computed: {

    hasEditRole() {
        return this.userContextService.hasAllRoles(Roles.COLLECTION_SETTINGS_EDIT);
    },

    hasSchemaReadRole() {
        return this.userContextService.hasAllRoles(Roles.SCHEMAS_READ);
    },

    name() {
      return this.value && this.value.name ? this.value.name : null;
    },

    collectionSettings() {
      return this.$store.getters[MODULE_NAME + "/collectionSettings"];
    },

    schema() {
      return this.$store.getters[MODULE_NAME + "/schema"];
    },

    i18n() {
      return this.$store.getters[MODULE_NAME + "/i18n"];
    },

    parameter() {
      return this.$store.getters[MODULE_NAME + "/parameter"];
    },

    schemas() {
        return this.$store.getters[MODULE_NAME + "/schemas"];
    },

    timeIntervals: {

      get() {
        return this.$store.getters[MODULE_NAME + "/timeIntervals"];
      },

      set(timeIntervals) {
        this.$store.dispatch(MODULE_NAME + '/updateTimeIntervals', {
                timeIntervals: timeIntervals
            });
      }
        
    },

    detailDialogUrlTemplate: {

        get() {
            return get(this.collectionSettings, 'clientSettings.detailDialogUrlTemplate', '/collections/${collectionId}/documents/${documentId}');
        },

        set(detailDialogUrlTemplate) {
            this.$store.dispatch(MODULE_NAME + '/updateDetailDialogUrlTemplate', {
                detailDialogUrlTemplate: detailDialogUrlTemplate
            });
        }
    },

    listDialogUrlTemplate: {

        get() {
            return get(this.collectionSettings, 'clientSettings.listDialogUrlTemplate', '/collections/${collectionId}/documents');
        },

        set(listDialogUrlTemplate) {
            this.$store.dispatch(MODULE_NAME + '/updateListDialogUrlTemplate', {
                listDialogUrlTemplate: listDialogUrlTemplate
            });
        }
    },

    embeddedCollections: {

        get() {

            return this.collectionSettings.embeddedCollections 
                 ? this.collectionSettings.embeddedCollections
                 : []; 
        },

        set(items) {
            
            // Update the embedded collections in the vuex store.
            this.$store.dispatch(MODULE_NAME + '/updateEmbeddedCollections', {
                embeddedCollections: items
            });
            
        }
    },

    custData: {
      get() {
        return this.$store.getters[MODULE_NAME + "/custData"];
      },

      set(custData) {
        this.$store.dispatch(MODULE_NAME + "/updateCustData", {
          custData: custData,
        });
      },
    },

    custDataItems() {
      let items = [];
      for (let key in this.custData) {
        items.push({ name: key, value: this.custData[key] });
      }
      return items;
    },

    documentChangeLogging: {
            get() {
                return get(this.collectionSettings, 'loggingSettings.documentChangeLogging', 'TENANT')
            },

            set(value) {
                this.$store.dispatch(MODULE_NAME + '/updateDocumentChangeLogging', value);
            }
    },
    queryLogging: {

        get() {
            return get(this.collectionSettings, 'loggingSettings.queryLogging', 'TENANT')
        },

        set(value) {
            this.$store.dispatch(MODULE_NAME + '/updateQueryLogging', value);
        }
    },
  },

  created() {
    this.refresh();
  },

  
};
</script>

<i18n>
{
    "en" : {

        "collection-settings-editor.view.warning.schemaReadRoleMissing" : "The role amentis-dxs-catalog-schemas-read  is not assigned to your user account. The dialog can therefore only be used with restrictions. In order to obtain the role, please contact to your admin.",

        "collection-settings-editor.view.tabs.schema.title" : "Schema",
        "collection-settings-editor.view.tabs.schema.subtitle" : "This card contains information for the assigned schema attributes.",
        
        "collection-settings-editor.view.tabs.accessPermissions.title" : "Access Permissions",
        "collection-settings-editor.view.tabs.accessPermissions.subtitle" : "This page summarizes the access permissions for this collection.",
        "collection-settings-editor.view.sections.dataProtection.disabledWarning.title" : "The Data Protection Add-On is currently not enabled for this Tenant",
        "collection-settings-editor.view.sections.dataProtection.disabledWarning.subtitle" : "Logged in users do have full data access. Please activate the add-on when fine grained access control is required.",

        "collection-settings-editor.view.fields.idTemplate.name" : "ID Template",
        "collection-settings-editor.view.fields.idTemplate.description" : "ID Template",

        "collection-settings-editor.view.fields.partitionIdTemplate.name" : "Partition-ID Template",
        "collection-settings-editor.view.fields.partitionIdTemplate.description" : "Partition-ID Template",

        "collection-settings-editor.view.clientSettings.title" : "Client Settings",
        "collection-settings-editor.view.clientSettings.description" : "These settings control displaying of the documents in this collection.",
        "collection-settings-editor.view.fields.listDialogUrlTemplate.name" : "URL Template Document List View",
        "collection-settings-editor.view.fields.listDialogUrlTemplate.description" : "The system will use the view with the given URL for displaying the document list. Please make sure that the URL is associated with an actual web view.",
        "collection-settings-editor.view.fields.listDialogUrlTemplate.validation" : "The URL template must not be empty.",
        "collection-settings-editor.view.fields.detailDialogUrlTemplate.name" : "URL Template Document Detail View",
        "collection-settings-editor.view.fields.detailDialogUrlTemplate.description" : "The system will use the view with the given URL for displaying the detail information of single document. Please make sure that the URL is associated with an actual web view.",
        "collection-settings-editor.view.fields.detailDialogUrlTemplate.validation" : "The URL template must not be empty.",

        "collection-settings-editor.view.tabs.general.title" : "General",
        "collection-settings-editor.view.tabs.general.subtitle" : "This page contains the general settings.",
        "collection-settings-editor.view.tabs.embeddedCollections.title" : "Embedded Collections",
        "collection-settings-editor.view.tabs.embeddedCollections.subtitle" : "By defining embedded collections you may add lists of subdocuments to each document in this collection. Embedded collections are helpful for collecting further contextual data  in a central place (e.g. all measures of an inspection process).",
        "collection-settings-editor.view.tabs.custData.title" : "Customer-specific Parameter",
        "collection-settings-editor.view.tabs.custData.subtitle" : "This page allows for defining customer-specific parameters. Please note: These parameters are not evaluated by the standard system, but requires additional customer-specific code.",

        "collection-settings-editor.view.tabs.logging.title" : "Logging",
        "collection-settings-editor.view.tabs.logging.subtitle" : "This page contains all settings related to logging.",
        "logging-config-page.component.sections.documentChangeLogging.title" : "Collection Change Log",
        "logging-config-page.component.sections.documentChangeLogging.subtitle" : "The change log records all changes to documents and makes the changes available chronologically.",
        "logging-config-page.component.sections.documentChangeLogging.fields" : "Recorded Data: Change Date and Time, User ID, Change Type, Collection ID, Document ID, Document Contents (JSON)",
        "logging-config-page.component.sections.documentChangeLogging.ON.title" : "Change Logging On",
        "logging-config-page.component.sections.documentChangeLogging.ON.subtitle" : "The system logs all changes to documents in this tenant.",
        "logging-config-page.component.sections.documentChangeLogging.OFF.title" : "Change Logging Off",
        "logging-config-page.component.sections.documentChangeLogging.OFF.subtitle" : "The system logs no changes to documents in this tenant.",
        "logging-config-page.component.sections.documentChangeLogging.tenantSetting.title" : "Apply Tenant Setting (Default)",
        "logging-config-page.component.sections.documentChangeLogging.tenantSetting.subtitle" : "Applies the setting which is set for the tenant.",

        "logging-config-page.component.sections.queryLogging.title" : "Collection Access Log",
        "logging-config-page.component.sections.queryLogging.subtitle" : "The access log records all accesses to collections and makes the data available for e.g. performance and usage statistics.",
        "logging-config-page.component.sections.queryLogging.fields" : "Recorded Data: Data and Time, User ID,  Collection ID, Query (JSON), Duration, Hit Count",
        "logging-config-page.component.sections.queryLogging.ON.title" : "Access Logging On",
        "logging-config-page.component.sections.queryLogging.ON.subtitle" : "The system logs all access to collections in this tenant.",
        "logging-config-page.component.sections.queryLogging.OFF.title" : "Access Logging Off",
        "logging-config-page.component.sections.queryLogging.OFF.subtitle" : "The system logs no access to collections in this tenant.",
        "logging-config-page.component.sections.queryLogging.tenantSetting.title" : "Apply Tenant Setting (Default)",
        "logging-config-page.component.sections.queryLogging.tenantSetting.subtitle" : "Applies the setting which is set for the tenant.",

        "collection-settings-editor.view.tabs.clientSettings.title": "Client Settings",
        "collection-settings-editor.view.tabs.clientSettings.subtitle": "This page contains the settings for displaying the data."
    },

    "de" : {

        "collection-settings-editor.view.warning.schemaReadRoleMissing" : "Die Rolle amentis-dxs-catalog-schemas-read  ist Ihrem Benuterkonto aktuell nicht zugewiesen. Die Benutzung des Dialogs ist nur eingeschränkt möglich. Bitte wenden Sie sich an Ihren Systembetreuer, um die Rolle zu erhalten.",
        
        "collection-settings-editor.view.tabs.schema.title" : "Schema",
        "collection-settings-editor.view.tabs.schema.subtitle" : "Diese Karte enthält die Informationen zu den Attributen des Datenbereichs.",
        
        "collection-settings-editor.view.tabs.accessPermissions.title" : "Zugriffsberechtigungen",
        "collection-settings-editor.view.tabs.accessPermissions.subtitle" : "Diese Seite enthält die Zugriffsberechtigungen für diesen Datenbereich.",
        "collection-settings-editor.view.sections.dataProtection.disabledWarning.title" : "Das Add-On für Datensicherheit ist auf Ihrem Mandanten nicht aktiviert",
        "collection-settings-editor.view.sections.dataProtection.disabledWarning.subtitle" : "Authentifizierte Anwender erhalten vollen Zugriff auf alle gespeicherten Daten. Aktivieren Sie das Add-On, um feingranulare Zugriffe auf Daten zu definieren.",

        "collection-settings-editor.view.fields.idTemplate.name" : "ID Template",
        "collection-settings-editor.view.fields.idTemplate.description" : "ID Template",

        "collection-settings-editor.view.fields.partitionIdTemplate.name" : "Partition-ID Template",
        "collection-settings-editor.view.fields.partitionIdTemplate.description" : "Partition-ID Template",

        "collection-settings-editor.view.clientSettings.title" : "Client-Einstellungen",
        "collection-settings-editor.view.clientSettings.subtitle" : "Mit diesen Einstellungen können Sie die Darstellung des Datenbereichs an der Benutzeroberfläche beeinflussen.",
        "collection-settings-editor.view.fields.listDialogUrlTemplate.name" : "URL-Template Dokumentenliste",
        "collection-settings-editor.view.fields.listDialogUrlTemplate.description" : "Das System verwendet diese URL zur Anzeige der Dokumentenliste. Bitte stellen Sie sicher, dass unter dieser URL eine Anzeige-Komponente registriert ist.",
        "collection-settings-editor.view.fields.listDialogUrlTemplate.validation" : "Das URL-Template darf nicht leer sein.",
        "collection-settings-editor.view.fields.detailDialogUrlTemplate.name" : "URL-Template Einzeldokument",
        "collection-settings-editor.view.fields.detailDialogUrlTemplate.description" : "Das System verwendet diese URL zur Anzeige der Einzeldokumente. Bitte stellen Sie sicher, dass unter dieser URL eine Anzeige-Komponente registriert ist.",
        "collection-settings-editor.view.fields.detailDialogUrlTemplate.validation" : "Das URL-Template darf nicht leer sein.",

        "collection-settings-editor.view.tabs.general.title" : "Allgemein",
        "collection-settings-editor.view.tabs.general.subtitle" : "Diese Seite enthält die allgemeinen Einstellungen",

        "collection-settings-editor.view.tabs.embeddedCollections.title" : "Eingebettete Datenbereiche",
        "collection-settings-editor.view.tabs.embeddedCollections.subtitle" : "Mit der Definition von eingebetteten Datenbereichen können Sie jedem Dokument zusätzliche Listen von Dokumenten hinzufügen. Hierdurch können Sie weitere Kontextdaten mit dem Dokument verbinden und zusammengehörige Daten an einem zentralen Ort sammeln (z.B. alle Messwerte einer Prüfung).",
        
        "collection-settings-editor.view.tabs.custData.title" : "Kundenspezifische Parameter",
        "collection-settings-editor.view.tabs.custData.subtitle" : "Diese Seite ermöglicht Ihnen die Definition kundenspezifische Parameter. Nach der Anlage können diese Parameter durch Kunden-spezifischen Code ausgewertet werden (z.B. in Mapping Jobs).",

        "collection-settings-editor.view.tabs.logging.title" : "Protokollierung",
        "collection-settings-editor.view.tabs.logging.subtitle" : "Diese Seite enthält alle Einstellungen für die Protokollierung.",
        "logging-config-page.component.sections.documentChangeLogging.title" : "Änderungsprotokoll Datenbereiche",
        "logging-config-page.component.sections.documentChangeLogging.subtitle" : "Das Änderungsprotokoll zeichnet alle Änderungen an Dokumenten auf und stellt die Änderungen chronologisch zur Verfügung.",
        "logging-config-page.component.sections.documentChangeLogging.fields" : "Aufgezeichnete Datenfelder: Zeitpunkt, Benutzerkennung, Art der Änderung, Datenbereich-ID, Dokumenten-ID, Dokumenten-Inhalt (JSON)",
        "logging-config-page.component.sections.documentChangeLogging.ON.title" : "Protokollierung einschalten",
        "logging-config-page.component.sections.documentChangeLogging.ON.subtitle" : "Das System protokolliert alle Änderungen an Dokumenten in diesem Mandanten.",
        "logging-config-page.component.sections.documentChangeLogging.OFF.title" : "Protokollierung ausschalten",
        "logging-config-page.component.sections.documentChangeLogging.OFF.subtitle" : "Das System protokolliert keine Änderungen an Dokumenten in diesem Mandanten.",
        "logging-config-page.component.sections.documentChangeLogging.tenantSetting.title" : "Übernehme Mandanten Einstellung (Standard)",
        "logging-config-page.component.sections.documentChangeLogging.tenantSetting.subtitle" : "Übernimmt die Einstellung des Mandanten.",

        "logging-config-page.component.sections.queryLogging.title" : "Zugriffsprotokoll Datenbereiche",
        "logging-config-page.component.sections.queryLogging.subtitle" : "Das Zugriffsprotokoll zeichnet alle Zugriffe auf Datenbereiche auf und stellt die Änderungen z.B. für Performance - und Nutzungsstatistiken zur Verfügung.",
        "logging-config-page.component.sections.queryLogging.fields" : "Aufgezeichnete Datenfelder: Zeitpunkt, Benutzerkennung, Datenbereich-ID, Abfrage (JSON), Ausführungsdauer, Anzahl der Treffer",
        "logging-config-page.component.sections.queryLogging.ON.title" : "Protokollierung einschalten",
        "logging-config-page.component.sections.queryLogging.ON.subtitle" : "Das System protokolliert alle Zugriffe auf Datenbereiche in diesem Mandanten.",
        "logging-config-page.component.sections.queryLogging.OFF.title" : "Protokollierung ausschalten",
        "logging-config-page.component.sections.queryLogging.OFF.subtitle" : "Das System protokolliert keine Zugriffe auf Datenbereiche in diesem Mandanten.",
        "logging-config-page.component.sections.queryLogging.tenantSetting.title" : "Übernehme Mandanten Einstellung (Standard)",
        "logging-config-page.component.sections.queryLogging.tenantSetting.subtitle" : "Übernimmt die Einstellung des Mandanten.",

        "collection-settings-editor.view.tabs.clientSettings.title": "Anzeige-Einstellungen",
        "collection-settings-editor.view.tabs.clientSettings.subtitle": "Diese Seite enthält die Einstellungen für die Anzeige der Daten."
    }
}
</i18n>