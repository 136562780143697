<template>
        <!--<v-divider />-->
        <!-- <v-subheader>{{title}}</v-subheader> -->
    <v-list-group :value="false" color="grey darken-3" :prepend-icon="icon" class="mt-3 mb-3" v-if="isAnyAuthorized(routes)">
    <v-divider />
    <template v-slot:activator>
        <v-list-item-title :title="title">{{title}}</v-list-item-title>
    </template>
    <template v-for="route of sortedRoutes">
        <dxs-navigation-item  class="grey lighten-5" v-if="isAuthorized(route)"  :route="route"  :key="route.path + '_item'" />
    </template>
    <v-divider />
    </v-list-group>

</template>

<script>
import NavigationItemComponent from './navigation-item.component';
import { get } from 'lodash'; 

export default {

    props: {

        title: {
            type: String,
            required: true
        },

        routes: {
            type: Array,
            required: true
        },

        icon: {
            type: String,
            required: true,

        }
    },

    inject: ['routerService'],

    methods: {

        isAuthorized(route){
            return this.routerService.isAuthorizedForRoute(route);
        },

        isAnyAuthorized(routes) {
            for (const route of routes) {
                if (this.isAuthorized(route)) {
                    return true;
                }
            }
            return false;
        }

    },

    computed: {

        sortedRoutes() {

            let sortedRoutes = [];
            for (let route of this.routes) {
                sortedRoutes.push(route);
            }

            sortedRoutes = sortedRoutes.sort((first, second) => get(first, 'meta.navigation.order', 0) - get(second, 'meta.navigation.order', 0));
            return sortedRoutes;
        },

        
    },

    components: {
        'dxs-navigation-item' : NavigationItemComponent
    }
}
</script>

<style>

</style>