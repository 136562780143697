<template>
    <v-sheet  color="secondary">

        <c-section :title="$t('mapping-job-templates-list.view.sections.list.title')" :subtitle="$t('mapping-job-templates-list.view.sections.list.subtitle')" class="secondary">

            <v-card class="mt-5 mb-5" tile>
                <c-item-list 
                    :title="$t('mapping-job-templates-list.view.toolbar.title')" 
                    :items="items" 
                    :disable-add-item="!hasCreateRole" 
                    :disable-edit-item="!hasEditRole && !hasReadRole" 
                    :disable-delete-item="!hasDeleteRole"
                    @refresh-list="refresh"
                    @item-added="onItemAdded" 
                    @item-edited="onItemEdited"
                    @item-deleted="onItemDeleted"
                    @item-list-filter-applied="onFilterChanged"
                >
                    <template v-slot:item-list-table-head-columns>
                        <th>{{$t('mapping-job-templates-list.view.columns.sourceCollectionId')}}</th>
                        <th>{{$t('mapping-job-templates-list.view.columns.targetCollectionId')}}</th>
                    </template>

                    <template v-slot:item-list-table-body-columns="props">
                        <td>{{props.item ? props.item.sourceCollectionId : '-'}}</td>
                        <td>{{props.item ? props.item.targetCollectionId : '-'}}</td>
                    </template>
                </c-item-list>
                <v-card-text></v-card-text>
                <v-divider />
                <v-subheader class="grey lighten-5">{{$t('mapping-job-templates-list.view.help.title')}}</v-subheader>
                <v-card-text class="grey lighten-5">{{$t('mapping-job-templates-list.view.help.description')}}</v-card-text>
            </v-card>
        </c-section>
        
    </v-sheet>


</template>

<script>
import Roles from '@/roles';

const MODULE_NAME = 'mappingJobTemplatesList';

export default {

    inject: ['errorHandlerService', 'progressIndicatorService', 'userContextService'],

    computed: {

        response() {
            return this.$store.getters[MODULE_NAME + '/response'];
        },

        items() {
            return this.response.items;
        },

        hasReadRole() {
            return this.userContextService.hasAllRoles(Roles.MAPPING_JOB_TEMPLATES_READ);
        },

        hasEditRole() {
            return this.userContextService.hasAllRoles(Roles.MAPPING_JOB_TEMPLATES_EDIT);
        },

        hasCreateRole() {
            return this.userContextService.hasAllRoles(Roles.MAPPING_JOB_TEMPLATES_CREATE);
        },

        hasDeleteRole() {
            return this.userContextService.hasAllRoles(Roles.MAPPING_JOB_TEMPLATES_DELETE);
        }
    },

    methods: {

        async refresh() {

            this.progressIndicatorService.show();
            try {
                await this.$store.dispatch(MODULE_NAME + "/loadResponse")
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }

        },

        onItemEdited(event) {

            if (event) {
                this.$router.push({
                    name: 'mappingJobTemplatesEditor',
                    params: {
                        name : event.item.name,
                    }
                });
            } else {
                this.$log.debug("Event object is undefined. Can't navigate to schema editor.")
            }
        },

        async onItemDeleted({ item }) {
            this.progressIndicatorService.show();
            try {

                // Delete the item, then reload the list in order to reflect change.
                await this.$store.dispatch(MODULE_NAME + "/deleteItem", item);
                await this.$store.dispatch(MODULE_NAME + "/loadResponse");
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },

        async onFilterChanged(event) {

            this.progressIndicatorService.show();
            try {
                await this.$store.dispatch(MODULE_NAME + "/updateFilter", event.filter)
                await this.refresh()
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },

        onItemAdded() {
            this.$router.push({ name: 'mappingJobTemplatesWizard'});
        }
    },

    created() {
        this.refresh();
    }
}
</script>

<i18n>
{
    "en" : {

        "mapping-job-templates-list.view.sections.list.title" : "Mapping Job Templates",
        "mapping-job-templates-list.view.sections.list.subtitle" : "You may use this dialog for creating, editing or deleting mapping job templates.",
        "mapping-job-templates-list.view.toolbar.title" : "Mapping Job Templates",

        "mapping-job-templates-list.view.columns.sourceCollectionId" : "Source Collection",
        "mapping-job-templates-list.view.columns.targetCollectionId" : "Target Collection",

        "mapping-job-templates-list.view.help.title" : "What are Mapping Job Templates?",
        "mapping-job-templates-list.view.help.description" : "Mapping job templates are used for data preparation, e.g. for training data set in machine learning. In a mapping job template, you define mapping rules for transforming data. You can execute the template as often as you like. Here, data is read from a source collection, transformed and finally written to the target collection."

    },

    "de" : {

        "mapping-job-templates-list.view.sections.list.title" : "Mapping Job Vorlagen",
        "mapping-job-templates-list.view.sections.list.subtitle" : "Sie können neue Templates für Mapping Jobs anlegen, Templates editieren oder löschen.",
        "mapping-job-templates-list.view.toolbar.title" : "Mapping Job Vorlagen",

        "mapping-job-templates-list.view.columns.sourceCollectionId" : "Quelldatenbereich",
        "mapping-job-templates-list.view.columns.targetCollectionId" : "Zieldatenbereich",

        "mapping-job-templates-list.view.help.title" : "Was sind Mapping Job Vorlage?",
        "mapping-job-templates-list.view.help.description" : "Mapping Job Vorlagen dienen der Datenvorbereitung, z.B. für Trainingsdaten im Machine Learning. In einer Mapping Job Vorlage definieren Sie Abbildungsregeln zur Transformation von Daten zwischen zwei Datenbereichen. Sie können die Vorlage beliebig oft ausführen. Hierbei werden Daten aus einem Quelldatenbereich ausgelesen, transformiert und schließlich in den Zieldatenbereich geschrieben."
    }
}
</i18n>