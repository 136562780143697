<template>
    <div>
        <c-section :title="$t('collection-settings-list.view.title')" :subtitle="$t('collection-settings-list.view.subtitle')"  class="secondary">
            <v-card class="mt-5 mb-5" tile>
                <c-item-list 
                   :title="$t('collection-settings-list.view.toolbar.title')" 
                   :items="items"
                   @refresh-list="refresh"
                   @item-added="onItemAdded" 
                   @item-edited="onItemEdited"  
                   @item-list-filter-applied="onFilterChanged"

                   :disable-edit-item="!hasEditRole && !hasReadRole" 
                   :supportsDeleteItem="false"
                   :supportsAddItem="false"
                >
                
                    <template v-slot:item-list-table-head-columns>
                        <th class="text-center">{{$t('collection-settings-list.view.columns.open')}}</th>
                    </template>

                    <template v-slot:item-list-table-body-columns="props">
                        <td class="text-center"><v-icon small @click="goToCollectionsView(props.item)">open_in_new</v-icon></td>
                    </template>
                    
                </c-item-list>
                <v-card-text></v-card-text>
                <v-divider />
                <v-subheader class="grey lighten-5">{{$t('collection-settings-list.view.help.title')}}</v-subheader>
                <v-card-text class="grey lighten-5">{{$t('collection-settings-list.view.help.description')}}</v-card-text>
            </v-card>
            <!--
            <c-item-list-container :value="{ items: items}" @item-clicked="onItemClicked" @item-list-filter-changed="onFilterChanged" :loading="loading">

            </c-item-list-container>
            -->
            <v-dialog v-model="displayConfirmationDialog" width="420" >
                <v-card tile>
                    <v-card-title>
                        {{$t('collection-settings-list.view.reindex.dialog.title')}}
                    </v-card-title>
                    <v-spacer/>
                    <v-card-subtitle>
                        {{$t('collection-settings-list.view.reindex.dialog.subtitle')}}
                    </v-card-subtitle>
                    <v-card-actions class="text-right pa-5">
                        <v-btn text class="accent" @click="onConfirm">{{$t('collection-settings-list.view.reindex.dialog.confirm')}}</v-btn>
                        <v-btn text @click="onCancel">{{$t('collection-settings-list.view.reindex.dialog.cancel')}}</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

        </c-section>
        <v-dialog>

        </v-dialog>
    </div>
</template>

<script>
const MODULE_NAME = 'systemCollectionSettingsList';
import Roles from '@/roles';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';

export default {

    inject: ['errorHandlerService', 'progressIndicatorService', 'userContextService'],

     data: () =>({

        collectionSetting : {},

        displayConfirmationDialog : false
        
     }),

    computed: {

        response() {
            return this.$store.getters[MODULE_NAME + '/response'];
        },

        items() {
            return this.response.items;
        },

        hasReadRole() {
            return this.userContextService.hasAllRoles(Roles.COLLECTION_SETTINGS_READ);
        },

        hasEditRole() {
            return this.userContextService.hasAllRoles(Roles.COLLECTION_SETTINGS_EDIT);
        },

        hasCreateRole() {
            return this.userContextService.hasAllRoles(Roles.COLLECTION_SETTINGS_CREATE);
        },

        hasDeleteRole() {
            return this.userContextService.hasAllRoles(Roles.COLLECTION_SETTINGS_DELETE);
        }
    },

    methods: {

        async refresh() {

            this.progressIndicatorService.show();
            try {
                await this.$store.dispatch(MODULE_NAME + "/loadResponse")
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }

        },

        hasEmbeddedCollections(collectionSetting) {
            return !_isEmpty(_get(collectionSetting, 'embeddedCollections', []));
        },

        onItemEdited(event) {

            if (event) {

                this.$router.push({
                    name: 'systemCollectionSettingsDashboard',
                    params: {
                        name : event.item.name
                    }
                });
            } else {
                this.$log.debug("Event object is undefined. Can't navigate to prediction scenario.")
            }
        },

        onItemAdded() {
            this.$router.push({ name: 'collectionSettingsWizardView'});
        },

        async onFilterChanged(event) {

            this.progressIndicatorService.show();
            try {
                await this.$store.dispatch(MODULE_NAME + "/updateFilter", event.filter)
                await this.refresh()
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },

        onConfirm() {
            this.startReindexCollection(this.collectionSetting);
            this.hideConfirmationDialog();
        },

        onCancel() {
           this.hideConfirmationDialog(); 
        },

        goToAPIView(collectionSetting) {
            this.$router.push({ 
                name: 'collectionSettingsAPI',
                params: {
                    name : collectionSetting.name
                }
            });
        },

        goToCollectionsView(collectionSetting){
            this.$router.push({ 
                name: 'collectionDocumentsView',
                params: {
                    collectionId : collectionSetting.name
                }
            });
        },

        async startReindexCollection(collectionSetting){
            let jobInstanceId = await this.$store.dispatch(MODULE_NAME + "/startReindexCollection", collectionSetting);

            // In case everything went as expected, navigate forward to the job status dashboard. 
                this.$router.push({
                    name: 'mappingJobInstanceDetails',
                    params: { name: jobInstanceId} 
                });
        },

        showConfirmationDialog(collectionSetting) {
            this.collectionSetting = collectionSetting;
            this.displayConfirmationDialog = true;
        },

        hideConfirmationDialog() {
            this.collectionSetting = {};
            this.displayConfirmationDialog = false;
        },

    },

    created() {
        this.refresh();
    }
}
</script>

<i18n>
{
    "en" : {
        "collection-settings-list.view.title" : "System Collection Settings",
        "collection-settings-list.view.subtitle" : "You may use this dialog for creating, editing or deleting collection settings.",
        "collection-settings-list.view.toolbar.title" : "System Collection Settings",

        "collection-settings-list.view.columns.open" : "Open",
        "collection-settings-list.view.columns.reindex" : "Reindex",
        "collection-settings-list.view.columns.apis" : "APIs",
        "collection-settings-list.view.columns.embeddedCollections" : "Embed. Coll.",
        "collection-settings-list.view.columns.systemCollection" : "System?",
        "collection-settings-list.view.columns.systemCollection.hint" : "System Collections are automatically provided by the platform in order to provided flexible access to technical data like dead letters, logs, etc.",

        "collection-settings-list.view.help.title" : "What are Collection Settings?",
        "collection-settings-list.view.help.description" : "Collection Settings are used for storing data documents. On the level of collection settings you can make settings regarding data governance and access.",

        "collection-settings-list.view.reindex.dialog.title" : "Reindex",
        "collection-settings-list.view.reindex.dialog.subtitle" : "The reindexing of a collection may take up to several minutes. Do you really want to start this process?",
        "collection-settings-list.view.reindex.dialog.confirm" : "Start now",
        "collection-settings-list.view.reindex.dialog.cancel" : "Cancel"


    },

    "de" : {
        "collection-settings-list.view.title" : "System-Datenbereiche",
        "collection-settings-list.view.subtitle" : "Diese Liste enthält die vom System verwalteten Datenbereiche.",
        "collection-settings-list.view.toolbar.title" : "System-Datenbereiche",

        "collection-settings-list.view.columns.open" : "Öffnen",
        "collection-settings-list.view.columns.reindex" : "Neu indizieren",
        "collection-settings-list.view.columns.apis" : "APIs",
        "collection-settings-list.view.columns.embeddedCollections" : "Unterbereiche?",
        "collection-settings-list.view.columns.systemCollection" : "System?",
        "collection-settings-list.view.columns.systemCollection.hint" : "System-Datenbereiche werden von der Plattform automatisch bereitgestellt. Sie ermöglichen für Systembetreuer den flexiblen Zugriff auf technische Informationen wie Logs und fehlerhafte Nachrichten.",


        "collection-settings-list.view.help.title" : "Was sind Datenbereiche?",
        "collection-settings-list.view.help.description" : "Das System speichert die Daten-Dokumente in Datenbereichen. Auf Ebene des Datenbereichs können Einstellungen zur Governance und zur Zugriffssteuerung durchgeführt werden.",

        "collection-settings-list.view.reindex.dialog.title" : "Neu indizieren",
        "collection-settings-list.view.reindex.dialog.subtitle" : "Die Neuindizierung eines Datenbereichs kann ggf. einige Minuten dauern. Möchten Sie diesen Prozess wirklich starten?",
        "collection-settings-list.view.reindex.dialog.confirm" : "Jetzt ausführen",
        "collection-settings-list.view.reindex.dialog.cancel" : "Abbrechen"

    }
}
</i18n>