<template>
  <v-sheet>
    <c-sticky-content>
        <v-toolbar>
            <v-toolbar-title>Ergebnisse</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
                <v-btn text  @click="refresh" color="primary"><v-icon class="pr-5">refresh</v-icon>Neu Laden</v-btn>
            </v-toolbar-items>
        </v-toolbar>
        <v-divider />
    </c-sticky-content>
    <v-container fluid grid-list-md class="grey lighten-3">

        <v-row justify="space-between" >

            <v-col :cols="12" :md="3">
                <v-card tile height="100%">
                    <v-card-text class="text-h6">{{$t('test-track-detail.status.label')}}</v-card-text>

                    <v-card-text>
                        <div class="text-h4 text-center" v-bind:class="{'error--text': isMalfunction}">{{status | localizedName($i18n.locale)}}</div>
                        <div class="text-center">{{testTrackStatus.modificationDateTime | localized-date-time-format($i18n.locale)}}</div>
                    </v-card-text>
                </v-card>
            </v-col>

            <v-col :cols="12" :md="9">
                <v-card tile height="100%">
                    <v-card-text class="text-h6">
                        {{$t('test-track-detail.order-data.label')}}
                    </v-card-text>

                    <v-row>
                        <v-col>
                            <v-simple-table>
                                <tbody>
                                    <tr>
                                        <td><strong>{{orderDataAttribute('TVID') | localizedName($i18n.locale)}}</strong></td>
                                        <td>{{testTrackStatusAttribute('TVID')}}</td>
                                    </tr>
                                    <tr>
                                        <td><strong>{{orderDataAttribute('projectNumber') | localizedName($i18n.locale)}}</strong></td>
                                        <td>{{testTrackStatusAttribute('projectNumber')}}</td>
                                    </tr>
                                    <tr>
                                        <td><strong>{{orderDataAttribute('projectState') | localizedName($i18n.locale)}}</strong></td>
                                        <td>{{testTrackStatusAttribute('projectState')}}</td>
                                    </tr>
                                    <tr>
                                        <td><strong>{{orderDataAttribute('Versuchstitel') | localizedName($i18n.locale)}}</strong></td>
                                        <td>{{testTrackStatusAttribute('Versuchstitel')}}</td>
                                    </tr>
                                    <tr>
                                        <td><strong>{{orderDataAttribute('valveId') | localizedName($i18n.locale)}}</strong></td>
                                        <td>{{testTrackStatusAttribute('valveId')}}</td>
                                    </tr>
                                    <tr>
                                        <td><strong>{{orderDataAttribute('type') | localizedName($i18n.locale)}}</strong></td>
                                        <td>{{testTrackStatusAttribute('type')}}</td>
                                    </tr>
                                    <tr>
                                        <td><strong>{{orderDataAttribute('nennweite') | localizedName($i18n.locale)}}</strong></td>
                                        <td>{{testTrackStatusAttribute('nennweite')}}</td>
                                    </tr>
                                    <tr>
                                        <td><strong>{{orderDataAttribute('koerperwerkstoff') | localizedName($i18n.locale)}}</strong></td>
                                        <td>{{testTrackStatusAttribute('koerperwerkstoff')}}</td>
                                    </tr>
                                    <tr>
                                        <td><strong>{{orderDataAttribute('anschlusskennziffer') | localizedName($i18n.locale)}}</strong></td>
                                        <td>{{testTrackStatusAttribute('anschlusskennziffer')}}</td>
                                    </tr>

                                </tbody>
                            </v-simple-table>

                        </v-col>
                        <v-col>
                                <v-simple-table>
                                <tbody>
                                    <tr>
                                        <td><strong>{{orderDataAttribute('dichtungskennziffer') | localizedName($i18n.locale)}}</strong></td>
                                        <td>{{testTrackStatusAttribute('dichtungskennziffer')}}</td>
                                    </tr>
                                    <tr>
                                        <td><strong>{{orderDataAttribute('steuerfunktion') | localizedName($i18n.locale)}}</strong></td>
                                        <td>{{testTrackStatusAttribute('steuerfunktion')}}</td>
                                    </tr>
                                    <tr>
                                        <td><strong>{{orderDataAttribute('antriebsgroesse') | localizedName($i18n.locale)}}</strong></td>
                                        <td>{{testTrackStatusAttribute('antriebsgroesse')}}</td>
                                    </tr>
                                    <tr>
                                        <td><strong>{{orderDataAttribute('membrangroesse') | localizedName($i18n.locale)}}</strong></td>
                                        <td>{{testTrackStatusAttribute('membrangroesse')}}</td>
                                    </tr>
                                    <tr>
                                        <td><strong>{{orderDataAttribute('batchId') | localizedName($i18n.locale)}}</strong></td>
                                        <td>{{testTrackStatusAttribute('batchId')}}</td>
                                    </tr>
                                    <tr>
                                        <td><strong>{{orderDataAttribute('softwareVersion') | localizedName($i18n.locale)}}</strong></td>
                                        <td>{{testTrackStatusAttribute('softwareVersion')}}</td>
                                    </tr>
                                    <tr>
                                        <td><strong>{{orderDataAttribute('parameters') | localizedName($i18n.locale)}}</strong></td>
                                        <td>{{testTrackStatusAttribute('parameters')}}</td>
                                    </tr>
                                    <tr>
                                        <td><strong>{{orderDataAttribute('cavity') | localizedName($i18n.locale)}}</strong></td>
                                        <td>{{testTrackStatusAttribute('cavity')}}</td>
                                    </tr>
                                    <tr>
                                        <td><strong></strong></td>
                                        <td></td>
                                    </tr>
                                </tbody>
                                </v-simple-table>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
        
        <v-row justify="space-between" >
            <v-col>
                <v-card tile height="100%">
                    <v-card-text class="text-h6">
                        {{$t('test-track-detail.test-bench-data.label')}}
                    </v-card-text>
                    <v-simple-table>
                        <tbody>
                            <tr v-for="testBenchDataMeasure in testBenchOnlyDataMeasures" :key="testBenchDataMeasure.name">
                                <td><strong>{{testBenchDataMeasure | localized-name($i18n.locale)}}</strong></td>
                                <td>{{testTrackData.data[testBenchDataMeasure.name] | numeric-format('#,###.##', $i18n.locale)}}</td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                </v-card>
            </v-col>
            <v-col>
                <v-card tile height="100%">
                    <v-card-text class="text-h6">
                        {{$t('test-track-detail.test-track-data.label')}}
                    </v-card-text>
                    <v-simple-table>
                        <tbody>
                            <tr v-for="testTrackDataMeasure in testTrackOnlyDataMeasures" :key="testTrackDataMeasure.name">
                                <td><strong>{{testTrackDataMeasure | localized-name($i18n.locale)}}</strong></td>
                                <td>{{testTrackData.data[testTrackDataMeasure.name] | numeric-format('#,###.##', $i18n.locale)}}</td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                </v-card>
            </v-col>
        </v-row>

        <!--
        <v-row justify="start">
            <v-col :cols="12" v-for="(testTrackDataMeasure, i) in testTrackDataMeasures" :key="i">
                <v-lazy>
                    <v-card tile>
                        <v-card-text>
                            <div class="title">{{testTrackDataMeasure | localized-name($i18n.locale)}}</div>
                            <div class="subheading-2">{{$t('test-track-detail.last-ten-minutes.label')}}</div>
                        </v-card-text>
                        <v-card-text>
                            <v-skeleton-loader v-if="loadingTimeSeriesData.indexOf(testTrackDataMeasure.name) > 0"
                                tile
                                class="mx-auto"
                                type="image">
                            </v-skeleton-loader>

                            <template v-else-if="hasTimeSeriesData(testTrackDataMeasure)">
                                <c-e-chart :value="getTimeSeriesData(testTrackDataMeasure)"></c-e-chart>
                            </template>

                            <template v-else>
                                <c-empty-result-tile class="elevation-0"></c-empty-result-tile>
                            </template>
                        </v-card-text>
                    </v-card>
                </v-lazy>
            </v-col>
        </v-row>
        -->

    </v-container>


  </v-sheet>
</template>

<script>
const MODULE_NAME = 'testTrackDetail';
const STATUS_FLAG = ['statusOff', 'statusMalfunction', 'statusMalfunctionAcknowledge', 'statusRunning', 'statusBreak', 'statusFinished']
// const ORDER_DATA_ATTRIBUTE_KEYS = ["valveId", "type", "batchId", "antriebsgroesse", "steuerfunktion", "Versuchstitel", "projectNumber", "nennweite", "cavity", "projectState", "dichtungskennziffer", "koerperwerkstoff", "anschlusskennziffer", "membrangroesse", "TVID", "parameters", "softwareVersion"];

import * as moment from 'moment';
import _ from 'lodash';

export default {
    inject: ['progressIndicatorService', 'errorHandlerService'],

    props: {
        value: {
            type: Object,
            required: true,
        },
    },

    data: () => ({
        selectedTabIndex: 0,
        loadingTimeSeriesData: [],
        selectedTab: 0,
        lastFetchTime: null,
    }),

    computed: {
        testBenchId() {
            return (this.value) ? this.value.testBenchId : '';
        },

        testTrackId() {
             return (this.value) ? this.value.testTrackId : '';
        },

        collectionId() {
            return (this.value) ? this.value.collectionId : '';
        },

        testTrackTitle() {
            return `${this.testBenchId} / ${this.testTrackId}`;
        },

        testTrackData() {
            return this.$store.getters[MODULE_NAME + "/testTrackData"]
        },

        testTrackDataAttribute() {
            //console.log('>>>> ' + JSON.stringify(this.testTrackData));
            return attributeName => _.get(this.testTrackData.data, attributeName, '-');
        },

        testTrackDataAttributes() {
            return this.$store.getters[MODULE_NAME + "/testTrackDataAttributes"]
        },

        testTrackDataMeasures() {
            return _.sortBy(_.filter(this.testTrackDataAttributes, (attribute) => attribute.attributeType === 'MEASURE'), (measure) => {
                return measure.ordering
            });
        },

        testBenchOnlyDataMeasures() {
            return _.filter(this.testTrackDataMeasures, (attribute) => attribute.custData ? attribute.custData.attributeType === 'TestBench' : false)
        },

        testTrackOnlyDataMeasures() {
            return _.filter(this.testTrackDataMeasures, (attribute) => attribute.custData ? attribute.custData.attributeType !== 'TestBench' : true)
        },

        testTrackStatus() {
            return this.$store.getters[MODULE_NAME + "/testTrackStatus"];
        },

        testTrackStatusAttributes() {
            return this.$store.getters[MODULE_NAME + "/testTrackStatusAttributes"]
        },

        testTrackStatusAttribute() {
            return attributeName => _.get(this.testTrackStatus.data, attributeName, '-');
        },

        orderDataAttribute() {
            return attributeName => this.testTrackStatusAttributes[attributeName];
        },

        status() {
            const statusFlag = _.get(this.testTrackStatus, 'data.statusFlag', 0)
            const status = STATUS_FLAG[statusFlag];
            return this.testTrackStatusAttributes[status];
        },

        isMalfunction() {
            return _.get(this.testTrackStatus, 'data.error', false);
        },

        timeSeriesData() {
            return this.$store.getters[MODULE_NAME + "/timeSeriesData"];
        }
    },

    methods: {

        async refresh() {

            try {
                this.progressIndicatorService.show();
                
                try {
                    this.progressIndicatorService.updateText("Lade Streckendaten");
                    await this.loadTestTrackData();
                } catch (error) {
                    this.errorHandlerService.handleError(error);
                }

                try {
                    this.progressIndicatorService.updateText("Lade Auftragsdaten");
                    await this.loadTestTrackStatus();
                } catch (error) {
                    this.errorHandlerService.handleError(error);
                }
            } finally {
                this.progressIndicatorService.hide();
            }
            
        },
 
        async loadTestTrackData() {
            try {
                await this.$store.dispatch(MODULE_NAME + '/loadTestTrackData', {
                    collectionId : this.collectionId,
                    testBenchId: this.testBenchId,
                    testTrackId: this.testTrackId
                });

            } catch (error) {
                this.handleError(error);
            } finally {
                this.lastFetchTime = moment().format('DD.MM.YY HH:mm:ss').toString();
            }
        },

        async loadTestTrackStatus() {
            try {
                await this.$store.dispatch(MODULE_NAME + '/loadTestTrackStatus', {
                    collectionId : 'TestTrackStatus',
                    testBenchId: this.testBenchId,
                    testTrackId: this.testTrackId
                });

            } catch (error) {
                this.handleError(error);
            } finally {
                this.lastFetchTime = moment().format('DD.MM.YY HH:mm:ss').toString();
            }
        },


        handleError(error) {
            this.$log.debug(error.toJSON ? error.toJSON() : error );
            this.errorHandlerService.handleError(error);
        },


        

    },

    created() {
        this.refresh();
    },



}
</script>
<style scoped>
#sticky {
  position: sticky;
  position: -webkit-sticky;
  top: 64px;
  z-index: 1;
}
</style>

<i18n>
{
    "en": {
        "test-track-detail.test-tracks.label": "Test tracks",
        "test-track-detail.to-dashboard.label": "To the dashboard",
        "test-track-detail.export-protocol.label": "Export protocol",
        "test-track-detail.export-csv.label": "Export CSV",
        "test-track-detail.status.label": "Status",
        "test-track-detail.order-data.label": "Order Data",
        "test-track-detail.last-ten-minutes.label": "Last 10 minutes",
        "test-track-detail.view.timeseries.section.result.hint": "The time series charts are updated every minute.",
        "test-track-detail.test-bench-data.label": "Test Bench Data",
        "test-track-detail.test-track-data.label": "Test Track Data"
    },

    "de": {
        "test-track-detail.test-tracks.label": "Prüfstrecken",
        "test-track-detail.to-dashboard.label": "Zum Dashboard",
        "test-track-detail.export-protocol.label": "Protokoll Exportieren",
        "test-track-detail.export-csv.label": "CSV exportieren",
        "test-track-detail.status.label": "Status",
        "test-track-detail.order-data.label": "Auftragsdaten",
        "test-track-detail.last-ten-minutes.label": "Letzte 10 Minuten",
        "test-track-detail.view.timeseries.section.result.hint": "Die Zeitreihen Diagramme werden jede Minute aktualisiert.",
        "test-track-detail.test-bench-data.label": "Prüfstandsdaten",
        "test-track-detail.test-track-data.label": "Streckendaten"
    }
}
</i18n>