<template>
    <div>

            <v-container fluid class="secondary pt-10 pb-10">
                <v-row justify="center">
                    <v-col cols="12" lg="9">
                        <c-base-dialog
                            :value="collectionSetting"
                            :title="$t('new-collection-settings-wizard.view.title')"
                            :subtitle="$t('new-collection-settings-wizard.view.subtitle')"
                            color="secondary"
                            @dialog-saved="save"
                            @dialog-canceled="cancel"
                            >
                            <template v-slot:default="props">
                                <v-stepper v-model="currentStep" vertical non-linear>
                                    <v-stepper-step step="0" editable>
                                        {{$t('collection-settings-wizard.view.stepper.steps.1')}}
                                    </v-stepper-step> 
                                    <v-stepper-content step="0">
                                        <v-sheet class="pa-5">
                                            <c-name-input
                                                v-model="props.item.name"
                                                filled
                                            />
                                            <v-select
                                                v-model="props.item.schemaId"
                                                :label="$t('collection-settings-wizard.view.fields.schema.name')"
                                                :hint="$t('collection-settings-wizard.view.fields.schema.hint')"
                                                persistent-hint
                                                :items="schemas"
                                                filled
                                                :multiple="false"
                                                item-value="name"
                                                :return-object="false"
                                                :rules="[  v => v && !!v.trim()  || $t('collection-settings-wizard.view.fields.schema.validation.notEmpty') ]">
                                                    <template v-slot:selection="{ item }">
                                                    {{item | localized-name($i18n.locale)}}
                                                    </template>

                                                    <template v-slot:item="{ item, on, attrs }">
                                                        <v-list-item two-line v-on="on" v-bind="attrs" color="white">
                                                            <v-list-item-content>
                                                                <v-list-item-title>
                                                                    {{item | localized-name($i18n.locale)}}
                                                                </v-list-item-title>
                                                                <v-list-item-subtitle>
                                                                    {{item.name}}
                                                                </v-list-item-subtitle>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </template>
                                                </v-select>
                                        </v-sheet>
                                    </v-stepper-content>
                                    <v-stepper-step step="1" editable>
                                        {{$t('collection-settings-wizard.view.stepper.steps.2')}}
                                    </v-stepper-step> 
                                    <v-stepper-content step="1">
                                        <v-sheet class="pa-5">
                                            <v-text-field v-model="props.item.idTemplate" filled persistent-hint
                                            :label="$t('collection-settings-wizard.view.fields.idTemplate.name')"
                                            :hint="$t('collection-settings-wizard.view.fields.idTemplate.hint')"
                                            :rules="[  v => v && !!v.trim()  || $t('collection-settings-wizard.view.fields.idTemplate.validation.notEmpty') ]"
                                        />

                                        <v-text-field v-model="props.item.partitionIdTemplate" filled persistent-hint
                                            :label="$t('collection-settings-wizard.view.fields.partitionIdTemplate.name')"
                                            :hint="$t('collection-settings-wizard.view.fields.partitionIdTemplate.hint')"
                                            :rules="[  v => v && !!v.trim()  || $t('collection-settings-wizard.view.fields.partitionIdTemplate.validation.notEmpty') ]"
                                        />

                                        <v-sheet class="mb-5 mt-5">
                                            <template v-if="!selectedSchemaEmpty">
                                                 <dxs-collection-settings-wizard-help  :value="{schema: selectedSchema} "  />
                                            </template>
                                            <template v-else>
                                                <v-sheet class="text-body-2  pa-5" elevation="1">
                                                    <v-icon color="accent">info</v-icon> Bitte wählen Sie ein Schema aus, um die Hilfe anzuzeigen.
                                                </v-sheet>
                                            </template>
                                        </v-sheet>
                                       


                                        </v-sheet>
                                    </v-stepper-content>
                                </v-stepper>
                            </template>
                        </c-base-dialog>
                    </v-col>
                </v-row>

            </v-container>

    </div>
</template>

<script>
const MODULE_NAME = 'collectionSettingsWizard';

import CollectionSettingsWizardHelpComponent from '@/core/components/catalog/collection-settings/collection-settings-wizard-help.component';
import { get, isEmpty } from 'lodash';

export default {


    inject: ['errorHandlerService', 'progressIndicatorService'],

    components: {
        'dxs-collection-settings-wizard-help' : CollectionSettingsWizardHelpComponent
    },

    data: () => ({
        currentStep: 0
    }),

    methods: {

        async init() {
            this.progressIndicatorService.show();

            try {
                await this.$store.dispatch(MODULE_NAME + '/reset');
                await this.$store.dispatch(MODULE_NAME + '/loadSchemas')
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },

        async save() {

            this.progressIndicatorService.show();

            try {

                await this.$store.dispatch(MODULE_NAME + "/createCollectionSetting");

                this.$router.push( {name: 'collectionSettingsEditor', params: {name: this.name}  });

            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },

        cancel() {
            this.$router.push( {
                name: 'collectionSettingsList'
            });
        }
    },

    computed: {


        collectionSetting: {

            get() {
                return this.$store.getters[MODULE_NAME + '/collectionSetting'];
            },

            set(item) {

                this.$store
                    .dispatch(MODULE_NAME + "/updateCollectionSetting", item)
                    .catch(this.errorHandlerService.handleError);
            }
        },

        schemas: {

            get() {
                return this.$store.getters[MODULE_NAME + '/schemas'];
            }
        },

        selectedSchema() {
            const schemaId = get(this.collectionSetting, 'schemaId', null);
            let schema = {};
            if (schemaId) {
                schema = this.schemas.find( e => e.name === schemaId);
                if (!schema) schema = {};
            }

            return schema;
        },

        selectedSchemaEmpty() {
            return isEmpty(this.selectedSchema);
        },

        name: {

            get() {
                return this.collectionSetting ? this.collectionSetting.name : null;
            }
        }

    },

    created() {
        this.init();
    }


}
</script>

<i18n>
{
    "en" : {
        "new-collection-settings-wizard.view.title" : "Create New Collection",
        "new-collection-settings-wizard.view.subtitle" : "This wizard will guide you through the required steps for creating a new collection.",
        "collection-settings-wizard.view.fields.schema.name" : "Schema",
        "collection-settings-wizard.view.fields.schema.hint" : "By selecting the schema you define the actual structure of documents which will be stored in this collection.",
        "collection-settings-wizard.view.fields.schema.validation.notEmpty" : "No schema selected. Please select a schema in order to define the document structure for this collection.",

        "collection-settings-wizard.view.stepper.steps.1" : "General",
        "collection-settings-wizard.view.stepper.steps.2" : "Document Storage Settings",

        "collection-settings-wizard.view.fields.idTemplate.name" : "Document ID Template",
        "collection-settings-wizard.view.fields.idTemplate.hint" : "The Document ID Template defines how the system will create IDs for documents stored in this collection. Please make sure, that document IDs are actually unique.",
        "collection-settings-wizard.view.fields.idTemplate.validation.notEmpty": "Document ID Template must not be empty.",

        "collection-settings-wizard.view.fields.partitionIdTemplate.name" : "Partition-ID Template",
        "collection-settings-wizard.view.fields.partitionIdTemplate.hint" : "The Partition ID Template defines which partition is used for storing the document. Oftentimes a date-related partition is used (e.g. storing all data of a single month in one partition).",
        "collection-settings-wizard.view.fields.partitionIdTemplate.validation.notEmpty": "Partition ID Template must not be empty.",

        "collection-settings-wizard.view.help.title" : "Hilfe",
        "collection-settings-wizard.view.help.description" : "Die Dokumenten-ID und die Partitions-ID werden bei der Speicherung anhand der oben definierten Formel-Ausdrücke generiert. Die nachfolgende Auflistung gibt Ihnen einen Überblick über die zur Verfügung stehenden Datenobjekte."


    },

    "de" : {
        "new-collection-settings-wizard.view.title" : "Neuen Datenbereich anlegen",
        "new-collection-settings-wizard.view.subtitle" : "Dieser Assistent führt Sie durch die notwendigen Schritte zum Anlegen eines neuen Datenbereichs.",
        "collection-settings-wizard.view.fields.schema.name" : "Schema",
        "collection-settings-wizard.view.fields.schema.hint" : "Das ausgewählte Schema legt die Struktur der Dokumente fest, die in diesem Datenbereich gespeichert werden.",
        "collection-settings-wizard.view.fields.schema.validation.notEmpty" : "Kein Schema ausgewählt. Sie müssen ein Schema auswählen, um die Struktur festzulegen.",

        "collection-settings-wizard.view.stepper.steps.1" : "Allgemeine Einstellungen",
        "collection-settings-wizard.view.stepper.steps.2" : "Verwaltung Dokumentenspeicher",

        "collection-settings-wizard.view.fields.idTemplate.name" : "Vorlage Dokumenten-ID",
        "collection-settings-wizard.view.fields.idTemplate.hint" : "Mit der Vorlage für die Dokumenten-ID  legen Sie fest, wie das System IDs für die gespeicherten Dokumente in diesem Datenbereich generiert. Bitte achten Sie darauf, dass die Dokumenten-ID eine eindeutige Identifizierung des Dokuments ermöglichen muss.",
        "collection-settings-wizard.view.fields.idTemplate.validation.notEmpty": "Die Vorlage für die Dokumenten-ID darf nicht leer sein.",

        "collection-settings-wizard.view.fields.partitionIdTemplate.name" : "Vorlage Partition-ID",
        "collection-settings-wizard.view.fields.partitionIdTemplate.hint" : "Mit der Vorlage für die Partition-ID legen Sie fest, in welcher Partition das System Dokumente ablegen soll. Partitionen dienen dazu große Datenmengen zu verteilen und sind häufig abhängig von zeitlichen Faktoren (z.B. monatsweise Speicherung von Dokumenten).",
        "collection-settings-wizard.view.fields.partitionIdTemplate.validation.notEmpty": "Die Vorlage für die Partition-ID darf nicht leer sein."

    }
}
</i18n>