import api from '@/store/api';
import _ from 'lodash';

import aggregations from '@/core/views/collection/mock/collection-time-series-agg.mock'
import timeScales from '@/core/views/collection/mock/collection-time-series-timescales.mock'
import measureColors from '@/cust/views/measure-colors.map';

const MAX_PAGE_SIZE = 500;

export default {

    namespaced : true,

    state: {

        testTrackData: {},
        testTrackDataAttributes: {},

        testTrackStatus: {},
        testTrackStatusAttributes: {},
        timeSeriesData: [],

        timeSeriesTemporalFilter: {},

        totalItemCount: 0,
        documentCount: 0,
        pagination: {from: 0, size: MAX_PAGE_SIZE, searchAfter: []},
    },

    mutations: {
        increaseDocumentCount(state, documentCount) {
            state.documentCount  = state.documentCount + ( (documentCount && documentCount >= 0) ? documentCount : 0);
        },

        setDocumentCount(state, documentCount) {
            state.documentCount = (documentCount && documentCount >= 0) ? documentCount : 0;
        },

        setTestTrackData(state, response) {
            state.testTrackDataAttributes = _.get(response, 'meta.attributes', {});
            state.testTrackData = _.get(response, 'items', {})[0];

            // console.log('state.timeSeriesData[key]', state.timeSeriesData)
        },

        setTestTrackStatus(state, response) {
            state.testTrackStatusAttributes = _.get(response, 'meta.attributes', {});
            state.testTrackStatus = _.get(response, 'items', {})[0];            
        },
        
        setTimeSeriesData(state, response) {
            const attribute = response.request.timeSeries.measures[0].attribute;

            if (_.has(response, 'data.result')) {
                response.data.result.forEach(result => {
                    result.values.forEach(value => {
                        value['roundedValue'] = +value.value.toFixed(3)
                    });
                })
            }

            if (!_.isEmpty(attribute)) {
                state.timeSeriesData.push({...response, aggregations: aggregations, timeScales: timeScales});
            }            
        },
        
        setTotalItemCount(state, totalItemCount) {
            state.totalItemCount = (totalItemCount && totalItemCount >= 0) ? totalItemCount : 0;
        },

        setPagination(state, pagination) {

            // Create a default if no pagination has been set up.
            if (!state.pagination) {
                state.pagination =  {from: 0, size: MAX_PAGE_SIZE, searchAfter: []};
            }

            if (pagination) {

                state.pagination.from = (pagination.from) ? pagination.from : 0;
                state.pagination.size = (pagination.size) ? pagination.size : MAX_PAGE_SIZE;
                state.pagination.searchAfter = (pagination.searchAfter) ? pagination.searchAfter : [];
            }
        },

        setTimeSeriesTemporalFilter(state, timeSeriesTemporalFilter) {
            state.timeSeriesTemporalFilter = timeSeriesTemporalFilter;
        },

        pruneTimeSeriesData(state) {
            state.timeSeriesData = [];
        }
    },

    actions: {

        updatePagination(context, response) {

            if (response && response.data) {

                if (response.data.pagination) {
                    context.commit('setPagination', response.data.pagination);
                }

                if (response.data.result) {
                    for (let item of response.data.result) {
                        context.commit('increaseDocumentCount', item.docCount);
                    }
                }
            }
        },

        async loadTestTrackData(context, {collectionId, testBenchId, testTrackId}) {
            const query = {
                filter: {
                    facetFilters: [
                        {
                            filterQuery: testBenchId,
                            name: "testBenchId",
                            filterMode: "CONTAINS",
                            values: []
                        },
                        {
                            filterQuery: testTrackId,
                            name: "testTrackId",
                            filterMode: "CONTAINS",
                            "values": []
                        }
                    ],
                    filterQuery: "*",
                    filterQueryLanguage: "SIMPLE",
                    rangeFilters: []
                },

                pagination: {
                    from: 0,
                    searchAfter: [],
                    size: 1
                },

                sort: {
                    sortCriteria: [
                        {
                          name: "sampleDateTime",
                          ordering: "DESC"
                        }
                    ]
                }
            }
 
            return api.collections.documents.findByQuery(collectionId, query).then(response => context.commit('setTestTrackData', response));
        },

        async loadTestTrackStatus(context, {collectionId, testBenchId, testTrackId}) {
            const query = {
                filter: {
                    facetFilters: [
                        {
                            filterQuery: testBenchId,
                            name: "testBenchId",
                            filterMode: "CONTAINS",
                            values: []
                        },
                        {
                            filterQuery: testTrackId,
                            name: "testTrackId",
                            filterMode: "CONTAINS",
                            "values": []
                        }
                    ],
                    filterQuery: "*",
                    filterQueryLanguage: "SIMPLE",
                    rangeFilters: []
                },

                pagination: {
                    from: 0,
                    searchAfter: [],
                    size: 1
                },

                sort: {
                    sortCriteria: [
                        {
                          name: "sampleDateTime",
                          ordering: "DESC"
                        }
                    ]
                }
            }
 
            return api.collections.documents.findByQuery(collectionId, query).then(response => context.commit('setTestTrackStatus', response));
        },

        async loadTimeSeriesData(context, {collectionId, testBenchId, testTrackId, attribute}) {
            // reset pagination and document count
            context.commit('setPagination', {});
            context.commit('setDocumentCount', 0);
            const query = {
                filter: {
                    filterQuery: "*",
                    filterQueryLanguage: "SIMPLE",
                    facetFilters: [
                        {
                            filterQuery: testBenchId,
                            name: "testBenchId",
                            filterMode: "CONTAINS",
                            values: []
                        },
                        {
                            filterQuery: testTrackId,
                            name: "testTrackId",
                            filterMode: "CONTAINS",
                            "values": []
                        }
                    ],
                    rangeFilters: [
                        context.getters.timeSeriesTemporalFilter
                    ],
                },
                pagination: context.getters.pagination,
                dateAttribute: "sampleDateTime",
                timeScaleSeconds: 2,
                measures: [
                    {
                        attribute: attribute,
                        operation: "AVERAGE",
                        customData: {
                            color: measureColors[attribute] ? measureColors[attribute].value : "#E4F3D8"
                        }
                    }
                ]
                
            };

            try {
                let response = await api.collections.timeSeries.getTimeSeries(collectionId, query);

                let items = [];
                if (response && response.data) {

                    context.commit('setTotalItemCount', response.data.pagination.totalItemCount);
                    items.push(...response.data.result);

                    while (response.data.result && response.data.result.length > 0) {
                        context.dispatch('updatePagination', response);
                        response = await api.collections.timeSeries.getTimeSeries(collectionId, query);
                        if (response && response.data) {
                            items.push(...response.data.result);
                        }
                    }

                    if (response && response.data) {
                        response.data.result = items;
                    }
                }

                context.commit('setTimeSeriesData', response);
                return Promise.resolve();
            } catch (error) {
                return Promise.reject(error);
            }
        },

        setTimeSeriesTemporalFilter(context, timeSeriesTemporalFilter) {
            context.commit('setTimeSeriesTemporalFilter', timeSeriesTemporalFilter)
        },

        pruneTimeSeriesData(context) {
            context.commit('pruneTimeSeriesData');
        }
    },

    getters: {

        testTrackDataAttributes(state) {
            return state.testTrackDataAttributes;
        },

        testTrackData(state) {
            return state.testTrackData
        },

        testTrackStatus(state) {
            return state.testTrackStatus
        },

        testTrackStatusAttributes(state) {
            return state.testTrackStatusAttributes
        },

        timeSeriesData(state) {
            return state.timeSeriesData;
        },

        timeSeriesTemporalFilter(state) {
            return state.timeSeriesTemporalFilter;
        },

        pagination(state) {
            return state.pagination;
        }
    }


}
