
import TestBenchListView from '@/cust/views/test-bench-list.view';
import TestTrackListView from '@/cust/views/test-track-list.view';
import TestTrackDetailView from '@/cust/views/test-track-detail.view';
import TestTrackProtocolView from '@/cust/views/test-track-protocol-export.view';
import TestTrackDetailParamsView from '@/cust/views/test-track-detail-params.view';
import TestTrackDetailSamplesView from '@/cust/views/test-track-detail-samples.view';

import OrderStatusDashboardView from '@/cust/views/order-status-dashboard.view';
import PrintView from '@/views/print.view';
import TestTrackProtocolExportPrint from '@/cust/views/test-track-protocol-export.print';

import MembraneEvaluationView from '@/cust/views/reports/membrane-evaluation.view';

import { DateTime } from 'luxon';


export default [
    {
        name: 'testBenchesList',

        meta: {
            navigation: {

                categoryId: 'amentis-dxs-custom',
                icon: 'assignment_turned_in',
                order: 300,
    
                i18n: {
    
                    'en' : {
                        name: 'Test Benches'
                    },
    
                    'de' : {
                        name: 'Prüfstände'
                    }
                }
            },
        },


        path: '/testBenches',

        component: TestBenchListView,
    },
    {
        name: 'testTracksList',

        meta: {

            i18n : {
                'de' : {
                    name: 'Alle Prüfstrecken',
                    shortName: 'Prüfstrecken',
                    description: 'Liste der Prüfstrecken',
                },

                'en' : {
                    name: 'All Test Tracks',
                    shortName: 'Test Tracks',
                    description: 'List of Test Tracks',
                }
            },


            navigation : {
                display: true,
                icon: '',
                category: ''
            },
        },

        path: '/testBenches/:name/testTracks',
        component: TestTrackListView,
        props: (route) => ({ value: {name: route.params.name} }),
    },

    {
        name: 'testTracksDetail',
        path: '/testBenches/:testBenchType/:testBenchId/testTracks/:collectionId/:testTrackId',
        component: TestTrackDetailView,
        props: (route) => ({ value: {testBenchId: route.params.testBenchId, testTrackId: route.params.testTrackId, collectionId: route.params.collectionId, testBenchType: route.params.testBenchType} }),
        children: [
            {
                path: 'params',
                name: 'testTrackDetailParamsView',
                components: { testTrackRouterView: TestTrackDetailParamsView },
                props: { 
                    testTrackRouterView: (route) => ({
                        value: {
                            testBenchId: route.params.testBenchId, 
                            testTrackId: route.params.testTrackId, 
                            testBenchType: route.params.testBenchType,
                            collectionId: route.params.collectionId
                        } 
                    }) 
                }
            },
            {
                path: 'samples',
                name: 'testTrackSamplesView',
                components: { testTrackRouterView: TestTrackDetailSamplesView },
                props: { 
                    testTrackRouterView: (route) => getTestTrackSampleProps(route)
                }
            },
            {
                path: 'samples-aggregated',
                name: 'testTrackSamplesAggregatedView',
                components: { testTrackRouterView: TestTrackDetailSamplesView },
                props: { 
                    testTrackRouterView: (route) => getTestTrackSampleProps(route, true)
                }
            },
        ]
    },
    {

        name: 'testTrackExport',
        path: '/testBenches/:testBenchType/:testBenchId/testTracks/:collectionId/:testTrackId/export',
        component: TestTrackProtocolView,
        props: (route) => ({ value: {testBenchId: route.params.testBenchId, testBenchType: route.params.testBenchType, testTrackId: route.params.testTrackId, collectionId: route.params.collectionId} }),
    },

    {
        name: 'orderStateDashboard',

        meta: {
            navigation: {

                categoryId: 'amentis-dxs-custom',
                icon: 'assignment_ind',
                order: 400,
    
                i18n: {
    
                    'en' : {
                        name: 'Order Status'
                    },
    
                    'de' : {
                        name: 'Auftragsstatus'
                    }
                }
            },
        },

        path: '/orders/state',

        component: OrderStatusDashboardView,
    },

    {
        path: '/print',
        name: 'printViewTestTrackProtocol',
        component: PrintView,
        children: [
            {
                path: 'testTrackProtocolExportPrint',
                name: 'testTrackProtocolExportPrint',
                components: { printView: TestTrackProtocolExportPrint }
            }
        ]
    },

    {
        path : '/reports/membrane-evaluation',
        name: 'MembraneEvaluationReport',
        component: MembraneEvaluationView,

        meta: {
            navigation: {

                categoryId: 'amentis-dxs-custom',
                icon: 'rule',
                order: 600,
    
                i18n: {
    
                    'en' : {
                        name: 'Membrane Evaluation'
                    },
    
                    'de' : {
                        name: 'Bewertung Membrane'
                    }
                }
            },
        },
    }

];

function getTestTrackSampleProps(route, aggregatedSamplesView=false) {

    const today = DateTime.now();
    const yesterday = (aggregatedSamplesView) ? today.minus({ days: 90 }) : today.minus({ hours: 3 });

    let collectionId = route.params.collectionId;
    if (aggregatedSamplesView) {
        collectionId = collectionId + 'Agg';
    }

    const value = {
        testBenchId: route.params.testBenchId, 
        testTrackId: route.params.testTrackId,
        testBenchType: route.params.testBenchType,
        collectionId: collectionId,
        filterStartDateTime: yesterday.toISO(),
        filterEndDateTime: today.toISO(),
    };

    return  { value: value };
} 