<template>
    <v-simple-table >
        <thead>
            <tr>
                <th>{{$t('mapping-rule-expression-help.component.tab.field')}}</th>
                <th>{{$t('mapping-rule-expression-help.component.tab.datatype')}}</th>
                <th>{{$t('mapping-rule-expression-help.component.tab.note')}}</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="attribute of sortedAttributes" :key="'tr_data_' + attribute.name">
                <td><code>$data.{{attribute.name}}</code></td>
                <td>{{attribute.datatype}}</td>
                <td class="body-2">{{attribute | localized-name($i18n.locale)}} {{attribute | localized-description($i18n.locale)}}</td>
            </tr>
        </tbody>
    </v-simple-table>
</template>

<script>
import { clone } from 'lodash';

export default {
    name: 'expression-atrributes-help',

    props: {
        value: {
            type: Array,
            required: true
        }
    },

    computed: {
        sortedAttributes() {
            const sortedAttributes = clone(this.value ? this.value : []);
            return sortedAttributes.sort((a,b) => a.ordering - b.ordering);
        }
    }
}

</script>
 
<i18n>
{
    "en": {
        "mapping-rule-expression-help.component.tab.field" : "Field",
        "mapping-rule-expression-help.component.tab.datatype" : "Datatype",
        "mapping-rule-expression-help.component.tab.note" : "Note"
    },
    "de":{
        "mapping-rule-expression-help.component.tab.field" : "Feld",
        "mapping-rule-expression-help.component.tab.datatype" : "Datentyp",
        "mapping-rule-expression-help.component.tab.note" : "Erläuterung"
    }
}
</i18n>