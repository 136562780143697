<template>


    <div class="secondary">

        <!-- Loading screen -->
        <v-overlay :absolute="true" :value="loadingData || loadingMore">
            <c-data-loading-progress
                :rotate="0"
                :size="250"
                :width="3" >
                <template v-if="!loadingData">
                    {{items.length}} / {{pagination}}
                </template>
            </c-data-loading-progress>
        </v-overlay>

    <!-- Breadcrumbs -->
      <v-container fluid grid-list-md class="secondary lighten-1">
            <v-row justify="start" >
                <v-col :cols="12">
                     <v-btn text @click="goToTestTrackDetail(testBenchId, testTrackId, testBenchType, collectionId)"><v-icon class="mr-2">keyboard_backspace</v-icon>{{$t('test-track-detail-protocol-export.test-track.label')}}</v-btn>
                </v-col>
            </v-row>
      </v-container>

        <v-toolbar flat>
          <v-toolbar-title>{{testTrackTitle}} / {{$t('test-track-detail-protocol-export.create-test-protocol.label')}}</v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>

          </v-toolbar-items>
        </v-toolbar>


        <v-container fluid>
            <v-row justify="center" >
                <v-col :cols="12">
                    <v-card tile>
                        <v-card-text>
                            <div class="title">1. {{$t('test-track-detail-protocol-export.settings.label')}}</div>
                        </v-card-text>
                        <v-divider />
                        <v-subheader>{{$t('test-track-detail-protocol-export.set-period.label')}}</v-subheader>
                        <v-card-text>
                            <c-date-time-selector :label="$t('collection-time-series.print.from')" v-bind:value="from" @selection-changed="onDateTimeFromChanged" />
                            <c-date-time-selector :label="$t('collection-time-series.print.until')" v-bind:value="until" @selection-changed="onDateTimeUntilChanged"/>

                            <div class="error--text" v-if="showDateTimeError">
                                {{dateTimeErrorMessage}}
                            </div>
                        </v-card-text>

                        <v-divider />

                        <v-subheader>{{$t('test-track-detail-protocol-export.set-measures.label')}}</v-subheader>

                        <v-list-item-group v-model="selectedMeasures" multiple>
                            <v-list-item v-for="measure in measures" :key="measure.name">
                                <template v-slot:default="{ active }">
                                    <v-list-item-action>
                                    <v-checkbox :input-value="active"></v-checkbox>
                                    </v-list-item-action>

                                    <v-list-item-content>
                                    <v-list-item-title>{{measure | localized-name($i18n.locale)}}</v-list-item-title>
                                    <v-list-item-subtitle>{{measure | localized-description($i18n.locale)}}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </template>
                            </v-list-item>
                        </v-list-item-group>
                        <v-divider />

                        <v-card-actions>
                            <v-btn block text @click="createPreview()">{{$t('test-track-detail-protocol-export.show-preview.label')}}</v-btn>

                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>

        <v-container fluid v-if="showPreview && !moreElements && pagination === 0">
            <v-card tile>
                <v-card-text><div class="title">2. {{$t('test-track-detail-protocol-export.preview-print.label')}}</div></v-card-text>
            </v-card>
            <c-empty-result-tile />
        </v-container>

        <v-container fluid v-if="showPreview && !moreElements && pagination > 0">
            <v-card tile>
                <v-card-text><div class="title">2. {{$t('test-track-detail-protocol-export.preview-print.label')}}</div></v-card-text>
                <!-- <v-card-text>
                    <div class="title">2. {{$t('test-track-detail-protocol-export.preview-print.label')}}</div>
                    <div class="text-right">
                        <span>Load Data Time: {{loadingDataTime}} sec.</span>
                        <br/>
                        <span>Load More Time: {{loadingMoreTime}} sec.</span>
                        <br/>
                        <span>AVG Request Time: {{loadingMoreTime / loadMoreCount}} sec. for Page size: {{pageSize}}</span>
                        <br/>
                        <span>Request Time Prediction: {{(loadingMoreTime / loadMoreCount) * (pagination / pageSize) }} sec. for Page size: {{pageSize}}</span>
                    </div>
                </v-card-text> -->
                <v-row>
                    <v-col :cols="12" :md="6" :lg="6">
                        <v-card-title>{{$t('test-track-detail-protocol-export.order-data.label')}}:</v-card-title>
                        <v-card-text>
                            <table width="100%">
                                <tr>
                                    <td>{{$t('test-track-detail-protocol-export.order-number.label')}}:</td>
                                    <td>{{tvId}}</td>
                                </tr>
                                <tr>
                                    <td>{{$t('test-track-detail-protocol-export.applicant.label')}}:</td>
                                    <td>{{userName}}</td>
                                </tr>
                                <tr>
                                    <td>{{$t('test-track-detail-protocol-export.project.label')}}:</td>
                                    <td>-</td>
                                </tr>
                                <tr>
                                    <td>{{$t('test-track-detail-protocol-export.date.label')}}:</td>
                                    <td>{{printDate}}</td>
                                </tr>
                            </table>
                        </v-card-text>
                    </v-col>
                    <v-col :cols="12" :md="6" :lg="6">
                        <v-card-title>{{$t('test-track-detail-protocol-export.test-data.label')}}:</v-card-title>
                        <v-card-text>
                            <table width="100%">
                                <tr>
                                    <td>{{$t('test-track-detail-protocol-export.records.label')}}:</td>
                                    <td>{{totalItemCountFormatted}}</td>
                                </tr>
                                <tr>
                                    <td>{{meta.attributes['cycle'] | localized-description($i18n.locale)}}:</td>
                                    <td>{{latestItem.data.cycle}}</td>
                                </tr>
                                <tr>
                                    <td>{{$t('test-track-detail-protocol-export.period.label')}}:</td>
                                    <td>{{dateTimeFromFormatted}} - {{dateTimeUntilFormatted}}</td>
                                </tr>
                                <tr>
                                    <td>{{$t('test-track-detail-protocol-export.switching-cycle.actual.label')}}:</td>
                                    <td>{{latestItem.data.currentSwitchCounter}}</td>
                                </tr>
                                <tr>
                                    <td>{{$t('test-track-detail-protocol-export.switching-cycle.target.label')}}:</td>
                                    <td>{{latestItem.data.plannedSwitchCounter}}</td>
                                </tr>
                            </table>
                        </v-card-text>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col>
                        <v-card-text>
                            <gemue-e-chart ref="timeSeriesChart" v-if="!moreElements && hasTimeSeries" :value="timeSeries"></gemue-e-chart>
                        </v-card-text>
                    </v-col>
                </v-row>
                <v-divider />
                <v-card-actions>
                    <v-btn v-if="pagination > 0 && !loadingData && !loadingMore" :disabled="(moreElements || loadingData)" @click="goToPrintView()" block text>{{$t('test-track-detail-protocol-export.continue-print.label')}}</v-btn>
                </v-card-actions>
            </v-card>

            <template v-if="pagination === 0 && !loadingData && !loadingMore">
                <c-empty-result-tile />
            </template>

        </v-container>

    </div>
</template>

<script>
const MODULE_NAME = 'testTrackProtocolExport';
const MAX_TIME_RANGE_HOURS = 24;
const DATETIME_FORMAT_STRING = 'YYYY-MM-DD[T]HH:mm';
const DATETIME_FORMAT = 'DD.MM.YY HH:mm';

import _ from 'lodash';
import * as moment from 'moment';
import { DateTime } from 'luxon';

import GemueEChart from '@/cust/components/charts/e-chart.component';
import measureColors from '@/cust/views/measure-colors.map';

export default {
    inject: ['errorHandlerService', 'userContextService'],

    components: {
        'gemue-e-chart' : GemueEChart
    },

    props: {
        value: {
            type: Object,
            required: true,
        },
    },

    data: () => ({
        loadingData: true,
        loadingDataTime: 0,
        loadingMore: false,
        loadingMoreTime: 0,
        loadMoreCount: 0,
        selectedMeasuresIndices: [],
        from: null,
        until: null,
        showPreview: null,
        showDateTimeError: null,
        dateTimeErrorMessage: null,
        setDateTimeInterval: null,
        timeSeries: null,
        hasTimeSeries: false,
        chartType: false
    }),

    computed: {

        userName() {
            return this.userContextService.userName;
        },

        response() {
            return this.$store.getters[MODULE_NAME + "/response"]
        },

        items() {
            return _.get(this.response, 'items', []);
        },

        latestItem() {
            return _.maxBy(this.items, 'data.sampleDateTime')
        },

        meta() {
            return _.get(this.response, 'meta', {});
        },

        measures() {
            const attributes = _.get(this.meta, 'attributes', {});
            return _.sortBy(_.filter(attributes, attribute => attribute.attributeType === 'MEASURE'), (measure) => {
                return measure.i18n[this.$i18n.locale].name
            });
        },

        hasMeasures() {
            return !_.isEmpty(this.measures);
        },

        pagination() {
            return _.get(this.response, 'pagination.totalItemCount', 0);
        },

        pageSize() {
            return _.get(this.response, 'pagination.size', 1);
        },

        totalItemCountFormatted() {
            return this.$core.filters.numericFormat(this.pagination, '#,###', this.$i18n.locale);
        },

        moreElements() {
            return this.items.length < this.pagination;
        },

        tvId() {
            return _.uniq(_.map(this.response.items, 'data.TVID')).join(',');
        },

        testTrackTitle() {
            return (this.value) ? this.value.testBenchId + ' / ' + this.value.testTrackId : '';
        },

        testBenchId() {
            return (this.value) ? this.value.testBenchId : '';
        },
        
        testBenchType() {
            return (this.value) ? this.value.testBenchType : '';
        },

        testTrackId() {
             return (this.value) ? this.value.testTrackId : '';
        },

        collectionId() {
             return (this.value) ? this.value.collectionId : '';
        },

        dateTimeFromFormatted() {
            return moment(this.from).format(DATETIME_FORMAT);
        },

        dateTimeUntilFormatted() {
            return moment(this.until).format(DATETIME_FORMAT);
        },

        selectedMeasures: {
            get() {
                return this.selectedMeasuresIndices;
            },
            set(value) {
                this.selectedMeasuresIndices = value;
                this.createTimeSeries();
            }
        },

        printDate() {
            const d = new Date();
            return `${d.toLocaleDateString(this.$i18n.locale)} ${d.toLocaleTimeString(this.$i18n.locale)}`;
        },
    },

    methods: {

        setFilter() {
            this.$store.dispatch(MODULE_NAME + '/setFilter', { filter: {
                facetFilters:[
                    {
                        name:"testBenchId",
                        filterQuery: this.testBenchId,
                        filterMode: "CONTAINS",
                        values:[]
                    },
                    {
                        name:"testTrackId",
                        filterQuery: this.testTrackId,
                        filterMode: "CONTAINS",
                        values:[]
                    }
                ],
                filterQuery: "*",
                filterQueryLanguage: "SIMPLE",
                rangeFilters: [
                    {
                        type: "TEMPORAL",
                        name: "sampleDateTime",
                        filterMode: "CONTAINS",
                        from: this.from,
                        until: this.until
                        //from: "2021-02-21T20:00:00.000+01:00",
                        //until: "2021-02-21T21:00:00.000+01:00"
                    }
                ],
            }});
        },

        setSortCriteria() {
            this.$store.dispatch(MODULE_NAME + '/setSortCriteria', {
                sortCriteria: [
                    {
                        name: "sampleDateTime",
                        ordering: "DESC"
                    }
                ]
            });
        },

        async loadData() {
            this.loadingData = true;
            this.loadMoreCount = 0;
            this.loadingMoreTime = 0;
            const loadingDataStart = moment();
            try {
               await this.$store.dispatch(MODULE_NAME + '/loadData', {collectionId: this.collectionId});
            } catch (error) {
                this.handleError(error);
            } finally {
                this.loadingData = false;
                const loadingDataFinished = moment();
                this.loadingDataTime = loadingDataFinished.diff(loadingDataStart, 's');
            }
        },

        async loadMore() {
            this.loadingMore = true;
            this.loadMoreCount = this.loadMoreCount + 1;
            const loadingMoreStart = moment();
            try {
                await this.$store.dispatch(MODULE_NAME + '/loadMore', {collectionId: this.collectionId});
            } catch (error) {
                this.handleError(error);
            } finally {
                this.loadingMore = false;
                const loadingMoreFinished = moment();
                this.loadingMoreTime = this.loadingMoreTime + loadingMoreFinished.diff(loadingMoreStart, 's');
            }
        },

        setDateTime() {
            const until = moment();
            const from = moment(until).subtract(MAX_TIME_RANGE_HOURS, 'h');

            const untilISODateTime = DateTime.fromISO(until.format(DATETIME_FORMAT_STRING));
            const fromISODateTime = DateTime.fromISO(from.format(DATETIME_FORMAT_STRING));

            this.until = untilISODateTime.toString();
            this.from = fromISODateTime.toString();

            this.showPreview = false;
        },

        handleError(error) {
            this.$log.debug(error.toJSON ? error.toJSON() : error );
            this.errorHandlerService.handleError(error);
        },

        goToTestTrackDetail(testBenchId, testTrackId, testBenchType, collectionId) {

            console.log(">NAV:::: ", testBenchType)
            this.$router.push({
                name: 'testTrackDetailParamsView',
                params: { 
                    testBenchId: testBenchId, 
                    testTrackId: testTrackId,
                    testBenchType: testBenchType,
                    collectionId: collectionId
                }
            });
        },

        goToPrintView() {
            this.loading = true;
            const chart = this.$refs.timeSeriesChart.$data.eChart;
            const chartImage = chart.getDataURL({
                // Exporting format, can be either png, or jpeg
                type: 'png',
                // Resolution ratio of exporting image, 1 by default.
                pixelRatio: 2,
                // Background color of exporting image, use backgroundColor in option by default.
                backgroundColor: '#fff',
                // Excluded components list. e.g. ['toolbox']
                excludeComponents: ['toolbox', 'tooltip', 'dataZoom']
            });

            this.$router.push({
                name: 'testTrackProtocolExportPrint',
                params: {image: chartImage}
            });
        },

        createTimeSeries() {
            this.reservedColors = [];
            const timeSeries = {
                data: {
                    pagination: {},
                    result: []
                },
                request: {
                    timeSeries: {
                        dateAttribute: "sampleDateTime",
                        filter: {},
                        measures: [],
                        timeScaleSeconds: 2
                    }
                },
                schema: this.meta
            }

            const sortedItems = this.items.sort((a, b) => {
                return new Date(a.data.sampleDateTime).getTime() - new Date(b.data.sampleDateTime).getTime()
            });

            sortedItems.forEach(item => {
                timeSeries.data.result.push({
                    dateTime: item.data.sampleDateTime,
                    values: this.selectedMeasuresIndices.map(index => {
                        const measureName = this.measures[index].name;
                        const key = {
                            attribute: measureName,
                            customData: {color: measureColors[measureName] ? measureColors[measureName].value : "#E4F3D8"},
                            operation: ""
                        };

                        const value = _.get(item, "data." + this.measures[index].name);
                        const roundedValue = +value.toFixed(3);
                        return {
                            key: key,
                            value: value,
                            roundedValue: roundedValue
                        }
                    })
                });
            });

            this.selectedMeasuresIndices.forEach(index => {
                const measureName = this.measures[index].name;
                timeSeries.request.timeSeries.measures.push({
                    attribute: measureName,
                    customData: {color: measureColors[measureName] ? measureColors[measureName].value : "#E4F3D8"},
                    operation: ""
                });
            })

            this.timeSeries = timeSeries;
            this.hasTimeSeries = true;
        },

        onDateTimeFromChanged(event) {
            const from = moment(event.date);
            const until = moment(this.until);

            const diff = until.diff(from, 's', true) / 3600;
            this.showDateTimeError = diff > MAX_TIME_RANGE_HOURS || diff < 0;

            if (diff > MAX_TIME_RANGE_HOURS) {
                this.dateTimeErrorMessage = this.$t('test-track-detail-protocol-export.time-range.hint');
            } else if (diff < 0) {
                this.dateTimeErrorMessage = this.$t('test-track-detail-protocol-export.time-range-value.hint');
            }

            this.from = DateTime.fromISO(from.format(DATETIME_FORMAT_STRING)).toString();
            this.showPreview = false;
        },

        onDateTimeUntilChanged(event) {
            const from = moment(this.from);
            const until = moment(event.date);

            const diff = until.diff(from, 's', true) / 3600;
            this.showDateTimeError = diff > MAX_TIME_RANGE_HOURS || diff < 0;

            if (diff > MAX_TIME_RANGE_HOURS) {
                this.dateTimeErrorMessage = this.$t('test-track-detail-protocol-export.time-range.hint');
            } else if (diff < 0) {
                this.dateTimeErrorMessage = this.$t('test-track-detail-protocol-export.time-range-value.hint');
            }

            this.until = DateTime.fromISO(until.format(DATETIME_FORMAT_STRING)).toString();
            this.showPreview = false;
        },

        async createPreview() {
            if (this.selectedMeasuresIndices.length > 0) {
                this.hasTimeSeries = false;
                this.showPreview = true

                this.setFilter();
                await this.loadData();

                while(this.moreElements) {
                    await this.loadMore();
                }
                //this.loadData();
                this.createTimeSeries();
            }
        }
    },

    created() {
        this.setDateTime();
        this.setFilter();
        this.setSortCriteria();

        this.loadData();
    },
}
</script>

<i18n>
{
    "en" : {
        "test-track-detail-protocol-export.test-track.label": "Test track",
        "test-track-detail-protocol-export.create-test-protocol.label": "Create test protocol",
        "test-track-detail-protocol-export.settings.label": "Settings",
        "test-track-detail-protocol-export.set-period.label": "Set period",
        "test-track-detail-protocol-export.set-measures.label": "Set measures",
        "test-track-detail-protocol-export.show-preview.label": "Show preview",
        "test-track-detail-protocol-export.preview-print.label": "Preview & print",
        "test-track-detail-protocol-export.data-loading.label": "Data is beeing loaded ...",
        "test-track-detail-protocol-export.order-data.label": "Order data",
        "test-track-detail-protocol-export.order-number.label": "Order number",
        "test-track-detail-protocol-export.applicant.label": "Applicant",
        "test-track-detail-protocol-export.project.label": "Project",
        "test-track-detail-protocol-export.date.label": "Date",
        "test-track-detail-protocol-export.test-data.label": "Test data",
        "test-track-detail-protocol-export.records.label": "Records",
        "test-track-detail-protocol-export.interval.label": "Interval",
        "test-track-detail-protocol-export.period.label": "Period",
        "test-track-detail-protocol-export.switching-cycle.actual.label": "Switching cycle ACTUAL",
        "test-track-detail-protocol-export.switching-cycle.target.label": "Switching cycle TARGET",
        "test-track-detail-protocol-export.continue-print.label": "Continue to print",
        "test-track-detail-protocol-export.time-range.hint": "The time span of 24 hours cannot be exceeded.",
        "test-track-detail-protocol-export.time-range-value.hint": "The beginning may not be higher than the end of the period.",
        "collection-time-series.print.from" : "From (Incl.)",
        "collection-time-series.print.until" : "until (Incl.)"
       },

    "de" : {
        "test-track-detail-protocol-export.test-track.label": "Prüfstrecke",
        "test-track-detail-protocol-export.create-test-protocol.label": "Prüfprotokoll erstellen",
        "test-track-detail-protocol-export.settings.label": "Einstellungen",
        "test-track-detail-protocol-export.set-period.label": "Zeitraum festlegen",
        "test-track-detail-protocol-export.set-measures.label": "Kennzahlen festlegen",
        "test-track-detail-protocol-export.show-preview.label": "Vorschau anzeigen",
        "test-track-detail-protocol-export.preview-print.label": "Vorschau & Druck",
        "test-track-detail-protocol-export.data-loading.label": "Daten werden geladen ...",
        "test-track-detail-protocol-export.order-data.label": "Auftragsdaten",
        "test-track-detail-protocol-export.order-number.label": "Auftragsnummer",
        "test-track-detail-protocol-export.applicant.label": "Auftraggeber",
        "test-track-detail-protocol-export.project.label": "Projekt",
        "test-track-detail-protocol-export.date.label": "Datum",
        "test-track-detail-protocol-export.test-data.label": "Prüfungsdaten",
        "test-track-detail-protocol-export.records.label": "Datensätze",
        "test-track-detail-protocol-export.interval.label": "Intervall",
        "test-track-detail-protocol-export.period.label": "Zeitraum",
        "test-track-detail-protocol-export.switching-cycle.actual.label": "Schaltwechsel IST",
        "test-track-detail-protocol-export.switching-cycle.target.label": "Schaltwechsel SOLL",
        "test-track-detail-protocol-export.continue-print.label": "Weiter zum Drucken",
        "test-track-detail-protocol-export.time-range.hint": "Die Zeitspanne von 24 Stunden kann nicht überschritten werden.",
        "test-track-detail-protocol-export.time-range-value.hint": "Der Beginn darf nicht höher sein als das Ende des Zeitraums.",
        "collection-time-series.print.from" : "Von (inkl.)",
        "collection-time-series.print.until" : "bis (inkl.)"
       }
}

</i18n>