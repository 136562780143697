export default {
    "cycle": {
        i18n: {
            "en": {
                "description": "Red",
                "languageTag": "en",
                "name": "Red",
                "shortName": "Red"
            },
            "de": {
                "description": "Rot",
                "languageTag": "de",
                "name": "Rot",
                "shortName": "Rot"
            },
        },
        value: "#FF2410",
    },
    "systemWaterSupplyBar": {
        i18n: {
            "en": {
                "description": "Yellow",
                "languageTag": "en",
                "name": "Yellow",
                "shortName": "Yellow"
            },
            "de": {
                "description": "Gelb",
                "languageTag": "de",
                "name": "Gelb",
                "shortName": "Gelb"
            },
        },
        value: "#FFEA00",
    },
    "systemCompressedAirSupplyBar": {
        i18n: {
            "en": {
                "description": "Green",
                "languageTag": "en",
                "name": "Green",
                "shortName": "Green"
            },
            "de": {
                "description": "Grün",
                "languageTag": "de",
                "name": "Grün",
                "shortName": "Grün"
            },
        },
        value: "#4CAF50",
    },
    "driveActualValue": {
        i18n: {
            "en": {
                "description": "Blue",
                "languageTag": "en",
                "name": "Blue",
                "shortName": "Blue"
            },
            "de": {
                "description": "Blau",
                "languageTag": "de",
                "name": "Blau",
                "shortName": "Blau"
            },
        },
        value: "#039BE5",
    },
    "drivePlanValue": {
        i18n: {
            "en": {
                "description": "Menthol",
                "languageTag": "en",
                "name": "Menthol",
                "shortName": "Menthol"
            },
            "de": {
                "description": "Menthol",
                "languageTag": "de",
                "name": "Menthol",
                "shortName": "Menthol"
            },
        },
        value: "#CFF39C",
    },
    "currentSwitchCounter": {
        i18n: {
            "en": {
                "description": "Mint",
                "languageTag": "en",
                "name": "Mint",
                "shortName": "Mint"
            },
            "de": {
                "description": "Minze",
                "languageTag": "de",
                "name": "Minze",
                "shortName": "Minze"
            },
        },
        value: "#3EB488",
    },
    "nennweite": {
        i18n: {
            "en": {
                "description": "Tennessee Orange",
                "languageTag": "en",
                "name": "Tennessee Orange",
                "shortName": "Tennessee Orange"
            },
            "de": {
                "description": "Tennessee-Orange",
                "languageTag": "de",
                "name": "Tennessee-Orange",
                "shortName": "Tennessee-Orange"
            },
        },
        value: "#EF7701",
    },
    "systemTemperatureCelsius": {
        i18n: {
            "en": {
                "description": "Dark Cornflower Blue",
                "languageTag": "en",
                "name": "Dark Cornflower Blue",
                "shortName": "Dark Cornflower Blue"
            },
            "de": {
                "description": "Dunkles Kornblumenblau",
                "languageTag": "de",
                "name": "Dunkles Kornblumenblau",
                "shortName": "Dunkles Kornblumenblau"
            },
        },
        value: "#243A85",
    },
    "anschlusskennziffer": {
        i18n: {
            "en": {
                "description": "Yellow Orange",
                "languageTag": "en",
                "name": "Yellow Orange",
                "shortName": "Yellow Orange"
            },
            "de": {
                "description": "Gelb-Orange",
                "languageTag": "de",
                "name": "Gelb-Orange",
                "shortName": "Gelb-Orange"
            },
        },
        value: "#FCC01D",
    },
    "membrangroesse": {
        i18n: {
            "en": {
                "description": "Ruby",
                "languageTag": "en",
                "name": "Ruby",
                "shortName": "Ruby"
            },
            "de": {
                "description": "Rubinrot",
                "languageTag": "de",
                "name": "Rubinrot",
                "shortName": "Rubinrot"
            },
        },
        value: "#9B111E",
    },
    "plannedSwitchCounter": {
        i18n: {
            "en": {
                "description": "Celadon  Green",
                "languageTag": "en",
                "name": "Celadon  Green",
                "shortName": "Celadon  Green"
            },
            "de": {
                "description": "Seladongrün",
                "languageTag": "de",
                "name": "Seladongrün",
                "shortName": "Seladongrün"
            },
        },
        value: "#388688",
    },
    "systemPowerSupplyVolt": {
        i18n: {
            "en": {
                "description": "Nyanza",
                "languageTag": "en",
                "name": "Nyanza",
                "shortName": "Nyanza"
            },
            "de": {
                "description": "Nyanza",
                "languageTag": "de",
                "name": "Nyanza",
                "shortName": "Nyanza"
            },
        },
        value: "#E4F3D8",
    },

    "coldWaterAggregateFlow": {
        i18n: {
        },
        value: "#7bb2d1",
    },
    "coldWaterAggregatePressure": {
        i18n: {
        },
        value: "#ababdb",
    },
    "coldWaterAggregateTemperature": {
        i18n: {
        },
        value: "#f0ac3e",
    },
    "pressureAfterTestObject": {
        i18n: {
        },
        value: "#0c79b3",
    },
    "pressureBeforeTestObject": {
        i18n: {
        },
        value: "#79c7b7",
    },
    "pressureBoosterPressure": {
        i18n: {
        },
        value: "#07ba61",
    },
    "pressureBoosterTemperature": {
        i18n: {
        },
        value: "#a39c89",
    },
    "pressureBoosterWaterlevel": {
        i18n: {
        },
        value: "#521148",
    },
    "steamGeneratorPressure": {
        i18n: {
        },
        value: "#6c8f1b",
    },
    "steamGeneratorPressureInLoop": {
        i18n: {
        },
        value: "#543a85",
    },
    "steamGeneratorTemperatureInLoop": {
        i18n: {
        },
        value: "#446475",
    },
    "supplyAirPressure": {
        i18n: {
        },
        value: "#6b5705",
    },
    "temperatureAfterTestObject": {
        i18n: {
        },
        value: "#1b8f77",
    },
    "temperatureBeforeTestObject": {
        i18n: {
        },
        value: "#804177",
    },
    "temperatureInTestObject": {
        i18n: {
        },
        value: "#6f6f87",
    },
    "vacuumPumpPressure": {
        i18n: {
        },
        value: "#a89256",
    },
}