<template>
<div>
    <v-card tile flat>
        <c-sticky-content>
            <v-toolbar>
                <v-toolbar-title>{{$t('home.view.toolbar.title', {userName: userContextService.name})}} </v-toolbar-title>
                <v-spacer />
                <v-toolbar-items>
                    <v-btn icon @click="refresh"><v-icon>refresh</v-icon></v-btn>
                </v-toolbar-items>
            </v-toolbar>
            <v-divider />
        </c-sticky-content>

        <v-img src="@/cust/assets/img/home.jpg">
            <v-container fluid class="fill-height">
                <v-row justify="center">
                    <v-col cols="12">
                        <div class="pa-5">
                        <div class="text-h4 text-center white--text">{{$t('home.view.welcometitle')}}</div>
                        <div class="text-h6 text-center white--text">{{$t('home.view.welcomesubtitle')}}</div>
                        </div>
                    </v-col>
                </v-row>
             
                <v-row justify="center" >
                    <v-col cols="12" md="9">
                        <v-card tile>
                            <v-card-text class="text-h6">{{$t('home.view.tiles.listtemplates.title')}}</v-card-text>
                            <v-card-text class="text-body">{{$t('home.view.tiles.listtemplates.text')}}</v-card-text>
                            <v-divider />
                            <template v-if="!listTemplatesEmpty">
                                <v-list>
                                    <v-list-item two-line v-for="(item, index) of listTemplates" :key="'userlisttemplate_' + index">
                                        <v-list-item-avatar>
                                            <v-btn  @click="openDocumentList(item)" icon><v-icon>dataset</v-icon></v-btn>
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                            <v-list-item-title>{{$dxs.i18n.localizedName(item, $i18n.locale)}}</v-list-item-title>
                                            <v-list-item-subtitle>{{item.collectionId}}</v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <!-- <v-btn icon @click="openDocumentList(item)"><v-icon>more_vert</v-icon></v-btn> -->
                                        </v-list-item-action>
                                    </v-list-item>
                                </v-list>
                            </template>
                            <template v-else>
                                <v-card-text class="text-body grey lighten-3">{{$t('home.view.tiles.listtemplates.empty')}}</v-card-text>
                            </template>
                        </v-card>
                    </v-col>
                </v-row>

                <v-row justify="center" >

                    <v-col cols="12" md="3">
                        <v-card tile height="100%" color="white">
                            <v-card-text class="text-h6">{{$t('home.view.tiles.discover.title')}}</v-card-text>
                            <v-card-text class="text-body">{{$t('home.view.tiles.discover.text')}}</v-card-text>
                            
                        </v-card>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-card height="100%" tile>
                            <v-card-text class="text-h6">{{$t('home.view.tiles.visualize.title')}}</v-card-text>
                            <v-card-text class="text-body">{{$t('home.view.tiles.visualize.text')}}</v-card-text>
                            

                        </v-card>
                        
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-card height="100%" tile>
                            <v-card-text class="text-h6">{{$t('home.view.tiles.export.title')}}</v-card-text>
                            <v-card-text class="text-body">{{$t('home.view.tiles.export.text')}}</v-card-text>
                        </v-card>
                        
                    </v-col>
                </v-row>

        </v-container>
        </v-img>
    </v-card>
</div>
    
</template>



<script>
import PortalModule from '@/core/store/modules/portal.module';
import { isEmpty } from 'lodash';
// const MODULE_NAME = 'portal';


export default {
  name: 'portal',


  inject: ['progressIndicatorService', 'userContextService'],
    
    mounted() {
        //this.shellService.setTitle(this.$t('home.view.title'));
        //this.shellService.getViewActions().length = 0;
    },

    computed: {

        listTemplates() {
            return this.$store.getters[PortalModule.name + "/listTemplates"];
        },

        listTemplatesEmpty() {
            return isEmpty(this.listTemplates);
        }
    },

    methods: {

        async refresh() {
                
            this.progressIndicatorService.show();

            try {
                await this.$store.dispatch(PortalModule.name + '/loadUserListTemplates', {userId: this.userContextService.userName});
                
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },

        openDocumentList(template) {
            this.$router.push({
                name: 'collectionDocumentsView',
                params: {
                    collectionId : template.collectionId,
                    listTemplate: template
                }
            })
            console.log(template);
        }
    },

    created() {

        // Register the module on demand. 
        if (!this.$store.hasModule(PortalModule.name)){
            this.$store.registerModule(PortalModule.name, PortalModule);
        }

        this.refresh();
    }
}
</script>

<i18n>
{
    "en" : {
        "home.view.toolbar.title" : "{userName}'s Homepage",
        "home.view.tiles.listtemplates.title" : "My List Templates",
        "home.view.tiles.listtemplates.text" : "Select one of your list templates in order to display the data.",
        "home.view.tiles.listtemplates.empty" : "We are sorry, but at the moment no list templates are available for you."
    },

    "de" : {
        "home.view.toolbar.title" : "Startseite von {userName}",
        "home.view.tiles.listtemplates.title" : "Meine Listenvorlagen",
        "home.view.tiles.listtemplates.text" : "Klicken Sie auf eine Ihrer Listenvorlagen, um die Daten anzuzeigen.",
        "home.view.tiles.listtemplates.empty" : "Derzeit sind leider keine Listenvorlagen für Sie hinterlegt."
    }
}
</i18n>

