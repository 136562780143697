

import 'material-design-icons-iconfont/dist/material-design-icons.css'

import Vue from 'vue';
import Vuetify from 'vuetify/lib';


import CoreTheme from '@/core/theme';
import CustTheme from '@/cust/theme';
import { get } from 'lodash';



const options = {

    icons: {
      iconfont: 'md',
    },


    theme: {

        dark: get(CustTheme, 'dark', CoreTheme.dark),
        
        themes: {
          light: get(CustTheme, 'themes.light', CoreTheme.themes.light), 
  
          dark: get(CustTheme, 'themes.dark', CoreTheme.themes.dark),
        }
      },
      

};

Vue.use(Vuetify);
export default new Vuetify(options);
