<template>
  <v-card flat tile>
      <v-toolbar flat>
          <v-toolbar-items>
              <v-btn icon @click="cancel"><v-icon>arrow_back</v-icon></v-btn>
          </v-toolbar-items>
          <v-toolbar-title>{{name}}</v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
              <v-btn icon @click="cancel"><v-icon>close</v-icon></v-btn>
          </v-toolbar-items>
      </v-toolbar>
      <v-divider />
      
      <v-card-text>
            <div class="text-h6">Read Items from Collection {{name}}</div>
            <v-subheader>URL</v-subheader>
            <v-sheet class="pa-5 grey lighten-3">
               <strong>POST</strong> <pre>{{queryItemsUrl}}</pre>
            </v-sheet>

            <v-subheader>Request Headers</v-subheader>
            <v-sheet class="pa-5 grey lighten-3">
                
            </v-sheet>

            <v-subheader>Request</v-subheader>
            <v-sheet class="pa-5 grey lighten-3">
                
            </v-sheet>

            <v-subheader>Response</v-subheader>
            <v-sheet class="pa-5 grey lighten-3">
                <pre>{{jsonSchema}}</pre>
            </v-sheet>
      </v-card-text>
  </v-card>
</template>

<script>
const MODULE_NAME = 'collectionSettingsApi';

export default {

    props: {

        value: {
            type: Object,
            required: true
        }
    },

    inject: ['errorHandlerService', 'progressIndicatorService', 'envContextService'],

    computed: {

        name: {
            get() {
                return this.value ? this.value.name : null
            }
        },

        tenantId: {
            get() {
                return this.envContextService.tenantId;
            }
        },

        apiGatewayHostPort: {
            get() {
                return this.envContextService.apiGatewayHostPort;
            }
        },

        queryItemsUrl() {
            return this.apiGatewayHostPort + '/v1/' + this.tenantId + '/collections/' + this.name;
        },

        response: {
            get() {
                return this.$store.getters[MODULE_NAME + "/response"];
            }
        },

        data: {
            get() {
               return this.response.data ? this.response.data : {}; 
            }
        },

        schema: {
            get() {
                return this.data.schema ? this.data.schema : {};
            }
        },

        jsonSchema: {

            get() {

                let payload = {

                    tenantId: this.tenantId,

                    data: {
                        
                    }
                };

                if (this.schema.attributes) {
                    for (const key in this.schema.attributes) {

                        const attribute = this.schema.attributes[key];

                        payload.data[key] = attribute.datatype === 'String' ? 'Object' : 'String';
                    }
                }
                return JSON.stringify(payload, true, 2);
            }
        }
    },

    methods: {

        async refresh() {
            this.progressIndicatorService.show();
            try {
                await this.$store.dispatch(MODULE_NAME + "/loadResponse", { name: this.name })
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },

        cancel() {
            this.$router.push({ 
                name: 'collectionSettingsList',
            });
        }
        
    },

    created() {
        this.refresh();
    }
}
</script>

<style>

</style>