import api from '@/store/api';

export default {

    namespaced: true,

    state : {

        response: getDefaultResponse(),

        filter: getDefaultFilter(),

        userId: ""
    },

    mutations: {

        setResponse(state, response) {

            if (response) {
                state.response = response;
            }

        },

        setFilter(state, filter) {

            if (filter) {
                state.filter = filter;
            } else {
                state.filter = getDefaultFilter();
            }
        },

        setUser(state, userId) {
            state.userId = userId ? userId : "";
        }
    },

    actions: {

        async loadResponse(context) {

            try {
                let response = await api.me.listTemplates.findByFilter(context.state.filter);
                context.commit('setResponse', response);
            } catch (error) {
                context.commit('setResponse', getDefaultResponse());
                throw error;
            }

        },

        updateUser(context, userId) {
            context.commit('setUser', userId);
        },

        async updateFilter(context, filter) {

            context.commit('setFilter', filter);
        },

        async deleteTemplate(context, name) {

            try {
                let response = await api.me.listTemplates.delete(name);
                context.commit('setResponse', response);
            } catch (error) {
                context.commit('setResponse', getDefaultResponse());
                throw error;
            }
        }
    },

    getters: {

        response(state) {
            return state.response ? state.response : getDefaultResponse();
        },

        filter(state) {
            return state.filter ? state.filter : getDefaultFilter();
        },
        request(state) {
            return {

                name: state.schema.name,
                indexName: state.schema.name,
                attributes: {},
                i18n: {}
            };
        },
       
    }
}

function getDefaultResponse() {

    return {
        items: []
    };
}

function getDefaultFilter() {
    return '*';
}