import api from '@/store/api';
import { get } from 'lodash';

export default {

    namespaced: true,

    state: {
        data: {
            notificationsCount: 0,

            userAuthorization: emptyUserAuthorization(),

            userAuthorizationLoaded: true
        }
    },

    mutations: {

        setNotificationsCount(state, response) {
            state.data.notificationsCount = response;
        },

        setUserAuthorization(state, userAuthorization) {
            state.data.userAuthorization = userAuthorization;
            state.data.userAuthorizationLoaded = true;
        },

        setUserAuthorizationFailed(state) {
            state.data.userAuthorization = emptyUserAuthorization();
            state.data.userAuthorizationLoaded = false;
        }

    },

    actions: {

        async loadNotificationsCount(context) {
            try {

                let response = await api.collections.notifications.count();
                context.commit('setNotificationsCount', response);
                
            } catch (error) {
                console.error("An error has occured" + error);
                // Pass an empty response so nothing will be added actually.
                context.commit('setNotificationsCount', 0);
              }
        },

        async loadUserAuthorization(context, {userId}) {

            try {
                
                let response = await api.userMgmt.userAuthorization.findById(userId);
                let userAuthorization = get(response, 'data', emptyUserAuthorization());
                context.commit('setUserAuthorization', userAuthorization);

            } catch (error) {
                
                console.error("An error has occured when loading user authentication data: " + error);

                // Make known that loading of authorization data failed
                context.commit('setUserAuthorizationFailed');
              }
        }


    },

    getters: {
        notificationsCount(state) {
            return state.data.notificationsCount;
        },

        userAuthorizationDataAvailable(state) {
            return get(state, 'data.userAuthorizationLoaded', false);
        },
        

        userAuthorization(state) {
            return get(state, 'data.userAuthorization', emptyUserAuthorization());
        },

        collectionRuleMap(state, getters) {
            const userAuthorization = getters.userAuthorization;
            
            const acls = get(userAuthorization, 'accessControlLists');
            let map = {};

            for (const acl of acls) {
                
                const rules = get(acl, 'collectionAccess.collectionAccessRules', []);
                for (const rule of rules) {

                    const collectionId = rule.collectionId;
                    
                    if (!map[collectionId]) {
                        map[collectionId] = { 
                            collectionId: collectionId,
                            createPermission: false,
                            readPermission: false, 
                            updatePermission: false, 
                            deletePermission: false, 
                            importPermission: false,
                            exportPermission: false,
                            archivePermission: false
                        }
                    }

                    if (rule.createPermission) map[collectionId].createPermission = true;
                    if (rule.readPermission) map[collectionId].readPermission = true;
                    if (rule.updatePermission) map[collectionId].updatePermission = true;
                    if (rule.deletePermission) map[collectionId].deletePermission = true;
                    if (rule.importPermission) map[collectionId].importPermission = true;
                    if (rule.exportPermission) map[collectionId].exportPermission = true;
                    if (rule.archivePermission) map[collectionId].archivePermission = true;
                }
            }

            return map;
        }
    }
}

function emptyUserAuthorization() {
    return {
        accessControlLists: []
    };
}