<template>
<div>
  <table width="100%">
    <tfoot>
      <tr>
        <td id="footer-spacer"></td>
      </tr>
    </tfoot>
    <thead>
      <tr>
        <td class="text-right header-test-track-protocol"><dxs-logo-comoponent class="logo-test-track-protocol" type="PRINT_HEADER"></dxs-logo-comoponent></td>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>
            <div class="page">
              <table width="100%" class="table-print-size">
                <tr valign="top">
                  <td width="50%">
                    <table class="text-left" width="100%">
                      <th><td>{{$t('test-track-detail-protocol-export.order-data.label')}}:</td></th>
                      <tr>
                        <td>{{$t('test-track-detail-protocol-export.order-number.label')}}:</td>
                        <td>{{tvId}}</td>
                      </tr>
                      <tr>
                        <td>{{$t('test-track-detail-protocol-export.applicant.label')}}:</td>
                        <td>{{userName}}</td>
                      </tr>
                      <tr>
                        <td>{{$t('test-track-detail-protocol-export.project.label')}}:</td>
                        <td>Project XYZ</td>
                      </tr>
                      <tr>
                        <td>{{$t('test-track-detail-protocol-export.date.label')}}:</td>
                        <td>{{printDate}}</td>
                      </tr>
                    </table>
                  </td>
                  <td width="50%">
                    <table class="text-left" width="100%">
                      <th><td>{{$t('test-track-detail-protocol-export.test-data.label')}}:</td></th>
                      <tr>
                        <td>{{$t('test-track-detail-protocol-export.records.label')}}:</td>
                        <td>{{totalItemCountFormatted}}</td>
                      </tr>
                      <tr>
                        <td>{{meta.attributes['cycle'] | localized-description($i18n.locale)}}:</td>
                        <td>{{latestItem.data.cycle}}</td>
                      </tr>
                      <tr>
                        <td>{{$t('test-track-detail-protocol-export.period.label')}}:</td>
                        <td>{{temporalFilter.from | date-time-format('dd.MM.yy HH:mm', $i18n.locale)}} - {{temporalFilter.until | date-time-format('dd.MM.yy HH:mm', $i18n.locale)}}</td>
                      </tr>
                      <tr>
                        <td>{{$t('test-track-detail-protocol-export.switching-cycle.actual.label')}}:</td>
                        <td>{{latestItem.data.currentSwitchCounter}}</td>
                      </tr>
                      <tr>
                        <td>{{$t('test-track-detail-protocol-export.switching-cycle.target.label')}}:</td>
                        <td>{{latestItem.data.plannedSwitchCounter}}</td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>

              <table width="100%">
                <tr>
                  <td>
                    <img class="print-img full-width" v-bind:src="image">
                    <!-- <c-data-time-series-chart class="d-none" ref="timeSeriesChart" :printMode="true" :value="timeSeries"></c-data-time-series-chart> -->
                  </td>
                </tr>
              </table>
          </div>


        </td>
      </tr>
    </tbody>
  </table>

  <div class="print-footer text-center">
    <div class="footer-item">
      {{$t('collection-time-series.print.printedOn')}}: {{printDate}}
      <div class="mt-2">
        <dxs-logo-comoponent type="PRINT_FOOTER"></dxs-logo-comoponent>
      </div>
    </div>
    <div class="footer-item">{{$t('collection-time-series.print.printedBy')}}: {{userName}}</div>
    <div class="footer-item">
      <span class="d-block">{{collectionSettings | localized-name($i18n.locale)}}</span>
      <span class="d-block black--text"><strong>{{$t('test-track-detail-protocol-export.confidential')}}</strong></span>
    </div>
  </div>
</div>
</template>

<script>
import _ from 'lodash';
import LogoComponent from '@/components/logo.component'
const MODULE_NAME = "testTrackProtocolExport";

export default {
  data: () => ({
  }),

  inject: ['userContextService'],

  components: {
    'dxs-logo-comoponent' : LogoComponent
  },

  computed: {

    userName() {
        return this.userContextService.userName;
    },

    image() {
      return this.$route.params.image;
    },

    collectionSettings() {
      return this.response.meta;
    },

    response() {
        return this.$store.getters[MODULE_NAME + "/response"]
    },

    items() {
        return _.get(this.response, 'items', []);
    },

    latestItem() {
        return _.maxBy(this.items, 'data.sampleDateTime')
    },

    meta() {
      return _.get(this.response, 'meta', {});
    },

    filter() {
        return this.$store.getters[MODULE_NAME + "/filter"];
    },

    temporalFilter() {
      return _.get(this.filter, 'rangeFilters', []).find(filter => filter.type === 'TEMPORAL');
    },

    tvId() {
        return _.uniq(_.map(this.items, 'data.TVID')).join(',');
    },

    totalItemCount() {
      return _.get(this.response, 'pagination.totalItemCount', 0);
    },

    totalItemCountFormatted() {
      return this.$core.filters.numericFormat(this.totalItemCount, '#,###', this.$i18n.locale);
    },

    printDate() {
      const d = new Date();
      return `${d.toLocaleDateString(this.$i18n.locale)} ${d.toLocaleTimeString(this.$i18n.locale)}`;
    },
  },

  beforeMount() {
    /** allow only access when chart image is set
     * navigate to root page
     */
    if (!this.image) {
      this.$router.push("/");
    }
  }
};
</script>
<style lang="scss" scoped>

.table {
  width: 100%;
}
.header-test-track-protocol {
  height: 0cm;
}
.logo-test-track-protocol {
  @media print {
    position: fixed;
    right: 0;
    top: 0;
  }
}

.table-print-size {
  @media print {
    font-size: 10pt;
  }
}
</style>

<i18n>
{
  "en" : {
    "test-track-detail-protocol-export.order-data.label": "Order data",
    "test-track-detail-protocol-export.order-number.label": "Order number",
    "test-track-detail-protocol-export.applicant.label": "Applicant",
    "test-track-detail-protocol-export.project.label": "Project",
    "test-track-detail-protocol-export.date.label": "Date",
    "test-track-detail-protocol-export.test-data.label": "Test data",
    "test-track-detail-protocol-export.records.label": "Records",
    "test-track-detail-protocol-export.interval.label": "Interval",
    "test-track-detail-protocol-export.period.label": "Period",
    "test-track-detail-protocol-export.switching-cycle.actual.label": "Switching cycle ACTUAL",
    "test-track-detail-protocol-export.switching-cycle.target.label": "Switching cycle TARGET",
    "test-track-detail-protocol-export.continue-print.label": "Continue to print",
    "test-track-detail-protocol-export.time-range.hint": "The time span of 24 hours cannot be exceeded.",
    "test-track-detail-protocol-export.time-range-value.hint": "The beginning may not be higher than the end of the period.",
    "test-track-detail-protocol-export.confidential": "confidential",
    "collection-time-series.print.printDate" : "Print date",
    "collection-time-series.print.printedOn" : "Printed on",
    "collection-time-series.print.printedBy" : "Printed by"
  },

  "de" : {
    "test-track-detail-protocol-export.order-data.label": "Auftragsdaten",
    "test-track-detail-protocol-export.order-number.label": "Auftragsnummer",
    "test-track-detail-protocol-export.applicant.label": "Auftraggeber",
    "test-track-detail-protocol-export.project.label": "Projekt",
    "test-track-detail-protocol-export.date.label": "Datum",
    "test-track-detail-protocol-export.test-data.label": "Prüfungsdaten",
    "test-track-detail-protocol-export.records.label": "Datensätze",
    "test-track-detail-protocol-export.interval.label": "Intervall",
    "test-track-detail-protocol-export.period.label": "Zeitraum",
    "test-track-detail-protocol-export.switching-cycle.actual.label": "Schaltwechsel IST",
    "test-track-detail-protocol-export.switching-cycle.target.label": "Schaltwechsel SOLL",
    "test-track-detail-protocol-export.continue-print.label": "Weiter zum Drucken",
    "test-track-detail-protocol-export.confidential": "vertraulich",
    "collection-time-series.print.printDate" : "Druckdatum",
    "collection-time-series.print.printedOn" : "Ausgedruckt am",
    "collection-time-series.print.printedBy" : "Ausgedruckt von"
  }
}
</i18n>