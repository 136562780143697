<template>
    <v-card @click="fireCardClicked(item)">
        <v-toolbar dense flat>
            <div class="text-subtitle-2 text-left">{{item.TVID}}</div>
            <v-spacer />
            <v-toolbar-items>
                <v-menu top >
                    <template v-slot:activator="{ on}">
                        <v-btn icon v-on="on" @click="$event.stopPropagation()"><v-icon small>more_vert</v-icon></v-btn>
                    </template>
                    <v-list>
                        <v-list-item :disabled="isDashboardLinkDisabled" @click="goToDashboard(item)" >
                            <v-list-item-title class="body-2">{{$t('order-board-card.component.menu.goToDashboard')}}</v-list-item-title>
                        </v-list-item>
                        <v-list-item :disabled="isDashboardLinkDisabled" @click="goToReport(item)">
                            <v-list-item-title class="body-2">{{$t('order-board-card.component.menu.goToReporting')}}</v-list-item-title>
                        </v-list-item>
                        <v-divider />
                        <v-subheader>{{$t('order-board-card.component.menu.updatePriority')}}</v-subheader>
                        <v-list-item @click="fireChangePrio('HIGH', item)">
                            <v-list-item-icon><v-icon v-show="item.priority === 'HIGH'">done</v-icon></v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title class="body-2">{{$t('order-board-card.component.menu.updatePriority.high')}}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item @click="fireChangePrio('MEDIUM', item)">
                            <v-list-item-icon ><v-icon v-show="item.priority === 'MEDIUM'">done</v-icon></v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title class="body-2">{{$t('order-board-card.component.menu.updatePriority.medium')}}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item @click="fireChangePrio('LOW', item)">
                            <v-list-item-icon><v-icon  v-show="item.priority === 'LOW'">done</v-icon></v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title class="body-2">{{$t('order-board-card.component.menu.updatePriority.low')}}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-toolbar-items>
        </v-toolbar>
        <v-divider />
        <v-card-text class="text-body-2 text-left" >
            <!-- <div class="text-caption">{{$t('order-board-card.component.labels.projectNo')}}:</div> -->
            <div class="body-2">{{item.projectNumber}}</div>
            <!-- <div class="text-caption">{{$t('order-board-card.component.labels.reason')}}:</div> -->
            <template v-if="item.Versuchstitel">
                <div :title="item.Versuchstitel" class="body-2">{{item.Versuchstitel | truncate(20)}}</div>
            </template>
            <template v-else>
                <div class="body-2">-</div>
            </template>
        </v-card-text>
    </v-card>
</template>

<script>
export default {

    props: {
        value: {
            type: Object,
            required: true
        }
    },

    methods: {

        fireChangePrio(priority, item){
            this.$emit('priority-changed', {
                priority: priority,
                item: item
            });
        },

        fireCardClicked(item) {
            this.$emit('clicked', {
                item: item
            });
        },

        goToDashboard(item) {
            this.$router.push({
                name: 'testTrackDetailParamsView',
                params: {
                    testBenchId: item.testBenchId,
                    testTrackId: item.testTrackId,
                    testBenchType: item.testBenchType,
                    collectionId: `EnduranceTesting${item.testBenchType}Samples`
                }
            });
        },

        goToReport(item) {
            this.$router.push({
                name: 'testTrackExport',
                params: {
                    testBenchId: item.testBenchId,
                    testTrackId: item.testTrackId,
                    collectionId: `EnduranceTesting${item.testBenchType}Samples`
                }
            });
        }
    },

    computed: {

        item() {
            return this.value ? this.value : {}
        },

        isDashboardLinkDisabled() {
            return !(this.item.testTrackId && this.item.testBenchId && this.item.testBenchType)
        }
    }
}
</script>

<i18n>
{
    "en" : {

        "order-board-card.component.menu.goToDashboard" : "Go To Test Track...",
        "order-board-card.component.menu.goToReporting" : "Go To Reporting...",

        "order-board-card.component.menu.updatePriority" : "Update Priority",
        "order-board-card.component.menu.updatePriority.high" : "High",
        "order-board-card.component.menu.updatePriority.medium" : "Medium",
        "order-board-card.component.menu.updatePriority.low" : "Low",

        "order-board-card.component.labels.projectNo" : "Project ID",
        "order-board-card.component.labels.reason" : "Reason"

    },

    "de" : {

        "order-board-card.component.menu.goToDashboard" : "Zur Prüfstrecke gehen...",
        "order-board-card.component.menu.goToReporting" : "Bericht erzeugen...",

        "order-board-card.component.menu.updatePriority" : "Priorität anpassen",
        "order-board-card.component.menu.updatePriority.high" : "Hoch",
        "order-board-card.component.menu.updatePriority.medium" : "Mittel",
        "order-board-card.component.menu.updatePriority.low" : "Niedrig",

        "order-board-card.component.labels.projectNo" : "Projekt-Nr.",
        "order-board-card.component.labels.reason" : "Versuchstitel"

    }
}
</i18n>