<template>
    
    <v-card tile flat>

        <v-tabs  
            v-model="selectedTab" 
            color="accent" 
            background-color="white"
            :vertical="$vuetify.breakpoint.mdAndUp"
            show-arrows
            >

            <v-tab :key="0">{{$t('attribute-editor.component.tabs.general')}}</v-tab>
            <v-tab :key="1">{{$t('attribute-editor.component.tabs.i18n')}}</v-tab>
            <v-tab :key="2">{{$t('attribute-editor.component.tabs.valueMapping')}}</v-tab>
            <v-tab :key="3">{{$t('attribute-editor.component.tabs.clientSettings')}}</v-tab>
            <v-tab :key="4">{{$t('attribute-editor.component.tabs.custData')}}</v-tab>

            
             <v-tabs-items v-model="selectedTab">

                <!-- Common Settings -->
                <v-tab-item :key="0">
                    <v-card tile flat class="pl-5 pr-5">
                    <v-card-title>{{$t('attribute-editor.component.tabs.general')}}</v-card-title>
                    <v-divider />
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12">
                                <template v-if="newItem">
                                    <c-name-input 
                                        v-model="name" 
                                        filled />
                                </template>
                                <template v-else>

                                    <!-- 
                                        On some legacy installations we sometimes face non-compliant naming:
                                        In display only mode we must therefore make sure that no validation happens, 
                                        otherwise the dialog would not allow any kind of change at all.
                                    -->
                                    <v-text-field 
                                        v-model="name" 
                                        filled
                                        disabled
                                        :label="$t('attribute-editor.component.fields.name')" 
                                        :hint="$t('attribute-editor.component.fields.name.hint')" 
                                        persistent-hint
                                        />
                                </template>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-text-field
                                    v-model="path"  
                                    :label="$t('attribute-editor.component.fields.path')" 
                                    :hint="$t('attribute-editor.component.fields.path.hint')" 
                                    filled 
                                    :disabled="!newItem" 
                                    :rules="[ v => v && !!v.trim()  || $t('attribute-editor.component.fields.path.notEmpty') ]"
                                    
                                    persistent-hint />
                            </v-col>
                            <v-col cols="12" md="6">
                                    <v-text-field
                                    v-model="ordering"
                                    :rules="[ v => v && v >= 0  || $t('attribute-editor.component.fields.ordering.notEmpty') ]"  
                                    type="number"
                                    :label="$t('attribute-editor.component.fields.ordering')" 
                                    :hint="$t('attribute-editor.component.fields.ordering.hint')" 
                                    filled 
                                    persistent-hint />
                            </v-col>
                            <v-col cols="12" md="6">
                                <c-datatype-selector 
                                    v-model="datatype" 
                                    filled 
                                    :disabled="!newItem"
                                    :rules="[ v => v !== null && v !== undefined || $t('attribute-editor.component.fields.datatype.notEmpty') ]" />
                            </v-col>
                            <v-col>
                                <c-attribute-type-selector 
                                    v-model="attributeType" 
                                    filled 
                                    :rules="[ v => v !== null && v !== undefined || $t('attribute-editor.component.fields.analyticalType.notEmpty') ]"
                                />
                            </v-col>
                            
                            <v-col cols="12">
                                <v-list>
                                    <v-list-item>
                                        <v-list-item-action>
                                            <v-checkbox v-model="key" color="accent"></v-checkbox>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                            <v-list-item-title>{{$t('attribute-editor.component.fields.key')}}</v-list-item-title>
                                            <v-list-item-subtitle>{{$t('attribute-editor.component.fields.key.hint')}}</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item>
                                        <v-list-item-action>
                                            <v-checkbox v-model="optional" color="accent"></v-checkbox>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                            <v-list-item-title>{{$t('attribute-editor.component.fields.optional')}}</v-list-item-title>
                                            <v-list-item-subtitle>{{$t('attribute-editor.component.fields.optional.hint')}}</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-divider />
                                    <v-list-item>
                                        <v-list-item-action>
                                            <v-checkbox v-model="computed" color="accent" @click="resetScriptInput"></v-checkbox>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                            <v-list-item-title>{{$t('attribute-editor.component.fields.computed')}}</v-list-item-title>
                                            <v-list-item-subtitle>{{$t('attribute-editor.component.fields.computed.hint')}}</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                                <v-card v-if="key && optional" color="accent" flat>
                                    <v-card-text class="white--text">
                                        <v-icon color="white" class="pr-5">warning</v-icon>{{$t('attribute-editor.component.validations.keyAndOptional')}}
                                    </v-card-text>
                                </v-card>
                                
                                <v-container v-if="computed" fluid>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-text-field 
                                                v-model="script"
                                                filled 
                                                :label="$t('attribute-editor.component.fields.script')" 
                                                :rules="[ v => v && !!v.trim()  || $t('attribute-editor.component.fields.path.notEmpty') ]"
                                            />
                                        </v-col>
                                        <v-col cols="12">
                                            <v-expansion-panels class="mb-6" :popout="false">
                                                <v-expansion-panel>
                                                    <v-expansion-panel-header>
                                                        <div><v-icon color="accent" class="mr-2">help</v-icon>{{$t("attribute-editor.component.help.computedAttribute")}}</div>
                                                    </v-expansion-panel-header>
                                                    <v-expansion-panel-content>
                                                        <dxs-computed-attribute-expression-help :value="attributes" />
                                                    </v-expansion-panel-content>
                                                </v-expansion-panel>
                                            </v-expansion-panels>
                                            
                                        </v-col>
                                    </v-row>
                                </v-container>
                        </v-col>
                    </v-row>
                    </v-container>
                    </v-card>
                </v-tab-item>

                <!-- I18N Settings -->
                <v-tab-item :key="1">
                    <v-card tile flat class="pl-5 pr-5">
                    <v-card-title>{{$t('attribute-editor.component.tabs.i18n')}}</v-card-title>
                    <v-divider />    
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12">
                                <c-i18n-editor 
                                    header-style="lite" 
                                    flat 
                                    :items="i18n"
                                    @item-updated="onI18NUpdated" 
                                    @item-removed="onI18NRemoved" />
                            </v-col>
                            <v-col cols="12">
                                <c-i18n-format-editor 
                                    header-style="lite" 
                                    flat 
                                    :items="format"
                                    @item-updated="onFormatUpdated" 
                                    @item-removed="onFormatRemoved" />
                            </v-col>
                        </v-row>
                    </v-container>
                    </v-card>
                 </v-tab-item>

                <!-- Value Mapping -->
                <v-tab-item :key="2">
                    <v-card tile flat class="pl-5 pr-5">
                        <v-card-title>{{$t('attribute-editor.component.tabs.valueMapping')}}</v-card-title>
                        <v-divider />
                        <v-container fluid>
                        <v-row>
                            <v-col cols="12">
                                <c-value-mapping-editor 
                                    header-style="lite" 
                                    flat 
                                    :items="valueMappingRules"
                                    @item-updated="onValueMappingRulesUpdated" 
                                    @item-removed="onValueMappingRulesRemoved" 

                                />
                            </v-col>
                        </v-row>
                    </v-container>
                    </v-card>
                </v-tab-item>

                <!-- Client Settings -->
                 <v-tab-item :key="3">
                    <v-card tile flat class="pl-5 pr-5">
                    <v-card-title>{{$t('attribute-editor.component.tabs.clientSettings')}}</v-card-title>
                    <v-divider />
                    <!-- Link -->
                    <!--
                    <v-divider />
                    <v-subheader>Verweise</v-subheader>
                    <v-card-text>Sie können ein dynamisches Text-Template definieren, anhand dessen beim Klick auf den Wert eine Navigation durchgeführt wird.</v-card-text>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field filled label="Verweis-Template" v-model="linkTemplate"/>
                            </v-col>
                        </v-row>
                    </v-container>
                    -->

                        <!-- List Settings -->
                        <v-subheader>{{$t('attribute-editor.component.sections.clientSettings.title')}}</v-subheader>
                        <v-card-text>{{$t('attribute-editor.component.sections.clientSettings.subtitle')}}</v-card-text>
                        <v-container fluid>
                        <v-row>
                            <v-col cols="12">
                            <v-list>
                                <v-list-item>
                                    <v-list-item-action>
                                        <v-checkbox v-model="filterActive"  color="accent"></v-checkbox>
                                    </v-list-item-action>

                                    <v-list-item-content>
                                        <v-list-item-title>{{$t('attribute-editor.component.fields.filterActive')}}</v-list-item-title>
                                        <v-list-item-subtitle>{{$t('attribute-editor.component.fields.filterActive.hint')}}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item>
                                    <v-list-item-action>
                                        <v-checkbox v-model="facetFilterActive"  color="accent"></v-checkbox>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title>{{$t('attribute-editor.component.fields.facetFilterActive')}}</v-list-item-title>
                                        <v-list-item-subtitle>{{$t('attribute-editor.component.fields.facetFilterActive.hint')}}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item>
                                    <v-list-item-action>
                                        <v-checkbox v-model="sortActive"  color="accent"></v-checkbox>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title>{{$t('attribute-editor.component.fields.sortActive')}}</v-list-item-title>
                                        <v-list-item-subtitle>{{$t('attribute-editor.component.fields.sortActive.hint')}}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item>
                                    <v-list-item-action>
                                        <v-checkbox v-model="displayTable"  color="accent"></v-checkbox>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title>{{$t('attribute-editor.component.fields.displayTable')}}</v-list-item-title>
                                        <v-list-item-subtitle>{{$t('attribute-editor.component.fields.displayTable.hint')}}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item>
                                    <v-list-item-action>
                                        <v-checkbox v-model="displayTile"  color="accent"></v-checkbox>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title>{{$t('attribute-editor.component.fields.displayTile')}}</v-list-item-title>
                                        <v-list-item-subtitle>{{$t('attribute-editor.component.fields.displayTile.hint')}}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                
                                <v-list-item>
                                    <v-list-item-action>
                                        <v-checkbox v-model="exportActive"  color="accent"></v-checkbox>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title>{{$t('attribute-editor.component.fields.exportActive')}}</v-list-item-title>
                                        <v-list-item-subtitle>{{$t('attribute-editor.component.fields.exportActive.hint')}}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                            </v-col>
                        </v-row>
                        </v-container>

                    <!-- Conditional Rendering -->
                    <!--
                    <v-divider />
                    <v-subheader>Bedingte Formatierung</v-subheader>
                    <v-card-text>Hinterlegen Sie Regeln zur bedingten Formatierung, um Werte abhängig von Formelausdrücken hervorzuheben.</v-card-text>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12">
                                <c-formatting-rule-editor header-style="lite" flat />
                            </v-col>
                        </v-row>
                    </v-container>
                -->
                    <v-divider />
                    <v-subheader>{{$t('attribute-editor.component.sections.tooltip.title')}}</v-subheader>
                    <v-card-text>{{$t('attribute-editor.component.sections.tooltip.subtitle')}}</v-card-text>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field 
                                    v-model="tooltipTemplate"
                                    filled 
                                    :label="$t('attribute-editor.component.fields.tooltip')" 
                                />
                            </v-col>
                            <v-col cols="12">
                                <v-expansion-panels class="mb-6" :popout="false">
                                    <v-expansion-panel>
                                        <v-expansion-panel-header>
                                            <div><v-icon color="accent" class="mr-2">help</v-icon>{{$t("attribute-editor.component.help.tooltip")}}</div>
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <div class="pa-5">
                                                <div class="text-body-2 font-weight-bold">{{$t("attribute-editor.component.help.tooltip.title")}}</div>
                                                <br />
                                                <div class="text-body-2">{{$t("attribute-editor.component.help.tooltip.subtitle")}}</div>
                                            </div>
                                            <div class="pa-5">
                                                
                                                <div class="text-body-2 font-weight-bold">{{$t("attribute-editor.component.help.tooltip.variables.title")}}</div>
                                                <br />
                                                <div class="text-body-2">{{$t("attribute-editor.component.help.tooltip.variables.subtitle")}}</div>
                                                <br />
                                                <v-divider />
                                                <v-simple-table>
                                                    <thead>

                                                        <tr>
                                                            <th>{{$t('attribute-editor.component.help.tooltip.variables.table.cols.variables')}}</th>
                                                            <th>{{$t('attribute-editor.component.help.tooltip.variables.table.cols.description')}}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td><code>{{$t('attribute-editor.component.help.tooltip.variables.table.rows.data.name')}}</code></td>
                                                            <td class="text-body-2">{{$t('attribute-editor.component.help.tooltip.variables.table.rows.data.description')}}</td>
                                                        </tr>
                                                        <tr>
                                                            <td><code>{{$t('attribute-editor.component.help.tooltip.variables.table.rows.i18n.name')}}</code></td>
                                                            <td class="text-body-2">{{$t('attribute-editor.component.help.tooltip.variables.table.rows.i18n.description')}}</td>
                                                        </tr>
                                                    </tbody>
                                                </v-simple-table>
                                            </div>
                                        
                                        
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>
                                
                            </v-col>
                        </v-row>
                    </v-container>
                    </v-card>
                 </v-tab-item>
                
                <!-- Customer specific Data -->
                 <v-tab-item :key="4">
                    <v-card tile flat class="pl-5 pr-5">
                    <v-card-title>{{$t('attribute-editor.component.tabs.custData')}}</v-card-title>
                    <v-divider />

                    <v-card-text>
                        {{$t('attribute-editor.component.sections.custData.subtitle')}}
                        </v-card-text>
                        <v-container fluid>
                        <v-row>
                            <v-col cols="12">
                                <c-params-editor 
                                    title="Kundenspezifische Parameter" 
                                    header-style="lite" 
                                    flat 
                                    :items="custDataItems"
                                    @item-updated="onCustDataUpdated" 
                                    @item-removed="onCustDataRemoved" 
                                    />
                            </v-col>
                        </v-row>
                        </v-container>
                    </v-card>
                 </v-tab-item>

             </v-tabs-items>
        </v-tabs>

        
    
    
</v-card>
</template>

<script>
import ComputedAttributeExpressionHelpComponent from './computed-attribute-expression-help.component';

import { cloneDeep } from 'lodash';
import { get } from 'lodash';
import { set } from 'lodash';
import { remove } from 'lodash';
import { findIndex } from 'lodash';

export default {

    props: {
        
        value: {
            type: Object,
            required: true
        },

        newItem: {
            type: Boolean,
            required: true
        },

        returnObject: {
            type: Boolean,
            required: false,
            default: true
        },
        
        attributes:{
            type: Array,
            required: false
        }

    },

    data: () => ({
        selectedTab: 0
    }), 

    components: {
      'dxs-computed-attribute-expression-help' : ComputedAttributeExpressionHelpComponent
    },

    methods: {
        

        onValueMappingRulesUpdated(event) {
            
            this.$log.debug(JSON.stringify(event));
   
            if (event && event.item) {
                let clone = cloneDeep(this.valueMappingRules);

                // Add or update the new item.
                const index = findIndex(clone, item => item.value === event.item.value);
                if (index < 0) {
                    clone.push(event.item);
                } else {
                    clone[index] = event.item;
                }

                
                this.valueMappingRules = clone;
            }
            
        },

        onValueMappingRulesRemoved(event) {
            
            this.$log.debug(JSON.stringify(event));
            
            if (event && event.item) {
                let clone = cloneDeep(this.valueMappingRules); 

                remove(clone, item => item.value === event.item.value);
                this.valueMappingRules = clone;
            }
        } ,

        onI18NUpdated(event) {

            if (event && event.item) {
                let clone = cloneDeep(this.i18n);
                clone[event.item.languageTag] = event.item;
                this.i18n = clone;
            }
        },

        onI18NRemoved(event) {
            
            if (event && event.item) {
                let clone = cloneDeep(this.i18n); 
                delete clone[event.item.languageTag];

                this.i18n = clone;
            }
        },
        
        onFormatUpdated(event) {

            this.$log.debug(JSON.stringify(event));

            if (event && event.item) {
                let clone = cloneDeep(this.format);
                clone[event.item.languageTag] = event.item;
                this.format = clone;
            }
        },

        onFormatRemoved(event) {
            
            this.$log.debug(JSON.stringify(event));
            if (event && event.item) {
                let clone = cloneDeep(this.format); 
                delete clone[event.item.languageTag];

                this.format = clone;
            }
        },


        onCustDataUpdated(event) {

            this.$log.debug(JSON.stringify(event));
            
            if (event && event.item) {
                let clone = cloneDeep(this.custData);
                clone[event.item.name] = event.item.value;

                this.custData = clone;
            }

        },

        onCustDataRemoved(event) {
            
            this.$log.debug(JSON.stringify(event));


            if (event && event.item) {
                let clone = cloneDeep(this.custData);
                delete clone[event.item.name];

                this.custData = clone;
            }
        },

        resetScriptInput() {
            set(this.value, 'computation.script', "");  
        }
    },


    // ===============================================================================
    // Binding must be accomplished by direct reference to this.value -> when making
    // a copy of data, the values are not transported back to the editable-list-framework
    // ============================================================================== 
    computed: {
        
        name: {
            
            get() {
                return get(this.value, 'name', null); 
            },

            set(name) {
                set(this.value, 'name', name ? name : null);
            }
        },

        key: {
            
            get() {
                return get(this.value, 'key', false); 
            },

            set(key) {
                set(this.value, 'key', key ? key : false);
            }
        },
        
        optional: {
            
            get() {
                return get(this.value, 'optional', false); 
            },

            set(optional) {
                set(this.value, 'optional', optional ? optional : false);
            }
        },

        computed: {
            
            get() {
                return get(this.value, 'computed', false); 
            },

            set(computed) {
                set(this.value, 'computed', computed ? computed : false);
            }
        },

        script: {
            
            get() {
                return get(this.value, 'computation.script', null); 
            },

            set(script) {
                set(this.value, 'computation.script', script ? script : null);
            }
        },

        ordering: {
            
            get() {
                return get(this.value, 'ordering', 100); 
            },

            set(ordering) {
                set(this.value, 'ordering', ordering ? ordering : 100);
            }
        },

        path: {
            
            get() {
                return get(this.value, 'path', null); 
            },

            set(path) {
                set(this.value, 'path', path ? path : null);
            }
        },

        attributeType: {
            
            get() {
                return get(this.value, 'attributeType', null); 
            },

            set(attributeType) {
                set(this.value, 'attributeType', attributeType ? attributeType : null);
            }
        },

        datatype: {
            
            get() {
                return get(this.value, 'datatype', null); 
            },

            set(datatype) {
                set(this.value, 'datatype', datatype ? datatype : null);
            }
        },

        linkTemplate: {
            
            get() {
                return get(this.value, 'linkTemplate', null); 
            },

            set(linkTemplate) {
                set(this.value, 'linkTemplate', linkTemplate ? linkTemplate : null);
            }
        },  
        
        tooltipTemplate: {
            
            get() {
                return get(this.value, 'clientSettings.tooltipTemplate', null); 
            },

            set(tooltipTemplate) {
                set(this.value, 'clientSettings.tooltipTemplate', tooltipTemplate ? tooltipTemplate : null);
            }
        },  

        i18n: {
           
           get() {
                return get(this.value, 'i18n', {}); 
            },

            set(i18n) {
                set(this.value, 'i18n', i18n ? i18n : {});
            }
        },

        valueMappingRules: {

            get() {

                // The backend will return a map-like structure. We need to adjust it to the requirements of the client first.
                const valueMapping = get(this.value, 'valueMapping', {});
                const items = [];
                for (const key in valueMapping) {
                    
                    items.push(valueMapping[key]);
                }

                return items;
            },

            set(valueMappingRules) {

                // The frontend operates on the basis of arrays, we need to transform these structure to a map 
                // before passing it back to the service layer.

                let valueMapping = {};
                for (const item of valueMappingRules) {
                    valueMapping[item.value] = item;
                }

                // TODO: Return to backend.
                set(this.value, 'valueMapping', valueMapping ? valueMapping : {});
            }
        },

        format: {
            get() {
                return get(this.value, 'format', {}); 
            },

            set(format) {
                set(this.value, 'format', format ? format : {});
            }
        },
         
        custData: {
           
           get() {
                return get(this.value, 'custData', {}); 
            },

            set(custData) {
                set(this.value, 'custData', custData ? custData : {});
            }
        },

        custDataItems() {

            let items = [];
            for (let key in this.custData) {

                items.push({name: key, value: this.custData[key]})
            }
            return items;
        },  

        filterActive: {
            
            get() {
                return get(this.value, 'clientSettings.filterActive', true); 
            },

            set(filterActive) {
                set(this.value, 'clientSettings.filterActive', filterActive);
            }
        },

        facetFilterActive: {
            
            get() {
                return get(this.value, 'clientSettings.facetFilterActive', false); 
            },

            set(facetFilterActive) {
                set(this.value, 'clientSettings.facetFilterActive', facetFilterActive);
            }
        },

        sortActive: {
            
            get() {
                return get(this.value, 'clientSettings.sortActive', true); 
            },

            set(sortActive) {
                set(this.value, 'clientSettings.sortActive', sortActive);
            }
        },

        exportActive: {
            
            get() {
                return get(this.value, 'clientSettings.exportActive', true); 
            },

            set(exportActive) {
                set(this.value, 'clientSettings.exportActive', exportActive);
            }
        },

        displayTable: {
            
            get() {
                return get(this.value, 'clientSettings.displayTable', true); 
            },

            set(displayTable) {
                set(this.value, 'clientSettings.displayTable', displayTable);
            }
        },

        displayTile: {
            
            get() {
                return get(this.value, 'clientSettings.displayTile', true); 
            },

            set(displayTile) {
                set(this.value, 'clientSettings.displayTile', displayTile);
            }
        },
           
    },



}
</script>

<i18n>
{
    "en" : {
        "attribute-editor.component.title" : "Attributes",
        "attribute-editor.component.sections.general" : "General Settings",
        "attribute-editor.component.sections.localization" : "Localization and Translations",


        "attribute-editor.component.tabs.general" : "General Settings",
        "attribute-editor.component.tabs.i18n" : "Localization",
        "attribute-editor.component.tabs.valueMapping" : "Value Mapping",
        "attribute-editor.component.tabs.clientSettings" : "Client Settings",
        "attribute-editor.component.tabs.custData" : "Customer-specific Parameters",

        "attribute-editor.component.fields.name" : "Technical ID",
        "attribute-editor.component.fields.name.hint" : "The technical ID can't be altered after initial creation.", 

        "attribute-editor.component.fields.path" : "Technical Mapping",
        "attribute-editor.component.fields.path.hint" : "The technical mapping defines storage in the underlying data structure. This mapping name is used e.g. for Lucene-bases queries.",
        "attribute-editor.component.fields.path.notEmpty" : "The Technical Mapping must not be empty.",
        "attribute-editor.component.fields.ordering" : "Order",
        "attribute-editor.component.fields.ordering.hint" : "The Order is a numerical value which defines the logical ordering, e.g. when displaying columns",
        "attribute-editor.component.fields.ordering.notEmpty" : "The Order must be greater than zero.",
        "attribute-editor.component.fields.datatype" : "Select Technical Datatype",
        "attribute-editor.component.fields.datatype.hint" : "The Technical Datatype defines what kind of data can be stored in this attribute (e.g. text, numbers, dates, etc.)",
        "attribute-editor.component.fields.datatype.notEmpty" : "The Technical Datatype must not be empty. Please select an appropriate type.",
        "attribute-editor.component.fields.analyticalType" : "Analytical Type",
        "attribute-editor.component.fields.analyticalType.hint" : "The Analytical Type decides usage of this attribute in the system's data analytics functions.",
        "attribute-editor.component.fields.analyticalType.notEmpty" : "The Analytical Type must not be empty. Please select an appropriate type.",
        "attribute-editor.component.fields.computation.notEmpty" : "The Computed Expression must not be empty. See the Guide below to enter a Computed Expression.",

        "attribute-editor.component.fields.key" : "Key",
        "attribute-editor.component.fields.key.hint" : "The attribute is part of the document's key.",
        "attribute-editor.component.fields.optional" : "Optional",
        "attribute-editor.component.fields.optional.hint" : "The attribute is optional and may be left empty.",
        "attribute-editor.component.fields.computed" : "Computed",
        "attribute-editor.component.fields.computed.hint" : "The attribute will be computed with a script expression.",
        "attribute-editor.component.fields.script" : "Computed Expression",

        "attribute-editor.component.sections.clientSettings.title" : "Client Settings",
        "attribute-editor.component.sections.clientSettings.subtitle" : "The following options define how the client will handle this attribute, e.g. when displayed in lists.",

        "attribute-editor.component.fields.exportActive" : "Use for Export",
        "attribute-editor.component.fields.exportActive.hint" : "The attribute will be included when exporting documents.",
        "attribute-editor.component.fields.displayTile" : "Display in Tile View",
        "attribute-editor.component.fields.displayTile.hint" : "The attribute will be displayed in the list tile view.",
        "attribute-editor.component.fields.displayTable" : "Display in Table View",
        "attribute-editor.component.fields.displayTable.hint" : "The attribute will be displayed as a column in the list table view.",
        "attribute-editor.component.fields.sortActive" : "Allow for Sorting",
        "attribute-editor.component.fields.sortActive.hint" : "The attribute can be used as a sorting criterion.",
        "attribute-editor.component.fields.facetFilterActive" : "Allow for Facet Filter",
        "attribute-editor.component.fields.facetFilterActive.hint" : "The attribute is automatically provided as a facet filter (max. 10 Facets)",
        "attribute-editor.component.fields.filterActive" : "Allow for Filtering",
        "attribute-editor.component.fields.filterActive.hint" : "The attribute can be used as a filter criterion.",

        "attribute-editor.component.fields.tooltip" : "Tooltip Text Template",

        "attribute-editor.component.sections.tooltip.title" : "Tooltip",
        "attribute-editor.component.sections.tooltip.subtitle" : "You can customize the tooltip to be displayed when hovering over a cell. Please provide a template string to adapt the tooltip to your needs.",


        "attribute-editor.component.sections.custData.title" : "Custom Parameters",
        "attribute-editor.component.sections.custData.subtitle" : "You can define additional parameters for the purpose of customizing. Please note: These parameters will not be evaluated from the application and need to be processed by means of customer specific code.",

        "attribute-editor.component.validations.keyAndOptional" : "A key field can't be made optional. The system will therefore ignore this setting.",

        "attribute-editor.component.help.tooltip" : "How To Define a Tooltip Text Template",
        "attribute-editor.component.help.tooltip.title" : "What are Tooltip Text Templates? And what can I achieve by using them?",
        "attribute-editor.component.help.tooltip.subtitle" : "With text templates you can easily customize the information which is displayed in list, when you hover over a cell with your mouse. Tooltips are therefore frequently used for displaying contextual information. Templates are typically made up from variables, but can also contain static texts.",
        "attribute-editor.component.help.tooltip.variables.title" : "Which Variables can  be used in the Text Templates?",
        "attribute-editor.component.help.tooltip.variables.subtitle" : "The following table contains a list of variables, which can be used in the definition of tooltip text templates.",
        "attribute-editor.component.help.tooltip.variables.table.cols.variables" : "Variable",
        "attribute-editor.component.help.tooltip.variables.table.cols.description" : "Description",
        "attribute-editor.component.help.tooltip.variables.table.rows.data.name" : "$data",
        "attribute-editor.component.help.tooltip.variables.table.rows.data.description" : "Contains all data attributes of the current document. Attributes can be acces by means of the notation $data.name. 'name' must represent the technical ID of the attribute to display.",
        "attribute-editor.component.help.tooltip.variables.table.rows.i18n.name" : "$i18n.locale",
        "attribute-editor.component.help.tooltip.variables.table.rows.i18n.description" : "The user's language code e.g. 'en' or 'de'",
        "attribute-editor.component.help.computedAttribute" : "How To Define a Computed Attribute"
    },

    "de" : {
        "attribute-editor.component.title" : "Attribute",
        "attribute-editor.component.sections.general" : "Allgemeine Einstellungen",
        "attribute-editor.component.sections.localization" : "Lokalisierung und Übersetzung",

        "attribute-editor.component.tabs.general" : "Allgemeine Einstellungen",
        "attribute-editor.component.tabs.i18n" : "Internationalisierung",
        "attribute-editor.component.tabs.valueMapping" : "Abbildungsregeln",
        "attribute-editor.component.tabs.clientSettings" : "Anzeigeeinstellungen",
        "attribute-editor.component.tabs.custData" : "Kundenspezifische Parameter",

        "attribute-editor.component.fields.name" : "Technischer Bezeichner",
        "attribute-editor.component.fields.name.hint" : "Der technische Bezeichner kann nach der Anlage nicht mehr geändert werden.", 

        "attribute-editor.component.fields.path" : "Technisches Mapping",
        "attribute-editor.component.fields.path.hint" : "Das technische Mapping definiert die Abbildung auf ein Feld für die technische Speicherung. Dieser Name wird z.B. für Abfragen mit Lucene-Syntax genutzt.",
        "attribute-editor.component.fields.path.notEmpty" : "Das technische Mapping darf nicht leer sein.",
        "attribute-editor.component.fields.ordering" : "Sortierreihenfolge",
        "attribute-editor.component.fields.ordering.hint" : "Die Sortierreihenfolge ist ein numerischer Wert der die Reihenfolg der Darstellung von Attributen festlegt,z.B. bei der Anzeige als Spalten in Tabellen.",
        "attribute-editor.component.fields.ordering.notEmpty" : "Die Sortierreihenfolge muss größer als 0 sein.",
        "attribute-editor.component.fields.datatype" : "Technischen Datentyp auswählen",
         "attribute-editor.component.fields.datatype.hint" : "Die Auswahl des technischen Datentyps legt die Art der Daten fest, die in einem Feld gespeichert werden können (Zahlen, Text, Datumsangaben, etc.).",
        "attribute-editor.component.fields.datatype.notEmpty" : "Der technische Datentyp darf nicht leer sein. Bitte wählen Sie eine passende Option aus.",
        "attribute-editor.component.fields.analyticalType" : "Analytics-Typ auswählen",
        "attribute-editor.component.fields.analyticalType.hint" : "Der Analytics-Typ bestimmt den Umgang des Systems mit dem Attribut in Verbindung mit analytischen Funktionen.",
        "attribute-editor.component.fields.analyticalType.notEmpty" : "Der Analytics-Typ darf nicht leer sein. Bitte wählen Sie eine passende Option aus.",
        "attribute-editor.component.fields.computation.notEmpty" : "Das berechnete Attribut darf nicht leer sein. Siehe hierfür die Anleitung für berechnete Attribute.",

        "attribute-editor.component.fields.key" : "Schlüssel",
        "attribute-editor.component.fields.key.hint" : "Das Attribut ist Bestandteil des Dokumentenschlüssels.",
        "attribute-editor.component.fields.optional" : "Optional",
        "attribute-editor.component.fields.optional.hint" : "Das Attribut ist optional und kann leer sein.",
        "attribute-editor.component.fields.computed" : "Berechnet",
        "attribute-editor.component.fields.computed.hint" : "Das Attribut berechnet sich aus einem Skriptausdruck.",
        "attribute-editor.component.fields.script" : "Skriptausdruck",

        "attribute-editor.component.sections.clientSettings.title" : "Anzeigeeinstellungen",
        "attribute-editor.component.sections.clientSettings.subtitle" : "Im folgenden Abschnitt legen Sie die Anzeigeeinstellungen des Attributes fest, z.B. bei der Anzeige in Listen.",

        "attribute-editor.component.fields.exportActive" : "Export aktivieren",
        "attribute-editor.component.fields.exportActive.hint" : "Das Attribut wird beim Export der Datei hinzugefügt.",
        "attribute-editor.component.fields.displayTile" : "In Kartenansicht anzeigen",
        "attribute-editor.component.fields.displayTile.hint" : "Das Attribut wird in der Kartenansicht dargestellt.",
        "attribute-editor.component.fields.displayTable" : "In Tabellenansicht anzeigen",
        "attribute-editor.component.fields.displayTable.hint" : "Das Attribut wird in der Tabellenansicht als Spalte dargestellt.",
        "attribute-editor.component.fields.sortActive" : "Als Sortierkriterium verwenden",
        "attribute-editor.component.fields.sortActive.hint" : "Das Attribut kann in Listen als Sortierkriterium verwendet werden.",
        "attribute-editor.component.fields.facetFilterActive" : "Als Facetten-Filter verwenden",
        "attribute-editor.component.fields.facetFilterActive.hint" : "Für das Attribut werden in Listen automatisch Facetten-Filter bereitgestellt (max. 10 Facetten)",
        "attribute-editor.component.fields.filterActive" : "Als Filterkriterium verwenden",
        "attribute-editor.component.fields.filterActive.hint" : "Das Attribut wird in Listen automatisch als Filterkriterium angeboten.",

        "attribute-editor.component.fields.tooltip" : "Template-Ausdruck Tooltip",

        "attribute-editor.component.sections.tooltip.title" : "Tooltip",
        "attribute-editor.component.sections.tooltip.subtitle" : "An dieser Stelle können Sie den Tooltip anpassen, der in den Tabellenzellen angezeigt wird. Bitte hinterlegen Sie hierfür einen Template-Ausdruck.",

        "attribute-editor.component.sections.custData.title" : "Kundenspezifische Parameter",
        "attribute-editor.component.sections.custData.subtitle" : "Sie können an dieser Stelle kundenspezifische Parameter für das Customizing hinterlegen. Bitte beachten Sie: Diese Parameter werden durch das Standardsystem nicht ausgewertet. Hierfür ist zusätzlicher kundenspezifischer Code notwendig.",

        "attribute-editor.component.validations.keyAndOptional" : "Ein Schlüsselfeld kann nicht optional sein. Das System wird diese Einstellung deshalb ignorieren.",

        "attribute-editor.component.help.tooltip" : "Anleitung für Template-Ausdrücke für Tooltips",
        "attribute-editor.component.help.tooltip.title" : "Was sind Template-Ausdrücke? Und wofür kann ich sie benutzen?",
        "attribute-editor.component.help.tooltip.subtitle" : "Mit Template-Ausdrücken können Sie auf einfache Weise die Informationen anpassen, die in Tooltips in Listen angezeigt werden, wenn Sie mit der Maus über einen Wert in einer Zelle fahren. Ausdrücke können aus statischem Text und Variablen zusammengesetzt werden.",
        "attribute-editor.component.help.tooltip.variables.title" : "Welche Variablen stehen zur Verfügung?",
        "attribute-editor.component.help.tooltip.variables.subtitle" : "Die nachfolgende Tabelle enthält eine Aufstellung der Variablen, die Ihnen bei der Definition des Template-Ausdrucks zur Verfügung stehen.",
        "attribute-editor.component.help.tooltip.variables.table.cols.variables" : "Variable",
        "attribute-editor.component.help.tooltip.variables.table.cols.description" : "Beschreibung",
        "attribute-editor.component.help.tooltip.variables.table.rows.data.name" : "$data",
        "attribute-editor.component.help.tooltip.variables.table.rows.data.description" : "Enthält alle Datenfelder des aktuellen Dokuments. Der Zugriff auf die Datenfelder geschieht über die Notation $data.name. Der Bezeichner 'name' steht hierbei für die technische Bezeichnung des Attributs.",
        "attribute-editor.component.help.tooltip.variables.table.rows.i18n.name" : "$i18n.locale",
        "attribute-editor.component.help.tooltip.variables.table.rows.i18n.description" : "Enthält den Sprachcode des Anwender, z.B. 'en' oder 'de'. Die ausgewählte Sprache wird in diesem Moment genutzt, um die passenden Bezeichnungen auf der Oberfläche anzuzeigen.",
        "attribute-editor.component.help.computedAttribute" : "Anleitung für Template-Ausdrücke für berechnete Attribute"

    }
}

</i18n>

