import Colors from 'vuetify/lib/util/colors';
// Please Note: The theme will be merged with the actual vuetify configuration in the \src\plugins\vuetify.js module

export default {
     
     dark: false,
         
     themes: {
       light: {
         primary: '#F40000',
         secondary: '#E2E2E2',
         tertiary: '#F0F0F0',
         accent: '#404040',
 
         error: '#6D1009',
         warning: '#FDD835',
         info: '#FCDEDC',
         success: Colors.green.base
       },
 
       dark: {
         primary: '#F40000',
         secondary: '#E2E2E2',
         tertiary: '#F0F0F0',
         accent: '#404040',
 
         error: '#6D1009',
         warning: '#FDD835',
         info: '#FCDEDC',
         success: Colors.green.base
       }
     }  
     
 /*
         dark: false,
         
         themes: {
           light: {
             primary: '#404040',
             secondary: '#E2E2E2',
             tertiary: '#F0F0F0',
             accent: '#F40000',
   
             error: '#6D1009',
             warning: '#FDD835',
             info: '#FCDEDC',
             success: Colors.green.base
           },
   
           dark: {
             primary: '#404040',
             secondary: '#E2E2E2',
             tertiary: '#F0F0F0',
             accent: '#F40000',
   
             error: '#6D1009',
             warning: '#FDD835',
             info: '#FCDEDC',
             success: Colors.green.base
           }
         }
 */
 
 }