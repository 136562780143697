import api from '@/store/api';
import { v4 as uuidv4  } from 'uuid';

export default {

    namespaced: true,

    state : {

        response: getDefaultResponse(), 

        reindexResponse : null,

        filter: getDefaultFilter()
    },

    mutations: {

        setResponse(state, response) {

            if (response) {

                let items = [];
                for (let item of response) {
                    items.push(item.collectionSettings);
                }

                state.response = {
                    items: items
                };
            }

        },

        setReindexResponse(state, response) {

            if (response) {
                state.reindexResponse = response;
            }

        },


        setFilter(state, filter) {

            if (filter) {
                state.filter = filter;
            } else {
                state.filter = getDefaultFilter()
            }
        }
    },

    actions: {

        async loadResponse(context) {

            try {
                let response = await api.catalog.collectionSettings.findAll(context.state.filter);
                context.commit('setResponse', response);
            } catch (error) {
                context.commit('setResponse', getDefaultResponse());
                throw error;
            }

        },

        async deleteItem(context, {name}) {
            
            await api.catalog.collectionSettings.deleteById(name);
        },

        async updateFilter(context, filter) {

            context.commit('setFilter', filter);
        },

        async startReindexCollection(context, collectionSetting) {
           
            try{
                let collectionId = collectionSetting.collectionId ? collectionSetting.collectionId : "";
                let jobInstanceId = collectionId + '-reindex-' + uuidv4();
                let response = await api.dataPreparation.mappingJobInstances.startReindex(collectionId, jobInstanceId);
                context.commit('setReindexResponse', response);

                return jobInstanceId;
            } catch (error) {
                context.commit('setResponse', getDefaultResponse());
                throw error;
            }
        }
    },

    getters: {

        response(state) {
            return state.response ? state.response : getDefaultResponse();
        },

        filter(state) {
            return state.filter ? state.filter : getDefaultFilter();
        }
    }
}

function getDefaultResponse() {

    return {
        items: []
    };
}

function getDefaultFilter() {
    return '*';
}