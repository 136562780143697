import TenantEditorModule from '@/core/store/modules/catalog/tenant/tenant-editor.module';

import NotificationsDashboardModule from '@/core/store/modules/notifications/notifications-dashboard.module';

import CollectionSettingsListModule from '@/core/store/modules/catalog/collection-settings/collection-settings-list.module';
import CollectionSettingsEditorModule from '@/core/store/modules/catalog/collection-settings/collection-settings-editor.module';
import CollectionSettingsWizardModule from '@/core/store/modules/catalog/collection-settings/collection-settings-wizard.module';
import CollectionSettingsApiModule from '@/core/store/modules/catalog/collection-settings/collection-settings-api.module';

import CollectionPermissionsEditorModule from '@/core/store/modules/catalog/collection-permissions/collection-permissions-editor.module';
import CollectionPermissionsListModule from '@/core/store/modules/catalog/collection-permissions/collection-permissions-list.module';
import CollectionPermissionsWizardModule from '@/core/store/modules/catalog/collection-permissions/collection-permissions-wizard.module';

import SystemCollectionSettingsListModule from '@/core/store/modules/catalog/system-collection-settings/system-collection-settings-list.module';

import UserGroupsListModule from '@/core/store/modules/user-mgmt/user-groups/user-groups-list.module';

import UsersListModule from '@/core/store/modules/user-mgmt/users/users-list.module';

import MappingJobTemplatesEditorModule from '@/core/store/modules/catalog/mapping-job-templates/mapping-job-templates-editor.module';
import MappingJobTemplatesListModule from '@/core/store/modules/catalog/mapping-job-templates/mapping-job-templates-list.module';
import MappingJobTemplatesWizardModule from '@/core/store/modules/catalog/mapping-job-templates/mapping-job-templates-wizard.module';

import SchemaListModule from '@/core/store/modules/catalog/schemas/schema-list.module';
import SchemaEditorModule from '@/core/store/modules/catalog/schemas/schema-editor.module';
import NewSchemaWizardModule from '@/core/store/modules/catalog/schemas/new-schema-wizard.module';

import UserListTemplatesListModule from '@/core/store/modules/catalog/user-list-templates/user-list-templates-list.module.js';
import MyUserListTemplatesListModule from '@/core/store/modules/catalog/user-list-templates/my-user-list-templates-list.module.js';
import UserListTemplatesEditorModule from '@/core/store/modules/catalog/user-list-templates/user-list-templates-editor.module.js';

import SystemParamsModule from '@/core/store/modules/settings/system-params/system-params.module';
import ServiceAvailabilityModule from '@/core/store/modules/system/service-availability-dashboard.module.js';

import UploadFileModule from '@/core/store/modules/upload-file/upload-file.module';
import DeadLetterModule from '@/core/store/modules/integration/dead-letters/dead-letter-detail.module';

import CollectionDetailModule from '@/core/store/modules/collections/collection-detail.module';
import CollectionListModule from '@/core/store/modules/collections/collection-list.module';
import CollectionStatisticsModule from '@/core/store/modules/collections/collection-statistics.module';
import CollectionSeriesModule from '@/core/store/modules/collections/collection-series.module';
import CollectionTimeSeriesModule from '@/core/store/modules/collections/collection-time-series.module';
import CollectionDocumentsModule from '@/core/store/modules/collections/collection-documents.module';
import CollectionDistributionModule from '@/core/store/modules/collections/collection-distribution.module';
import CollectionSchemaModule from '@/core/store/modules/collections/collection-schema.module';
import CollectionCorrelationCockpitModule from '@/core/store/modules/collections/collection-schema.module';
import collectionMetricsModule from '@/core/store/modules/collections/collection-metrics.module';

import MappingJobInstanceAdminDashboardModule from './modules/data-preparation/mapping-job-instances/mapping-job-instance-admin-dashboard.module';
import MappingJobInstanceDetailsModule from './modules/data-preparation/mapping-job-instances/mapping-job-instance-details.module';

import WebhookSubscriptionsListModule from './modules/webhooks/webhook-subscriptions/webhook-subscriptions-list.module';
import WebhookSubscriptionsEditorModule from './modules/webhooks/webhook-subscriptions/webhook-subscriptions-editor.module';
import WebhookSubscriptionsWizardModule from './modules/webhooks/webhook-subscriptions/webhook-subscriptions-wizard.module';

export default {

    // ==============================================================
    // Tenant
    // ==============================================================
    tenantEditor: TenantEditorModule,

    notificationsDashboard: NotificationsDashboardModule,

    // ==============================================================
    // Collections
    // ==============================================================
    collectionList: CollectionListModule,

    collectionDocuments: CollectionDocumentsModule,
    collectionDistribution: CollectionDistributionModule,
    collectionSeries: CollectionSeriesModule,
    collectionTimeSeries: CollectionTimeSeriesModule,
    collectionStatistics: CollectionStatisticsModule,
    collectionCorrelation: CollectionCorrelationCockpitModule,
    collectionSchema: CollectionSchemaModule,
    collectionDetail: CollectionDetailModule,
    collectionMetrics: collectionMetricsModule,

    // ================================================================
    // Catalog
    // ================================================================

    collectionSettingsList: CollectionSettingsListModule,
    collectionSettingsEditor: CollectionSettingsEditorModule,
    collectionSettingsWizard: CollectionSettingsWizardModule,
    collectionSettingsApi: CollectionSettingsApiModule,

    systemCollectionSettingsList: SystemCollectionSettingsListModule,

    collectionPermissionsEditor: CollectionPermissionsEditorModule,
    collectionPermissionsList: CollectionPermissionsListModule,
    collectionPermissionsWizard: CollectionPermissionsWizardModule,

    usersList: UsersListModule,
    userGroupsList: UserGroupsListModule,

    mappingJobTemplatesEditor: MappingJobTemplatesEditorModule,
    mappingJobTemplatesList: MappingJobTemplatesListModule,
    mappingJobTemplatesWizard: MappingJobTemplatesWizardModule,

    schemaList: SchemaListModule,
    schemaEditor: SchemaEditorModule,
    newSchemaWizard: NewSchemaWizardModule,

    userListTemplatesList: UserListTemplatesListModule,
    myUserListTemplatesList: MyUserListTemplatesListModule,
    UserListTemplatesEditor: UserListTemplatesEditorModule,

    // ================================================================
    // Jobs
    // ================================================================
    mappingJobInstanceAdminDashboard: MappingJobInstanceAdminDashboardModule,
    mappingJobInstanceDetails: MappingJobInstanceDetailsModule,

    
    // ================================================================
    // System
    // ================================================================
    systemParams: SystemParamsModule,
    serviceAvailability: ServiceAvailabilityModule,

    uploadFile: UploadFileModule,
    deadLetter: DeadLetterModule,

    webhookSubscriptionsList: WebhookSubscriptionsListModule,
    webhookSubscriptionsEditor: WebhookSubscriptionsEditorModule,
    webhookSubscriptionsWizard: WebhookSubscriptionsWizardModule

}