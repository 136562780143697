
import TestBenchListModule from './modules/test-bench-list.module';
import TestTrackListModule from './modules/test-track-list.module';
import TestTrackDetailModule from './modules/test-track-detail.module';
import TestTrackDetailSamplesModule from './modules/test-track-detail-samples.module';
import TestTrackProtocolExportModule from './modules/test-track-protocol-export.module';
import OrderStatusDashboard from './modules/order-status-dashboard.module';

export default {

    // Add customer-specific stores here
    testBenchList: TestBenchListModule,
    testTrackList: TestTrackListModule,
    testTrackDetail: TestTrackDetailModule,
    testTrackDetailSamples: TestTrackDetailSamplesModule,
    testTrackProtocolExport: TestTrackProtocolExportModule,
    orderStatusDashboard: OrderStatusDashboard
}