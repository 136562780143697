<template>
<div class="secondary darken-1" >
    <v-toolbar color="secondary darken-1" flat >
        <v-breadcrumbs>
            <v-breadcrumbs-item :to="{ name: 'collectionList' }" :disabled="false" :link="true">{{$t('collection-view.breadcrumbs.root')}}</v-breadcrumbs-item>
            <v-breadcrumbs-divider><v-icon>chevron_right</v-icon></v-breadcrumbs-divider>
            <v-breadcrumbs-item :to="{ name: 'collectionList' }" v-if="hasReadPermission">
                {{collectionSettings | localizedName($i18n.locale)}}
            </v-breadcrumbs-item>
            <v-breadcrumbs-item :to="{ name: 'collectionList' }" v-else>
                {{$t("collection.view.notFound.breadcrumb")}}
            </v-breadcrumbs-item>
        </v-breadcrumbs>
        <v-spacer />
        <v-toolbar-items v-if="hasReadPermission">
            <v-menu offset-y v-if="showMenu">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on"><v-icon @click="refresh">more_vert</v-icon></v-btn>
                </template>
                <v-list>
                    <v-subheader>{{$t('collection.view.sections.masterdata.title')}}</v-subheader>
                    <v-list-item v-if="isCollectionSettingsAdmin" two-line :to="collectionSettingsPath">
                        <v-list-item-avatar>
                            <v-icon>dataset</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title>{{ $t('collection.view.actions.goToCollectionSettings') }}</v-list-item-title>
                            <v-list-item-subtitle>{{collectionSettings.name}}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item  v-if="isSchemaAdmin" two-line  :to="schemaPath">
                        <v-list-item-avatar>
                            <v-icon>schema</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title >{{ $t('collection.view.actions.goToSchema') }}</v-list-item-title>
                            <v-list-item-subtitle>{{schema.name}}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider />
                    <v-subheader>{{$t('collection.view.sections.protocol.title')}}</v-subheader>
                    <v-list-item>
                        <v-list-item-avatar>
                            <v-icon v-if="accessLogOn" small>edit</v-icon>
                            <v-icon v-else small>edit_off</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title >{{ $t('collection.view.sections.protocol.accessLog.title') }}</v-list-item-title>
                            <v-list-item-subtitle v-if="accessLogOn">{{ $t('collection.view.sections.protocol.state.on') }}</v-list-item-subtitle>
                            <v-list-item-subtitle v-else>{{ $t('collection.view.sections.protocol.state.off') }}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-avatar>
                            <v-icon v-if="changeLogOn" small>edit</v-icon>
                            <v-icon v-else small>edit_off</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title >{{ $t('collection.view.sections.protocol.changeLog.title') }}</v-list-item-title>
                            <v-list-item-subtitle v-if="changeLogOn">{{ $t('collection.view.sections.protocol.state.on') }}</v-list-item-subtitle>
                            <v-list-item-subtitle v-else>{{ $t('collection.view.sections.protocol.state.off') }}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-menu>

        </v-toolbar-items>
    </v-toolbar>

    <c-section :title="collectionSettings | localizedName($i18n.locale)" :subtitle="value.collectionId" class="secondary" >
        
        

        <template v-if="hasReadPermission">
            <v-tabs fixed-tabs color="secondary darken-3" background-color="transparent" slider-color="accent">

                <!-- The first tab is always visible -->
                <v-tab :to="tabItems[0].route" :key="tabItems[0].label">{{$t('collection-documents.view.title')}}</v-tab>
                
                <!-- ******************************************** -->
                <!-- DESKTOP NAVIGATION                           -->
                <!-- ******************************************** -->
                <template v-if="$vuetify.breakpoint.mdAndUp">
                    <v-tab v-for="item of tabItems.slice(1)" :key="item.label" :to="item.route">
                        {{$t(item.label)}}
                    </v-tab>
                </template>
                
                <!-- ******************************************** -->
                <!-- MOBILE NAVIGATION                            -->
                <!-- ******************************************** -->
                <template v-else>
                    <v-menu bottom left>
                    
                        <template v-slot:activator="{ on, attrs }">
                            <!-- Hint: The button is styled in Tab fashion via v-tab CSS class in order to mimic the regular tab layout -->
                            <v-btn text class="v-tab align-self-center" v-bind="attrs" v-on="on">
                                {{$t('collection.view.actions.more')}}
                                <v-icon right>
                                arrow_drop_down
                                </v-icon>
                            </v-btn>
                        </template>

                        <v-list>
                            <v-list-item v-for="item of tabItems.slice(1)" :key="item.label" :to="item.route">
                                {{$t(item.label)}}
                            </v-list-item>
                        </v-list>

                    </v-menu>
                </template>
            </v-tabs>
        

            <router-view  name="collectionRouterView"></router-view>
        </template>

        <template v-else>
            <v-container fluid>
                <v-row justify="center">
                    <v-col cols="12" lg="9">
                        <v-card tile>
                            <v-card-text class="text-h6 text-center">
                                {{$t('collection.view.notFound.title')}}
                            </v-card-text>
                            <v-card-text class="text-center">
                                <v-icon x-large>sentiment_very_dissatisfied</v-icon>
                            </v-card-text>
                            <v-card-text class="text-center">
                                {{$t('collection.view.notFound.description')}}
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </template>
    </c-section>
    
    <!-- ******************************************* -->
    <!-- Sitemap                                     -->
    <!-- ******************************************* -->
    <c-section :title="$t('site-map-section.component.title')" :subtitle="$t('site-map-section.component.subtitle')" class="secondary darken-1 text-center">
        <v-container  fluid>
            <v-row v-if="!allCollectionsEmpty" justify="center">
                    <v-col cols="4" sm="3" md="2" lg="1" v-for="c of allCollections" :key="'collection_' + c.collectionId" class="mb-5">
                        <div class="font-weight-bold text-body-2 mb-5" :title="c | localizedName($i18n.locale)">{{c | localizedName($i18n.locale) | truncate-middle(16)}}</div>
                        <div class="text-body-2" v-for="(item, index) of tabItems" :key="c.name + 'tab_' + index">
                            <router-link :to="{ name: item.route.name, params: {collectionId: c.collectionId} }">{{$t(item.label)}}</router-link>
                        </div>
                     </v-col>
            </v-row>
            <v-row v-else justify="center">
                <v-col cols="12" >
                    <!--
                    <div class="text-center">
                        <v-btn fab color="primary" @click="refresh"><v-icon>refresh</v-icon></v-btn>
                    </div>
                    -->
                    <p />
                    <v-banner>
                        <div>{{$t('site-map-section.component.noPlanningAreas')}}</div>
                    </v-banner>
                    
                </v-col>
            </v-row>
        </v-container>
    </c-section>
</div>
</template>

<script>
import Roles from '@/roles';
import { get, isEmpty } from 'lodash';

const MODULE_NAME = 'collectionDetail';
const ALL_COLLECTIONS_MODULE = 'collectionList';

export default {

    name: 'collection',

    props: {
        value: {
            type: Object,
            required: false,
        }        
    },

    watch: {
        $route() {
            this.refresh();
        }
    }, 

    inject: ['collectionPermissionService', 'userContextService'],

    computed: {

        hasReadPermission() {

            // System collections are authorized by means of functional roles.
            return this.collectionSettings.systemCollection || this.collectionPermissionService.hasReadPermission({collectionId: this.value.collectionId});
        },

        response() {

            let response = this.$store.getters[MODULE_NAME + '/response'];
            return response;
        },

        collectionSettings() {
            return this.response.collectionSettings ? this.response.collectionSettings : {};
        },

        schema() {
            return this.response.schema ? this.response.schema : {};
        },

        collectionContext() {

            return {

                tenantId: this.value.tenantId,
                collectionId: this.value.collectionId
            }
        },

        breadcrumbs() {
            return [
                {text: 'Übersicht', disabled: false, to: { name: 'collectionList' } },
                {text: 'Hier', disabled: true }
            ]
        },

        allCollections() {

            let response = this.$store.getters[ALL_COLLECTIONS_MODULE + '/response'];
            let collections = response.data ? response.data : {};
            let locale = this.$i18n.locale;

        
            // Apply sorting
            collections.sort((first, second) => {

            const s1 = this.$core.filters.localizedName(first.collectionSettings, locale);
            const s2 = this.$core.filters.localizedName(second.collectionSettings, locale);
            return s1.localeCompare(s2, locale);
            });
        
            return collections;
        },

        allCollectionsEmpty() {
           return isEmpty(this.allCollections);
        },

        tabItems()  {

           const params = {
                collectionId: this.value.collectionId,
           };

           return  [
                { label: 'collection-documents.view.title', route: {name: 'collectionDocumentsView', params: params } },
                { label: 'collection-metrics.view.title', route: {name: 'collectionMetricsView', params: params } },
                { label: 'collection-series.view.title', route: {name: 'collectionSeriesView', params: params } },
                { label: 'collection-time-series.view.title', route: {name: 'collectionTimeSeriesView', params: params } },
                { label: 'collection-statistics.view.title', route: {name: 'collectionStatisticsView', params: params } },
                { label: 'collection-distribution.view.title', route: {name: 'collectionDistributionView', params: params } },
                { label: 'collection-settings.view.title', route: {name: 'collectionMetaView', params: params } }
            ];
        },

        showMenu() {
            return this.isCollectionSettingsAdmin
                || this.isSchemaAdmin;
        },

        isCollectionSettingsAdmin() {
            return this.userContextService.hasAllRoles(Roles.COLLECTION_SETTINGS_READ);
        },

        isSchemaAdmin() {
            return this.userContextService.hasAllRoles(Roles.SCHEMAS_READ);
        },

        changeLogOn() {
            return get(this.collectionSettings, 'loggingSettings.documentChangeLogging', 'OFF') === 'ON';
        },

        accessLogOn() {
            return get(this.collectionSettings, 'loggingSettings.queryLogging', 'OFF') === 'ON';
        },

        collectionSettingsPath() {

            const route = this.$router.resolve({
                name: 'collectionSettingsEditor',
                params: { name: get(this.collectionSettings, 'name') }
            });
            return route.href;
        },

        schemaPath() {
            const route = this.$router.resolve({
                name: 'schemaEditor',
                params: { name: get(this.schema, 'name') }
            });
            return route.href;
        }
    },


    methods: {
        
        async refresh() {

            this.loading = true;

            try {
                await this.$store.dispatch(MODULE_NAME + '/loadData', {
                    collectionId : this.value.collectionId
                });
                
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.loading = false;
            }


            this.$store.dispatch(ALL_COLLECTIONS_MODULE + '/load');
            
        }
    },

    

    created() {

        // Refresh will trigger loading of the collection settings information.
        this.refresh();
    },
}
</script>

<i18n>
{
    "en" : {
        "collection.view.notFound.title" : "Collection Not Found. We are sorry...",
        "collection.view.notFound.description" : "Sorry, but we cannot find the collection you are looking for. Please contact your system support for further details and help.",
        "collection.view.notFound.breadcrumb" : "Collection Not Found",
        "collection.view.actions.goToCollectionSettings" : "Open Collection Settings",
        "collection.view.actions.goToSchema" : "Open Schema",
        "collection.view.sections.masterdata.title" : "Masterdata",
        "collection.view.sections.protocol.title" : "Logging",
        "collection.view.sections.protocol.state.on" : "State: On",
        "collection.view.sections.protocol.state.off" : "State: Off",
        "collection.view.sections.protocol.changeLog.title" : "Change Log",
        "collection.view.sections.protocol.accessLog.title" : "Access Log"

    },

    "de" : {
        "collection.view.notFound.title" : "Der Datenbereich wurde leider nicht gefunden",
        "collection.view.notFound.description" : "Es tut uns leid, aber wir können den von Ihnen gesuchten Datenbereich nicht finden. Bitte kontaktieren Sie Ihren Systembetreuer für weitergehende Unterstützung.",
        "collection.view.notFound.breadcrumb" : "Datenbereich nicht gefunden",
        "collection.view.actions.goToCollectionSettings" : "Datenbereich öffnen",
        "collection.view.actions.goToSchema" : "Schema öffnen",
        "collection.view.sections.masterdata.title" : "Stammdaten",
        "collection.view.sections.protocol.title" : "Protokollierung",
        "collection.view.sections.protocol.state.on" : "Status: Eingeschaltet",
        "collection.view.sections.protocol.state.off" : "Status: Ausgeschaltet",
        "collection.view.sections.protocol.changeLog.title" : "Änderungsprotokoll",
        "collection.view.sections.protocol.accessLog.title" : "Zugriffsprotokoll"
    }
}
</i18n>