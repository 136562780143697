export default {


    COLLECTIONS_READ : 'amentis-dxs-collections-read',
    COLLECTIONS_EXPORT : 'amentis-dxs-collections-export',
    COLLECTIONS_UPLOAD : 'amentis-dxs-collections-upload',
    COLLECTIONS_CHANGELOG_READ : 'amentis-dxs-collections-changelog-read',
    COLLECTIONS_CHANGELOG_RESTORE: 'amentis-dxs-collections-changelog-restore',

    SYSTEM_LOGS_READ : 'amentis-dxs-system-servicelogs-read',
   

    TENANT_READ : 'amentis-dxs-catalog-tenant-read',
    TENANT_EDIT : 'amentis-dxs-catalog-tenant-edit',
    
    SCHEMAS_READ    : 'amentis-dxs-catalog-schemas-read',
    SCHEMAS_EDIT  : 'amentis-dxs-catalog-schemas-edit',
    SCHEMAS_CREATE  : 'amentis-dxs-catalog-schemas-create',
    SCHEMAS_DELETE  : 'amentis-dxs-catalog-schemas-delete',

    COLLECTION_SETTINGS_READ    : 'amentis-dxs-catalog-collectionsettings-read',
    COLLECTION_SETTINGS_EDIT  : 'amentis-dxs-catalog-collectionsettings-edit',
    COLLECTION_SETTINGS_CREATE  : 'amentis-dxs-catalog-collectionsettings-create',
    COLLECTION_SETTINGS_DELETE  : 'amentis-dxs-catalog-collectionsettings-delete',

    COLLECTION_PERMISSIONS_READ    : 'amentis-dxs-catalog-collectionpermissions-read',
    COLLECTION_PERMISSIONS_EDIT  : 'amentis-dxs-catalog-collectionpermissions-edit',
    COLLECTION_PERMISSIONS_CREATE  : 'amentis-dxs-catalog-collectionpermissions-create',
    COLLECTION_PERMISSIONS_DELETE  : 'amentis-dxs-catalog-collectionpermissions-delete',

    SYSTEM_PARAMETERS_READ    : 'amentis-dxs-catalog-parameters-read',
    SYSTEM_PARAMETERS_EDIT    : 'amentis-dxs-catalog-parameters-edit',
    SYSTEM_PARAMETERS_CREATE  : 'amentis-dxs-catalog-parameters-create',
    SYSTEM_PARAMETERS_DELETE  : 'amentis-dxs-catalog-parameters-delete',

    SYSTEM_CHANGELOG_READ : 'amentis-dxs-system-changelog-read',
    SYSTEM_CHANGELOG_RESTORE : 'amentis-dxs-system-changelog-restore',

    SYSTEM_QUERYLOG_READ : 'amentis-dxs-system-querylog-read',

    SYSTEM_SERVICEAVAILABILITY_READ : 'amentis-dxs-system-serviceavailability-read',

    MAPPING_JOB_TEMPLATES_READ    : 'amentis-dxs-jobs-mappingjobtemplates-read',
    MAPPING_JOB_TEMPLATES_EDIT  : 'amentis-dxs-jobs-mappingjobtemplates-edit',
    MAPPING_JOB_TEMPLATES_CREATE  : 'amentis-dxs-jobs-mappingjobtemplates-create',
    MAPPING_JOB_TEMPLATES_DELETE  : 'amentis-dxs-jobs-mappingjobtemplates-delete',

    USERS_READ : 'amentis-dxs-usermgmt-users-read',
    USERS_EDIT : 'amentis-dxs-usermgmt-users-edit',
    USERS_CREATE : 'amentis-dxs-usermgmt-users-create',
    USERS_DELETE : 'amentis-dxs-usermgmt-users-delete',

    USER_GROUPS_READ : 'amentis-dxs-usermgmt-usergroups-read',
    USER_GROUPS_EDIT : 'amentis-dxs-usermgmt-usergroups-edit',
    USER_GROUPS_CREATE : 'amentis-dxs-usermgmt-usergroups-create',
    USER_GROUPS_DELETE : 'amentis-dxs-usermgmt-usergroups-delete',

    USER_LIST_TEMPLATES_READ : 'amentis-dxs-usermgmt-userlisttemplates-read',
    USER_LIST_TEMPLATES_EDIT : 'amentis-dxs-usermgmt-userlisttemplates-edit',
    USER_LIST_TEMPLATES_CREATE : 'amentis-dxs-usermgmt-userlisttemplates-create',
    USER_LIST_TEMPLATES_DELETE : 'amentis-dxs-usermgmt-userlisttemplates-delete',

    ACCESS_CONTROL_LISTS_READ : 'amentis-dxs-usermgmt-accesscontrollists-read',
    ACCESS_CONTROL_LISTS_EDIT : 'amentis-dxs-usermgmt-accesscontrollists-edit',
    ACCESS_CONTROL_LISTS_CREATE : 'amentis-dxs-usermgmt-accesscontrollists-create',
    ACCESS_CONTROL_LISTS_DELETE : 'amentis-dxs-usermgmt-accesscontrollists-delete',
   
    DEADLETTERS_READ : 'amentis-dxs-integration-deadletters-read',
    DEADLETTERS_EDIT : 'amentis-dxs-integration-deadletters-edit',
    DEADLETTERS_CREATE : 'amentis-dxs-integration-deadletters-create',
    DEADLETTERS_DELETE : 'amentis-dxs-integration-deadletters-delete',
    
    WEBHOOKS_SUBSCRIPTIONS_READ : 'amentis-dxs-webhooksubscriptions-read',
    WEBHOOKS_SUBSCRIPTIONS_EDIT : 'amentis-dxs-webhooksubscriptions-edit',
    WEBHOOKS_SUBSCRIPTIONS_CREATE : 'amentis-dxs-webhooksubscriptions-create',
    WEBHOOKS_SUBSCRIPTIONS_DELETE : 'amentis-dxs-webhooksubscriptions-delete',

    DOCUMENTS_DELETE : 'amentis-dxs-documents-delete'
}