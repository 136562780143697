<template>
  
  <v-sheet>
        <!-- *********************************************************************** -->
        <!-- Toolbar -->
        <!-- *********************************************************************** -->
        <c-sticky-content>
            <v-toolbar flat>
                <v-toolbar-title>
                   {{$t('test-track-detail-samples.view.results', {itemCount: items.length, totalItemCount: totalItemCount}) }}</v-toolbar-title>
                <v-spacer />
                <v-toolbar-items>
                    <v-btn text @click="refresh" color="primary"><v-icon class="pr-5">refresh</v-icon>{{$t('test-track-detail-samples.view.actions.refresh')}}</v-btn>
                    <v-btn text @click="exportCSV" :disabled="items.length == 0"><v-icon class="pr-5">cloud_download</v-icon>{{$t('test-track-detail-samples.view.actions.exportCSV')}}</v-btn>
                    <v-btn text @click="exportXLSX" :disabled="items.length == 0"><v-icon class="pr-5">cloud_download</v-icon>{{$t('test-track-detail-samples.view.actions.exportXLSX')}}</v-btn>
                </v-toolbar-items>
            </v-toolbar>          
            <v-divider />
        </c-sticky-content>
        <v-expansion-panels accordion multiple v-model="openPanels">
            <v-expansion-panel>
                <v-expansion-panel-header expand-icon="arrow_drop_down">
                    Filter &amp; Sortierung: {{filterAndSortText  | truncate-middle(196)}}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-container fluid class="white pa-5">
                        <v-row justify="center" dense>

                            <v-col cols="12" md="6">
                                <c-date-time-selector v-model="filterStartDateTime" />
                            </v-col>
                            <v-col cols="12" md="6">
                                <c-date-time-selector v-model="filterEndDateTime" />
                            </v-col>
                            
                            <v-col cols="12">
                                <v-text-field filled v-model="filterExpression" label="Filterausdruck"></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <c-item-selector v-model="selectedSortOption" filled chips :items="sortOptionList" :return-object="true" label="Sortierung" />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" class="text-right">
                                <v-btn @click="refresh" color="primary" depressed><v-icon color="white" class="pr-5">refresh</v-icon> {{$t('test-track-detail-samples.view.actions.refresh')}}</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>

                <v-expansion-panel-header expand-icon="arrow_drop_down">
                    {{$t('test-track-detail-samples.view.common.selectedMeasures')}} {{selectedMeasureText | truncate-middle(196)}}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-sheet  class="white pa-5" v-if="measureList.length > 0">
                        <c-item-multi-selector v-model="selectedMeasures" filled chips :items="measureList" return-object :label="$t('test-track-detail-samples.view.common.selectedMeasures.combobox.label')" />
                    </v-sheet>
                    <v-sheet class="accent white--text text-body-2 pa-5" v-else>
                        <v-icon class="pr-5" color="white">help</v-icon>  {{$t('test-track-detail-samples.view.common.selectedMeasures.loading')}}
                    </v-sheet>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
            
        <v-divider />

      <!-- *********************************************************************** -->
      <!-- Content                                                                 -->
      <!-- *********************************************************************** -->     
      <template v-if="!itemsEmpty">
        <v-container fluid class="grey lighten-3">
            
            <v-row class="pt-5">
                <v-col cols="12">
                    <v-card tile>
                        <v-card-text>
                            <div class="text-h6">{{$t('test-track-detail-samples.view.card.chart.title')}}</div>
                            <div>{{filterText  | truncate-middle(196)}}</div>
                        </v-card-text>
                        <v-card-text v-if="selectedMeasuresEmpty" class="accent">
                            
                            <div class="text-center pt-10">
                                <v-icon x-large color="white">area_chart</v-icon>
                            </div>
                            <div class="text-h6 text-center white--text">
                                {{$t('test-track-detail-samples.view.card.chart.noData.title')}}
                            </div>
                            <div class="text-center white--text pb-10">
                                {{$t('test-track-detail-samples.view.card.chart.noData.subtitle')}}
                            </div>
                        
                        </v-card-text>
                        <v-card-text v-else>
                            <c-metrics-time-series-chart :value="chartModel" class="pb-5" height="400px" fallbackChartType="line" legend="bottom"  zoom="top" />
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>

            <v-row justify="center">
                <v-col cols="12" sm="4">
                    <v-card v-if="items.length < totalItemCount" class="primary" flat @click="loadMore">
                        <v-card-text class="text-h6 white--text text-center" >
                            <v-icon color="white" class="pr-5" large>arrow_downward</v-icon> {{$t('test-track-detail-samples.view.common.loadMore.title')}}
                        </v-card-text>
                        <v-card-text class="white--text text-center"> {{$t('test-track-detail-samples.view.common.loadMore.subtitle')}}</v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            
            <v-row class="pt-5">
                <v-col cols="12">
                    <v-card tile>
                        <v-card-text>
                            <div class="text-h6">{{$t('test-track-detail-samples.view.card.detaildata.title')}}</div>
                            <div>{{filterText  | truncate-middle(196)}}</div>
                        </v-card-text>
                        <v-divider />
     
                        <c-data-table 
                                :value="tableModel" 
                                :column-config="tableColumnConfig"
                                @open-clicked="onOpenClicked" />
    

                        <v-card-text class="text-center">
                            {{$t('test-track-detail-samples.view.card.detaildata.footer.title')}}
                        </v-card-text>
                        <v-card-text class="text-center">
                             <v-btn color="accent" @click="showAllDataTableItems = true" depressed><v-icon class="pr-5">arrow_downward</v-icon>{{$t('test-track-detail-samples.view.card.detaildata.footer.actions.showAll')}}</v-btn>
                        </v-card-text>

                    </v-card>
                </v-col>
            </v-row>
        </v-container>
      </template>

      <!-- *********************************************************************** -->
      <!-- No Data -->
      <!-- *********************************************************************** -->  
      <template v-else>
        <v-container fluid class="grey lighten-3">    
            <v-row class="pt-5">
                <v-col cols="12">
                    <v-card tile class="accent"> 
                        <v-card-text >
                            
                            <div class="text-center pt-10">
                                <v-icon x-large color="white">data_saver_on</v-icon>
                            </div>
                            <div class="text-h6 text-center white--text">
                               {{$t('test-track-detail-samples.view.cards.noData.title')}}
                            </div>
                            <div class="text-center white--text pb-10">
                                {{$t('test-track-detail-samples.view.cards.noData.subtitle')}}
                            </div>
                        </v-card-text>
                        <v-card-text class="text-center">
                            <v-btn x-large color="primary" @click="refresh">{{$t('test-track-detail-samples.view.actions.refresh')}}</v-btn>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
      </template>
  </v-sheet>
</template>

<script>
//import MockResponse from './response';
import { get } from 'lodash';
import { isEmpty } from 'lodash';


import { QUERY_PAGE_SIZE } from '@/cust/constants/test-track-detail-samples.constants';
import { MAX_ITEMS } from '@/cust/constants/test-track-detail-samples.constants';

const MODULE_NAME = "testTrackDetailSamples";

const SORT_OPTIONS =  [
    { 
        name: 'asc',
        i18n: {
            en : {
                languageTag: "en",
                name: "Sample Date Time Ascending"
            },

            de : {
                languageTag: "de",
                name: "Prüfzeitpunkt Aufsteigend"
            },
        },
        sortCriteria: [
            {name: "sampleDateTime", ordering: "ASC"}
        ]
    },
    { 
        name: 'desc',
        i18n: {
            en : {
                languageTag: "en",
                name: "Sample Date Time Descending"
            },

            de : {
                languageTag: "de",
                name: "Prüfzeitpunkt Absteigend"
            },
        },
        sortCriteria: [
            {name: "sampleDateTime", ordering: "DESC"}
        ]
    }
];

export default {

    name: 'test-track-detail-samples',

    props: {
        value: {
            type: Object,
            required: false
        }
    },  

    inject: ['errorHandlerService', 'progressIndicatorService', 'messageService'],

    data() {


        return {

            selectedMeasures: [],
            selectedSortOption: SORT_OPTIONS[0],
            
            
            filterStartDateTime: get(this.value, 'filterStartDateTime'),
            filterEndDateTime: get(this.value, 'filterEndDateTime'),
            
            filterExpression: '*',

            openPanels : [0],

            showAllDataTableItems: false,

        };
    },

    computed: {

        collectionId() {
            return get(this.value, 'collectionId');
        },

        testBenchId() {
            return get(this.value, 'testBenchId');
        },

        testTrackId() {
            return get(this.value, 'testTrackId')
        },

        model() {
            const model = this.$store.getters[MODULE_NAME + "/model"]; 
            return model;
        },

        items() {
            return get(this.model, 'items', []);
        },

        itemsEmpty() {
            return isEmpty(this.items);
        },

        collectionSettings() {
            return get(this.model, 'collectionSettings', {});
        },

        schema() {
            return get(this.model, 'schema', {});
        },
        
        attributes() {
            return get(this.schema, 'attributes', {});
        },

        attributeList() {
            
            const attributeList = [];
            for (const attributeKey in this.attributes) {
                attributeList.push(this.attributes[attributeKey]);
            }

            // Apply sorting
            attributeList.sort((first, second) => first.ordering - second.ordering);

            return attributeList;
        },

        pagination() {
            return get(this.model, 'pagination');
        },

        totalItemCount() {
            return get(this.pagination, "totalItemCount", 0);
        },

        measureList() {
            return this.attributeList.filter(e => e.attributeType === 'MEASURE');
        },

        selectedMeasureText() {
            
            return !this.selectedMeasuresEmpty ? this.selectedMeasures.map(e => this.$dxs.i18n.localizedName(e, this.$i18n.locale)).join(', ') : this.$t('test-track-detail-samples.view.common.selectedMeasures.noSelection')
        },

        selectedMeasuresEmpty() {
            return isEmpty(this.selectedMeasures);
        },

        filterText() {

            const context = {
                fromDate: this.$dxs.i18n.localizedDateTimeFormat(this.filterStartDateTime, this.$i18n.locale),
                untilDate: this.$dxs.i18n.localizedDateTimeFormat(this.filterEndDateTime, this.$i18n.locale),
                filterExpression: this.filterExpression
            }
            return this.$t('test-track-detail-samples.view.common.filter',context)

        },

        filterAndSortText() {
            
            const context = {
                sortOption: this.$dxs.i18n.localizedName(this.selectedSortOption, this.$i18n.locale)
            };

            return this.filterText + ', ' + this.$t('test-track-detail-samples.view.common.sort', context);
        },

        sortOptionList() {

            return SORT_OPTIONS;
        },

        tableColumnConfig() {

            let config = {};
            let index = 0;
            for (const attribute of this.attributeList) {
                
                // Note: The frontend component expects the order to be given in absolute sequence 0, 1, 2, ... 5
                // for some reason. We therefore need to create such an ordering by means of the incremented index.

                config[attribute.name] = {
                    name: attribute.name,
                    display: true,
                    order: index
                };

                index = index + 1;
            }

            return config;

        },

        tableModel() {

            //const viewSettings = { attributes: []};
            /*const attributeMap = get(this.schema, 'attributes', {});
            for (const attributeKey in attributeMap) {

                viewSettings.attributes.push({
                    value: attributeMap[attributeKey],
                    display: true,
                })
            }*/

             return {
                items              : this.showAllDataTableItems ? this.items : this.items.slice(0, 150),
                meta               : this.schema,
                //collectionSettings : this.collectionSettings,
                //viewSettings       : viewSettings,
            }
        },

        chartModel() {

            const model = {
                items: [],

                series: {}
            };

            // Populate items
            for (const item of this.items) {

                const seriesItem = {
                    dateTime : item.data.sampleDateTime, 
                    values   : {}
                };
                
                for (const m of this.selectedMeasures) {
                    seriesItem.values[m.name] = item.data[m.name];
                }

                model.items.push(seriesItem);
            }
            //{dateTime : '2021-08-02T12:00:00.0+01:00', values: {avg: 13.5, min: 25.5, max: 46}} ,


            // Populate series for metadata
            for (const m of this.selectedMeasures) {
                model.series[m.name] = m;
            }

            return model;
        },

        filter: {
            get() {
                return {
                    testBenchId: this.testBenchId,
                    testTrackId: this.testTrackId,
                    filterStartDateTime: this.filterStartDateTime,
                    filterEndDateTime: this.filterEndDateTime,
                    filterQuery: this.filterExpression,
                }
            },
        },

        sort: {
            get() {
                return get(this.selectedSortOption, 'sortCriteria', []);
            }
        }
    },

    methods: {
        init() {

            // Re-initialize the data from the route values 
            // This is technically necessary when navigating between views with reusing of components.
            this.filterStartDateTime = get(this.value, 'filterStartDateTime');
            this.filterEndDateTime = get(this.value, 'filterEndDateTime');
            this.filterExpression = '*';

            this.selectedMeasures = [];
            this.selectedSortOption = SORT_OPTIONS[0];
            this.openPanels = [0];
            this.showAllDataTableItems = false;


            // Then push the state to the vuex store.
            this.$store.dispatch(MODULE_NAME + '/init', {
                collectionId: this.collectionId,
                filter: this.filter,
                sort: this.sort,
            });
        },

        async refresh() {

            // Update the filter with the current settings
            await this.$store.dispatch(MODULE_NAME + '/updateFilter', this.filter);

            // ==============================================
            // Start Data Loading
            // ==============================================

            this.progressIndicatorService.show();
            //this.progressIndicatorService.updateText({from: 0, to: MAX_ITEMS});
            
            try {
                // Load the initial data set.
                let totalItemCount = await this.$store.dispatch(MODULE_NAME + '/loadData');

                // Load further chunks, but only if not all items have already been loaded.
                
                let loadMore = (totalItemCount >= QUERY_PAGE_SIZE);
                
                while (loadMore) {
                    const itemCount = await this.$store.dispatch(MODULE_NAME + '/loadMoreData');
                    totalItemCount = totalItemCount + itemCount;

                    if (itemCount < QUERY_PAGE_SIZE || totalItemCount > MAX_ITEMS) {
                        loadMore = false;
                    }

                    this.$log.debug('Loaded ' + itemCount + ', Total: ' + totalItemCount );
                }

            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },

        async loadMore() {
            
            this.progressIndicatorService.show();
            //this.progressIndicatorService.updateText({from: 0, to: MAX_ITEMS});
            
            try {
                // Load the initial data set.
                let totalItemCount = 0;

                // Load further chunks, but only if not all items have already been loaded.
                let loadMore = true;
                
                while (loadMore) {
                    const itemCount = await this.$store.dispatch(MODULE_NAME + '/loadMoreData');
                    totalItemCount = totalItemCount + itemCount;

                    if (itemCount < QUERY_PAGE_SIZE || totalItemCount > MAX_ITEMS) {
                        loadMore = false;
                    }

                    this.$log.debug('Loaded ' + itemCount + ', Total: ' + totalItemCount );
                }

            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },

        onOpenClicked({collectionSettings, item}) {
            this.$router.push({
                name: 'documentView', 
                params: {
                    collectionId: collectionSettings.name, 
                    documentId: item.id
                }
            });
        },

        async exportCSV() {
            
            // Update the filter with the current settings
            await this.$store.dispatch(MODULE_NAME + '/updateFilter', this.filter);

            // Start async CSV export
            this.progressIndicatorService.show();

            try {
                await this.$store.dispatch(MODULE_NAME + '/exportDocuments', {collectionId: this.collectionId, format: 'CSV', charset: 'windows-1252'});

                this.messageService.show({
                    title: this.$t('collection-documents.view.messages.export.success.title', this.$i18n.locale),
                    text: this.$t('collection-documents.view.messages.export.success.message', this.$i18n.locale)
                });
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },

        async exportXLSX()  {

            // Update the filter with the current settings
            await this.$store.dispatch(MODULE_NAME + '/updateFilter', this.filter);

            // Start async CSV export
            this.progressIndicatorService.show();

            try {
                await this.$store.dispatch(MODULE_NAME + '/exportDocuments', {collectionId: this.collectionId, format: 'XLSX', charset: 'utf-8'});

                this.messageService.show({
                    title: this.$t('collection-documents.view.messages.export.success.title', this.$i18n.locale),
                    text: this.$t('collection-documents.view.messages.export.success.message', this.$i18n.locale)
                });
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },

        printToConsole() {
            console.log(JSON.stringify(this.tableModel));
        }
    },

    // ===================================================================
    // Watchers
    // ===================================================================
    watch: {
        '$route' : { 
            handler: function() {
                this.init();
            }
        }        
    },

    // ===================================================================
    // Lifecycle
    // ===================================================================
    created() {
        this.init();
    }
}
</script>

<i18n>
{
    "en" : {
        "test-track-detail-samples.view.results" : "Results ({itemCount} of {totalItemCount} Total Samples are displayed)",

        "test-track-detail-samples.view.actions.refresh" : "Refresh",
        "test-track-detail-samples.view.actions.exportCSV" : "Export CSV",
        "test-track-detail-samples.view.actions.exportXLSX" : "Export Excel",

        "test-track-detail-samples.view.common.filterAndSort" : "Filter and Sort:",
        "test-track-detail-samples.view.common.filter" : "Time Period: {fromDate} - {untilDate}, Filter Expression: {filterExpression}",
        "test-track-detail-samples.view.common.sort" : "Sort: {sortOption}",
        "test-track-detail-samples.view.common.selectedMeasures" : "Selected Measures for Display:",
        "test-track-detail-samples.view.common.selectedMeasures.combobox.label" : "Selected Measures",
        "test-track-detail-samples.view.common.selectedMeasures.noSelection" : "No measures selected",
        "test-track-detail-samples.view.common.selectedMeasures.loading" : "Please load the data first in order to select measures for display in the chart.",

         "test-track-detail-samples.view.cards.noData.title" : "No Samples Loaded",
         "test-track-detail-samples.view.cards.noData.subtitle" : "Please adjust your filter settings and then reload the data.",

       "test-track-detail-samples.view.common.loadMore.title" : "Load More",
        "test-track-detail-samples.view.common.loadMore.subtitle" : "There are further samples for the given filter criteria. Click in order to load another set of up to 10,000 samples.",

        "test-track-detail-samples.view.card.chart.title" : "Process Data (Chronology)",
        "test-track-detail-samples.view.card.chart.noData.title" : "Show Chart",
        "test-track-detail-samples.view.card.chart.noData.subtitle" : "Please select at least on measure in order to show the chart.",
        

        "test-track-detail-samples.view.card.detaildata.title" : "Process Data (Details)",
        "test-track-detail-samples.view.card.detaildata.footer.title" : "The table displays only the first 150 samples. If you'd like to display all samples, please click the button below. Warning: Dependning on the number of samples this may take a long time to display.",
        "test-track-detail-samples.view.card.detaildata.footer.actions.showAll" : "Show All Samples in Table"
    },

    "de" : {
        "test-track-detail-samples.view.results" : "Ergebnisse (Anzeige von {itemCount} von insgesamt {totalItemCount} Messungen)",
        
        "test-track-detail-samples.view.actions.refresh" : "Neu Laden",
        "test-track-detail-samples.view.actions.exportCSV" : "CSV Exportieren",
        "test-track-detail-samples.view.actions.exportXLSX" : "Excel Exportieren",

        "test-track-detail-samples.view.common.filterAndSort" : "Filter und Sortierung:",
        "test-track-detail-samples.view.common.filter" : "Zeitraum: {fromDate} - {untilDate}, Filterausdruck: {filterExpression}",
        "test-track-detail-samples.view.common.sort" : "Sortierung: {sortOption}",
        "test-track-detail-samples.view.common.selectedMeasures" : "Kennzahlen zur Anzeige:",
        "test-track-detail-samples.view.common.selectedMeasures.combobox.label" : "Kennzahlen auswählen",
        "test-track-detail-samples.view.common.selectedMeasures.noSelection" : "Keine Kennzahlen ausgewählt.",
        "test-track-detail-samples.view.common.selectedMeasures.loading" : "Bitte laden Sie zuerst die Daten, um Kennzahlen für die Anzeige im Verlaufsdiagramm auswählen zu können.",
        
         "test-track-detail-samples.view.cards.noData.title" : "Keine Messungen zur Anzeige",
         "test-track-detail-samples.view.cards.noData.subtitle" : "Bitte passen Sie Ihre Filtereinstellungen an und laden Sie die Daten danach neu.",

        "test-track-detail-samples.view.common.loadMore.title" : "Weitere Daten laden",
        "test-track-detail-samples.view.common.loadMore.subtitle" : "Es liegen weitere Prüfdaten für die ausgewählten Filterkriterien vor. Klicken Sie um bis zu 10.000 weitere Messungen zu laden.",

        "test-track-detail-samples.view.card.chart.title" : "Prozessdaten (Verlauf)", 
        "test-track-detail-samples.view.card.chart.noData.title" : "Diagramm anzeigen",
        "test-track-detail-samples.view.card.chart.noData.subtitle" : "Bitte wählen Sie mindestens eine Kennzahl aus, um das Verlaufsdiagramm anzuzeigen",
        
        "test-track-detail-samples.view.card.detaildata.title" : "Prozessdaten (Details)",
        "test-track-detail-samples.view.card.detaildata.footer.title" : "Die Tabelle zeigt aktuell die ersten 150 Messungen an. Möchten Sie alle Messungen anzeigen? Dieser Vorgang kann je nach Anzahl der Datensätze einige Zeit in Anspruch nehmen.",
        "test-track-detail-samples.view.card.detaildata.footer.actions.showAll" : "Alle Messungen in Tabelle anzeigen"

        
    }
}
</i18n>