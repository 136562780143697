<template>
  <v-card flat tile>
      <v-subheader>{{$t('target-attribute-mapping-editor.component.sections.target.title')}}</v-subheader>
      <v-card-text>
        {{$t('target-attribute-mapping-editor.component.sections.target.subtitle')}}
      </v-card-text>
      <v-container fluid>
          <v-row dense>
              <v-col cols="12" >
                <v-text-field 
                    disabled 
                    filled
                    :label="$t('target-attribute-mapping-editor.component.fields.targetCollectionName')"
                    :value="targetCollectionSettings | localized-name($i18n.locale)"
                />
              </v-col>
              <v-col cols="12">
                <c-attribute-single-selector 
                    v-model="targetAttributeId" 
                    :items="targetAttributes" 
                    :return-object="false"
                    filled 
                    :label="$t('target-attribute-mapping-editor.component.fields.targetAttributeName')"
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field 
                    disabled 
                    filled
                    :label="$t('target-attribute-mapping-editor.component.fields.targetAttributeId')"
                    :value="targetAttributeId"
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field 
                    disabled 
                    filled
                    :label="$t('target-attribute-mapping-editor.component.fields.targetAttributeDataType')"
                    :value="targetAttributeDataType"
                />
              </v-col>
          </v-row>
      </v-container>
      <v-divider />
      <v-subheader>{{$t('target-attribute-mapping-editor.component.sections.mappingRules.title')}}</v-subheader>
      <v-card-text>
       {{$t('target-attribute-mapping-editor.component.sections.mappingRules.subtitle')}}
      </v-card-text>
      <v-card-text>
        <dxs-mapping-rule-expression-input
            v-model="defaultMappingRule" 
            :label="$t('target-attribute-mapping-editor.component.fields.defaultMappingRule')" 
            :hint="$t('target-attribute-mapping-editor.component.fields.defaultMappingRule.hint')" 
            persistent-hint
            :source="source"
            filled 
        />
      </v-card-text>
      <v-card-text>
          <v-expansion-panels>
              <v-expansion-panel>
                  <v-expansion-panel-header>
                      <div><v-icon color="accent" class="mr-2">help</v-icon>{{$t('target-attribute-mapping-editor.component.sections.mappingRules.writeRule')}}</div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                      
                      <v-card tile flat>
                        <v-card-text>
                              {{$t('target-attribute-mapping-editor.component.sections.mappingRules.information')}}
                        </v-card-text>
                        <dxs-mapping-rule-expression-help :value="{source, target, custData}" />
                      </v-card>
                  </v-expansion-panel-content>
              </v-expansion-panel>
          </v-expansion-panels>
      </v-card-text>

       <v-card-text>
            {{$t('target-attribute-mapping-editor.component.fields.optionalNotice')}}
      </v-card-text>

  </v-card>
</template>

<script>
import { get } from 'lodash';
import { set } from 'lodash';

import MappingRuleExpressionInputComponent from './mapping-rule-expression-input.component';
import MappingRuleExpressionHelpComponent from './mapping-rule-expression-help.component';
export default {

    name: 'target-attribute-mapping-editor',

    props: {

        value: {
            type: Object,
            required: true
        },
        
        source: {
            type: Object,
            required: true
        },

        target: {
            type: Object,
            required: true
        },

        custData: {
            type: Object,
            required: false
        }
        
    },

    components: {
        'dxs-mapping-rule-expression-input' : MappingRuleExpressionInputComponent,
        'dxs-mapping-rule-expression-help' : MappingRuleExpressionHelpComponent
    },



    computed: {
        targetAttributeId: {
            get() {
                return get(this.value, 'targetAttributeId', null);
            },
            
            set(value) {
                set(this.value, 'targetAttributeId', value);
            } 
        },

        targetAttributeDataType: {
            get() {
                return get (this.targetAttributes, this.targetAttributeId + '.datatype', null);
            }
        },

        defaultMappingRule: {
            get() {
                return get(this.value, 'defaultMappingRule', null);
            },
            
            set(value) {
                set(this.value, 'defaultMappingRule', value);
            }
        },

        targetCollectionSettings() {
            return get(this.target, 'collectionSettings', {});
        },

        targetAttributes() {

            let targetAttributes = get(this.target, 'schema.attributes', {});
            return targetAttributes;
        },


        sourceSchema() {
            return get(this.source, 'schema', {});
        },

        sourceAttributes() {
            return get(this.sourceSchema, 'attributes', {})
        },

        sourceAttributeList() {
            let sourceAttributes = [];
            for (let key in this.sourceAttributes) {
                sourceAttributes.push(this.sourceAttributes[key]);
            }

            return sourceAttributes;
        }
    }
}
</script>

<i18n>
{
    "en" : {
        "target-attribute-mapping-editor.component.sections.target.title" : "Target",
        "target-attribute-mapping-editor.component.sections.target.subtitle" : "Please specify the target attribute for this mapping. The values will be written into this field after the mapping has been processed.",
        "target-attribute-mapping-editor.component.sections.mappingRules.title" : "Mapping Rules",
        "target-attribute-mapping-editor.component.sections.mappingRules.subtitle" : "Mapping Rules specify how the source document should be mapped to the target attribute.",

        "target-attribute-mapping-editor.component.fields.targetCollectionName" : "Target Collection",
        "target-attribute-mapping-editor.component.fields.targetAttributeName" : "Target Attribute",
        "target-attribute-mapping-editor.component.fields.targetAttributeId" : "Target Technical ID",
        "target-attribute-mapping-editor.component.fields.targetAttributeDataType" : "Target Data Type",
        "target-attribute-mapping-editor.component.fields.defaultMappingRule" : "Default Mapping Rule",
         "target-attribute-mapping-editor.component.fields.defaultMappingRule.hint" : "The System uses the default mapping in order to set the value of the target field, when no specific conditions apply.",

        "target-attribute-mapping-editor.component.sections.mappingRules.writeRule" : "Write Mapping Rules",
        "target-attribute-mapping-editor.component.sections.mappingRules.information" : "Please select one of the topics below for detailed information",
        "target-attribute-mapping-editor.component.fields.optionalNotice" : "Optional: You can additionally define one or more mapping rules to handle special cases which deviate from the default mapping rule."

    },

    "de" : {
        "target-attribute-mapping-editor.component.sections.target.title" : "Ziel",
        "target-attribute-mapping-editor.component.sections.target.subtitle" : "Bitte legen Sie das Zielattribut für dieses Mapping fest. Die Werte werden nach Abschluss des Mappings in dieses Feld geschrieben.",
        "target-attribute-mapping-editor.component.sections.mappingRules.title" : "Mapping-Regeln",
        "target-attribute-mapping-editor.component.sections.mappingRules.subtitle" : "Mapping-Regeln definieren wie das System die Daten des Quell-Dokuments auf das Ziel-Attribut abbilden soll.",

        "target-attribute-mapping-editor.component.fields.targetCollectionName" : "Zieldatenbereich",
        "target-attribute-mapping-editor.component.fields.targetAttributeId" : "Technischer Bezeichner Zielattribut",
         "target-attribute-mapping-editor.component.fields.targetAttributeName" : "Zielattribute",
         "target-attribute-mapping-editor.component.fields.targetAttributeDataType" : "Datentyp Zielattribut",
         "target-attribute-mapping-editor.component.fields.defaultMappingRule" : "Standard-Mapping",
         "target-attribute-mapping-editor.component.fields.defaultMappingRule.hint" : "Das System verwendet das Standard-Mapping zur Befüllung des Zielfelds, wenn keine spezifischeren Regeln zutreffen.",

        "target-attribute-mapping-editor.component.sections.mappingRules.writeRule" : "Mapping-Regeln schreiben",
        "target-attribute-mapping-editor.component.sections.mappingRules.information" : "Wählen Sie bitte eines der untenstehenden Themen für detaillierte Informationen aus",
        "target-attribute-mapping-editor.component.fields.optionalNotice" : "Optional: Sie können zusätzlich eine oder mehrere Mapping-Regeln definieren, um Sonderfälle zu behandeln, die von der Standard-Mapping-Regel abweichen."

    }
}
</i18n>