<template>
  <v-progress-linear :value="progress" :color="color"></v-progress-linear>
</template>

<script>
export default {

    props: {
        value: {
            type: Object,
            required: true
        },

        ranges: {
            type: Object, 
            required: true
        }
    },

    computed : {

        items() {
            return this.value && this.value.items ? this.value.items : [];
        },

        

        progress() {
            let max = this.ranges && this.ranges.critical ? this.ranges.critical : 10;
            let count = this.items && this.items.length ? this. items.length : 0;
            return  (count/max) * 100;
        },

        color() {

            let count = this.items.length;

            if (this.ranges) {
                
                if (count < this.ranges.okay) return 'green';
                else if (count < this.ranges.warning) return 'yellow';
                else if (count < this.ranges.critical) return 'red';
                else return 'red';
            } else {
                return 'grey';
            }

            
            
            
        }
    }
}
</script>

<style>

</style>