<template>
    <v-container fluid grid-list-md :class="backgroundCssClass">
        <v-row>
            <v-col cols v-for="(lane, index) of lanes" :key="lane.name">
                <slot name="board-lane" v-bind:lane="lane" v-bind:index="index" > 
                    <gemue-board-lane :title="lane" :items="lane.items" :color="lane.color" :header-style="laneHeaderStyle" @item-click="handleItemClickEvent">
                        
                        <!-- Delegates rendering to the board component's board-lane-title slot -->
                        <template v-slot:board-lane-title>
                            <slot name="board-lane-title" v-bind:lane="lane" />
                        </template>

                        <template v-slot:board-lane-body-empty>
                            <slot name="board-lane-body-empty" v-bind:lane="lane" />
                        </template>

                        <template v-slot:board-lane-body>
                            <slot name="board-lane-body" v-bind:items="lane.items" v-bind:lane="lane" /> 
                        </template>  

                        <!-- Delegates rendering to the board component's board-lane-header slot -->
                        <template v-slot:board-lane-header>
                            <slot name="board-lane-header" v-bind:lane="lane" />
                        </template>

                        <!-- Delegates rendering to the board component's board-lane-footer slot -->
                        <template v-slot:board-lane-footer>
                            <slot name="board-lane-footer" v-bind:lane="lane" />
                        </template>

                    </gemue-board-lane>
                </slot>
            </v-col>            
        </v-row>
    </v-container>
</template>

<script>
import BoardLaneComponent from '@/cust/components/board/board-lane.component';


export default {

    name: 'board',

    props: {

        items: {
            type : [Object, Array],
            required: true        
        },

        color: {
            type : String,
            required: false
        },

        laneHeaderStyle: {
            type: String,
            required: false,
            default: 'default'
        }
    },

    components: {
        
        'gemue-board-lane': BoardLaneComponent,
    },

    methods : {
        handleItemClickEvent(event) {
            console.log(">>> " + JSON.stringify(event))
        }
    },

    computed: {

        lanes() {
            return this.items ? this.items : [];
        },

        backgroundCssClass() {
            return this.color ? this.color : 'grey lighten-2'
        }
    }
}
</script>

<style>

</style>