<template>
  <v-container fluid class="grey lighten-3">
    
    <template v-if="baseUrlEmpty">
        <v-row justify="center">
            <v-col cols="12" lg="9">
                <v-card color="error" >
                    <v-card-text>
                        <div class="text-h6 white--text">Customizing Error</div>
                        <div class="white--text">
                            Please ask your administrator to set the system parameter <strong>MembraneEvalBaseUrl</strong> to a valid URL in order to proceed.
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </template>
    <template v-else>
        <v-row justify="center">
            <v-col cols="12" lg="9">
                <v-form v-model="formValid">
                    <v-card class="ma-5">
                        <v-card-text>
                            <div class="text-h6">{{$t('membrane-evaluation.view.title')}}</div>
                        </v-card-text>
                        <v-divider />
                        <v-card-text>
                            {{ $t('membrane-evaluation.view.description')}}
                        </v-card-text>
                        <v-card-text>
                            <v-text-field
                                v-model="name"
                                filled 
                                counter="30"
                                :label="$t('membrane-evaluation.view.form.fields.fileName')"
                                :rules="[
                                v => v && !!v.trim()  || $t('membrane-evaluation.view.form.fields.fileName.validation.notEmpty'),
                                    v => v && v.length <= 30 || $t('membrane-evaluation.view.form.fields.fileName.validation.tooManyCharacters')
                                ]" 
                            />
                        </v-card-text>
                        <v-card-actions>
                            <v-btn @click="onOpen" depressed block color="accent" :disabled="!formValid">{{$t('membrane-evaluation.view.form.actions.openReport')}}</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-form>
            </v-col>
        </v-row>
        <template v-if="showBanner">
        <v-divider />
        <v-row justify="center">
            <v-col cols="12" lg="9">
                <v-banner  single-line color="accent" class="mt-5 ml-5 mr-5" icon="open_in_new" icon-color="white">
                    <span class="white--text">{{$t('membrane-evaluation.view.banner.title')}}</span>
                    <template v-slot:actions>
                        <v-btn text color="white" @click="showBanner = false">OK</v-btn>
                    </template>
                </v-banner>
            </v-col>
        </v-row>
        </template>
    </template>
  </v-container>
</template>

<script>
import MembraneEvaluationModule from '@/cust/store/modules/reports/membrane-evaluation.module';
import { isEmpty } from 'lodash';
const MODULE_ID = 'cust-membrane-evaluation';


export default {

    name: 'gemue-membrane-evaluation',

    inject: ['errorHandlerService', 'progressIndicatorService'],

    data: () => ({
        showBanner: false,
        formValid: true,
    }),

    computed: {

        name: {

            get() {
                return this.$store.getters[MODULE_ID + '/name'];
            },

            set(name) {
                this.$store.dispatch(MODULE_ID + "/applyName", { name });
            }
        },

        baseUrl: {
            
            get() {
                return this.$store.getters[MODULE_ID + '/baseUrl'];
            }
        },

        baseUrlEmpty: {

            get() {
                return isEmpty(this.baseUrl);
            }
        },

        url: {

            get() {
                return this.$store.getters[MODULE_ID + '/url'];
            }
        }
    },

    methods: {

        onOpen() {

            // Show the hint banner.
            this.showBanner = true;
            
            // Open up the url in a new tab.
            this.$log.debug('Invoking External URL: ' + this.url);
            if (window) {
                window.open(this.url, '_blank');
            }

            // Finally reset the name.
            this.name = null;
        },

        async initDialog() {

            // Load Masterdata from the backend.
            this.progressIndicatorService.show();
            try {
                await this.$store.dispatch(MODULE_ID + "/loadBaseUrlFromParams");
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        }

    },

    created() {
        
        // Dynamic VUEX Module registration
        if (!this.$store.hasModule(MODULE_ID)) {
            this.$store.registerModule(MODULE_ID, MembraneEvaluationModule);
        }

        // Initialize with data from the backend in order to allow parameterization of the base URL.
        this.initDialog();
    }
}
</script>

<i18n>
{
    "en" : {

        "membrane-evaluation.view.title" : "Membrane Evaluation Report",
        "membrane-evaluation.view.description" : "Enter a name in the text field below and click the Open Report button in order to open the  Report for Membrane Evaluation.",

        "membrane-evaluation.view.form.fields.fileName" : "Name",
        "membrane-evaluation.view.form.fields.fileName.validation.notEmpty" : "The field Name must not be empty. Please enter a valid name.",
        "membrane-evaluation.view.form.fields.fileName.validation.tooManyCharacters" : "The field Name must not contain more than 30 characters. Please choose a name with fewer characters.",
        "membrane-evaluation.view.form.actions.openReport" : "Open Report",

        "membrane-evaluation.view.banner.title" : "The report has been opened for you in a new browser tab."
    },

    "de" : {
        "membrane-evaluation.view.title" : "Bewertung Membrane",
        "membrane-evaluation.view.description" : "Geben Sie in das untenstehende Feld einen Namen ein und klicken Sie auf die Schaltfläche Report öffnen, um den Report für die Bewertung der Membranen anzuzeigen.",

        "membrane-evaluation.view.form.fields.fileName" : "Name",
        "membrane-evaluation.view.form.fields.fileName.validation.notEmpty" : "Das Feld Name darf nicht leer sein. Bitte geben Sie eine gültige Bezeichnung ein.",
        "membrane-evaluation.view.form.fields.fileName.validation.tooManyCharacters" : "Das Feld Name darf nicht mehr als 30 Zeichen enthalten. Bitte wählen Sie eine Bezeichnung mit weniger Zeichen.",
        "membrane-evaluation.view.form.actions.openReport" : "Report Öffnen",

        "membrane-evaluation.view.banner.title" : "Der Report wurde in einem neuen Tab für Sie geöffnet."
    }
}
</i18n>