import api from '@/store/api';
import _ from 'lodash';

export default {

    namespaced : true,

    state: {

        testBenches: [],
        tvIds: [],
        tvIdsFilter: [],

        items: [],

        meta: {},

        query: {

            filter: {

                filterQuery: '*',

                filterQueryLanguage: 'SIMPLE',

                facetFilters: [],

                rangeFilters: []
            },

            sort: {
                sortCriteria: []
            },

            pagination: {
                from: 0,
                size: 50,
                searchAfter: []
            },
        },

        viewSettings: {

            attributes: []
        },

        pagination: {
            from: 0,
            size: 50,
            searchAfter: []
        },

        facets: {},

        links: {},
    },

    mutations: {

        setPagination(state, pagination) {
            state.pagination = pagination;
        },
        
        setResponse(state, response) {

            const testBenchIds = _.groupBy(response.items, 'data.testBenchId');
            const testBenches = [];
            _.forEach(testBenchIds, (v, i) => {
                const testBench = {
                    testBenchId: i,
                    tvIds: _.map(v, 'data.TVID').filter(tvid => tvid),
                    statusOff: _.sumBy(v, 'data.statusOff'),
                    statusBreak: _.sumBy(v, 'data.statusBreak'),
                    statusRunning: _.sumBy(v, 'data.statusRunning'),
                    statusMalfunction: _.sumBy(v, 'data.statusMalfunction'),
                    statusMalfunctionAcknowledge: _.sumBy(v, 'data.statusMalfunctionAcknowledge'),
                    statusFinished: _.sumBy(v, 'data.statusFinished'),
                    modificationDateTime: _.maxBy(v, 'modificationDateTime').modificationDateTime,
                    numberOfTestTracks: _.sumBy(v, 'data.statusOff') + _.sumBy(v, 'data.statusBreak') + 
                        _.sumBy(v, 'data.statusRunning') + _.sumBy(v, 'data.statusMalfunction') + 
                        _.sumBy(v, 'data.statusMalfunctionAcknowledge') + _.sumBy(v, 'data.statusFinished'),
                    testBenchType: _.head(_.uniq(_.map(v, 'data.testBenchType')))
                }

                testBenches.push(testBench);

                // const testBench1 = {
                //     testBenchId: i + 1,
                //     tvIds: ["TV_hBuUJ1", "TV_GIERm1", "TV_bvRRX1", "TV_oRYNq1", "TV_AXvdu1", "TV_ZrCLl1", "TV_KXNsI1", "TV_aFSWf1", "TV_wRlSp1", "TV_mfcVM", "TV_SJxNV", "TV_zqKUs", "TV_gTPQi", "TV_HfsTM", "TV_PLjNS", "TV_aLjgA", "TV_rdeGL", "TV_RDOzY", "TV_XLYaJ", "TV_gNzhe", "TV_bgxpP", "TV_EuKFD", "TV_MHyrr", "TV_oczLH"],
                //     statusOff: _.sumBy(v, 'data.statusOff'),
                //     statusBreak: _.sumBy(v, 'data.statusBreak'),
                //     statusRunning: _.sumBy(v, 'data.statusRunning'),
                //     statusMalfunction: _.sumBy(v, 'data.statusMalfunction'),
                //     statusMalfunctionAcknowledge: _.sumBy(v, 'data.statusMalfunctionAcknowledge'),
                //     statusFinished: _.sumBy(v, 'data.statusFinished'),
                //     modificationDateTime: _.maxBy(v, 'modificationDateTime').modificationDateTime,
                //     numberOfTestTracks: _.sumBy(v, 'data.statusOff') + _.sumBy(v, 'data.statusBreak') + 
                //         _.sumBy(v, 'data.statusRunning') + _.sumBy(v, 'data.statusMalfunction') + 
                //         _.sumBy(v, 'data.statusMalfunctionAcknowledge') + _.sumBy(v, 'data.statusFinished')
                // }
                
                //testBenches.push(testBench1);
            });

            state.testBenches = testBenches;
            
            const tvIds = _.map(response.items, 'data.TVID').filter(tvid => tvid);
            state.tvIds = tvIds;

            state.items = response.items ? response.items : [];
            state.meta  = response.meta ? response.meta : {};
            
            state.facets     = response.facets ? response.facets : {};
            state.links      = response.links ? response.links : {};
            state.pagination = response.pagination ? response.pagination : {from: 0, size: 50, searchAfter: []};
            
            state.query.filter     = (response.query && response.query.filter) ? response.query.filter : { filterQuery: '*', filterQueryLanguage: 'SIMPLE', facetFilters: [], rangeFilters: [] };
            state.query.sort       = (response.query && response.query.sort) ? response.query.sort : { sortCriteria: [] },
            state.query.pagination = (response.query && response.query.pagination) ? response.query.pagination : { from: 0, size: 50, searchAfter: [] };

            // DXS-99: Reset the view settings if an empty response has been received.
            // It is necessary to do so in order to support the reusing of components by vue router.
            // Otherwise the view settings will not change when navigating.
            if (_.isEmpty(response)) { // Always remember: Empty objects are NOT falsy... thus use the lodash is empty method for a safe check.
                state.viewSettings.attributes = [];   
            }

            // If no explicit view settings have been set, 
            // compute them on the fly.
            

            if (_.isEmpty(state.viewSettings.attributes)) {
                _.forOwn(state.meta.attributes, attribute => state.viewSettings.attributes.push({
                    value: attribute,
                    display: !!_.get(attribute, 'clientSettings.table.display', true)
                }));
                

                state.viewSettings.attributes.sort( (first, second) => first.value.ordering - second.value.ordering);
            }

            

            
        },      

    },

    actions: {
        setPagination(context, pagination) {
            context.commit('setPagination', pagination);
        },

        async loadData(context, {collectionId}) {
            const query = context.getters.query;
            return api.collections.documents.findByQuery(collectionId, query).then(response => context.commit('setResponse', response));
        },
    },

    getters: {

        testBenches(state) {
            return state.testBenches;
        },

        tvIds(state) {
            return state.tvIds;
        },

        tvIdsFilter(state) {
            return state.tvIdsFilter;
        },

        query(state) {
            
            return { 
                filter: state.query.filter,
                sort: state.query.sort,
                pagination: state.pagination,
                queryTimeoutMiliseconds: 25 * 1000 // 25 seconds
            }
        },

        filter(state) {
            
            return state.query.filter;  
        },

        response(state) {

            return {
                items        : state.items,
                meta         : state.meta,
                filter       : state.query.filter,
                viewSettings : state.viewSettings,
                sort         : state.query.sort,
                pagination   : state.pagination,
                facets       : state.facets,
                links        : state.links
            }
        }
    }


}
