import dotenv from 'dotenv';
// import _ from 'lodash';

dotenv.config();

export default class KibanaDashboardFactory {
    static get CONFIG() {
        return {
            KIBANA_HOST: '$VUE_APP_GEMUE_KIBANA_HOST',
            KIBANA_PORT: '$VUE_APP_GEMUE_KIBANA_PORT',
            KIBANA_LT_DASHBOARD_ID: '$VUE_APP_GEMUE_KIBANA_LT_DASHBOARD_ID',
            KIBANA_HT_DASHBOARD_ID: '$VUE_APP_GEMUE_KIBANA_HT_DASHBOARD_ID',
            KIBANA_DASHBOARD_REFRESH_INTERVAL_MS: '$VUE_APP_GEMUE_KIBANA_DASHBOARD_REFRESH_INTERVAL_MS',
            KIBANA_DASHBOARD_TIME: '$VUE_APP_GEMUE_KIBANA_DASHBOARD_TIME'
        }
    }

    static get URL() {
        let host = this.value('KIBANA_HOST');
        let port = this.value('KIBANA_PORT');

        if (isNaN(port)) {
            throw Error('VUE_APP_KIBANA_PORT must be a number')
        }

        // remove trailing slashes
        host = host.replace(/^(.+?)\/*?$/, "$1");
        return `${host}:${port}`;
    }

    static getDashboard(testBenchId, testTrackId, testBenchType) {
        let dashboardId = this.value(`KIBANA_${testBenchType}_DASHBOARD_ID`);

        let refreshInterval = this.value('KIBANA_DASHBOARD_REFRESH_INTERVAL_MS');
        let time = this.value('KIBANA_DASHBOARD_TIME');

        if (isNaN(refreshInterval)) {
            throw Error('KIBANA_DASHBOARD_REFRESH_INTERVAL_MS must be a number')
        }
        
        const dasboardUrl = `${this.URL}/app/dashboards#/view/${dashboardId}?_g=(filters:!((query:(match_phrase:(data_testBenchId:'${testBenchId}'))),(query:(match_phrase:(data_testTrackId:'${testTrackId}')))),refreshInterval:(pause:!f,value:${refreshInterval}),time:(from:${time},to:now))`;
        return dasboardUrl;
    }

    static value(name) {
        if (!(name in this.CONFIG)) {
            console.log(`Configuration: There is no key named "${name}"`)
            return
        }

        const value = this.CONFIG[name]

        if (!value) {
            console.log(`Configuration: Value for "${name}" is not defined`)
            return
        }

        if (value.startsWith('$VUE_APP_')) {
            // value was not replaced, it seems we are in development.
            // Remove $ and get current value from process.env
            const envName = value.substr(1)
            const envValue = process.env[envName]
            if (envValue) {
                return envValue
            } else {
                console.log(`Configuration: Environment variable "${envName}" is not defined`)
            }
        } else {
            // value was already replaced, it seems we are in production.
            return value
        }
    }
}