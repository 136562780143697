import api from '@/store/api';
import {get, set} from 'lodash';

import {QUERY_PAGE_SIZE, QUERY_TIMEOUT_MS} from '@/cust/constants/test-track-detail-samples.constants';

//const MAX_ITEM_COUNT = 100000;

export default {

    namespaced : true,

    state: {

        collectionId: null,
        
        response: {
            collectionSettings: {},
            schema: {},
            items: {},
            pagination: {}
        },
        

        filter: {
            filterStartDateTime: null,
            filterEndDateTime: null,
            testBenchId: null,
            testTrackId: null,

            filterQuery: null,
        },

        measures: [],

        sort: []
    },

    mutations: {

        setCollectionId(state, collectionId) {
            state.collectionId = collectionId;
        },

        setFilter(state, filter) {
            state.filter = filter;
        },

        setSort(state, sort) {
            state.sort = sort;
        },

        setResponse(state, response) {
            
            set(state, 'response.collectionSettings', get(response, 'collectionSettings', {}));
            set(state, 'response.schema', get(response, 'meta', {}));
            set(state, 'response.items', get(response, 'items', []));
            set(state, 'response.pagination', get(response, 'pagination', {}));
        },

        appendResponse(state, response) {
            
            const newItems = get(response, 'items', []);
            const currentItems = get(state, 'response.items', []);
            currentItems.push(...newItems);

            set(state, 'response.items', currentItems);
            set(state, 'response.pagination', get(response, 'pagination', {}));
        }
    },

    actions: {

        /**
         * Must be called on initialization in order to set all required information.
         * @param {*} context 
         * @param {*} param1 
         */
        async init(context, { collectionId, filter, sort }) {

            context.commit('setCollectionId', collectionId);
            context.commit('setFilter', filter);
            context.commit('setSort', sort);

            // Clear the response in order to get rid of leftovers from previous invocations.
            context.commit('setResponse', {});
        },

        /**
         * Updates filter only.
         * @param {*} context 
         * @param {*} filter 
         */
        async updateFilter(context, filter) {
            context.commit('setFilter', filter);
        },

        /**
         * Updates sort only.
         * @param {*} context 
         * @param {*} sort 
         */
        async updateSort(context, sort) {
            context.commit('setSort', sort);
        }, 

        async loadData(context) {

            // Reset Response before loading new data.
            context.commit('setResponse', {}); 

            // =================================================================
            // Initial query
            // =================================================================
            const query = {
                filter: context.getters.filter, 
                sort: context.getters.sort,
                pagination: {
                    from : 0,
                    searchAfter : [], 
                    size : QUERY_PAGE_SIZE
                },
                queryTimeoutMiliseconds: QUERY_TIMEOUT_MS
            }

            let response = await api.collections.documents.findByQuery(context.getters.collectionId, query);

            context.commit('setResponse', response);
            
            // Return the number of items returned by the query
            return get(response, 'items.length', 0);
        },

        async loadMoreData(context) {
            // Subsequent queries will only append items
            const query = {
                filter: context.getters.filter, 
                sort: context.getters.sort,
                pagination: context.getters.pagination,
                queryTimeoutMiliseconds: QUERY_TIMEOUT_MS
            }

            let response = await api.collections.documents.findByQuery(context.getters.collectionId, query);

            context.commit('appendResponse', response);
            
            // Return the number of items returned by the query
            return get(response, 'items.length', 0);
        },

        /**
         * Send command for asynchronous CSV exporting.
         * 
         * @param {*} context 
         * @param {*} param1 
         * @returns 
         */
        async exportDocuments(context, { collectionId, format='CSV', charset='windows-1252' }) {
            
            const request = {
                filter: context.getters.filter,
                sort: context.getters.sort,
                format: format,
                charset: charset
            }

            return api.collections.documents.exportDocument(collectionId, request).then(() => {});
        }

        
    },

    getters: {

        collectionId(state) {
            return state.collectionId;
        },

        model(state) {
            return {
                collectionSettings: get(state, 'response.collectionSettings', {}),
                schema: get(state, 'response.schema', {}),
                items: get(state, 'response.items', []),
                pagination: get(state, 'response.pagination', {})
            }
        },

        filter(state) {

            // Date range and test bench/test track context is directly applied to facet and range filters.
            const filter = {
                filterQuery : get(state, 'filter.filterQuery', '*'),
                filterQueryLanguage : 'SIMPLE',
                facetFilters  : [
                    {name: 'testBenchId', filterQuery: get(state ,'filter.testBenchId'), filterMode: 'CONTAINS', values: []},
                    {name: 'testTrackId', filterQuery: get(state ,'filter.testTrackId'), filterMode: 'CONTAINS', values: []}
                ],
                rangeFilters : [{ 
                    type: 'TEMPORAL', 
                    name: 'sampleDateTime',
                    filterMode: 'CONTAINS',
                    from: get(state, 'filter.filterStartDateTime'),
                    until: get(state, 'filter.filterEndDateTime') 
                }]
            }

            return filter;
        },

        sort(state) {

            const sort = {
                sortCriteria: get(state, 'sort', [])
            }

            return sort;
        },

        pagination(state) {
            
            const pagination = {
                from : 0,
                searchAfter : get(state, 'response.pagination.searchAfter', []), 
                size : QUERY_PAGE_SIZE
            };

            return pagination;
        }

    }
}