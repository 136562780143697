<template>
  <div class="echarts" ref="chartContainer" />
</template>


<script>
import * as echarts from "echarts";
import _ from 'lodash';

export default {
  props: {
    value: {
      required: true,
      type: Object,
    },

    options: {
      required: false,
      type: Object,
    },
  },

  data: () => ({
    eChart: null,
    eChartOptions: null,
    dateTimes: [],
    items: [],
  }),

  watch: {
    'value': 'createChartData',
    'locale': 'createChartData'
  },

  methods: {
    createChartData() {
      const dateTimes = [];
      const items = [];
      this.value.data.result.forEach((result) => {
        dateTimes.push(result.dateTime);
        result.values.forEach((value) => {
          const item = items.find((_item) => _item[1] === value.key.attribute && _item[0] === value.key.operation);
          const _value = this.$core.filters.numericFormat(value.value, '#,###', this.$i18n.locale);
          const _roundedValue = value.roundedValue;
          const requestMeasure = this.value.request.timeSeries.measures.find(measure => measure.attribute === value.key.attribute && measure.operation === value.key.operation);
          if (item) {
            item[3].push(_value);
            item[4].push(_roundedValue);
          } else {
            items.push([
              value.key.operation,
              value.key.attribute,
              requestMeasure.customData.color,
              [_value],
              [_roundedValue],
            ]);
          }
        });
      });

      this.dateTimes = dateTimes;
      this.items = items;

      this.setEChartOptions();
    },

    setEChartOptions() {
      this.eChartOptions = {
        title: {
          text: this.getOption('title.text') || "",
        },
        tooltip: {
          trigger: this.getOption('tooltip.trigger') || "axis",
          showContent: this.getOption('tooltip.showContent') || true,
        },
        legend: {
          data: this.items.map(item => this.getSeriesName(item)),
          orient: this.getOption('legend.orient') || "horizontal",
          center: this.getOption('legend.center') || 0,
          bottom: this.getOption('legend.bottom') || 0,
          zlevel: 1
        },
        
        grid: {
          left: this.getOption('grid.left') || 5,
          right: this.getOption('grid.right') || 5,
          bottom: this.getOption('grid.bottom') || 50,
          containLabel: this.getOption('grid.containLabel') || true,
          tooltip: {
            axisPointer: {
              label: {
                formatter: this.getOption('tooltip.axisPointer.label.formatter') || ((value) => {
                  return this.$core.filters.dateTimeFormat(value.value, 'dd.MM.yy HH:mm:ss', this.$i18n.locale);
                })
              }
            }
          }
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: this.dateTimes,
          axisLabel: {
            formatter: this.getOption('xAxis.axisLabel.formatter') || ((value) => {
              return this.$core.filters.dateTimeFormat(value, 'dd.MM.yy HH:mm:ss', this.$i18n.locale);
            })
          }
        },
        yAxis: {
          type: "value",
          axisLabel: {
            formatter: this.getOption('yAxis.axisLabel.formatter') || ((value) => {
              return this.$core.filters.numericFormat(value, '#,###', this.$i18n.locale)
            })
          }
        },
        dataZoom: [
          {
            type: this.getOption('dataZoom.type') || "inside",

          },
          {
            top: 0,
          },
        ],
        series: this.items.map(item => ({
            id: `${item[1]}_${item[0]}`,
            name: this.getSeriesName(item),
            type: "line",
            color: item[2],
            data: item[4],
            roundedValueData: item[3]
        }))
      };

      
      this.eChart.setOption(this.eChartOptions, true, true);
    },

    getOption(path) {
      return _.get(this.options, path, undefined);
    },

    getSeriesName(item) {
      const seriesName = `${this.$core.filters.localizedName(this.value.schema.attributes[item[1]], this.$i18n.locale)} ${this.getAggregation(item[0])}`;
      return seriesName.trim();
    },

    getAggregation(operation) {
      const aggregation = this.aggregations.find(aggregation => aggregation.name === operation);
      return aggregation ? this.$core.filters.localizedName(aggregation, this.$i18n.locale) : '';
    }
  },

  computed: {
    locale() {
      return this.$i18n.locale;
    },

    aggregations() {
      return _.get(this.value, 'aggregations', []);
    }
  },

  mounted() {
    const chart = this.$refs.chartContainer;
    this.eChart = echarts.init(chart);
    window.onresize = () => this.eChart.resize();

    this.createChartData();
  },

  beforeDestroy() {
    window.onresize = null;
    this.eChart.dispose();
  }
};
</script>

<style>
.echarts {
  width: 100%;
  height: 400px;
}
</style>