<template>
    <c-item-editor-page :title="$t('collection-access-editor.component.title')" :subtitle="$t('collection-access-editor.component.subtitle')" class="mb-5" flat>

        <v-card-text>
            <dxs-warning-message v-if="collectionAccessRulesEmpty" class="mt-3 mb-3" >
                {{$t('collection-access-editor-page.component.sections.collectionAccess.warning')}}
            </dxs-warning-message>
        </v-card-text>

        <v-card-text>
            <div class="text-subtitle-1 font-weight-medium pb-3">{{$t('collection-access-editor.component.sections.collectionAccessRules.title')}}</div>
            <div>{{$t('collection-access-editor.component.sections.collectionAccessRules.description')}}</div>
        </v-card-text>

        <v-card-text>
            <dxs-collection-access-rule-editor 
                v-model="collectionAccessRules" 
                header-style="lite" 
                flat 
                :collection-settings="collectionSettings" />
        </v-card-text>

    </c-item-editor-page>
</template>

<script>
import { get, set, cloneDeep, isEmpty } from 'lodash';
import CollectionAccessRuleEditorComponent from './collection-access-rule-editor.component';
import WarningMessageComponent from '@/components/messages/warning-message.component';


export default {

    name: 'collection-access-editor-page',
    

    
    props: {

        value: {
            type: Object,
            required: true,
        }
    },

    components: {
        'dxs-warning-message' : WarningMessageComponent,
        'dxs-collection-access-rule-editor' : CollectionAccessRuleEditorComponent
    },

    data() {

        return {

            // Perform a deep clone in order to avoid unintentional changes due to call by reference.
            model : cloneDeep(this.value)
        }
    },

    watch: {
        /**
         * Occassionally the response from the backend will not arrive fast enough, thus resulting
         * the model to have only default values. By adding this watcher, we can react properly to 
         * updates properties.
         */
        value(newValue) {
            this.model = cloneDeep(newValue);
        }
    },

    methods: {

        fireInput() {
            this.$emit('input', cloneDeep(this.model));
        }

    },

    computed: {

        collectionAccessRules: {
            
            get() {

                console.log("Trigger get")
                return get(this.model, 'accessControlList.collectionAccess.collectionAccessRules', []);
            },

            set(value) {

                console.log("Trigger set " + JSON.stringify(value))
                set(this.model, 'accessControlList.collectionAccess.collectionAccessRules', value);
                this.fireInput();
            }
        },

        collectionAccessRulesEmpty() {
            return isEmpty(this.collectionAccessRules);
        },

        collectionSettings: {
            get() {
                return get(this.model, 'collectionSettings');
            },
        }
    },


}
</script>

<i18n>
{
    "en" : {
        "collection-access-editor.component.title" : "Collections",
        "collection-access-editor.component.subtitle" : "This page contains options for access control to collections.",

        "collection-access-editor.component.sections.collectionAccessRules.title" : "Collection Access Rules",
        "collection-access-editor.component.sections.collectionAccessRules.description" : "By means of collection access rules, you define which permissions the system grants to a user when accessing a collection.",

        "collection-access-editor-page.component.sections.collectionAccess.warning" : "This access control list does not contain rules for collection access. Please define rules in order to grant users access to collections."
    
    },

    "de" : {
        "collection-access-editor.component.title" : "Datenbereiche",
        "collection-access-editor.component.subtitle" : "Diese Seite enthält Optionen zur Steuerung des Zugriffs auf Datenbereiche.",
        
        "collection-access-editor.component.sections.collectionAccessRules.title" : "Zugriffsregeln für Datenbereiche",
        "collection-access-editor.component.sections.collectionAccessRules.description" : "Durch Zugriffsregeln legen Sie fest, welche Berechtigungen das System einem Anwender beim Zugriff auf einen Datenbereich gewährt.",

        "collection-access-editor-page.component.sections.collectionAccess.warning" : "Diese Konfiguration enthält keine Zugriffsregeln für Datenbereiche. Bitte hinterlegen Sie entsprechende Regeln, um Anwendern  den Zugriff auf Datenbereiche zu erlauben."
    }
}
</i18n>